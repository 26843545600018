import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import { connect } from 'react-redux';

import { getAlbumStatisticsForPreflight } from '../../selectors/legacy';
import { AlbumStatisticsForPreflightShape } from '../shapes';

const ExportPreflight = ({
  albumStatistics: { inlaySpreadsCount, spreadsNeeded, spine },
}) => (
  <ListGroup className="stats text-muted" variant="flush">
    <ListGroup.Item>
      <b>
        Seiten (ohne Umschlag):{' '}
        <span className="text-muted">
          {inlaySpreadsCount} Doppel, {inlaySpreadsCount * 2} Einzel
        </span>
      </b>
    </ListGroup.Item>

    <ListGroup.Item variant={spreadsNeeded ? 'danger' : null}>
      <b>
        Fehlende Doppelseiten für Druck:{' '}
        <span className="qa-spreads-needed">{spreadsNeeded}</span>
      </b>
    </ListGroup.Item>

    <ListGroup.Item>
      <b>
        Rückenstärke: <span className="text-muted">{spine}mm</span>
      </b>
    </ListGroup.Item>
  </ListGroup>
);

ExportPreflight.propTypes = {
  albumStatistics: AlbumStatisticsForPreflightShape.isRequired,
};

const mapStateToProps = state => ({
  albumStatistics: getAlbumStatisticsForPreflight(state),
});

export default connect(mapStateToProps)(ExportPreflight);
