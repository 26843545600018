/**
 * Helper function to find a 1D position in a 2D array.
 *
 * Example:
 *  const arr = [['a', 'b', 'c'], ['d', 'e', 'f'], ['g', 'h', 'i']];
 *  const positionIn1D = 4;
 *
 *  > convertPositionIn1DTo2D(arr, positionIn1D);
 *  [1, 1] // => 'e'
 */
export const convertPositionIn1DTo2D = (arr, positionIn1D) => {
  let counter = 0;
  let indexIn2D = null;

  for (let i = 0; i < arr.length; i += 1) {
    for (let j = 0; j < arr[i].length; j += 1) {
      if (counter === positionIn1D) {
        indexIn2D = [i, j];
      }

      counter += 1;
    }
  }

  return indexIn2D;
};

// Parses the `cssText` rotation value of the current draft style block
export function parseBlockRotation(draftStyleBlock) {
  const { cssText } = draftStyleBlock.closest('.text').style;
  const rotateStr = cssText.match(/(?<=rotate\()-?[0-9]+\.[0-9]+/)?.[0];

  return parseFloat(rotateStr || 0);
}

/**
 * Loops over an array of DOM rects and compares their max `bottom` value
 * against another DOM rect's center point. If the latter is greater, the
 * rect is on a new line.
 */
export function getRectIsOnNewLine(rect, rectsOnLine) {
  const maxBottom = Math.max(
    ...rectsOnLine.map(rectOnLine => rectOnLine.bottom)
  );

  return rect.top + rect.height / 2 > maxBottom;
}

/**
 * Takes a draft.js DOM node as input, loops through its spans and
 * manually adds HTML line breaks (`<br>`) to the text node's HTML wherever
 * the client would break the line. Initial approach found here:
 * https://www.bennadel.com/blog/4310-detecting-rendered-line-breaks-in-a-text-node-in-javascript.htm
 */
export function addLineBreaks(draftStyleBlock) {
  /**
   * Each draft style block has at least one child span with a `data-offset-key` that defines the
   * inline style. The actual `data-text` spans are nested within.
   */
  const childSpans = [
    ...draftStyleBlock.querySelectorAll('span[data-offset-key*="0-"'),
  ];
  const textNodes = childSpans
    .map(childSpan => childSpan.querySelector('span[data-text="true"]'))
    .filter(Boolean);

  // Not every draft style block has text content (might be a `br`), we skip those
  if (textNodes.length === 0) {
    return;
  }

  // Overwrites the original rotation by its negative value to reverse it, will later be reset
  const rotate = parseBlockRotation(draftStyleBlock);
  draftStyleBlock.style.rotate = `${-rotate}deg`; // eslint-disable-line no-param-reassign

  const ranges = [];

  textNodes.forEach(textNode => {
    /**
     * We create a `Range` for every `textNode` in the current draft style block,
     * add text characters to it, and determine where lines break in the browser
     * using the range's bounding boxes.
     */
    const range = document.createRange();

    const { textContent } = textNode.firstChild;
    const lines = [];
    let lineCharacters = [];

    for (let i = 0; i < textContent.length; i += 1) {
      range.setStart(textNode.firstChild, 0);
      range.setEnd(textNode.firstChild, i + 1);

      // The number of range client rects determines what line we're on within the text node
      const lineIndex = range.getClientRects().length - 1;

      if (!lines[lineIndex]) {
        lineCharacters = [];
        lines.push(lineCharacters);
      }

      lineCharacters.push(textContent.charAt(i));
    }

    ranges.push({ lines, rects: range.getClientRects() });
  });

  /**
   * Each element in `ranges` has
   * 1) a `lines` array of arrays, each array mapping to a line in the browser
   * 2) a `rects` array of client rects for each of those lines
   *
   * `ranges.lines.length === ranges.rects.length` is always true.
   *
   * We merge each line array with its corresponding client rects array.
   */
  const linesWithClientRects = ranges.map(({ lines, rects }) => {
    return lines.map((line, idx) => [line, rects[idx]]);
  });

  /**
   * Each line has their own `clientRect` (via `Range.getClientRects()`). To merge
   * lines across text nodes (multiple spans on the same line), we check whether a given
   * span's center point is smaller than the max `bottom` within that line.
   */
  const newLines = [];
  const lineStartIndexes = [];
  let lineRects = [];
  let charCounter = 0;

  linesWithClientRects.forEach(lines => {
    lines.forEach(currLine => {
      const [line, rect] = currLine;

      const isFirstLine = charCounter === 0;
      const isOnNewLine = getRectIsOnNewLine(rect, lineRects);

      if (isFirstLine || isOnNewLine) {
        newLines.push(line);
        lineStartIndexes.push(charCounter);
        lineRects = [rect];
      } else {
        newLines[newLines.length - 1].push(...line);
        lineRects.push(rect);
      }

      charCounter += line.length;
    });
  });

  /**
   * Each span in our block might have its own inline style. We store the index of
   * the first character of the span (relative to the whole `div.textContent`)
   * along with its `style.cssText` to later apply the styles within lines + across line
   * breaks.
   */
  let start = 0;
  const inlineStyles = childSpans.map(span => {
    const { cssText } = span.style;
    const inlineStyle = { start, cssText };

    // Next span starts where this one ends
    start += span.textContent.length;

    return inlineStyle;
  });

  /**
   * We will eventually wrap each line in a div, so spans starting on one line and
   * closing on another will be wrapped in multiple divs. Hence, we need to
   * 1) close a given span at the end of the line and
   * 2) open another one with the same style on the next line
   *
   * To do this, we enrich the `inlineStyles` array with the indexes of characters
   * starting a new line, sort them and "look up" the `cssText` prop of the previous span.
   */
  lineStartIndexes.forEach(idx => {
    if (idx > 0) {
      inlineStyles.push({ start: idx, isFirstChar: true });
    }
  });

  inlineStyles
    .sort(({ start: a }, { start: b }) => a - b) // Make sure the array is sorted by `start` index
    .forEach((style, idx) => {
      if (style.cssText === undefined && inlineStyles.length > 1) {
        // eslint-disable-next-line no-param-reassign
        style.cssText = inlineStyles[idx - 1].cssText;
      }
    });

  /**
   * We know the start indexes of each span relative to the `div.textContent`.
   * These 1D positions need to be converted to 2D positions in the `lines` array.
   *
   * This works because the joined text content of all `childSpans` has the same length
   * as the joined `lines` array.
   */
  inlineStyles.forEach(({ start: startIdx, cssText, isFirstChar }, idx) => {
    const [x, y] = convertPositionIn1DTo2D(newLines, startIdx);
    inlineStyles[idx] = { ...inlineStyles[idx], line: x, char: y };

    // For all but the first span, we need to close the previous span before opening a new one
    newLines[x][y] = `${
      !isFirstChar && startIdx > 0 ? '</span>' : ''
    }<span style='${cssText}'>${newLines[x][y]}`;
  });

  /**
   * Make sure each span is closed at the end of the line, EOL chars are removed, and a manual
   * line break is added.
   */
  newLines.forEach(line => {
    // eslint-disable-next-line no-param-reassign, no-return-assign
    line[line.length - 1] = `${line[line.length - 1].trimEnd()}</span><br>`;
  });

  /**
   * The current block computes its `text-align` value via its closest
   * `.draft-block-wrapper` parent. We read it here to apply it to the divs we
   * are wrapping the text in.
   */
  const { textAlign } = draftStyleBlock.closest('.draft-block-wrapper').style;

  /**
   * At this point, `newLines` contains
   * 1) arrays of 1-character strings for each line (including inline styles)
   * 2) an empty string array for every newline character
   *
   * We only care about 1) and filter out 2) by checking for empty string arrays before creating a new
   * `innerHTML` string, applying the proper `text-align` values deduced above.
   */
  const isEmptyStringArray = arr => arr.length === 1 && arr[0] === ' ';
  const filteredLines = newLines
    .filter(line => !isEmptyStringArray(line))
    .map(line => line.join('').replace('\n', '')); // We already deduced breaks from the range client rects

  const innerHTML = filteredLines.map((line, idx) => {
    const whiteSpaceRule = `white-space: ${
      textAlign === 'justify' ? 'nowrap' : 'pre'
    }`;
    const textAlignRule =
      idx === filteredLines.length - 1 && textAlign === 'justify'
        ? 'text-align-last: left'
        : '';

    return `<div style='${whiteSpaceRule};${textAlignRule};'>${line}</div>`;
  });

  const blockStyle = `text-align: ${textAlign}; text-align-last: ${textAlign};`;

  // eslint-disable-next-line no-param-reassign
  draftStyleBlock.innerHTML = `<div class="parsed-block" style='${blockStyle}'>${innerHTML.join(
    ''
  )}</div>`;

  // We overwrote the rotation above, setting it to 0 here again so the original value is re-applied
  draftStyleBlock.style.rotate = '0deg'; // eslint-disable-line no-param-reassign
}

export default addLineBreaks;
