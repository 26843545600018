import React, { useMemo, useRef } from 'react';
import { string } from 'prop-types';
import { Flipper, Flipped } from 'react-flip-toolkit';
import { useSelector } from 'react-redux';
import partition from 'lodash/partition';
import Card from 'react-bootstrap/Card';

import SidebarSticker from '../SidebarSticker';
import {
  makeGetStickersForSection,
  getPlacedStickerNumbers,
} from '../../../selectors/stickers';
import { makeStickerMatchesFilter } from '../SectionPanel/util';
import useFadeIn from '../../../hooks/useFadeIn';
import ButtonWithTooltip from '../../menu/ButtonWithTooltip';
import useTutorial from '../../../hooks/useTutorial';

function StickersList({ sectionId, filter }) {
  const domRef = useRef();
  const { style } = useFadeIn(domRef);
  const { linkTo } = useTutorial();

  const getStickersForSection = useMemo(makeGetStickersForSection, []);
  const stickers = useSelector(state =>
    getStickersForSection(state, sectionId)
  );

  const placedStickerNumbers = useSelector(getPlacedStickerNumbers);
  const placedStickerIds = Object.keys(placedStickerNumbers);

  const stickerMatchesFilter = makeStickerMatchesFilter(filter);

  const [placedStickers, unplacedStickers] = partition(stickers, sticker =>
    placedStickerIds.includes(sticker.id)
  ).map(part => part.filter(stickerMatchesFilter));

  const orderedPlacedStickers = placedStickers.sort(
    (stickerA, stickerB) =>
      placedStickerNumbers[stickerA.id] - placedStickerNumbers[stickerB.id]
  );

  const flipKey = [...orderedPlacedStickers, ...unplacedStickers]
    .map(({ id }) => id)
    .join('');

  const newSticker = useMemo(
    () => ({ id: `section-${sectionId}-new-sticker`, number: 0 }),
    [sectionId]
  );

  return (
    <div className="mb-2 pb-4" ref={domRef} style={style}>
      <Flipper flipKey={flipKey} className="qa-stickers-list-flipper">
        <Card bg="light" text="dark" className="mb-2">
          <Card.Header className="d-flex bg-light text-muted align-items-center justify-content-between unplaced-stickers">
            Unplatzierte Sticker
            <ButtonWithTooltip
              href={linkTo('stickerPlacement')}
              target="_BLANK"
              icon="help"
              tooltip="Sticker platzieren"
              className="sticker-placement-help bg-transparent border-0"
            />
          </Card.Header>
          <Card.Body className="p-2">
            <div className="d-flex flex-wrap qa-stickers-unplaced">
              {unplacedStickers.map((sticker, index) => {
                return (
                  <Flipped key={sticker.id} flipId={sticker.id} spring="gentle">
                    {flippedProps => (
                      <SidebarSticker
                        sectionId={sectionId}
                        index={index}
                        key={sticker.id}
                        id={sticker.id}
                        sticker={sticker}
                        {...flippedProps}
                      />
                    )}
                  </Flipped>
                );
              })}
              <SidebarSticker
                index={stickers.length}
                sectionId={sectionId}
                placeholder
                sticker={newSticker}
              />
            </div>
          </Card.Body>
        </Card>
        <div
          className="d-flex flex-wrap qa-stickers-placed"
          style={{ opacity: 0.5 }}
        >
          {placedStickers.map((sticker, index) => {
            return (
              <Flipped key={sticker.id} flipId={sticker.id} spring="gentle">
                {flippedProps => (
                  <SidebarSticker
                    sectionId={sectionId}
                    index={index}
                    key={sticker.id}
                    id={sticker.id}
                    sticker={sticker}
                    stickerNumber={placedStickerNumbers[sticker.id]}
                    {...flippedProps}
                  />
                )}
              </Flipped>
            );
          })}
        </div>
      </Flipper>
    </div>
  );
}

StickersList.defaultProps = {
  filter: '',
};

StickersList.propTypes = {
  sectionId: string.isRequired,
  filter: string,
};

export default StickersList;
