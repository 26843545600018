import { createSelector } from 'reselect';

import { dimensions } from '../constants';
import { getAxisAlignedBounds } from '../util/geometry';
import {
  getSelectedElementIds,
  getSpreadsCount,
  getTargetSpreadIndex,
  getTargetNode,
  getWorkspace,
  createMemoizedSelectorWithDeepComparison,
  getTargetSpreadId,
  getIsolation,
  getAlbumStatisticsForPreflight,
} from './legacy';
import { getSelectInside } from './selection';

const { pageWidth, pageHeight, pageBleed } = dimensions;

const innerVerticalGuides = [
  {
    id: 'x0',
    type: 'spread',
    dimension: 'x',
    value: -pageBleed,
  },
  { id: 'x1', type: 'spread', dimension: 'x', value: pageWidth / 2 },
  {
    id: 'x2',
    type: 'spread',
    dimension: 'x',
    value: pageWidth,
  },
  { id: 'x3', type: 'spread', dimension: 'x', value: pageWidth * 1.5 },
  {
    id: 'x4',
    type: 'spread',
    dimension: 'x',
    value: pageWidth * 2 + pageBleed,
  },
];

const horizontalGuides = [
  { id: 'y0', type: 'spread', dimension: 'y', value: -pageBleed },
  { id: 'y1', type: 'spread', dimension: 'y', value: pageHeight / 2 },
  {
    id: 'y2',
    type: 'spread',
    dimension: 'y',
    value: pageHeight + pageBleed,
  },
];

const getSpreadGuides = createSelector(
  [getTargetSpreadIndex, getSpreadsCount, getAlbumStatisticsForPreflight],
  (spreadIndex, spreadsCount, { spine }) => {
    const frontCover = spreadIndex === 0;
    const backCover = spreadIndex === spreadsCount - 1;
    const cover = frontCover || backCover;
    const coverGuides = [
      {
        id: 'x0',
        type: 'spread',
        dimension: 'x',
        value: frontCover ? -spine : -pageBleed,
      },
      {
        id: 'x1',
        type: 'spread',
        dimension: 'x',
        value: pageWidth / 2,
      },
      {
        id: 'x2',
        type: 'spread',
        dimension: 'x',
        value: pageWidth + pageBleed,
      },
    ];
    return [
      ...horizontalGuides,
      ...(cover ? coverGuides : innerVerticalGuides),
    ];
  }
);

const rectToGuides = (id, { x, y, width, height }) => [
  { id: `${id}-xMin`, type: 'element', dimension: 'x', value: x },
  { id: `${id}-xMax`, type: 'element', dimension: 'x', value: x + width / 2 },
  { id: `${id}-xMid`, type: 'element', dimension: 'x', value: x + width },
  { id: `${id}-yMid`, type: 'element', dimension: 'y', value: y },
  { id: `${id}-yMin`, type: 'element', dimension: 'y', value: y + height / 2 },
  { id: `${id}-yMax`, type: 'element', dimension: 'y', value: y + height },
];

const getRelevantElements = createMemoizedSelectorWithDeepComparison(
  [getSelectedElementIds, getWorkspace, getTargetNode],
  (selectedElementIds, workspace, targetNode) => {
    const { nodes } = workspace;
    const { children } = targetNode;
    const relevantIds = children.filter(id => !selectedElementIds.includes(id));
    return relevantIds.map(id => nodes[id]);
  }
);

const getElementGuides = createSelector(
  [getTargetSpreadId, getRelevantElements],
  (spreadId, relevantElements) => {
    const target = `[data-id='${spreadId}']`;
    if (!document.querySelector(target)) {
      return [];
    }
    return relevantElements.flatMap(element => {
      const bounds = getAxisAlignedBounds([element], target);
      if (!bounds) {
        return [];
      }
      return rectToGuides(element.props.id, bounds);
    });
  }
);

export const getGuides = createSelector(
  [getElementGuides, getSpreadGuides, getSelectInside, getIsolation],
  (elementGuides, spreadGuides, selectInside, isolation) =>
    selectInside || isolation ? [] : [...elementGuides, ...spreadGuides]
);

const identity = param => param;

export const getActiveGuideIds = createMemoizedSelectorWithDeepComparison(
  [state => state.smartGuides.activeGuideIds],
  identity
);

export const getActiveGuides = createSelector(
  [getGuides, getActiveGuideIds],
  (guides, activeIds) => guides.filter(({ id }) => activeIds.includes(id))
);
