import { bool } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getActiveGuides } from '../../../selectors/smartGuides';
import SmartGuide from './SmartGuide';

function SmartGuides({ isCover }) {
  const activeGuides = useSelector(getActiveGuides);
  return activeGuides.map(props => (
    <SmartGuide {...props} key={props.id} isCover={isCover} />
  ));
}

SmartGuides.propTypes = {
  isCover: bool.isRequired,
};

export default SmartGuides;
