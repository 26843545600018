import React from 'react';
import { number } from 'prop-types';
import { useSelector } from 'react-redux';

import { clockwiseCornerPivotNames } from './pivots';
import ScaleHandle from './ScaleHandle';
import { getAnimatedZoom } from '../../selectors/viewport';

function ScaleOperation({ width, height }) {
  const zoom = useSelector(getAnimatedZoom);
  const handleRadius = 5 / zoom;

  return clockwiseCornerPivotNames.map((pivotName, index) => {
    const opposingPivotName =
      clockwiseCornerPivotNames[(index + 2) % clockwiseCornerPivotNames.length];

    return (
      <ScaleHandle
        key={pivotName}
        pivotName={pivotName}
        opposingPivotName={opposingPivotName}
        handleRadius={handleRadius}
        width={width}
        height={height}
      />
    );
  });
}

ScaleOperation.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
};

export default ScaleOperation;
