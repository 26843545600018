import React from 'react';
import { node } from 'prop-types';

import ViewportProvider from './ViewportProvider';
import Viewport from './Viewport';

function ViewportContainer({ children, ...rest }) {
  return (
    <ViewportProvider>
      <Viewport {...rest}>{children}</Viewport>
    </ViewportProvider>
  );
}

ViewportContainer.propTypes = {
  children: node.isRequired,
};

export default ViewportContainer;
