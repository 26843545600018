import { albumChanged } from './history';

const UPDATE_COLORS = 'colorsAndFonts/UPDATE_COLORS';
const UPDATE_FONTS = 'colorsAndFonts/UPDATE_FONTS';
const REPLACE_COLORS = 'colorsAndFonts/REPLACE_COLORS';
const REPLACE_FONTS = 'colorsAndFonts/REPLACE_FONTS';

export const initialState = {
  colors: [],
  fonts: {},
};

function updateReplace(state, action) {
  switch (action.type) {
    case UPDATE_COLORS:
      return {
        ...state,
        colors: [
          ...state.colors.slice(0, action.index),
          action.payload,
          ...state.colors.slice(action.index + 1),
        ],
      };
    case UPDATE_FONTS:
      return {
        ...state,
        fonts: {
          ...state.fonts,
          [action.key]: action.payload,
        },
      };
    case REPLACE_COLORS:
      return {
        ...state,
        colors: action.payload,
      };
    case REPLACE_FONTS:
      return {
        ...state,
        fonts: action.payload,
      };
    default:
      return state;
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_COLORS:
    case REPLACE_COLORS:
    case UPDATE_FONTS:
    case REPLACE_FONTS:
      return updateReplace(state, action);
    default:
      return state;
  }
};

export const updateColor = (index, payload) => dispatch => {
  dispatch(albumChanged());
  dispatch({ type: UPDATE_COLORS, index, payload });
};

export const updateFont = (key, payload) => dispatch => {
  dispatch({ type: UPDATE_FONTS, key, payload });
};

export const replaceColors = payload => dispatch => {
  dispatch(albumChanged());
  dispatch({ type: REPLACE_COLORS, payload });
};

export const replaceFonts = payload => dispatch => {
  dispatch({ type: REPLACE_FONTS, payload });
};
