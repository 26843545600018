import React from 'react';
import { node } from 'prop-types';
import Fade from 'react-bootstrap/Fade';
import { useSelector } from 'react-redux';

import { selectAlbumLoaded } from '../../selectors/albums';

function FadeInWhenAlbumLoaded({ children }) {
  const albumLoaded = useSelector(selectAlbumLoaded);

  if (!albumLoaded) {
    return null;
  }

  return <Fade in={albumLoaded}>{children}</Fade>;
}

FadeInWhenAlbumLoaded.propTypes = {
  children: node.isRequired,
};

export default FadeInWhenAlbumLoaded;
