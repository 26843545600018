import React from 'react';
import { bool, func, string } from 'prop-types';

import Icon from '../../../../Icon';
import { ColorsShape } from '../../../../shapes';

export function ColorTile({ color, magnifier }) {
  return (
    <div className="color-tile qa-color-tile" style={{ background: color }}>
      {magnifier && (
        <div className="magnifier qa-magnifier" style={{ background: color }}>
          <Icon name="caret_down_fill" />
        </div>
      )}
    </div>
  );
}

ColorTile.defaultProps = {
  magnifier: false,
};

ColorTile.propTypes = {
  color: string.isRequired,
  magnifier: bool,
};

function ColorTiles({ colors, className, onClick, magnifier, active, square }) {
  return (
    <div
      className={`color-tiles${
        square ? ' color-tiles--square' : ''
      } qa-color-tiles d-flex rounded position-relative border border-muted p-1 ${className}${
        active ? ' active qa-active' : ''
      }`}
      onClick={onClick}
    >
      {colors.map(color => (
        <ColorTile
          key={color}
          color={color}
          magnifier={magnifier}
          square={square}
        />
      ))}
    </div>
  );
}

ColorTiles.defaultProps = {
  className: '',
  onClick: undefined,
  magnifier: false,
  active: false,
  square: false,
};

ColorTiles.propTypes = {
  colors: ColorsShape.isRequired,
  className: string,
  onClick: func,
  magnifier: bool,
  active: bool,
  square: bool,
};

export default ColorTiles;
