import { useState } from 'react';
import pick from 'lodash/pick';

import useApi from '../../../hooks/useApi';
import useNotifications from '../../../hooks/useNotifications';

function useEditRecord({ initialRecord, url, pickKeys, onSuccess }) {
  const [editedRecord, setEditedRecord] = useState(initialRecord);
  const [submitting, setSubmitting] = useState(false);
  const api = useApi();
  const { createError } = useNotifications();

  const createHandler = key => e => {
    setEditedRecord({ ...editedRecord, [key]: e.target.value });
  };

  async function handleSubmit(e) {
    e.preventDefault();

    setSubmitting(true);
    try {
      await api.patch(url, pick(editedRecord, pickKeys));
      onSuccess();
    } catch (err) {
      createError(`Fehler beim Speichern: ${err.toString()}`);
    }
    setSubmitting(false);
  }

  return {
    handleSubmit,
    createHandler,
    editedRecord,
    submitting,
  };
}

export default useEditRecord;
