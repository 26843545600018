import BlueprintsPanel from '../../AlbumWizard/Sidebar/BlueprintsPanel/index.container';
import OrganizationForm from '../../AlbumWizard/Content/OrganizationForm';

export default [
  {
    key: 'blueprints',
    title: 'Wähle eine Vorlage',
    content:
      'Unsere Grafiker haben tolle Vorlagen für dich gestaltet. Wähle deine Wunschvorlage und passe sie im nächsten Schritt nach deinem persönlichem Geschmack an.',
    component: BlueprintsPanel,
  },
  {
    key: 'data',
    title: 'Fast geschafft!',
    content: "Ein paar letzte Infos, dann geht's los!",
    renderContent: () => null,
    renderPreviewContent: OrganizationForm,
    renderHeader: () => null,
    renderFooter: () => null,
    hideSidebar: true,
    ignoreInCount: true,
    businessOnly: true,
    withoutOrganizationOnly: true,
  },
];
