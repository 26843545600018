import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

function useClientId() {
  const [token, setToken] = useState(undefined);

  function setSessionToken(nextToken) {
    localStorage.setItem('session_token', nextToken);
    setToken(nextToken);
  }

  function initializeToken() {
    const initToken = localStorage.getItem('session_token') || uuidv4();
    setSessionToken(initToken);
  }

  useEffect(initializeToken, []);

  return [token, setSessionToken];
}

export default useClientId;
