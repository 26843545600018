// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';

import { dimensions } from '../../constants';
import Sticker from './elements/Sticker';
import Page from './Page';
import Content from './Content';
import { getPlacedStickersSortedWithSections } from '../../selectors/stickers';

class StickerRenderer extends React.Component {
  render() {
    const { includeBleed, range, stickers, colors, selectInside } = this.props;

    const { bleed, trim, marks } = includeBleed
      ? Page.defaultProps
      : { bleed: 0, trim: 0, marks: 'none' };

    let num = 1;

    const pages = [];

    stickers.forEach((sticker, j) => {
      const { section } = sticker;

      const count = sticker.doubleSticker ? 2 : 1;
      for (let page = 0; page < count; page += 1) {
        const key = `sticker${j}_page${page}`;

        const contentX = 0;
        const contentY = 0;

        const contentWidth = dimensions.stickerWidth;
        const contentHeight = dimensions.stickerHeight;

        const pageWidth = dimensions.stickerWidth;
        const pageHeight = dimensions.stickerHeight;

        if (!range || range.indexOf(num) !== -1) {
          pages.push(
            <Page
              key={key}
              id={key}
              width={pageWidth}
              height={pageHeight}
              bleed={bleed}
              trim={trim}
              marks={marks}
            >
              <Content
                key={key}
                id={key}
                x={contentX}
                y={contentY}
                width={contentWidth}
                height={contentHeight}
                bleed={bleed}
              >
                <Sticker
                  spreadProps={{
                    sectionId: section.id,
                  }}
                  colors={colors}
                  selectInside={selectInside}
                  sticker={sticker}
                  x={0}
                  y={0}
                  width={contentWidth}
                  height={contentHeight}
                  sectionName={section.name}
                  {...sticker}
                  doubleStickerOffset={
                    page === 0 ? 0 : -dimensions.stickerWidth
                  }
                />
              </Content>
            </Page>
          );
        }

        num += 1;
      }
    });

    return (
      <div>
        {pages}
        {pages.length && <div className="render-complete" />}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  stickers: getPlacedStickersSortedWithSections(state),
  colors: state.colorsAndFonts.colors,
  selectInside: state.selection.selectInside,
});

export default connect(mapStateToProps)(StickerRenderer);
