import React from 'react';

import FormStateProvider from './FormStateProvider';
import StickerUploadForm from './StickerUploadForm';

function StickerUploadProvider(props) {
  return (
    <FormStateProvider>
      <StickerUploadForm {...props} />
    </FormStateProvider>
  );
}

export default StickerUploadProvider;
