import assert from 'assert';

import * as Sentry from '@sentry/react';

const elementTypes = [
  'Circle',
  'Group',
  'Image',
  'Line',
  'Rectangle',
  'Sticker',
  'StickerCell',
  'Text',
  'Comment',
];

export function validate(workspace) {
  const childrenNodeIds = []; // Will contain all node IDs that are another node's child
  const { nodes, root } = workspace;

  const enumerate = (id, level = 0, parentId = null, nodeIndex = -1) => {
    const node = nodes[id];
    childrenNodeIds.push(id);

    assert(Boolean(node), 'All node-refs must be defined');

    const { parent, type, children, props } = node;

    assert(id === props.id, 'Its id must be identical to props.id');
    if (level === 0) {
      assert(!parent, `The parent must empty for Root`);
      assert(type === 'Root', 'Level 0 needs to be of type `Root`');
    } else {
      assert(
        parent === parentId,
        `The parent must be correct. actual: ${parent}, expected: ${parentId}, id: ${id}, type: ${type}`
      );
      const parentChildIndex = nodes[parentId].children.indexOf(id);
      assert(parentChildIndex === nodeIndex, 'No duplicate ids in children');
      if (level === 1) {
        assert(type === 'Section', 'Level 1 needs to be of type `Section`');
      } else if (level === 2) {
        assert(type === 'Spread', 'Level 2 needs to be of type `Spread`');
      } else {
        assert(
          elementTypes.includes(type),
          'Level >= 3 needs to be an element-type'
        );
        const isGroup = type === 'Group';
        const hasChildren = children.length > 0;
        assert(
          isGroup === hasChildren,
          'Groups must have children, other elements not'
        );
      }
    }

    children.forEach((childId, index) =>
      enumerate(childId, level + 1, id, index)
    );
  };

  enumerate(root);

  // const orphanedNodeIds = Object.keys(nodes).filter(
  //   nodeId => !childrenNodeIds.includes(nodeId)
  // );
  // assert(
  //   orphanedNodeIds.length === 0,
  //   `Every non-root node must be another node's child; orphaned node IDs: ${orphanedNodeIds}`
  // );
}

export default (state, action) => {
  try {
    validate(state);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    Sentry.captureException(`${action.type}: ${error}`, {
      brokenState: state,
      action,
      actionJson: JSON.stringify(action),
    });
  }
  return state;
};
