import React, { useState } from 'react';
import { func, string, bool } from 'prop-types';
import { connect } from 'react-redux';
import ListGroup from 'react-bootstrap/ListGroup';

import Icon from '../../../Icon';
import { mapIconToElementType, getNodeDescription } from './util';
import { IdListShape, RawDraftContentStateShape } from '../../../shapes';
import {
  getSelectedElementIds,
  getWorkspace,
  getInteractiveParentIds,
} from '../../../../selectors/legacy';
import { elementSelect } from '../../../../modules/selection';
import { updateElements } from '../../../../actions/workspace';

const alwaysOnTopElementTypes = [
  'Circle',
  'Group',
  'Image',
  'Line',
  'Rectangle',
  'Text',
];

export const TreeNode = ({
  children,
  isDragging,
  isInteractive,
  isLocked,
  isAlwaysOnTop,
  isSelected,
  onLock,
  onToggleAlwaysOnTop,
  onSelect,
  symbol,
  text,
  type,
}) => {
  const [hovered, setHovered] = useState(false);

  const showLock = isInteractive && (isLocked || hovered);

  const supportsAlwaysOnTop = alwaysOnTopElementTypes.includes(type);
  const showAlwaysOnTop =
    isInteractive && supportsAlwaysOnTop && (isAlwaysOnTop || hovered);

  const iconName = mapIconToElementType(type);
  const nodeDescription = getNodeDescription(type, text, symbol).slice(0, 20);

  return (
    <ListGroup.Item
      action
      onClick={isInteractive ? onSelect : undefined}
      className={isSelected ? 'active' : ''}
      style={{ opacity: isDragging ? 0.5 : 1, cursor: 'pointer' }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="d-flex justify-content-center align-items-center">
        <div className="me-2">
          <Icon name={iconName} className="node-icon" />
        </div>
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center">
            <span>{nodeDescription}</span>
            <div className="d-flex">
              {showAlwaysOnTop && (
                <Icon
                  onClick={onToggleAlwaysOnTop}
                  name="always_on_top"
                  className={`always-on-top-icon qa-always-on-top ${
                    isAlwaysOnTop ? 'active' : ''
                  }`}
                />
              )}
              {showLock && (
                <Icon
                  onClick={onLock}
                  name={!isLocked ? 'unlock' : 'lock'}
                  className={`lock-icon qa-lock-node ${
                    isLocked ? 'active' : ''
                  }`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {children.length > 0 && (
        <ListGroup className="ms-4">
          {children
            .slice()
            .reverse()
            .map(childId => (
              <ConnectedTreeNode key={childId} id={childId} />
            ))}
        </ListGroup>
      )}
    </ListGroup.Item>
  );
};

TreeNode.defaultProps = {
  children: [],
  isDragging: false,
  symbol: undefined,
  text: undefined,
};

TreeNode.propTypes = {
  children: IdListShape,
  isDragging: bool,
  isInteractive: bool.isRequired,
  isLocked: bool.isRequired,
  isAlwaysOnTop: bool.isRequired,
  isSelected: bool.isRequired,
  onSelect: func.isRequired,
  onLock: func.isRequired,
  onToggleAlwaysOnTop: func.isRequired,
  symbol: string,
  text: RawDraftContentStateShape,
  type: string.isRequired,
};

const mapStateToProps = (state, { id, targetNodeId }) => {
  const { nodes } = getWorkspace(state);
  const { [id]: node } = nodes;
  const {
    type,
    props: { locked, symbol, text, alwaysOnTop },
    children,
  } = node;

  const selectedIds = getSelectedElementIds(state);
  const isInteractive = getInteractiveParentIds(state).includes(targetNodeId);

  return {
    children,
    type,
    symbol,
    text,
    targetNodeId,
    isLocked: !!locked,
    isAlwaysOnTop: !!alwaysOnTop,
    isSelected: selectedIds.includes(id),
    selectedIds,
    isInteractive,
  };
};

const mapDispatchToProps = {
  elementSelect,
  updateElements,
};

export const mergeProps = (
  { isLocked, isAlwaysOnTop, selectedIds, ...stateProps },
  { elementSelect, updateElements }, // eslint-disable-line no-shadow
  { id, isDragging }
) => ({
  ...stateProps,
  isDragging,
  isLocked,
  isAlwaysOnTop,
  onSelect: () => elementSelect([id]),
  onLock: event => {
    event.stopPropagation();
    updateElements({
      [id]: {
        locked: !isLocked,
      },
    });
    elementSelect(selectedIds.filter(selectedId => selectedId !== id));
  },
  onToggleAlwaysOnTop: event => {
    event.stopPropagation();
    updateElements({
      [id]: {
        alwaysOnTop: !isAlwaysOnTop,
      },
    });
  },
});

const ConnectedTreeNode = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(TreeNode);

export default ConnectedTreeNode;
