import React from 'react';
import { node } from 'prop-types';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/icons/logo.svg';
import UserMenu from '../../../containers/editor/UserMenu';
import Footer from './Footer';

function AppLayout({ children }) {
  return (
    <div className="app-layout w-100 d-flex flex-column justify-content-between">
      <div>
        <Navbar bg="white" variant="light">
          <Container>
            <Navbar.Brand>
              <Link to="/">
                <img
                  src={Logo}
                  className="logo qa-logo"
                  alt="stickerstars logo"
                  width="100"
                />
              </Link>
            </Navbar.Brand>
            <UserMenu />
          </Container>
        </Navbar>
        <Container>{children}</Container>
      </div>
      <Footer />
    </div>
  );
}

AppLayout.defaultProps = {};

AppLayout.propTypes = {
  children: node.isRequired,
};

export default AppLayout;
