import React, { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import shuffle from 'lodash/shuffle';
import chunk from 'lodash/chunk';
import flatten from 'lodash/flatten';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import PhotoResult from './PhotoResult';
import GridLoader from './GridLoader';
import usePexel from '../../../hooks/usePexel';
import useLocale from '../../../hooks/localization/useLocale';
import useAnalytics from '../../../containers/app/useAnalytics';
import useTutorial from '../../../hooks/useTutorial';

const PER_PAGE = 16;

function StockImagesPanel() {
  const { t } = useLocale();
  const { linkTo } = useTutorial();

  const [showAlert, setShowAlert] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fetching, setFetching] = useState(false);

  const [query, setQuery] = useState(t('editor.stockPanel.defaultQuery'));
  const [lastPage, setLastPage] = useState(1);
  const [results, setResults] = useState([]);

  const searchInputRef = useRef();
  const resultsRef = useRef();
  const { executeSearchQuery } = usePexel();
  const analytics = useAnalytics();

  async function getPhotos(page) {
    if (fetching) {
      return;
    }

    setFetching(true);
    setLastPage(page);

    const { photos } = await executeSearchQuery({
      query,
      per_page: PER_PAGE,
      locale: 'de-DE',
      page,
    });

    setResults([
      ...results,
      ...flatten(
        chunk(
          photos.map((photo, index) => ({
            ...photo,
            landscape: index % 2 === 0,
          })),
          2
        ).map(row => shuffle(row))
      ),
    ]);

    setFetching(false);

    analytics.track('Stock Searched', {
      query,
    });
  }

  useDebounce(
    async () => {
      if (query.length < 3) {
        return;
      }
      setLoading(true);
      await getPhotos(1);
      setLoading(false);
    },
    750,
    [query]
  );

  useEffect(() => {
    setResults([]);
  }, [query]);

  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  function handleWheel() {
    const element = resultsRef.current;

    if (!element) {
      return;
    }

    const scrollY = element.scrollHeight - element.scrollTop;
    const height = element.offsetHeight;
    const offset = height - scrollY;

    if (offset === 0 || offset === 1) {
      getPhotos(lastPage + 1);
    }
  }

  return (
    <>
      <div className="sidebar-menu py-3">
        <div className="w-100">
          <Form.Control
            className="qa-stock-filter"
            name="stock_filter"
            placeholder={`Fotos suchen, z. B. "Hochzeit"`}
            onChange={e => setQuery(e.target.value)}
            value={query}
            type="text"
            ref={searchInputRef}
          />
        </div>
      </div>
      {showAlert && (
        <Alert
          className="border qa-stock-welcome-alert"
          variant="light"
          dismissible
          onClose={() => setShowAlert(false)}
        >
          Hier findest du eine große Auswahl an kostenlosen Bildern für dein
          Album. Gib einfach im Suchfeld einen Begriff oder ein Thema ein. Ziehe
          ein Bild auf die Zeichenfläche, um es im Album zu platzieren.{' '}
          <a href={linkTo('stock')} target="_BLANK" rel="noopener noreferrer">
            Mehr erfahren
          </a>
        </Alert>
      )}
      {loading ? (
        <div>
          <GridLoader className="w-100" />
        </div>
      ) : (
        <div className="flex grow overflow-auto">
          <div
            className="images-grid qa-stock-results p-0 scrollable"
            ref={resultsRef}
            onWheel={handleWheel}
          >
            {results.map(photo => (
              <PhotoResult key={photo.id} photo={photo} />
            ))}
          </div>
        </div>
      )}
      <div className="py-3">
        <a href="https://www.pexels.com">
          <img
            src="https://images.pexels.com/lib/api/pexels.png"
            alt="pexels logo"
            className="w-20"
          />
        </a>
      </div>
    </>
  );
}

export default StockImagesPanel;
