import React from 'react';

import Spinner from '../../components/generic/Spinner';

function FullScreenSpinner() {
  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <Spinner animation="border" />
    </div>
  );
}

export default FullScreenSpinner;
