import { add } from 'date-fns';

const generatedDefaultReleaseDate = add(new Date(), { months: 2 });

export default generatedDefaultReleaseDate;

export const smoothScrollElementToView = element => {
  const activeElement = element.querySelector('.active');

  if (!activeElement) {
    return;
  }

  if (
    activeElement.offsetLeft < element.scrollLeft ||
    activeElement.offsetLeft + activeElement.offsetWidth >
      element.scrollLeft + element.offsetWidth
  ) {
    element.scrollTo({
      left:
        activeElement.offsetLeft -
        (element.offsetWidth - activeElement.offsetWidth) / 2,
      behavior: 'smooth',
    });
  }
};
