import React from 'react';
import { func, string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../../../../Icon';
import { textAligns } from '../../../elements/Text/TextConstants';

function TextAlignButtons({ makeHandleChange, dropDirection, align }) {
  const placement = dropDirection === 'up' ? 'bottom' : 'top';

  return textAligns.map(({ value, label, icon }) => (
    <OverlayTrigger
      placement={placement}
      key={value}
      overlay={<Tooltip>{label}</Tooltip>}
    >
      <Button
        active={value === align}
        onClick={makeHandleChange('align', value)}
        className={`rounded-0 qa-align-${value}`}
      >
        <Icon name={icon} />
      </Button>
    </OverlayTrigger>
  ));
}

TextAlignButtons.propTypes = {
  align: string.isRequired,
  dropDirection: string.isRequired,
  makeHandleChange: func.isRequired,
};

export default TextAlignButtons;
