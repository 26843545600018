import { number } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { resolutions } from '../../../constants';
import { getZoom } from '../../../selectors/viewport';
import { computeResolution } from '../../../util/images';

const minZoom = 0.5;

const withAutoResolution = WrappedComponent => {
  const Component = props => {
    const { scale, cscale, workspaceScale } = props;
    const zoom = useSelector(getZoom);
    const finalScale = scale * cscale * workspaceScale * zoom;

    const pixelSizeInClient = finalScale * 100;
    const resolution =
      zoom < minZoom ? resolutions.small : computeResolution(pixelSizeInClient);

    return <WrappedComponent {...props} resolution={resolution} />;
  };

  Component.defaultProps = {
    scale: 1,
    cscale: 1,
    workspaceScale: 1,
  };

  Component.propTypes = {
    scale: number,
    cscale: number,
    workspaceScale: number,
  };

  return Component;
};

export default withAutoResolution;
