import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { func, number } from 'prop-types';
import { useGesture } from '@use-gesture/react'; // eslint-disable-line import/no-unresolved
import clamp from 'lodash/clamp';

import AlbumSvg from '../../../svg/Viewport/AlbumSvg';
import Sticker from '../../../svg/elements/Sticker';
import { ImageContext } from '../../../ImageContext';
import { dimensions, resolutions } from '../../../../constants';
import Icon from '../../../Icon';
import { ImageObjectShape, RefShape, StickerShape } from '../../../shapes';
import {
  viewportToStickerImageMatrix,
  clientToStickerImageCoordinates,
} from '../../../../util/geometry';
import { getStickerPositionFromFace } from '../../../../util/faceapi';
import useScreenSize from '../../../../hooks/useScreenSize';
import { calculateZoom } from '../../../svg/Viewport/useViewportGestures';
import { getShowStickers } from '../../../../selectors/controls';
import { computeNativeImageSize } from '../../../../util/images';

const scaleSensitivity = 0.002;
const scaleLimits = { min: 0.0001, max: 9 }; // magic number for min and max scale (approximated via range input)

function StickerPreview({
  viewportRef,
  sticker,
  imageObject,
  viewportScale,
  setOperationActive,
  updateSticker,
}) {
  const [faceFitHovered, setFaceFitHovered] = useState(false);
  const { isMobile } = useScreenSize();

  const imageContext = useContext(ImageContext);
  const showStickers = useSelector(getShowStickers);

  const imageSize = computeNativeImageSize(imageObject);

  /**
   * on mobile we have an smaller Factor for slower movement
   * so users adjust their images easiear
   */
  const panFactor = isMobile ? 0.2 : 0.33;

  const scaleRoot = 2;
  const face = imageObject.details?.face;
  const hasFace = !!face;

  const spreadProps = { sectionId: '' };

  const tooltipFaceFit = hasFace
    ? 'Gesicht einpassen'
    : 'Kein Gesicht gefunden';

  const imageContextWithShowStickers = {
    ...imageContext,
    resolution: resolutions.large,
    showStickers,
    stickerRendering: true,
    rendering: true,
  };
  const stickerWidth =
    dimensions.stickerWidth * (sticker.doubleSticker ? 2 : 1);
  const { stickerHeight } = dimensions;
  const width = stickerWidth * viewportScale;
  const height = stickerHeight * viewportScale;

  function keepPivot(pivot, callback) {
    const matrix = viewportToStickerImageMatrix(
      viewportRef.current,
      sticker.id
    );
    const before = matrix.transformPoint(pivot);
    const nextMatrix = callback(matrix);
    const after = nextMatrix.transformPoint(pivot);
    const cx = sticker.cx + (before.x - after.x);
    const cy = sticker.cy + (before.y - after.y);
    return { cx, cy };
  }

  const handleDrag = ({ first, last, delta: [dx, dy] }) => {
    if (first) {
      setOperationActive(true);
      return;
    }
    const { cx, cy } = sticker;
    updateSticker({
      cx: cx + dx * panFactor,
      cy: cy + dy * panFactor,
    });
    if (last) {
      setOperationActive(false);
    }
  };

  function handleFit() {
    updateSticker(getStickerPositionFromFace(face, imageSize));
  }

  function handleWheel({ event }) {
    event.preventDefault();
    const { deltaY, clientX, clientY } = event;
    const pivot = clientToStickerImageCoordinates(
      viewportRef.current,
      sticker.id,
      { x: clientX, y: clientY }
    );
    let delta = 1 + Math.abs(deltaY) * scaleSensitivity;
    if (deltaY > 0) {
      delta = 1 / delta;
    }
    const cscale = sticker.cscale * delta;
    const { cx, cy } = keepPivot(pivot, matrix => matrix.scale(delta));
    updateSticker({ cscale, cx, cy });
  }

  function handlePinch({
    first,
    last,
    origin: [ox, oy],
    offset,
    delta,
    event,
  }) {
    if (first) {
      setOperationActive(true);
      return;
    }
    event.preventDefault();
    const wantedCscale = calculateZoom({
      type: 'pinch',
      zoom: sticker.cscale,
      delta,
      offset,
    });

    const pivot = clientToStickerImageCoordinates(
      viewportRef.current,
      sticker.id,
      {
        x: ox,
        y: oy,
      }
    );
    const finalCscale = clamp(wantedCscale, scaleLimits.min, scaleLimits.max);
    const { cx, cy } = keepPivot(pivot, matrix =>
      matrix.scale(finalCscale / sticker.cscale)
    );
    updateSticker({ cscale: finalCscale, cx, cy });
    if (last) {
      setOperationActive(false);
    }
  }

  function handleRotate(step) {
    const absStep = Math.abs(step);
    const quant = Math.round(sticker.crotation / absStep);
    const mod = quant % (360 / absStep);
    const crotation = mod * absStep + step;
    const delta = crotation - sticker.crotation;
    const pivot = { x: imageSize.width / 2, y: imageSize.height / 2 };
    const { cx, cy } = keepPivot(pivot, matrix => matrix.rotate(delta));
    updateSticker({ crotation, cx, cy });
  }

  function handleScaleChange(event) {
    const cscale = event.target.value ** scaleRoot;
    const delta = cscale / sticker.cscale;
    const pivot = hasFace
      ? {
          x: (face.x + face.width / 2) * imageSize.width,
          y: (face.y + face.height / 2) * imageSize.height,
        }
      : { x: imageSize.width / 2, y: imageSize.height / 2 };
    const { cx, cy } = keepPivot(pivot, matrix => matrix.scale(delta));
    updateSticker({ cscale, cx, cy });
  }

  useGesture(
    {
      onDrag: handleDrag,
      onWheel: handleWheel,
      onPinch: handlePinch,
    },
    {
      target: viewportRef.current,
      eventOptions: { passive: false },
    }
  );

  return (
    <>
      <Form.Group className="position-relative d-flex justify-content-center">
        <ImageContext.Provider value={imageContextWithShowStickers}>
          <AlbumSvg
            className="sticker-preview shadow"
            viewportRef={viewportRef}
            viewBox={`0 0 ${stickerWidth} ${stickerHeight}`}
            style={{ width, height }}
          >
            <Sticker
              {...sticker}
              x={0}
              y={0}
              width={width}
              height={height}
              doubleStickerOffset={0}
              spreadProps={spreadProps}
              withFaceBox={faceFitHovered}
              resolution={resolutions.medium}
            />
          </AlbumSvg>
        </ImageContext.Provider>
        <div className="icon-move" />
      </Form.Group>
      <Form.Group className="control-group-scale inline">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip>{tooltipFaceFit}</Tooltip>}
        >
          <Icon
            name="face_fit"
            className={`face-fit ${!hasFace && 'disabled'} qa-face-fit`}
            onClick={hasFace ? handleFit : null}
            onMouseEnter={() => setFaceFitHovered(true)}
            onMouseLeave={() => setFaceFitHovered(false)}
          />
        </OverlayTrigger>
        <Form.Control
          type="range"
          min={scaleLimits.min ** (1 / scaleRoot)}
          max={scaleLimits.max ** (1 / scaleRoot)}
          step={0.01}
          className="mx-3 qa-sticker-scale"
          value={sticker.cscale ** (1 / scaleRoot)}
          onChange={handleScaleChange}
          custom
        />
        <Icon
          name="rotate_step"
          className="rotate-step cursor-pointer"
          onClick={() => handleRotate(90)}
        />
      </Form.Group>
    </>
  );
}

StickerPreview.propTypes = {
  viewportRef: RefShape.isRequired,
  updateSticker: func.isRequired,
  setOperationActive: func.isRequired,
  sticker: StickerShape.isRequired,
  imageObject: ImageObjectShape.isRequired,
  viewportScale: number.isRequired,
};

export default StickerPreview;
