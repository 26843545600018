import { useRouteMatch } from 'react-router-dom';
import intersection from 'lodash/intersection';
import { useSelector } from 'react-redux';

import useAuth from '../../../hooks/useAuth';
import useScreenSize from '../../../hooks/useScreenSize';
import { selectAlbumHasOrder } from '../../../selectors/albums';
import { selectCurrentBlueprintCategory } from '../../../selectors/blueprints';
import { selectOrganizations } from '../../../selectors/user';

function useScopedSidebarTabs(tabs) {
  const {
    user: { roles },
  } = useAuth();

  const {
    params: { key: activeKey },
  } = useRouteMatch();

  const { isMobile } = useScreenSize();
  const albumHasOrder = useSelector(selectAlbumHasOrder);
  const blueprintCategory = useSelector(selectCurrentBlueprintCategory);
  const userOrganizations = useSelector(selectOrganizations);

  const availableTabs = tabs.filter(
    ({
      allowedRoles,
      mobileOnly,
      desktopOnly,
      withOrderOnly,
      businessOnly,
      withoutOrganizationOnly,
    }) => {
      const isAllowed =
        !allowedRoles || intersection(allowedRoles, roles).length > 0;

      const isVisible =
        (!withOrderOnly || albumHasOrder) &&
        (!businessOnly || blueprintCategory === 'business') &&
        (!withoutOrganizationOnly || userOrganizations.length === 0);
      const isSupportedDevice = isMobile ? !desktopOnly : !mobileOnly;

      return isAllowed && isVisible && isSupportedDevice;
    }
  );

  const availableKeys = availableTabs.map(tab => tab.key);
  const activeTab = availableTabs.find(tab => tab.key === activeKey);
  const activeTabIndex = availableTabs.findIndex(tab => tab.key === activeKey);

  const countableTabs = availableTabs.filter(
    ({ ignoreInCount }) => !ignoreInCount
  );
  const activeCountableTabIndex = countableTabs.findIndex(
    ({ key }) => key === activeKey
  );

  return {
    tabs: availableTabs,
    keys: availableKeys,
    activeTab,
    activeTabIndex,
    countableTabs,
    activeCountableTabIndex,
  };
}

export default useScopedSidebarTabs;
