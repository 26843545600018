import { connect } from 'react-redux';

import { updateControls } from '../../../modules/controls';
import {
  elementSelect,
  setLassoSpreadIndex,
  spreadSelect,
} from '../../../modules/selection';
import {
  getIsolation,
  getSelectedElementIds,
  getSpreadIds,
  getSpreadsCount,
  getWorkspace,
} from '../../../selectors/legacy';
import {
  getLassoSelectableElementAreas,
  getLassoSelectableElementIds,
} from '../../../selectors/selection';
import Selector from './Selector';

const mapStateToProps = state => ({
  workspace: getWorkspace(state),
  isolationId: getIsolation(state),
  spreadIds: getSpreadIds(state),
  selectedElementIds: getSelectedElementIds(state),
  spreadsCount: getSpreadsCount(state),
  lassoSelectableElementAreas: getLassoSelectableElementAreas(state),
  lassoSelectableElementIds: getLassoSelectableElementIds(state),
});

const mapDispatchToProps = {
  setLassoSpreadIndex,
  elementSelect,
  spreadSelect,
  updateControls,
};

export default connect(mapStateToProps, mapDispatchToProps)(Selector);
