import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { createSticker } from '../../../../actions/stickers';
import { updateElements } from '../../../../actions/workspace';
import { getSingleSelectedElement } from '../../../../selectors/legacy';
import { getSectionIdForSelection } from '../../../../selectors/selection';
import { historyAnchor } from '../../../../modules/history';
import useImageUpload from '../../../../hooks/useImageUpload';
import useLoading, { commonTypes } from '../../../../hooks/useLoading';
import { fileNameToStickerName, generateId } from '../../../../util';
import Icon from '../../../Icon';
import FileInputButton from '../../../generic/FileInputButton';
import useAnalytics from '../../../../containers/app/useAnalytics';

function StickerImageUploadButton({ dropDirection }) {
  const dispatch = useDispatch();
  const { startLoading, stopLoading } = useLoading(commonTypes.uploadingFiles);
  const { createStickerImage } = useImageUpload();
  const analytics = useAnalytics();

  const sectionId = useSelector(getSectionIdForSelection);
  const { id: stickerCellId } =
    useSelector(getSingleSelectedElement)?.props || {};

  if (!sectionId || !stickerCellId) {
    return null;
  }

  async function handleChange(event) {
    const [file] = Array.from(event.target.files);

    startLoading();
    const {
      id: imageId,
      blob: { filename },
    } = await createStickerImage(file);
    stopLoading();

    const stickerId = generateId();

    const sticker = {
      id: stickerId,
      sectionId,
      image: imageId,
      name: fileNameToStickerName(filename),
    };

    batch(() => {
      dispatch(createSticker(sticker));
      dispatch(updateElements({ [stickerCellId]: { stickerId } }));
      dispatch(historyAnchor());
    });

    analytics.track('Sticker Created', {
      id: stickerId,
      fromFile: true,
      drop: false,
    });

    analytics.track('Sticker Placed', {
      id: sticker.id,
      sectionId,
      fromFile: true,
      drop: false,
    });
  }

  return (
    <OverlayTrigger
      placement={dropDirection === 'up' ? 'bottom' : 'top'}
      overlay={<Tooltip>Stickerbild hochladen</Tooltip>}
    >
      <div>
        <FileInputButton
          name="sticker_image_upload"
          accept="image/jpeg, image/png, image/svg+xml"
          className="m-0 qa-sticker-image-upload-button"
          onChange={handleChange}
          disabled={!stickerCellId}
        >
          <Icon name="image" />
        </FileInputButton>
      </div>
    </OverlayTrigger>
  );
}

StickerImageUploadButton.propTypes = {
  dropDirection: string.isRequired,
};

export default StickerImageUploadButton;
