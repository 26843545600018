import { useParams } from 'react-router';

import useQuery from '../../hooks/useQuery';

function useInvitationFromParams() {
  const { token } = useParams();

  const {
    data: invitation,
    error,
    isLoading,
  } = useQuery(`/public/invitations/${token}`, [token]);

  return {
    invitation,
    error,
    isLoading,
  };
}

export default useInvitationFromParams;
