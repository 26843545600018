import axios from 'axios';
import { useCallback, useEffect, useRef } from 'react';

import useAuth from './useAuth';
import { API_URL } from '../constants';

const client = axios.create({
  baseURL: API_URL,
});

function useApi() {
  const { getAccessTokenSilently, logout } = useAuth();

  /**
   * Force-logout the user when API returns a 401.
   */
  useEffect(() => {
    client.interceptors.response.use(undefined, error => {
      if (error.response.status === 401) {
        logout();
      }

      return Promise.reject(error);
    });
  }, [logout]);

  const request = useCallback(
    async (method, url, opts = {}) => {
      const headers = { ...opts.headers };

      /**
       * Some of our API routes are public. In order to not have the consumer
       * make the distinction, we add Authorization headers to the request where
       * possible/necessary, then let the server handle the actual authentication.
       */
      try {
        const accessToken = await getAccessTokenSilently();
        headers.Authorization = `Bearer ${accessToken}`;
      } catch (err) {
        // catch `OAuthError: Login required` here to allow navigating public routes
      }

      return client({
        method,
        url,
        headers,
        ...opts,
      });
    },
    [getAccessTokenSilently]
  );

  const makeRequest = method => (path, data, opts = {}) =>
    request(method, path, { data, ...opts });

  const get = makeRequest('get');
  const post = makeRequest('post');
  const patch = makeRequest('patch');
  const destroy = makeRequest('delete');

  const api = useRef({
    get,
    patch,
    post,
    delete: destroy,
    getAccessTokenSilently,
  });

  return api.current;
}

export default useApi;
