import React, { memo, useMemo } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import Renderer from '../renderer';
import Editor from '../editor/index.container';
import Start from '../start';
import AlbumWizard from '../album_wizard/index.container';
import StickerUploadForm from '../StickerUploadForm';
import Notifications from './Notifications';
import { ConfirmationServiceProvider } from '../../components/ui/ConfirmModal/ConfirmationService';
import Profile from '../profile';
import OrdersIndex from '../orders/OrdersIndex';
import OrderDetails from '../orders/OrderDetails';
import Checkout from '../checkout';
import withAnalytics from './withAnalytics';
import withAuthentication from './withAuthentication';
import AuthConfirmation from '../album_wizard/AuthConfirmation';
import PublicAlbumPreview from '../PublicAlbumPreview';
import OrdersDashboard from '../orders/OrdersDashboard';
import AffiliateDashboard from '../profile/AffiliateDashboard';
import AcceptInvitation from '../invitations/AcceptInvitation';
import ConfirmInvitation from '../invitations/ConfirmInvitation';

const RouteWithAnalytics = withAnalytics(Route);
const PrivateRoute = withAuthentication(RouteWithAnalytics);
const AdminRoute = withAuthentication(RouteWithAnalytics, { adminOnly: true });

const App = memo(() => {
  const publicRoutes = useMemo(
    () => [
      // public routes
      // render-routes are also public, need api key if not authenticated
      <Route
        exact
        key="renderer"
        path="/render/:albumId?"
        render={props => <Renderer {...props} />}
      />,

      <Route
        exact
        key="sticker-uploads"
        path="/s/:token"
        render={props => <StickerUploadForm {...props} />}
      />,

      <Route
        exact
        key="public-album-preview"
        path="/album/:albumId/preview/:token"
        render={props => <PublicAlbumPreview {...props} />}
      />,

      <Route
        exact
        key="affiliate-dashboard"
        path="/affiliate"
        render={props => <AffiliateDashboard {...props} />}
      />,

      <Route
        exact
        key="accept-invitation"
        path="/invitations/:token/accept"
        render={props => <AcceptInvitation {...props} />}
      />,
    ],
    []
  );

  const wizardRoutes = useMemo(
    () => [
      <PrivateRoute
        exact
        key="confirm"
        path="/album/new/confirm"
        render={props => <AuthConfirmation {...props} />}
        signup
        prefillEmail
      />,
      <RouteWithAnalytics
        exact
        key={5}
        path="/album/new/:key?"
        render={props => <AlbumWizard {...props} />}
        preFillEmail
      />,
    ],
    []
  );

  const privateRoutes = useMemo(
    () => [
      <PrivateRoute exact key={3} path="/" component={Start} />,
      <PrivateRoute
        exact
        key={4}
        path="/start"
        render={props => <Start {...props} />}
      />,
      <PrivateRoute
        key={7}
        path="/profile"
        render={props => <Profile {...props} />}
      />,
      <PrivateRoute
        exact
        key="orders"
        path="/orders"
        render={props => <OrdersIndex {...props} />}
      />,
      <PrivateRoute
        exact
        key="orders-show"
        path="/orders/:id"
        render={props => <OrderDetails {...props} />}
      />,
      <PrivateRoute
        exact
        key={8}
        path="/album/:albumId/checkout"
        render={props => <Checkout {...props} />}
      />,
      <PrivateRoute
        exact
        key="editor"
        path="/album/:albumId/:key?/:childKey?/:childChildKey?"
        render={props => <Editor {...props} />}
      />,
      <PrivateRoute
        exact
        key="confirm-invitation"
        path="/invitations/:token/confirm"
        render={props => <ConfirmInvitation {...props} />}
        preFillEmail
      />,
    ],
    []
  );

  const adminRoutes = useMemo(
    () => [
      <AdminRoute
        exact
        key="orders-dashboard"
        path="/dashboard"
        render={props => <OrdersDashboard {...props} />}
      />,
    ],
    []
  );

  const catchAll = (
    <Route key={6}>
      <Redirect to="/start" />
    </Route>
  );

  const routes = [
    ...publicRoutes,
    ...wizardRoutes,
    ...privateRoutes,
    ...adminRoutes,
    catchAll,
  ];

  return (
    <main className="app">
      <ConfirmationServiceProvider>
        <Switch>{routes}</Switch>
        <Notifications />
      </ConfirmationServiceProvider>
    </main>
  );
});

export default App;
