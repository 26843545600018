import { useDispatch, useSelector } from 'react-redux';
import { useMachine } from 'react-robot';
import { createMachine, state as machineState, transition } from 'robot3';

import { setAlbumData } from '../../../../../modules/albums';
import {
  replaceColors,
  replaceFonts,
} from '../../../../../modules/colorsAndFonts';
import { selectStickerTemplateSettings } from '../../../../../selectors/albums';
import { getColors, getFonts } from '../../../../../selectors/colorsAndFonts';
import { defaultStickerTemplateId } from '../../../../../constants';

const machine = createMachine({
  StylesSelector: machineState(
    transition('colorsClick', 'ColorsSelector'),
    transition('fontsClick', 'FontsList'),
    transition('stickerTemplateClick', 'StickerTemplateList')
  ),
  ColorsSelector: machineState(
    transition('colorPresetsClick', 'ColorPresetsList'),
    transition('customColorClick', 'CustomColorSelector'),
    transition('back', 'StylesSelector')
  ),
  ColorPresetsList: machineState(transition('back', 'ColorsSelector')),
  CustomColorSelector: machineState(transition('back', 'ColorsSelector')),
  FontsList: machineState(transition('back', 'StylesSelector')),
  StickerTemplateList: machineState(transition('back', 'StylesSelector')),
});

function useStylesStateMachine() {
  const [current, send] = useMachine(machine);
  const dispatch = useDispatch();

  const colors = useSelector(getColors);
  const fonts = useSelector(getFonts);
  const stickerTemplateId =
    useSelector(selectStickerTemplateSettings)?.id || defaultStickerTemplateId;

  const setColors = nextColors => {
    dispatch(replaceColors(nextColors));
  };

  const setFonts = nextFonts => {
    dispatch(replaceFonts(nextFonts));
  };

  const setStickerTemplateId = nextStickerTemplateId => {
    dispatch(
      setAlbumData({ stickerTemplateSettings: { id: nextStickerTemplateId } })
    );
  };

  return {
    current,
    send,
    colors,
    fonts,
    stickerTemplateId,
    setColors,
    setFonts,
    setStickerTemplateId,
  };
}

export default useStylesStateMachine;
