import React from 'react';
import ContentLoader from 'react-content-loader';

const SidebarLoader = props => (
  <ContentLoader
    speed={2}
    width={550}
    height={650}
    viewBox="0 0 550 650"
    {...props}
  >
    <rect x="0" y="26" rx="0" ry="0" width="217" height="40" />
    <rect x="0" y="114" rx="0" ry="0" width="162" height="54" />
    <rect x="217" y="128" rx="0" ry="0" width="97" height="32" />
    <rect x="0" y="216" rx="0" ry="0" width="513" height="48" />
    <rect x="0" y="286" rx="0" ry="0" width="513" height="256" />
    <rect x="380" y="130" rx="0" ry="0" width="132" height="25" />
  </ContentLoader>
);

export default SidebarLoader;
