import React, { useCallback, useMemo } from 'react';
import flow from 'lodash/flow';
import identity from 'lodash/identity';
import { bool, func, number, string } from 'prop-types';
import { useDispatch } from 'react-redux';

import { satisfyGrid } from '../../../util/geometry';
import snapToPoint from '../../../util/snapToPoint';
import { makeMapPropsToDeltaMap } from '../../../util/workspace';
import { InitialOperationValuesShape } from '../../shapes';
import extendGuidesForMoveOperation from './extendGuidesForMoveOperation';
import useMoveOperation from './useMoveOperation';
import { clearSelection, setSelectInside } from '../../../modules/selection';
import { setIsolation } from '../../../modules/controls';

function MoveOperation({
  id,
  width,
  height,
  initialValues,
  zoom,
  gridEnabled,
  updateElements,
  updateGuides,
  canSelectInside,
  canIsolate,
}) {
  const dispatch = useDispatch();

  const handleDoubleClick = useCallback(
    event => {
      if (canSelectInside) {
        event.stopPropagation();
        dispatch(setSelectInside(true));
        return;
      }

      if (canIsolate) {
        event.stopPropagation();
        dispatch(clearSelection());
        dispatch(setIsolation(id));
      }
    },
    [canIsolate, canSelectInside, dispatch, id]
  );

  const movementGuides = useMemo(() => {
    if (!initialValues) {
      return null;
    }

    const { guides, selectionBounds } = initialValues;
    if (!selectionBounds) {
      return null;
    }

    return extendGuidesForMoveOperation(guides, selectionBounds);
  }, [initialValues]);

  const handleUpdate = useCallback(
    ({ eventParams, initialPoint, currentPoint }) => {
      const { shiftKey, ctrlKey } = eventParams;
      const { selectionBounds, selectedElements, selectInside } = initialValues;

      if (!selectionBounds) {
        return;
      }

      const makeAppendPoint = (point, factor = 1) => ({ x, y }) => ({
        x: x + point.x * factor,
        y: y + point.y * factor,
      });

      const satisfySnapping = point => {
        const [nextPoint, activeGuideIds] = snapToPoint(
          point,
          movementGuides,
          zoom
        );
        updateGuides({ activeGuideIds });
        return nextPoint;
      };

      const clearGuides = _ => {
        updateGuides({ activeGuideIds: [] });
        return _;
      };

      const limitToAxis = point => {
        const minorAxis = Math.abs(point.x) > Math.abs(point.y) ? 'y' : 'x';
        const nextPoint = { ...point };
        nextPoint[minorAxis] = 0;
        return nextPoint;
      };

      const makeApplyDelta = point =>
        selectInside
          ? ({ cx = 0, cy = 0 }) => ({ cx: cx + point.x, cy: cy + point.y })
          : ({ x = 0, y = 0 }) => ({ x: x + point.x, y: y + point.y });

      const propsDeltaMap = flow(
        // Subtract the initial point
        makeAppendPoint(initialPoint, -1),

        // Append the selection bound
        makeAppendPoint(selectionBounds),

        // Satisfy grid
        gridEnabled ? satisfyGrid : identity,

        // Pressing control-key ignores snapping
        ctrlKey ? clearGuides : satisfySnapping,

        // Subtract the selection bounds, resulting in the final delta
        makeAppendPoint(selectionBounds, -1),

        // Pressing shift-key limits movement to one axis
        shiftKey ? limitToAxis : identity,

        // Convert the point into a function that adds this point
        makeApplyDelta,

        // Calculate the delta map
        makeMapPropsToDeltaMap(selectedElements)
      )(currentPoint);
      updateElements(propsDeltaMap);
    },
    [
      initialValues,
      updateElements,
      updateGuides,
      zoom,
      gridEnabled,
      movementGuides,
    ]
  );

  const handleMouseDown = useMoveOperation(id, initialValues, handleUpdate);

  return (
    <rect
      className="box qa-handle-move"
      width={width}
      height={height}
      fill="none"
      onDoubleClick={handleDoubleClick}
      onMouseDown={handleMouseDown}
    />
  );
}

MoveOperation.defaultProps = {
  initialValues: null,
};

MoveOperation.propTypes = {
  id: string.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  zoom: number.isRequired,
  gridEnabled: bool.isRequired,
  initialValues: InitialOperationValuesShape,
  updateElements: func.isRequired,
  updateGuides: func.isRequired,
  canSelectInside: bool.isRequired,
  canIsolate: bool.isRequired,
};

export default MoveOperation;
