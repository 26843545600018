import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

import useAuth from '../../hooks/useAuth';

function useFeedbackDialog() {
  const { user } = useAuth();

  const showFeedbackDialog = useCallback(() => {
    const mockError = new Error('User Feedback submitted');

    Sentry.captureException(mockError);
    Sentry.showReportDialog({
      title: 'Das passt noch nicht so ganz',
      subtitle: 'Danke für dein Feedback! Wir sind ganz Ohr',
      subtitle2: '',
      labelSubmit: 'Feedback abschicken',
      labelClose: 'abbrechen',
      labelComments: 'Was ist passiert?',
      user: {
        name: user.nickname,
        email: user.email,
      },
    });
  }, [user]);

  if (!user) {
    return null;
  }

  return showFeedbackDialog;
}

export default useFeedbackDialog;
