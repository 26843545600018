import React, { useMemo } from 'react';
import { string, number as numberType } from 'prop-types';

import formatStickerNumber from './formatStickerNumber';
import Node from '../../Node';
import { WorkspaceShape } from '../../../shapes';

function StickerDesign({
  name,
  position,
  number,
  workspace,
  clipPath,
  className,
}) {
  const localTexts = useMemo(
    () => ({
      name,
      position,
      number: formatStickerNumber(number),
      nextnumber: formatStickerNumber(number, true),
    }),
    [name, position, number]
  );

  return (
    <g shapeRendering="auto" clipPath={clipPath} className={className}>
      <Node id={workspace.root} workspace={workspace} localTexts={localTexts} />
    </g>
  );
}

StickerDesign.defaultProps = {
  className: '',
  name: '',
  position: '',
  number: null,
};

StickerDesign.propTypes = {
  workspace: WorkspaceShape.isRequired,
  name: string,
  position: string,
  number: numberType,
  clipPath: string.isRequired,
  className: string,
};

export default StickerDesign;
