import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';

import useMouseDragCoordinates from '../../../hooks/useMouseDragCoordinates';
import { domMatrix } from '../../../util/geometry';
import useViewport from '../../svg/Viewport/useViewport';
import moveBeyondSpreadBounds from '../../../actions/moveBeyondSpreadBounds';
import { operationEnd, operationStart } from '../../../actions/operations';

export default function useMoveOperation(moveNodeId, initialValues, onUpdate) {
  const dispatch = useDispatch();
  const { clientToViewport } = useViewport();

  const handleStart = useCallback(() => {
    dispatch(operationStart({ moveNodeId }));
  }, [dispatch, moveNodeId]);

  const handleUpdate = useCallback(
    eventParams => {
      const { initialPointer, currentPointer } = eventParams;
      const { operationTargetNodeId } = initialValues;

      /**
       * The initial and current pointers are in client-coordinantes,
       * which need to be converted to the parent element's coordinates.
       */
      const parentDomNode = document.querySelector(
        `[data-id='${operationTargetNodeId}']`
      );
      const matrix = domMatrix(parentDomNode.getScreenCTM()).inverse();
      const initialPoint = matrix.transformPoint(initialPointer);
      const currentPoint = matrix.transformPoint(currentPointer);

      onUpdate({ eventParams, initialPoint, currentPoint });
    },
    [onUpdate, initialValues]
  );

  const handleEnd = useCallback(
    ({ currentPointer }) => {
      batch(() => {
        const viewportPoint = clientToViewport(currentPointer);
        dispatch(moveBeyondSpreadBounds(viewportPoint));
        dispatch(operationEnd());
      });
    },
    [dispatch, clientToViewport]
  );

  const { handleMouseDown } = useMouseDragCoordinates({
    onStart: handleStart,
    onUpdate: initialValues && handleUpdate,
    onEnd: handleEnd,
  });

  return handleMouseDown;
}
