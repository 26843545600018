import React from 'react';
import { func, string, bool } from 'prop-types';
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button';

import { RefShape } from '../../shapes';
import Icon from '../../Icon';

function SectionPlaceholder({
  onHeaderClick,
  onNameChange,
  onNameBlur,
  handleNameKeyDown,
  name,
  editing,
  titleInputRef,
  isHovered,
  setIsHovered,
}) {
  return (
    <div
      className="w-100 px-1 qa-section-new"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        onClick={onHeaderClick}
        className="w-100 d-flex align-items-center new-section-title qa-section-header-new"
        role="button"
        tabIndex={0}
        onKeyPress={onHeaderClick}
      >
        <Button
          className="text-muted"
          style={{
            visibility: !isHovered && 'hidden',
          }}
          variant="link"
        >
          <Icon name="plus" />
        </Button>
        <span className="m-0 py-4 w-100">
          {editing ? (
            <FormControl
              type="text"
              name="new_section_name"
              placeholder="Neues Kapitel"
              onChange={onNameChange}
              onBlur={onNameBlur}
              onKeyDown={handleNameKeyDown}
              value={name}
              ref={titleInputRef}
              maxLength={50}
            />
          ) : (
            <h6
              className={`m-0 ${isHovered ? 'text-primary' : 'text-muted'}`}
              variant="link"
            >
              Kapitel hinzufügen
            </h6>
          )}
        </span>
      </div>
    </div>
  );
}

SectionPlaceholder.defaultProps = {
  isHovered: false,
};

SectionPlaceholder.propTypes = {
  onHeaderClick: func.isRequired,
  titleInputRef: RefShape.isRequired,
  onNameChange: func.isRequired,
  onNameBlur: func.isRequired,
  handleNameKeyDown: func.isRequired,
  name: string.isRequired,
  editing: bool.isRequired,
  isHovered: bool,
  setIsHovered: func.isRequired,
};

export default SectionPlaceholder;
