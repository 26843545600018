import { node } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setViewportHeight } from '../../../actions/viewport';
import {
  resetViewToSpreadIndex,
  setPan,
  setZoom,
} from '../../../modules/controls';
import { getSpreadsCount } from '../../../selectors/legacy';
import {
  getAnimatedPan,
  getAnimatedZoom,
  getPan,
  getZoom,
} from '../../../selectors/viewport';
import {
  calculateViewLimits,
  restrictPanAndZoomToContentArea,
} from '../../../util/geometry';
import clientToViewportCoordinates from './clientToViewportCoordinates';
import ViewportContext from './ViewportContext';

function ViewportProvider({ children }) {
  const dispatch = useDispatch();
  const pan = useSelector(getPan);
  const zoom = useSelector(getZoom);
  const viewportRef = useRef(null);

  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    if (!viewportRef.current) {
      return null;
    }

    const observer = new ResizeObserver(entries => {
      if (!entries || entries.length === 0) {
        return;
      }

      const [entry] = entries;

      setDimensions({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
    });

    observer.observe(viewportRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(resetViewToSpreadIndex());
  }, [dispatch, dimensions]);

  const clientWidth = dimensions.width;
  const clientHeight = dimensions.height;

  // Todo: avoid the need for storing the viewportHeight in the redux-store
  useEffect(() => {
    dispatch(setViewportHeight(clientHeight));
  }, [clientHeight, dispatch]);

  // Closure over parameters for clientToViewportCoordinates
  const clientToViewport = useCallback(
    (point, somePan = pan, someZoom = zoom) =>
      clientToViewportCoordinates(point, somePan, someZoom, viewportRef),
    [pan, zoom]
  );

  // Closure over parameters for restrictPanAndZoomToContentArea
  const spreadsCount = useSelector(getSpreadsCount);

  const limits = calculateViewLimits(
    clientWidth,
    clientHeight,
    spreadsCount,
    zoom
  );

  const restrictPanAndZoom = (somePan, someZoom) =>
    restrictPanAndZoomToContentArea(somePan, someZoom, limits, clientWidth);

  // Calculate the viewbox (using pan like a scroll-offset)
  const animatedPan = useSelector(getAnimatedPan);
  const animatedZoom = useSelector(getAnimatedZoom);

  const viewBox = [
    animatedPan.x,
    animatedPan.y,
    clientWidth / animatedZoom,
    clientHeight / animatedZoom,
  ].join(' ');

  return (
    <ViewportContext.Provider
      value={{
        pan,
        zoom,
        viewportRef,
        viewBox,
        restrictPanAndZoom,
        clientToViewport,
        setPan: value => dispatch(setPan(value)),
        setZoom: value => dispatch(setZoom(value)),
        clientWidth,
        clientHeight,
        limits,
      }}
    >
      {children}
    </ViewportContext.Provider>
  );
}

ViewportProvider.propTypes = {
  children: node.isRequired,
};

export default ViewportProvider;
