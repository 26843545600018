import { bool, number, string } from 'prop-types';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateElements } from '../../../../actions/workspace';
import useAuth from '../../../../hooks/useAuth';
import { elementSelect } from '../../../../modules/selection';
import { getOperationInitialValues } from '../../../../selectors/operations';
import Icon from '../../../Icon';
import { ImageContext } from '../../../ImageContext';
import useMoveOperation from '../../../operations/MoveOperation/useMoveOperation';
import UnzoomedContent from '../../UnzoomedContent';
import CommentForm from './CommentForm';
import { selectIsAlbumEditable } from '../../../../selectors/albums';
import useScreenSize from '../../../../hooks/useScreenSize';

const commentIconSize = 30;

function Comment({
  id,
  x,
  y,
  userId,
  text: textFromProps,
  isSelected,
  preview,
}) {
  const initialPosition = useRef();
  const dispatch = useDispatch();
  const { rendering } = useContext(ImageContext);
  const isAlbumEditable = useSelector(selectIsAlbumEditable);
  const [isHovered, setIsHovered] = useState(false);
  const { isMobile } = useScreenSize();
  const {
    user: { sub },
    isAdmin,
  } = useAuth();

  const shouldRenderForm = isSelected || isHovered;
  const isDisabled = !isAdmin && sub !== userId;

  const [text, setText] = useState(textFromProps);
  const initialValues = useSelector(getOperationInitialValues);

  const handleUpdate = useCallback(
    ({ initialPoint, currentPoint }) => {
      dispatch(
        updateElements({
          [id]: {
            x: initialPosition.current.x + currentPoint.x - initialPoint.x,
            y: initialPosition.current.y + currentPoint.y - initialPoint.y,
          },
        })
      );
    },
    [dispatch, id]
  );

  const handleStartMove = useMoveOperation(id, initialValues, handleUpdate);

  const handleMouseDown = useCallback(
    event => {
      initialPosition.current = { x, y };
      handleStartMove(event);
      dispatch(elementSelect([id]));
    },
    [dispatch, id, handleStartMove, x, y]
  );

  const handleChange = ({ target: { value } }) => {
    setText(value);
    dispatch(updateElements({ [id]: { text: value } }));
  };

  /**
   * Comments should never be part of an exported album PDF and
   * should not be rendered in preview mode.
   *
   * TODO: The app crashes on mouse down events on mobile.
   */
  if (rendering || !isAlbumEditable || isMobile || preview) {
    return null;
  }

  return (
    <g
      data-id={id}
      transform={`translate(${x},${y})`}
      onMouseDown={handleMouseDown}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      className="comment qa-Comment-node"
    >
      <UnzoomedContent
        width={commentIconSize}
        height={commentIconSize}
        hideBelowSize={0}
      >
        <Icon name="comment_circle" className="qa-comment-icon" />
        {shouldRenderForm && (
          <CommentForm
            value={text}
            initialFocus={isSelected}
            handleChange={handleChange}
            disabled={isDisabled}
          />
        )}
      </UnzoomedContent>
    </g>
  );
}

Comment.defaultProps = {
  preview: false,
};

Comment.propTypes = {
  id: string.isRequired,
  text: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  isSelected: bool.isRequired,
  userId: string.isRequired,
  preview: bool,
};

export default Comment;
