import React, { useEffect } from 'react';

import useAnalytics from './useAnalytics';

const withAnalytics = WrappedComponent => props => {
  const analytics = useAnalytics();

  useEffect(() => {
    if (!analytics.acceptedNecessary) {
      return;
    }

    analytics.setCookies();
    analytics.identify();
    analytics.page();
  }, [analytics]);

  return <WrappedComponent {...props} />;
};

export default withAnalytics;
