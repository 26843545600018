import { Splide, SplideSlide } from '@splidejs/react-splide';
import isEqual from 'lodash/isEqual';
import { arrayOf } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { wizardMobileSplideOptions } from '../../../../../../constants';
import useAnalytics from '../../../../../../containers/app/useAnalytics';
import { replaceFonts } from '../../../../../../modules/colorsAndFonts';
import { FontsShape } from '../../../../../shapes';
import FontTiles from '../FontTiles';

function FontsCarousel({ options, value }) {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  function handleChange(nextFonts) {
    dispatch(replaceFonts(nextFonts));

    analytics.track('Wizard Fonts Updated', {
      fontsValue: value,
    });
  }

  return (
    <div className="bg-white pt-4">
      <div className="wizard-styles-panel-mobile__carousels__label text-muted mb-2">
        Schriftstil
      </div>
      <Splide
        options={{
          ...wizardMobileSplideOptions,
          fixedWidth: '7rem',
        }}
      >
        {options.map(option => (
          <SplideSlide key={Object.values(option).join('')}>
            <FontTiles
              square
              className="py-1"
              onClick={() => handleChange(option)}
              fonts={option}
              active={isEqual(value, option)}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

FontsCarousel.propTypes = {
  options: arrayOf(FontsShape).isRequired,
  value: FontsShape.isRequired,
};

export default FontsCarousel;
