import React from 'react';
import { number, node, string } from 'prop-types';

function HandlesGroup({ className, x, y, width, height, rotation, children }) {
  return (
    <g
      transform={`translate(${x},${y}) rotate(${rotation})`}
      className={className}
    >
      <rect className="border" width={width} height={height} />
      {children}
    </g>
  );
}

HandlesGroup.defaultProps = {
  children: null,
  rotation: 0,
};

HandlesGroup.propTypes = {
  className: string.isRequired,
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  rotation: number,
  children: node,
};

export default HandlesGroup;
