import { arrayOf, bool, func, string } from 'prop-types';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import CheckoutSummary from './CheckoutSummary';
import { CheckoutFormDataShape } from '../../components/shapes';
import { CheckoutShape } from './shapes';
import CheckoutContactForm from './CheckoutContactForm';
import CheckoutProductForm from './CheckoutProductForm';
import CheckoutSubmitControl from './CheckoutSubmitControl';
import { commentMaxLength } from './config';
import CheckoutAlbumPreview from './album_preview/CheckoutAlbumPreview';

export function InvalidAlbumMessage({ errors }) {
  return (
    <Alert variant="danger" className="border mb-0">
      <ul className="my-2">
        {errors.map(err => (
          <li key={err}>{err}</li>
        ))}
      </ul>
    </Alert>
  );
}

InvalidAlbumMessage.propTypes = { errors: arrayOf(string).isRequired };

function CheckoutForm({
  checkout,
  onCheckoutAlbumCountChange,
  formData,
  setFormData,
  isFetchingCheckout,
}) {
  const {
    errors: albumErrors,
    album,
    blueprint_category: blueprintCategory,
    available_features: availableFeatures,
  } = checkout;

  const [checkoutStep, setCheckoutStep] = useState('preview');
  const productsFromLineItems = checkout.line_items.map(item => item.product);

  return (
    <Form className="checkout-form qa-checkout-form">
      {checkoutStep === 'preview' ? (
        <Row className="d-flex flex-column align-items-center">
          <Col>
            {albumErrors.length > 0 && (
              <InvalidAlbumMessage errors={albumErrors} />
            )}
          </Col>
          <Col className="pt-3">
            <CheckoutAlbumPreview />
          </Col>
          <Col md={4} className="pt-3 text-center">
            <Form.Group className="pt-3 text-center">
              <CheckoutSubmitControl
                checkoutStep={checkoutStep}
                blueprintCategory={blueprintCategory}
                setCheckoutStep={setCheckoutStep}
                formData={formData}
                album={album}
                albumErrors={albumErrors}
              />
            </Form.Group>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={8}>
            {checkoutStep === 'product' && (
              <CheckoutProductForm
                productsFromLineItems={productsFromLineItems}
                availableFeatures={availableFeatures}
                blueprintCategory={blueprintCategory}
                formData={formData}
                setFormData={setFormData}
                onCheckoutAlbumCountChange={onCheckoutAlbumCountChange}
              />
            )}
            {checkoutStep === 'contact' && (
              <CheckoutContactForm
                blueprintCategory={blueprintCategory}
                formData={formData}
                setFormData={setFormData}
                commentMaxLength={commentMaxLength}
              />
            )}
          </Col>
          <Col md={4}>
            {albumErrors.length > 0 && (
              <InvalidAlbumMessage errors={albumErrors} />
            )}
            {albumErrors.length === 0 && (
              <CheckoutSummary
                setFormData={setFormData}
                formData={formData}
                checkout={checkout}
                isFetchingCheckout={isFetchingCheckout}
              />
            )}
            <Form.Group className="pt-3 text-center">
              <CheckoutSubmitControl
                checkoutStep={checkoutStep}
                blueprintCategory={blueprintCategory}
                setCheckoutStep={setCheckoutStep}
                formData={formData}
                album={album}
                albumErrors={albumErrors}
              />
            </Form.Group>
          </Col>
        </Row>
      )}
    </Form>
  );
}

CheckoutForm.propTypes = {
  checkout: CheckoutShape.isRequired,
  onCheckoutAlbumCountChange: func.isRequired,
  formData: CheckoutFormDataShape.isRequired,
  setFormData: func.isRequired,
  isFetchingCheckout: bool.isRequired,
};

export default CheckoutForm;
