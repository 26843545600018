import React, { useState } from 'react';
import { func, node, number, shape, string } from 'prop-types';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Badge from 'react-bootstrap/Badge';

import AppLayout from '../../../components/ui/AppLayout';
import Icon from '../../../components/Icon';
import useQuery from '../../../hooks/useQuery';
import Pagination from '../../../components/generic/Pagination';
import OrdersTable from './OrdersTable';

const orderStates = {
  show_all: 'Alle',
  pending_review: 'Review',
  pending_transfer: 'Transfer',
  pending_shipment: 'Versand',
  shipped: 'versandt',
};

function OrderStatusTabs({ activeKey, onChange, children, meta }) {
  return (
    <Tabs
      activeKey={activeKey}
      className="mb-3"
      onSelect={key => onChange(key)}
    >
      {Object.keys(orderStates).map(key => (
        <Tab
          key={key}
          eventKey={key}
          title={
            <>
              {orderStates[key]} {meta[key] > 0 && <Badge>{meta[key]}</Badge>}
            </>
          }
          tabClassName={`qa-status-tab-${key}`}
        >
          {children}
        </Tab>
      ))}
    </Tabs>
  );
}

OrderStatusTabs.defaultProps = {
  meta: {},
};

OrderStatusTabs.propTypes = {
  activeKey: string.isRequired,
  onChange: func.isRequired,
  children: node.isRequired,
  meta: shape({
    pending_review: number,
    pending_transfer: number,
    pending_shipment: number,
  }),
};

function SearchBar({ onSubmit, value, onChange }) {
  return (
    <Form onSubmit={onSubmit}>
      <InputGroup>
        <InputGroup.Text>
          <Icon name="search" />
        </InputGroup.Text>
        <FormControl
          onChange={onChange}
          value={value}
          type="search"
          className="me-2 qa-orders-search-input"
          name="orders-search-input"
          placeholder="Search"
        />
      </InputGroup>
    </Form>
  );
}

SearchBar.propTypes = {
  onSubmit: func.isRequired,
  value: string.isRequired,
  onChange: func.isRequired,
};

function SortControls({
  sortBy,
  sortOrder,
  onSortByChange,
  onSortOrderChange,
}) {
  const sortByOptions = {
    release_date: 'Event',
    created_at: 'Erstellt am',
  };

  const sortOrderOptions = {
    asc: <Icon name="chevron_up" />,
    desc: <Icon name="chevron_down" />,
  };

  return (
    <>
      <ButtonGroup>
        {Object.keys(sortByOptions).map(key => (
          <Button
            variant="light"
            active={sortBy === key}
            onClick={() => onSortByChange(key)}
            className={`qa-sort-by-${key}`}
          >
            {sortByOptions[key]}
          </Button>
        ))}
      </ButtonGroup>
      <ButtonGroup className="ml-3">
        {Object.keys(sortOrderOptions).map(key => (
          <Button
            variant="light"
            active={sortOrder === key}
            onClick={() => onSortOrderChange(key)}
            className={`qa-sort-order-${key}`}
          >
            {sortOrderOptions[key]}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
}

SortControls.propTypes = {
  sortBy: string.isRequired,
  sortOrder: string.isRequired,
  onSortByChange: func.isRequired,
  onSortOrderChange: func.isRequired,
};

function OrdersDashboard() {
  const [stateFilter, setStateFilter] = useState('show_all');
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState('');
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState('release_date');
  const [sortOrder, setSortOrder] = useState('asc');

  const {
    data,
    error,
    isLoading,
    pagination,
  } = useQuery(
    `/orders/dashboard?page=${page}&per=20&scope=${stateFilter}&query=${search}&sort_by=${sortBy}&sort_order=${sortOrder}`,
    [page, stateFilter, search, sortBy, sortOrder]
  );

  function handleScopeChange(key) {
    setPage(1);
    setStateFilter(key);
    setQuery('');
    setSearch('');
  }

  function handleSearch(event) {
    event.preventDefault();

    setSearch(query);
    setStateFilter('show_all');
    setPage(1);
  }

  const infoAlert =
    'Deadlines basieren auf dem Event- oder Transfer-Datum: Wenn noch kein Transfer stattgefunden hat, berechnen wir die spätestmöglichen Zeitpunkte, um rechtzeitig auszuliefern. Hat der Transfer stattgefunden, berechnen wir die Zeitpunkte, zu denen wir Alben-Versand und Versand bei unseren Partnern erwarten (Transfer + X Tage).';

  return (
    <AppLayout>
      <Row className="my-5">
        <Col sm={12} md={6} lg={6}>
          <h1 className="font-weight-bold">Dashboard</h1>
        </Col>
      </Row>
      {error && (
        <Alert variant="danger">
          <p>Fehler: {error.message}</p>
        </Alert>
      )}
      <Row className="my-3">
        <Col lg={12} md={12} className="d-flex">
          <div className="flex-grow-1 mr-3">
            <SearchBar
              value={query}
              onChange={({ target: { value } }) => setQuery(value)}
              onSubmit={handleSearch}
            />
          </div>
          <SortControls
            sortBy={sortBy}
            sortOrder={sortOrder}
            onSortByChange={value => setSortBy(value)}
            onSortOrderChange={value => setSortOrder(value)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col lg={12} md={12}>
          <OrderStatusTabs
            activeKey={stateFilter}
            onChange={key => handleScopeChange(key)}
            meta={data?.meta}
          >
            <Alert variant="light" className="border">
              {infoAlert}
            </Alert>
            <OrdersTable data={data} isLoading={isLoading} />
            <Pagination
              active={page}
              total={pagination?.total_pages}
              onChange={setPage}
            />
          </OrderStatusTabs>
        </Col>
      </Row>
    </AppLayout>
  );
}

OrdersDashboard.defaultProps = {};

OrdersDashboard.propTypes = {};

export default OrdersDashboard;
