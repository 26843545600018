import React, { useRef } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import Popover from 'react-bootstrap/Popover';
import { bool, func, number, shape, string } from 'prop-types';

import useTimeSwitch from './useTimeSwitch';

/**
 * By default, we will show a Hint automatically
 * after 3 and hide it again after 8 seconds.
 */
const defaultHintToggleOptions = {
  onDelay: 3000,
  offDelay: 5000,
  autoOn: true,
  autoOff: true,
};

/**
 * A `Hint` is an explanatory tooltip. It uses render props to pass the `target` ref
 * to clients that will use it to attach it to DOM nodes. By default, it will show
 * and hide itself after a few seconds.
 */
function Hint({ children, text, placement, toggle }) {
  const target = useRef();

  const { value, setOn: show, setOff: hide } = useTimeSwitch({
    ...defaultHintToggleOptions,
    ...toggle,
  });

  return (
    <>
      {children({ target, show, hide })}
      <Overlay
        className="qa-hint-overlay"
        target={target}
        show={value}
        placement={placement}
      >
        <Popover className="popover-hint qa-popover-hint shadow">
          <Popover.Content>{text}</Popover.Content>
        </Popover>
      </Overlay>
    </>
  );
}

Hint.defaultProps = {
  placement: undefined,
  toggle: defaultHintToggleOptions,
};

Hint.propTypes = {
  children: func.isRequired,
  text: string.isRequired,
  placement: string,
  toggle: shape({
    onDelay: number,
    offDelay: number,
    autoOn: bool,
    autoOff: bool,
  }),
};

export default Hint;
