import React from 'react';
import { useSelector } from 'react-redux';

import { actions } from '../../../../constants';
import { getSingleSelectedElement } from '../../../../selectors/legacy';
import { getTextEditActive } from '../../../../selectors/selection';
import ActionButton from '../ActionButton';

function TextEditButton(props) {
  const textEditActive = useSelector(getTextEditActive);
  const singleSelectedElement = useSelector(getSingleSelectedElement);

  // Do not render button in multi-selection
  if (!singleSelectedElement) {
    return null;
  }

  return (
    <ActionButton
      {...props}
      actionName={textEditActive ? actions.exitEditText : actions.editText}
      tooltip={textEditActive ? 'Bearbeitung beenden' : 'Text bearbeiten'}
      icon={textEditActive ? 'close' : 'edit'}
      disabled={!!singleSelectedElement?.props?.symbol}
    />
  );
}

export default TextEditButton;
