// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/react';
import Button from 'react-bootstrap/Button';

class ErrorBoundary extends React.Component {
  state = {
    errorInfo: null,
  };

  componentDidMount() {
    window.addEventListener('error', this.windowError);
  }

  componentWillUnmount() {
    window.removeEventListener('error', this.windowError);
  }

  handleError(error) {
    console.error(error);
    Sentry.captureException(error);

    this.setState({ errorInfo: error.message });
  }

  componentDidCatch(error) {
    this.handleError(error);
  }

  windowError = ({ error }) => {
    // An event with message "ResizeObserver loop limit exceeded" and no event.error shows up since the viewport-backport
    // This is probably because of the weird "shrinking scrollbar" behavior during load and should be fixed when the editor-layout
    // is cleaned up.
    // Todo: Avoid workaround (see https://stickerstars.atlassian.net/browse/STC-344)
    if (error) {
      this.handleError(error);
    }
  };

  render() {
    const { children } = this.props;
    const { errorInfo } = this.state;

    if (errorInfo) {
      return (
        <div className="w-100 h-100 d-flex align-items-center justify-content-center">
          <div className="text-center w-25">
            <h1>Oh no :(</h1>
            <p>
              Bei der letzten Aktion ist leider etwas schiefgegangen. Unsere
              Entwickler:innen wurden informiert. Dein letzter Stand wurde
              gespeichert.
            </p>
            <p>
              <code>Error: {errorInfo}</code>
            </p>
            <Button onClick={() => window.location.reload()}>Neu Laden</Button>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default connect()(ErrorBoundary);
