import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addToQueue, removeFromQueue } from '../modules/loading';
import useFirstTime from './useFirstTime';

export const commonTypes = {
  uploadingFiles: 'uploadingFiles',
  fetchingAlbum: 'fetchingAlbum',
  fetchingBlueprints: 'fetchingBlueprints',
  fetchingPexel: 'fetchingPexel',
  fetchingOnboarding: 'fetchingOnboarding',
  patchTitleReleaseDate: 'patchTitleReleaseDate',
};

/**
 * A super thin wrapper around the `loading` store to start/stop
 * and get current loading state of a specific loading key
 * (e. g. `uploadingFiles` = currently uploading images).
 *
 * @param {string} jobId
 * @param {boolean} initialValue
 */
function useLoading(jobId, initialValue = false) {
  const dispatch = useDispatch();
  const queue = useSelector(state => state.loading);

  const startLoading = useCallback(() => dispatch(addToQueue(jobId)), [
    jobId,
    dispatch,
  ]);

  const stopLoading = useCallback(() => dispatch(removeFromQueue(jobId)), [
    jobId,
    dispatch,
  ]);

  const loadWhile = useCallback(
    async callback => {
      startLoading();
      await callback();
      stopLoading();
    },
    [startLoading, stopLoading]
  );

  useFirstTime(startLoading, initialValue);

  const isLoading = !!queue.find(({ id }) => id === jobId);

  return {
    startLoading,
    stopLoading,
    isLoading,
    loadWhile,
  };
}

export default useLoading;
