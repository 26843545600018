import React, { useEffect, useState } from 'react';
import { shape, string } from 'prop-types';
import axios from 'axios';
import { useWindowSize, useTitle } from 'react-use';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';
import isEmpty from 'lodash/isEmpty';

import { API_URL } from '../../constants';
import steps from './steps';
import { useFormState } from './FormStateProvider';
import Header from './Header';
import Navigation from './Navigation';
import useClientId from './useClientId';

function StickerUploadForm(props) {
  const {
    match: {
      params: { token },
    },
  } = props;

  const [clientId] = useClientId();
  const [error, setError] = useState(undefined);

  const [authenticated, setAuthenticated] = useState(false);
  const [config, setConfig] = useState({
    title: '',
    whiteLabel: true,
    content: '',
  });

  const { height } = useWindowSize();

  const { step, setStep } = useFormState();
  const { component: CurrentStep } = steps[step];

  useTitle(
    config.whiteLabel ? 'Foto-Uploader' : 'Stickerstars | Foto-Uploader',
    [config.whiteLabel]
  );

  useEffect(() => {
    if (isEmpty(token)) {
      return;
    }

    axios
      .get(`${API_URL}/public/sticker_uploads/${token}`)
      .then(
        ({
          data: {
            title,
            content,
            white_label: whiteLabel,
            require_position: requirePosition,
            sections,
          },
        }) => {
          setAuthenticated(true);
          setConfig({
            title,
            whiteLabel,
            content,
            requirePosition,
            sections,
          });
        }
      )
      .catch(setError);
  }, [token]);

  return (
    <div className="sticker-upload-form" style={{ height }}>
      <div className="form-content">
        {!error && !authenticated && (
          <div className="h-100 d-flex align-items-center justify-content-center">
            <Spinner animation="border" />
          </div>
        )}
        {error && (
          <Alert className="m-2 qa-disabled" variant="primary">
            Dieses Album existiert nicht oder akzeptiert im Moment keine
            Uploads.
          </Alert>
        )}
        {authenticated && (
          <>
            <Header config={config} />

            <div className="body">
              <div className="w-100 m-auto">
                <CurrentStep
                  clientId={clientId}
                  token={token}
                  config={config}
                />
              </div>
            </div>
            <Navigation config={config} step={step} setStep={setStep} />
          </>
        )}
      </div>
    </div>
  );
}

StickerUploadForm.propTypes = {
  match: shape({
    params: shape({
      token: string,
    }),
  }).isRequired,
};

export default StickerUploadForm;
