import { useEffect } from 'react';
import Webfont from 'webfontloader';
import { useDispatch } from 'react-redux';

import { webFontsUrl, webFontFamilies } from './constants';
import { updateControls } from './modules/controls';

function WebFontsLoader() {
  const dispatch = useDispatch();

  const handleStoreUpdate = () => {
    dispatch(updateControls({ webFontsLoaded: true }));
  };

  useEffect(() => {
    Webfont.load({
      custom: {
        families: webFontFamilies,
        urls: [webFontsUrl],
      },
      active: handleStoreUpdate,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
}

export default WebFontsLoader;
