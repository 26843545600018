import * as Sentry from '@sentry/react';

// We need to temporarily bundle and import from our own pexels fork.
import { createClient } from '../lib/pexels/dist/main';
import { getPexelsCache } from '../selectors/pexels';
import { ADD_PHOTOS_TO_CACHE } from '../modules/pexels';
import { onlyUnique } from '../util';
import { getSimplifiedImageNodes } from '../selectors/legacy';

const API_KEY = '563492ad6f917000010000016ec79ce2ef0d4bb4aa04b1c2e050f68d';
const client = createClient(API_KEY);

export const addPhotosToCache = payload => ({
  type: ADD_PHOTOS_TO_CACHE,
  payload,
});

export const executeSearchQuery = (...args) => async dispatch => {
  const result = await client.photos.search.apply(window, args);
  if (Array.isArray(result.photos)) {
    dispatch(addPhotosToCache(result.photos));
  }
  return result;
};

export const resolvePexelsId = id => async (dispatch, getState) => {
  if (!id) {
    return null;
  }
  const cache = getPexelsCache(getState());
  if (cache?.[id]) {
    return cache[id];
  }

  /**
   * If pexels authorization fails, the API will respond with a 502 status
   * code that the pexels client cannot process. We need to escape the
   * corresponding error to prevent our client from crashing.
   *
   * FYI: The pexels client crash was observed with 502 responses, but
   * will probably be caused by other server error responses as well.
   */
  let result;
  try {
    result = await client.photos.show({ id });
  } catch (err) {
    Sentry.captureException(`Pexels request failed: ${err}`, { pexelsId: id });
    return null;
  }

  if (result.src?.small) {
    const img = new Image();
    img.src = result.src.small;
  }

  dispatch(addPhotosToCache([result]));
  return result;
};

export const fetchPexelsImagesForWorkspace = workspace => async dispatch => {
  const images = getSimplifiedImageNodes({
    workspaceAndStickers: { present: { workspace } },
  });
  const pexelsImages = images.filter(({ props: { pexelsId } }) => !!pexelsId);
  const pexelsIds = pexelsImages
    .map(({ props: { pexelsId } }) => pexelsId)
    .filter(onlyUnique);
  return Promise.all(pexelsIds.map(id => dispatch(resolvePexelsId(id))));
};
