import { createSelector } from 'reselect';

export const getColors = state => state.colorsAndFonts.colors;

export const getFonts = state => state.colorsAndFonts.fonts;

export const makeGetColorByIndexOrValue = () =>
  createSelector(
    [getColors, (_, colorIndexOrValue) => colorIndexOrValue],
    (colors, colorIndexOrValue) => {
      if (colorIndexOrValue === null || colorIndexOrValue === 'null') {
        return null;
      }

      if (typeof colorIndexOrValue === 'string') {
        return colorIndexOrValue;
      }

      return colors[colorIndexOrValue];
    }
  );
