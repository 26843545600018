import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import sortBy from 'lodash/sortBy';

import {
  SWAP_ELEMENTS,
  SEND_ELEMENTS_TO_FRONT,
  SEND_ELEMENTS_TO_BACK,
  MOVE_ELEMENTS_TO_SPREAD,
  UNGROUP_ELEMENTS,
  REPLACE_ELEMENTS,
  INSERT_ELEMENTS,
  GROUP_ELEMENTS,
  UPDATE_ELEMENTS,
} from '../../actions/workspace';
import { elementSortTiers } from '../../constants';

export const relevantActions = [
  SWAP_ELEMENTS,
  SEND_ELEMENTS_TO_FRONT,
  SEND_ELEMENTS_TO_BACK,
  MOVE_ELEMENTS_TO_SPREAD,
  GROUP_ELEMENTS,
  UNGROUP_ELEMENTS,
  REPLACE_ELEMENTS,
  INSERT_ELEMENTS,
  UPDATE_ELEMENTS,
];

function drawOrder(workspace) {
  const { nodes, root } = workspace;

  const cache = {};
  const getSortTierType = id => {
    const { type, children, props } = nodes[id];

    if (props.alwaysOnTop) {
      return 'ElementAlwaysOnTop';
    }

    if (type !== 'Group') {
      return type;
    }

    if (!cache[id]) {
      const hasTextChildren = children?.some(
        childId => getSortTierType(childId) === 'Text'
      );
      cache[id] = hasTextChildren ? 'GroupOfText' : type;
    }
    return cache[id];
  };

  const callback = node => {
    const { children } = node;
    /**
     * "This method performs a stable sort, that is, it preserves the original sort order of equal elements."
     * https://lodash.com/docs/#sortBy
     * Since the original order for equal elements is preserved, we can return only 3 distinct numbers:
     */
    const sortedChildren = sortBy(children, childId => {
      // Draw text elements above all
      const effectiveType = getSortTierType(childId);
      return elementSortTiers[effectiveType];
    });

    const equal = isEqual(children, sortedChildren);

    return equal ? node : { ...node, children: sortedChildren };
  };

  return {
    root,
    nodes: mapValues(nodes, callback),
  };
}

export default (state, action) => {
  const { type } = action;

  // For performance: don't apply for this action, since it can't change the order
  if (!relevantActions.includes(type)) {
    return state;
  }
  return drawOrder(state);
};
