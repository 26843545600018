import React from 'react';
import { number } from 'prop-types';

import { getAppearanceFromProps } from '../../../selectors/legacy';
import BaseElement from './BaseElement';

function Circle(props) {
  const { height, width } = props;

  return (
    <BaseElement {...props}>
      <ellipse
        {...getAppearanceFromProps(props)}
        cx={width / 2}
        cy={height / 2}
        rx={width / 2}
        ry={height / 2}
      />
    </BaseElement>
  );
}

Circle.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
};

export default Circle;
