import React from 'react';
import { string, func, number, oneOfType } from 'prop-types';

import { resolutions } from '../../../constants';
import Image from '../Image';
import { RefShape } from '../../shapes';

function ImageItem({
  src,
  image: imageId,
  pexelsId,
  className,
  onClick,
  parentRef,
  dragRef,
  alt,
}) {
  return (
    <div
      role="presentation"
      className={`h-100 image-item qa-image-item ${className}`}
      onClick={onClick}
      ref={parentRef}
    >
      <div className={`h-100 qa-image-${imageId}`} ref={dragRef}>
        <Image
          alt={alt}
          src={src}
          imageId={imageId}
          pexelsId={pexelsId}
          resolution={resolutions.medium}
        />
      </div>
    </div>
  );
}

ImageItem.defaultProps = {
  alt: 'alternative missing. Sorry!',
  src: null,
  image: null,
  pexelsId: null,
  className: '',
  onClick: () => {},
  dragRef: () => {},
  parentRef: null,
};

ImageItem.propTypes = {
  alt: string,
  src: string,
  image: oneOfType([string, number]),
  pexelsId: number,
  className: string,
  onClick: func,
  dragRef: func,
  parentRef: RefShape,
};

export default ImageItem;
