import React, { useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import AlbumPreviewNavigator from '../../../components/ui/AlbumWizard/Content/AlbumPreviewNavigator';
import { getWorkspace } from '../../../selectors/legacy';
import useApi from '../../../hooks/useApi';
import { receiveAlbumPreview } from '../../../modules/albums';
import useScreenSize from '../../../hooks/useScreenSize';
import SvgDefs from '../../editor/SvgDefs';

function CheckoutAlbumPreview() {
  const dispatch = useDispatch();
  const workspace = useSelector(getWorkspace);
  const { isMobile } = useScreenSize();

  const api = useApi();
  const {
    params: { albumId },
  } = useRouteMatch();

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { album },
        } = await api.get(`/albums/${albumId}/preview`);
        dispatch(receiveAlbumPreview(album));
      } catch (error) {
        // do nothing
      }
    })();
  }, [dispatch, api, albumId]);

  return (
    <div className="checkout-album-preview d-flex flex-column w-100 h-100 justify-content-around">
      <Form.Label>
        <b>Sieht alles so aus, wie du es dir vorstellst?</b>
      </Form.Label>
      <div className="album-preview-container d-flex p-3">
        <AlbumPreviewNavigator workspace={workspace} flatButtons={isMobile} />
        <SvgDefs />
      </div>
    </div>
  );
}

export default CheckoutAlbumPreview;
