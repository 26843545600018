import React from 'react';
import { number } from 'prop-types';

import ResizeHandle from './ResizeHandle';
import { clockwiseEdgePivotNames, pivotNames } from './pivots';

const ResizeOperation = ({ width, height }) => {
  return clockwiseEdgePivotNames.map((pivotName, index) => {
    const opposingPivotName =
      clockwiseEdgePivotNames[(index + 4) % clockwiseEdgePivotNames.length];
    let fixDimension;
    if (pivotName === pivotNames.top || pivotName === pivotNames.bottom) {
      fixDimension = 'x';
    } else if (
      pivotName === pivotNames.left ||
      pivotName === pivotNames.right
    ) {
      fixDimension = 'y';
    } else {
      fixDimension = null;
    }
    return (
      <ResizeHandle
        key={pivotName}
        pivotName={pivotName}
        handleName={opposingPivotName}
        width={width}
        height={height}
        fixDimension={fixDimension}
      />
    );
  });
};

ResizeOperation.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
};

export default ResizeOperation;
