import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { bool, func } from 'prop-types';

function SubmitButtonWithLink({ onSubmit, disabled }) {
  /**
   * Ugly: Links cannot be disabled, so we prevent the default action
   * on invalid input.
   */
  const handleLinkClick = event => {
    if (disabled) {
      event.preventDefault();
    }

    return null;
  };

  return (
    <Link
      to={`/album/new/confirm${window.location.search}`}
      onClick={handleLinkClick}
    >
      <Button
        className="px-4 qa-finish-btn"
        onClick={onSubmit}
        disabled={disabled}
        block
      >
        Album erstellen
      </Button>
    </Link>
  );
}

SubmitButtonWithLink.defaultProps = {};

SubmitButtonWithLink.propTypes = {
  onSubmit: func.isRequired,
  disabled: bool.isRequired,
};

export default SubmitButtonWithLink;
