import { useCallback } from 'react';
import some from 'lodash/some';
import { useSelector, useDispatch } from 'react-redux';
import { useEvent } from 'react-use';

import { isComment, isTextFrame } from '../../../util/index';
import { historyAnchor } from '../../../modules/history';
import { getSelectedElements } from '../../../selectors/legacy';
import { ClipboardActions } from '../../../util/clipboard';
import { selectAllowedActions } from '../../../selectors/selection';
import { actions } from '../../../constants';

function Clipboard() {
  const selectedElements = useSelector(getSelectedElements);
  const selectInside = useSelector(state => state.selection.selectInside);
  const dispatch = useDispatch();
  const allowedActions = useSelector(selectAllowedActions);
  const isAllowedClipboardAction =
    allowedActions?.indexOf(actions.duplicateItems) > -1;

  const handleClipboardAction = useCallback(
    (action, event, setHistoryAnchor) => {
      /**
       * disable clipboardActions on comments if not admin or author
       */
      if (some(selectedElements, isComment) && !isAllowedClipboardAction) {
        return;
      }

      /**
       * Let draftjs handle clipboard actions when inside a text element,
       * otherwise dispatch own clipboard action.
       */
      if (some(selectedElements, isTextFrame) && selectInside) {
        return;
      }
      ClipboardActions[action](event, selectedElements);
      if (setHistoryAnchor) {
        dispatch(historyAnchor());
      }
    },
    [dispatch, selectInside, selectedElements, isAllowedClipboardAction]
  );

  useEvent('cut', event => handleClipboardAction('cutItems', event, true));
  useEvent('copy', event => handleClipboardAction('copyItems', event, false));
  useEvent('paste', event => handleClipboardAction('pasteItems', event, true));

  return null;
}

export default Clipboard;
