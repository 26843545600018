import React, { memo } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { useDispatch, useSelector } from 'react-redux';

import tools from './tools';
import ToolCreateButton from './ToolCreateButton';
import ToolButton from './ToolButton';
import { getShowStickers } from '../../../../selectors/controls';
import { updateControls } from '../../../../modules/controls';
import FadeInWhenAlbumLoaded from '../../../generic/FadeInWhenAlbumLoaded';

function ToolMenu() {
  const dispatch = useDispatch();
  const showStickers = useSelector(getShowStickers);

  const title = `Druckvorschau ${showStickers ? 'einschalten' : 'ausschalten'}`;

  return (
    <FadeInWhenAlbumLoaded>
      <div className="workspace-tool-menu">
        <ButtonGroup vertical className="rounded shadow qa-tool-menu">
          {tools.map(tool => (
            <ToolCreateButton key={tool.id} tool={tool} />
          ))}
          <ToolButton
            id="printer_fill"
            title={title}
            onClick={() =>
              dispatch(updateControls({ showStickers: !showStickers }))
            }
            active={!showStickers}
          />
        </ButtonGroup>
      </div>
    </FadeInWhenAlbumLoaded>
  );
}

export default memo(ToolMenu);
