import React, { memo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useMeasure } from 'react-use';

import useFadeIn from '../../../hooks/useFadeIn';
import useSelectionBounds from '../../../hooks/useSelectionBounds';
import { getOperationActive } from '../../../selectors/controls';
import { getSelectedElementIds } from '../../../selectors/legacy';
import {
  selectAllowedApplicableTools,
  getTextEditActive,
} from '../../../selectors/selection';
import { RefShape } from '../../shapes';
import ToolbarNotification from './ToolbarNotification';
import * as toolComponents from './tools';

/**
 * Offset to move toolbar above the element. It includes the height of the
 * toolbar itself (~ 50 px), leaving about 30px space. This amount is needed
 * to not block the handles
 */
const toolbarDistance = 80;

const dropdownMenuHeight = 190;

function Toolbar({ workspaceRef }) {
  const tools = useSelector(selectAllowedApplicableTools);
  const [measureRef, { width }] = useMeasure();
  const selectedElementIds = useSelector(getSelectedElementIds);
  const operationActive = useSelector(getOperationActive);
  const selectionBounds = useSelectionBounds();
  const textEditActive = useSelector(getTextEditActive);
  const ref = useRef();
  const { style } = useFadeIn(ref);

  const showToolbar = selectedElementIds.length > 0 && !operationActive;
  const workspaceRect = workspaceRef.current?.getBoundingClientRect();
  if (!showToolbar || !selectionBounds || !workspaceRect) {
    return null;
  }

  // During text-edit, the toolbar should be aligned to center, otherwise left
  const toolbarWidthInTextEditMode = 840;
  const toolbarCentered =
    selectionBounds.x +
    (selectionBounds.width - toolbarWidthInTextEditMode) / 2;
  const toolbarPosition = textEditActive ? toolbarCentered : selectionBounds.x;

  // Calculate left and top, keeping the toolbar in the workspace client rect
  const xMin = Math.max(workspaceRect.x, toolbarPosition);
  const left =
    toolbarPosition < workspaceRect.x
      ? workspaceRect.x
      : Math.min(xMin, workspaceRect.right - width);
  const yOffset = -toolbarDistance;
  const top = Math.max(workspaceRect.y, selectionBounds.y + yOffset);

  // sets the notification over or under the toolbar
  const notificationTop = top > 0 ? top - 50 : 50;
  const notificationPosition = { top: notificationTop, left };

  /**
   * If there is enough space above the toolbar, prefer dropping the
   * dropdowns "up", so the element that is being edited is not overlayed
   * by the dropdown-menus.
   */
  const dropDirection = top > dropdownMenuHeight ? 'up' : 'down';

  return (
    <div
      className="element-toolbar qa-toolbar position-fixed shadow rounded"
      ref={ref}
      style={{ ...style, left, top }}
    >
      <ToolbarNotification position={notificationPosition} />
      <div ref={measureRef}>
        {tools.map(toolName => {
          const Component = toolComponents[toolName];
          return <Component key={toolName} dropDirection={dropDirection} />;
        })}
      </div>
    </div>
  );
}

Toolbar.propTypes = {
  workspaceRef: RefShape.isRequired,
};

export default memo(Toolbar);
