import { createAction } from 'redux-actions';

export const SET_STICKER_UPLOADS = 'stickerUploads/set';
export const SET_MERGED_AT = 'stickerUploads/setMergedAt';
export const DELETE = 'stickerUploads/DELETE';
export const RESET = 'stickerUploads/RESET';

/**
 * Initialize sticker uploads with server response.
 */
export const setStickerUploads = createAction(SET_STICKER_UPLOADS);

/**
 * Set the `mergedAt` prop for a list of sticker uploads.
 */
export const setMergedAt = createAction(SET_MERGED_AT, (ids, mergedAt) => ({
  ids,
  mergedAt,
}));

/**
 * Delete uploads.
 */
export const deleteStickerUploads = createAction(DELETE, ids => ({ ids }));

/**
 * Reset to initial state.
 */
export const resetStickerUploads = createAction(RESET);
