import { bindActionCreators } from 'redux';
import { useDispatch } from 'react-redux';

import { resolvePexelsId, executeSearchQuery } from '../actions/pexels';

export default function usePexel() {
  const dispatch = useDispatch();
  const actions = { resolvePexelsId, executeSearchQuery };
  return bindActionCreators(actions, dispatch);
}
