export const selectWizardState = state => ({
  workspace: state.workspaceAndStickers.present.workspace,
  colors: state.colorsAndFonts.colors,
  fonts: state.colorsAndFonts.fonts,
  albumData: {
    title: state.albums.albumData.title,
    releaseDate: state.albums.albumData.releaseDate,
    stickerTemplateSettings: state.albums.albumData.stickerTemplateSettings,
    organization: state.albums.albumData.organization,
  },
  blueprintId: state.blueprints.currentBlueprintId,
  blueprintCategory: state.blueprints.currentBlueprintCategory,
});

export default selectWizardState;
