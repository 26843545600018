import React from 'react';
import { number } from 'prop-types';

import { getAppearanceFromProps } from '../../../selectors/legacy';
import BaseElement from './BaseElement';

function Line(props) {
  const { height, width } = props;
  const y = height / 2;

  return (
    <BaseElement {...props}>
      <line
        {...getAppearanceFromProps(props)}
        x1={0}
        y1={y}
        x2={width}
        y2={y}
      />
    </BaseElement>
  );
}

Line.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
};

export default Line;
