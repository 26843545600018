export const albumRanges = {
  wedding: {
    min: 10,
    max: 200,
  },
  business: {
    min: 10,
    max: 500,
  },
  sports: {
    min: 10,
    max: 200,
  },
  birthday: {
    min: 10,
    max: 200,
  },
  school: {
    min: 10,
    max: 200,
  },
  community: {
    min: 10,
    max: 500,
  },
};

export const sliderLabel = {
  wedding: 'Wieviele Gäste bekommen ein Album?',
  business: 'Wieviele Personen bekommen ein Album?',
  sports: 'Wieviele Personen bekommen ein Album?',
  birthday: 'Wieviele Gäste bekommen ein Album?',
  school: 'Wieviele Personen bekommen ein Album?',
  community: 'Wieviele Personen bekommen ein Album?',
};

export const eventLabel = {
  wedding: 'Wann findet deine Hochzeit statt?',
  business: 'Wann findet das Event statt?',
  sports: 'Wann findet das Event statt?',
  birthday: 'Wann findet die Feier statt?',
  school: 'Wann findet das Event statt?',
  community: 'Wann findet das Event statt?',
};

export const organizationLabel = {
  business: 'Unternehmen',
  sports: 'Verein / Organisation',
};

export const commentMaxLength = 1000;
