import isObject from 'lodash/isObject';
import { bool, number } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { checkLowImageResolution } from '../../../../util/images';
import { ImageObjectShape } from '../../../shapes';

const withCheckedResolution = WrappedComponent => {
  const Component = props => {
    const { imageObject, scale, workspaceScale, cscale, preview } = props;
    const finalScale = workspaceScale * scale * cscale;
    const isSVG =
      !isObject(imageObject?.blob) && imageObject?.blob?.includes('.svg');

    const [showResolutionWarningIcon, setShowResolutionWarningIcon] = useState(
      false
    );

    useEffect(() => {
      // We do not set a warning in preview mode or for unresolvable or SVG images
      if (!imageObject || preview || isSVG) {
        setShowResolutionWarningIcon(false);
        return;
      }

      setShowResolutionWarningIcon(
        checkLowImageResolution(imageObject, finalScale)
      );
    }, [imageObject, finalScale, preview, isSVG]);

    return (
      <WrappedComponent
        {...props}
        showResolutionWarningIcon={showResolutionWarningIcon}
      />
    );
  };

  Component.defaultProps = {
    imageObject: null,
    scale: 1,
    cscale: 1,
    workspaceScale: 1,
    preview: false,
  };

  Component.propTypes = {
    imageObject: ImageObjectShape,
    scale: number,
    cscale: number,
    workspaceScale: number,
    preview: bool,
  };

  return Component;
};

export default withCheckedResolution;
