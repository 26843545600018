import React from 'react';

import { resolutions } from '../../../constants';
import { ResolutionShape } from '../../shapes';
import SvgImageBase from './SvgImageBase';
import withAutoResolution from './withAutoResolution';

const SvgImageWithAutoResolution = withAutoResolution(SvgImageBase);

function SvgImage(props) {
  const { resolution } = props;
  return resolution === resolutions.auto ? (
    <SvgImageWithAutoResolution {...props} />
  ) : (
    <SvgImageBase {...props} />
  );
}

SvgImage.defaultProps = {
  resolution: resolutions.small,
};

SvgImage.propTypes = {
  resolution: ResolutionShape,
};

export default SvgImage;
