import React from 'react';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import { bool, func } from 'prop-types';

import AlbumPreviewNavigator from './AlbumPreviewNavigator';
import { WorkspaceShape } from '../../../shapes';
import Icon from '../../../Icon';
import useScreenSize from '../../../../hooks/useScreenSize';
import useScopedSidebarTabs from '../../Sidebar/useScopedSidebarTabs';
import wizardTabs from '../../Sidebar/WizardSidebar/wizardTabs';
import StepHeader from './StepHeader';
import useAnalytics from '../../../../containers/app/useAnalytics';

function Content({ onSubmit, workspace, submitting }) {
  const { isMobile } = useScreenSize();
  const analytics = useAnalytics();
  const { tabs, activeTabIndex, activeTab } = useScopedSidebarTabs(wizardTabs);

  const canContinue = Object.keys(workspace.nodes).length > 1; // non-empty workspace
  const submitDisabled = submitting || !canContinue;

  const nextStep = tabs[activeTabIndex + 1];
  const prevStep = tabs[activeTabIndex - 1];

  const onNext = () => {
    analytics.track('Wizard Navigated', {
      wizardStepKey: activeTab.key,
    });
  };

  const handleSubmit = () => {
    analytics.track('Create Album Button Clicked');

    onSubmit();
  };

  function renderHeader() {
    if (isMobile) {
      return null;
    }

    if (activeTab.renderHeader) {
      return activeTab.renderHeader();
    }

    return <StepHeader activeIndex={activeTabIndex} tabs={tabs} />;
  }

  function renderPreviewContent() {
    if (activeTab.renderPreviewContent) {
      return activeTab.renderPreviewContent({
        handleSubmit,
        backLinkTo: prevStep.key,
        submitDisabled,
      });
    }

    if (!isMobile) {
      return <AlbumPreviewNavigator workspace={workspace} showHint />;
    }

    return null;
  }

  return (
    <div
      className={`album-wizard-content ${!activeTab.renderPreviewContent &&
        'bg-light'}`}
    >
      {renderHeader()}
      <div className="album-preview-container p-0 px-3 h-100">
        {renderPreviewContent()}
      </div>
      <div className="wizard-content-footer">
        {activeTab.renderFooter ? (
          activeTab.renderFooter()
        ) : (
          <>
            <div>
              {prevStep && (
                <Link to={prevStep.key}>
                  <Button
                    className="px-4 qa-prev-step-btn bg-white text-primary"
                    variant="outline-primary"
                  >
                    Zurück
                  </Button>
                </Link>
              )}
            </div>
            {nextStep ? (
              <Link to={nextStep.key}>
                <Button
                  disabled={!canContinue}
                  className="px-4 qa-next-step-btn"
                  onClick={onNext}
                >
                  Weiter
                  <Icon className="d-inline-block" name="arrow_right" />
                </Button>
              </Link>
            ) : (
              <Link to={`/album/new/confirm${window.location.search}`}>
                <Button
                  className="px-4 qa-finish-btn"
                  onClick={handleSubmit}
                  disabled={submitDisabled}
                >
                  Album erstellen
                </Button>
              </Link>
            )}
          </>
        )}
      </div>
    </div>
  );
}

Content.propTypes = {
  onSubmit: func.isRequired,
  workspace: WorkspaceShape.isRequired,
  submitting: bool.isRequired,
};

export default Content;
