import React from 'react';
import { number, bool } from 'prop-types';

export const computeGuideLineFolding = (isFrontCover, isBackCover, width) => {
  if (isFrontCover) return 0;
  if (isBackCover) return width;
  return width / 2;
};

const SpreadGuides = ({
  x,
  y,
  height,
  width,
  spine,
  isFrontCover,
  isBackCover,
}) => {
  const guideLineFolding = computeGuideLineFolding(
    isFrontCover,
    isBackCover,
    width
  );

  return (
    <g>
      {spine !== null && (
        <rect
          x={x - spine}
          y={y}
          width={spine}
          height={height}
          fill="none"
          stroke="red"
          vectorEffect="non-scaling-stroke"
          strokeWidth="0.5"
        />
      )}

      <line
        x1={x + guideLineFolding}
        y1={y}
        x2={x + guideLineFolding}
        y2={y + height}
        stroke="#000"
        vectorEffect="non-scaling-stroke"
        strokeWidth="0.4"
      />

      <line
        x1={x + guideLineFolding}
        y1={y}
        x2={x + guideLineFolding}
        y2={y + height}
        stroke="#fff"
        strokeDasharray="2 2"
        strokeLinecap="square"
        vectorEffect="non-scaling-stroke"
        strokeWidth="0.4"
      />
    </g>
  );
};

SpreadGuides.defaultProps = {
  spine: null,
};

SpreadGuides.propTypes = {
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  isFrontCover: bool.isRequired,
  isBackCover: bool.isRequired,
  spine: number,
};

export default SpreadGuides;
