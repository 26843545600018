import React, { useEffect, useState } from 'react';
import { func, string } from 'prop-types';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import Icon from '../../../components/Icon';
import useApi from '../../../hooks/useApi';
import useNotifications from '../../../hooks/useNotifications';
import useLoading from '../../../hooks/useLoading';

function OrderExportJob({ className, orderId, refetch }) {
  const api = useApi();
  const [url, setUrl] = useState('');
  const { createError } = useNotifications();
  const { loadWhile: loadExporting, isLoading: isExporting } = useLoading(
    'esveco-artifact-export'
  );
  const { loadWhile: loadDownloading, isLoading: isDownloading } = useLoading(
    'esveco-artifact-download'
  );
  const { loadWhile: loadFetching } = useLoading('esveco-artifact-fetch');

  async function handleExport() {
    loadExporting(async () => {
      try {
        await api.post(`/orders/${orderId}/transfer`, { id: orderId });
        refetch();
      } catch (err) {
        createError(err.toString());
      }
    });
  }

  async function handleDownload() {
    loadDownloading(async () => {
      try {
        window.open(url);
      } catch (err) {
        createError(err.toString());
      }
    });
  }

  async function fetchArtifact() {
    loadFetching(async () => {
      try {
        const {
          data: { blob_url: blobUrl },
        } = await api.get(`/orders/${orderId}/esveco_artifact`);
        setUrl(blobUrl);
      } catch (err) {
        createError(err.toString());
      }
    });
  }

  useEffect(() => {
    fetchArtifact();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      {url && (
        <Button
          className="mr-2 qa-download-artifact-btn"
          onClick={handleDownload}
          size="sm"
          variant="outline-primary"
        >
          {isDownloading ? (
            <>
              <Spinner className="qa-spinner" size="sm" animation="border" />{' '}
            </>
          ) : (
            <Icon name="download" className="d-inline-block mr-2" />
          )}
          Download
        </Button>
      )}
      <Button
        className="qa-export-artifact-btn"
        onClick={handleExport}
        size="sm"
        variant="outline-primary"
      >
        {isExporting ? (
          <>
            <Spinner className="qa-spinner" size="sm" animation="border" />{' '}
          </>
        ) : (
          <Icon name="archive" className="d-inline-block mr-2" />
        )}
        Export starten
      </Button>
    </div>
  );
}

OrderExportJob.defaultProps = {
  className: '',
};

OrderExportJob.propTypes = {
  className: string,
  orderId: string.isRequired,
  refetch: func.isRequired,
};

export default OrderExportJob;
