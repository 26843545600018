import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import Viewport from '../Viewport';
import Root from '../elements/Root';
import Selector from '../Selector';
import Handles from '../Handles';
import ElementsTree from '../../ui/ElementsTree';
import Clipboard from './Clipboard';
import ToolMenu from './ToolMenu';
import HistoryControls from './HistoryControls';
import {
  getOperationActive,
  getSpacebarPressed,
  getLassoActive,
} from '../../../selectors/controls';
import Toolbar from '../Toolbar';
import AdminOnly from '../../generic/AdminOnly';
import { selectIsAlbumEditable } from '../../../selectors/albums';

const Workspace = () => {
  const operationActive = useSelector(getOperationActive);
  const lassoActive = useSelector(getLassoActive);
  const spacebarPanActive = useSelector(getSpacebarPressed);
  const workspaceRef = useRef();
  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  const shouldRenderSelector =
    !spacebarPanActive && !operationActive && isAlbumEditable;

  const shouldRenderHandles =
    !spacebarPanActive && !lassoActive && isAlbumEditable;

  return (
    <div className="workspace qa-workspace" ref={workspaceRef}>
      <Viewport showScrollbars>
        <Root preview={!isAlbumEditable} />
        {shouldRenderSelector && <Selector />}
        {shouldRenderHandles && <Handles />}
      </Viewport>

      {/* We need to disable all options to edit an album in preview mode. */}
      {isAlbumEditable && (
        <>
          <ToolMenu />
          <HistoryControls />
          <Clipboard />
          <Toolbar workspaceRef={workspaceRef} />
        </>
      )}

      <AdminOnly>
        <ElementsTree />
      </AdminOnly>
    </div>
  );
};

export default Workspace;
