import { Splide, SplideSlide } from '@splidejs/react-splide';
import { func, string } from 'prop-types';
import React from 'react';

import {
  stickerTemplateIds,
  wizardMobileSplideOptions,
} from '../../../../../../constants';
import useAnalytics from '../../../../../../containers/app/useAnalytics';
import { StickerTemplateButton } from '../views/StickerTemplateList';

function StickerTemplateCarousel({ stickerTemplateId, setStickerTemplateId }) {
  const analytics = useAnalytics();

  const handleClick = nextStickerTemplateId => {
    setStickerTemplateId(nextStickerTemplateId);

    analytics.track('Wizard Sticker Template Updated', {
      stickerTemplateId: nextStickerTemplateId,
    });
  };

  return (
    <div className="bg-white pt-4">
      <div className="wizard-styles-panel-mobile__carousels__label text-muted mb-2">
        Sticker-Design
      </div>
      <Splide
        options={{
          ...wizardMobileSplideOptions,
          fixedWidth: '6rem',
          gap: '0.9375rem',
        }}
      >
        {stickerTemplateIds.map(someStickerTemplateId => (
          <SplideSlide key={someStickerTemplateId} className="pt-2">
            <StickerTemplateButton
              key={someStickerTemplateId}
              stickerTemplateId={someStickerTemplateId}
              onClick={() => handleClick(someStickerTemplateId)}
              active={stickerTemplateId === someStickerTemplateId}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

StickerTemplateCarousel.propTypes = {
  stickerTemplateId: string.isRequired,
  setStickerTemplateId: func.isRequired,
};

export default StickerTemplateCarousel;
