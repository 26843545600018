import React from 'react';
import { bool, node } from 'prop-types';
import { useSelector } from 'react-redux';

import AlbumSvg from './AlbumSvg';
import useSpacebarPan from './useSpacebarPan';
import useViewportGestures from './useViewportGestures';
import { colorValues } from '../../../constants';
import { getIsolation } from '../../../selectors/legacy';
import ViewControlMenu from './ViewControlMenu';
import ViewportDropzone from './ViewportDropzone';
import useViewport from './useViewport';
import GridPattern from './GridPattern';
import ViewportScrollbars from './ViewportScrollbars';
import { selectIsAlbumEditable } from '../../../selectors/albums';

function Viewport({ children, showScrollbars }) {
  const { viewportRef, viewBox } = useViewport();
  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  const { spacebarPanActive, spacebarPressed } = useSpacebarPan();
  useViewportGestures();

  const isolationActive = !!useSelector(getIsolation);
  const grabCursor = spacebarPanActive ? 'grabbing' : 'grab';
  const style = {
    cursor: spacebarPressed ? grabCursor : null,
    background: isolationActive
      ? colorValues.backgroundIsolation
      : colorValues.background,
  };

  return (
    <ViewportDropzone>
      {/* Wrapper sets correct svg height in webkit browsers */}
      <object className="viewport-wrapper">
        <AlbumSvg
          className="viewport qa-svg-root w-100 h-100"
          viewportRef={viewportRef}
          shapeRendering="optimizeSpeed"
          imageRendering="optimizeSpeed"
          textRendering="optimizeSpeed"
          viewBox={viewBox}
          style={style}
          isolationActive={isolationActive}
          preview={!isAlbumEditable}
        >
          {children}
          <GridPattern />
        </AlbumSvg>
        {showScrollbars && <ViewportScrollbars />}
      </object>
      <ViewControlMenu />
    </ViewportDropzone>
  );
}

Viewport.defaultProps = {
  showScrollbars: false,
};

Viewport.propTypes = {
  children: node.isRequired,
  showScrollbars: bool,
};

export default Viewport;
