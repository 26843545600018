import React from 'react';
import { func, node } from 'prop-types';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';

import FadeInWhenAlbumLoaded from '../../components/generic/FadeInWhenAlbumLoaded';
import Icon from '../../components/Icon';
import { selectMobileFooterOpen } from '../../selectors/controls';

function MobileFooterMenu({ children, onClose }) {
  const isOpen = useSelector(selectMobileFooterOpen);

  return (
    <div
      className="mobile-footer-menu qa-mobile-footer-menu"
      style={{
        opacity: isOpen ? 1 : 0,
        transform: isOpen ? 'translateY(0)' : 'translateY(80px)',
      }}
    >
      <FadeInWhenAlbumLoaded>{children}</FadeInWhenAlbumLoaded>
      <Button
        onClick={onClose}
        className="btn-primary footer-menu-close-btn qa-footer-menu-close-btn"
      >
        <Icon name="closeThin" />
      </Button>
    </div>
  );
}

MobileFooterMenu.propTypes = {
  children: node.isRequired,
  onClose: func.isRequired,
};

export default MobileFooterMenu;
