import React from 'react';
import { arrayOf, bool } from 'prop-types';
import isEqual from 'lodash/isEqual';

import FontTiles from '../FontTiles';
import DropoutSection, { DropoutTitle } from '../DropoutSection';
import {
  FontsShape,
  StylesPanelStateMachineShape,
} from '../../../../../shapes';
import useAnalytics from '../../../../../../containers/app/useAnalytics';

function FontsList({ stateMachine, availableFonts, isWizard }) {
  const analytics = useAnalytics();
  const { send } = stateMachine;
  const { fonts: currentFonts, setFonts } = stateMachine;

  function handleClick(fonts) {
    setFonts(fonts);

    analytics.track(`${isWizard ? 'Wizard ' : ''}Fonts Updated`, {
      fontsValue: fonts,
    });
  }

  return (
    <div className="d-flex flex-column">
      <DropoutTitle label="Schriftstil wählen" onBack={() => send('back')} />
      <DropoutSection>
        {availableFonts.map(fonts => (
          <FontTiles
            key={Object.values(fonts).join(', ')}
            className="my-3"
            fonts={fonts}
            onClick={() => handleClick(fonts)}
            active={isEqual(fonts, currentFonts)}
          />
        ))}
      </DropoutSection>
    </div>
  );
}

FontsList.propTypes = {
  stateMachine: StylesPanelStateMachineShape.isRequired,
  availableFonts: arrayOf(FontsShape).isRequired,
  isWizard: bool.isRequired,
};

export default FontsList;
