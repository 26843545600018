import { useState } from 'react';
import { DirectUpload } from 'activestorage';
import omit from 'lodash/omit';

import { UPLOADS_URL } from '../constants';
import { generateId } from '../util/index';

/**
 * A reusable hook to use active storage direct uploads in components.
 * It provides an `upload` function that returns a promise which will resolve to the created `blob`
 * and some state about the currently running uploads. The `uploads` object has the following structure:
 *
 * {
 *   <unique uploadId>: {
 *     fileName: 'some-file.png',
 *     progress: 50 // percentage
 *   }
 *   ...
 * }
 *
 * The `upload` function accepts an optional second parameter "metaData" that is forwarded to
 * the upload item in state to e. g. group uploads on the component level or display a placeholder.
 */
function useFileUpload() {
  const [uploads, setUploads] = useState({});

  const upload = (file, metaData = {}) => {
    const uploadId = generateId();

    const uploader = {
      directUploadWillStoreFileWithXHR(request) {
        request.upload.addEventListener('progress', ({ loaded, total }) => {
          setUploads(prevUploads => ({
            ...prevUploads,
            [uploadId]: {
              progress: Math.round((loaded ? loaded / total : 0) * 100),
              fileName: file.name,
              metaData,
            },
          }));
        });
      },
    };

    return new Promise((resolve, reject) => {
      new DirectUpload(file, UPLOADS_URL, uploader).create((error, blob) => {
        setUploads(prevUploads => omit(prevUploads, uploadId));

        if (error) {
          reject(error);
        } else {
          resolve(blob);
        }
      });
    });
  };

  return {
    upload,
    uploads,
  };
}

export default useFileUpload;
