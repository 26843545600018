export default function moveMousePointAccordingToRatio(
  pivot,
  mouse,
  { width: originalWidth, height: originalHeight }
) {
  // Directed sizes can be negative
  const { x: directedWidth, y: directedHeight } = {
    x: mouse.x - pivot.x,
    y: mouse.y - pivot.y,
  };

  // In order to compare them we need the absolute sizes
  const xRatio = Math.abs(directedWidth / originalWidth);
  const yRatio = Math.abs(directedHeight / originalHeight);
  const scale = Math.max(xRatio, yRatio);

  // But keep the sign of the directed sized after their correction
  const correctedDirectedWidth =
    originalWidth * scale * (directedWidth < 0 ? -1 : 1);
  const correctedDirectedHeight =
    originalHeight * scale * (directedHeight < 0 ? -1 : 1);

  return {
    x: pivot.x + correctedDirectedWidth,
    y: pivot.y + correctedDirectedHeight,
  };
}
