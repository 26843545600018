import React from 'react';
import { string, number } from 'prop-types';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import 'react-circular-progressbar/dist/styles.css';

function ProgressBar({
  value,
  maxValue,
  text,
  width,
  height,
  pathColor,
  color,
  fontSize,
  className,
}) {
  const styles = buildStyles({
    backgroundColor: '#fff',
    pathColor,
    textColor: color,
    textSize: fontSize,
  });

  return (
    <div
      className={`qa-progress-bar ${className}`}
      style={{
        width: `${height}px`,
        height: `${width}px`,
      }}
    >
      <CircularProgressbar
        styles={styles}
        value={value}
        maxValue={maxValue}
        text={text}
      />
    </div>
  );
}

ProgressBar.defaultProps = {
  maxValue: 1,
  text: '',
  height: 60,
  width: 60,
  fontSize: '1.5rem',
  pathColor: '#004C97', // primary
  color: '#333',
  className: '',
};

ProgressBar.propTypes = {
  value: number.isRequired,
  maxValue: number,
  text: string,
  height: number,
  width: number,
  fontSize: string,
  pathColor: string,
  color: string,
  className: string,
};

export default ProgressBar;
