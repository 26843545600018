import React, { useCallback, useMemo } from 'react';
import flow from 'lodash/flow';
import { func, number, string } from 'prop-types';

import useMouseDragCoordinates from '../../hooks/useMouseDragCoordinates';
import { domMatrix } from '../../util/geometry';
import { makeMapPropsToDeltaMap } from '../../util/workspace';
import { InitialOperationValuesShape } from '../shapes';
import { getPivot, getPivotsFromInitialValues, pivotNames } from './pivots';
import withOperationProps from './withOperationProps';

const unprefixProps = ({ cx, cy, cscale }) => ({
  x: cx,
  y: cy,
  scale: cscale,
});

const prefixProps = ({ x, y, scale }) => ({
  cx: x,
  cy: y,
  cscale: scale,
});

function ScaleHandle({
  handleRadius,
  width,
  height,
  pivotName,
  opposingPivotName,
  initialValues,
  operationStart,
  updateElements,
  operationEnd,
}) {
  const pivots = useMemo(() => {
    // Calculate main and alt pivot
    return getPivotsFromInitialValues(
      [opposingPivotName, pivotNames.center],
      initialValues
    );
  }, [initialValues, opposingPivotName]);

  const handleUpdate = useCallback(
    ({ initialPointer, currentPointer, altKey }) => {
      const {
        operationTargetNodeId,
        selectInside,
        selectedElements,
      } = initialValues;
      const parentDomNode = document.querySelector(
        `[data-id='${operationTargetNodeId}']`
      );

      const { clientPivots, pivotDomNode } = pivots;

      const clientToPivot = domMatrix(pivotDomNode.getScreenCTM()).inverse();
      const initialPoint = clientToPivot.transformPoint(initialPointer);
      const currentPoint = clientToPivot.transformPoint(currentPointer);

      // Pressing alt-key will scale around the center
      const [clientPivot, altClientPivot] = clientPivots;
      const activeClientPivot = altKey ? altClientPivot : clientPivot;
      const pivot = clientToPivot.transformPoint(activeClientPivot);
      const initialVector = {
        x: Math.abs(initialPoint.x - pivot.x),
        y: Math.abs(initialPoint.y - pivot.y),
      };
      const currentVector = {
        x: Math.abs(currentPoint.x - pivot.x),
        y: Math.abs(currentPoint.y - pivot.y),
      };

      const deltaScale = Math.max(
        currentVector.x / initialVector.x,
        currentVector.y / initialVector.y
      );

      const clientToParent = domMatrix(parentDomNode.getScreenCTM()).inverse();
      const parentPivot = clientToParent.transformPoint(activeClientPivot);

      const applyScale = ({ x = 0, y = 0, scale = 1 }) => {
        return {
          x: (x - parentPivot.x) * deltaScale + parentPivot.x,
          y: (y - parentPivot.y) * deltaScale + parentPivot.y,
          scale: scale * deltaScale,
        };
      };
      const mapPropsToDeltaMap = makeMapPropsToDeltaMap(selectedElements);
      const applyScaleNormalized = selectInside
        ? flow(unprefixProps, applyScale, prefixProps)
        : applyScale;
      const propsDeltaMap = mapPropsToDeltaMap(applyScaleNormalized);

      updateElements(propsDeltaMap);
    },
    [updateElements, initialValues, pivots]
  );

  const { x, y } = getPivot(pivotName, { width, height });

  const { handleMouseDown } = useMouseDragCoordinates({
    onStart: operationStart,
    onUpdate: initialValues && handleUpdate,
    onEnd: operationEnd,
  });

  return (
    <circle
      onMouseDown={handleMouseDown}
      r={handleRadius}
      cx={x}
      cy={y}
      className={`handle scale ${pivotName} qa-handle-scale-${pivotName}`}
    />
  );
}

ScaleHandle.defaultProps = {
  initialValues: null,
};

ScaleHandle.propTypes = {
  handleRadius: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  pivotName: string.isRequired,
  opposingPivotName: string.isRequired,
  initialValues: InitialOperationValuesShape,
  operationStart: func.isRequired,
  updateElements: func.isRequired,
  operationEnd: func.isRequired,
};

export default withOperationProps(ScaleHandle);
