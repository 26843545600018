import { node, string } from 'prop-types';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function DropdownWithTooltip({ dropDirection, className, tooltip, children }) {
  return (
    <OverlayTrigger
      placement={dropDirection === 'up' ? 'bottom' : 'top'}
      overlay={<Tooltip>{tooltip}</Tooltip>}
    >
      <Dropdown className={className} drop={dropDirection}>
        {children}
      </Dropdown>
    </OverlayTrigger>
  );
}

DropdownWithTooltip.defaultProps = {
  dropDirection: 'up',
  className: null,
};

DropdownWithTooltip.propTypes = {
  tooltip: string.isRequired,
  children: node.isRequired,
  dropDirection: string,
  className: string,
};

export default DropdownWithTooltip;
