export const MERGE_USER = 'multiplayer/MERGE_USER';
export const MERGE_SELECTION = 'multiplayer/MERGE_SELECTION';
export const REMOVE_USER = 'multiplayer/REMOVE_USER';

/**
 * The state will look like this:
 * {
 *  users: {
 *   [userId]: {
 *     id: string,
 *     email: string,
 *     picture: string,
 *     color: string,
 *   }
 *  }
 *  selections: {
 *   [userId]: [nodeId, nodeId, nodeId]]
 *  }
 * }
 */

const initialState = {
  users: {},
  selections: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MERGE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          [action.payload.id]: action.payload,
        },
      };
    case MERGE_SELECTION:
      return {
        ...state,
        selections: {
          ...state.selections,
          ...action.payload,
        },
      };
    case REMOVE_USER: {
      const { [action.payload]: _, ...users } = state.users;
      const { [action.payload]: __, ...selections } = state.selections;

      return {
        ...state,
        users,
        selections,
      };
    }
    default:
      return state;
  }
};

/**
 * Actions
 */

export const mergeMultiplayerUser = user => (dispatch, getState) => {
  const {
    multiplayer: { users },
  } = getState();
  const color =
    // eslint-disable-next-line no-bitwise
    users[user.id]?.color || `hsla(${~~(360 * Math.random())}, 70%,  72%, 0.8)`;

  return dispatch({
    type: MERGE_USER,
    payload: {
      ...user,
      color,
    },
  });
};

export const mergeMultiplayerSelection = (userId, selection) => ({
  type: MERGE_SELECTION,
  payload: { [userId]: selection },
});

export const removeMultiplayerUser = userId => ({
  type: REMOVE_USER,
  payload: userId,
});

/**
 * Selectors
 */

export const selectMultiplayer = state => state.multiplayer;
