import React from 'react';
import { bool, node, string } from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';

import { RefShape } from '../../shapes';

function Sidebar({ children, minimized, sidebarRef, className }) {
  const {
    params: { key },
  } = useRouteMatch();

  return (
    <div
      className={`sidebar ${
        minimized ? 'minimized' : ''
      } shadow-sm qa-tab-navigation ${className}`}
      ref={sidebarRef}
    >
      <Tab.Container activeKey={key} mountOnEnter>
        {children}
      </Tab.Container>
    </div>
  );
}

Sidebar.defaultProps = {
  className: '',
  minimized: false,
};

Sidebar.propTypes = {
  children: node.isRequired,
  minimized: bool,
  sidebarRef: RefShape.isRequired,
  className: string,
};

export default Sidebar;
