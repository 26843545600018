import React from 'react';
import { string, func, bool } from 'prop-types';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../../../Icon';

function ToolButton({ id, dragRef, title, active, onClick }) {
  return (
    <OverlayTrigger placement="left" overlay={<Tooltip>{title}</Tooltip>}>
      <Button
        ref={dragRef}
        onClick={onClick}
        variant="light"
        className={`bg-white py-2 qa-create-element-${id} ${
          active ? 'active' : ''
        }`}
      >
        <Icon name={id.toLowerCase()} />
      </Button>
    </OverlayTrigger>
  );
}

ToolButton.defaultProps = {
  dragRef: undefined,
  active: false,
  onClick: undefined,
};

ToolButton.propTypes = {
  id: string.isRequired,
  dragRef: func,
  title: string.isRequired,
  active: bool,
  onClick: func,
};

export default ToolButton;
