import { number, shape, string } from 'prop-types';

export const PexelsPhotoShape = shape({
  id: number,
  width: number,
  height: number,
  photographer: string,
  src: shape({
    tiny: string,
    medium: string,
    large: string,
    original: string,
  }),
});

export default PexelsPhotoShape;
