import { func, string } from 'prop-types';
import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import useLoading from '../../../../hooks/useLoading';
import useApi from '../../../../hooks/useApi';

function OrderCommentForm({ orderId, refetchComments }) {
  const api = useApi();
  const { loadWhile, isLoading } = useLoading('create-order-comment');

  const [content, setContent] = useState('');
  const [error, setError] = useState(null);

  async function handleSubmit(e) {
    e.preventDefault();

    loadWhile(async () => {
      try {
        await api.post(`/orders/${orderId}/comments`, {
          content,
        });
        refetchComments();
        setContent('');
        setError(null);
      } catch (err) {
        setError(err.message);
      }
    });
  }

  return (
    <Form className="qa-order-comment-form my-3" onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Control
          className="qa-order-comment-content-input"
          name="order_comment_content"
          type="text"
          as="textarea"
          required
          placeholder="Neuer Kommentar"
          value={content}
          onChange={e => setContent(e.target.value)}
        />
      </Form.Group>
      {error && (
        <Alert variant="danger">
          Das hat leider nicht funktioniert. Bitte versuche es nochmal.
        </Alert>
      )}
      <Form.Group>
        <Button
          className="qa-order-comment-submit-btn"
          variant="primary"
          disabled={isLoading || content.length === 0}
          type="submit"
        >
          Speichern
        </Button>
      </Form.Group>
    </Form>
  );
}

OrderCommentForm.defaultProps = {};

OrderCommentForm.propTypes = {
  orderId: string.isRequired,
  refetchComments: func.isRequired,
};

export default OrderCommentForm;
