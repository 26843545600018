import React from 'react';
import { Badge } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectPendingStickerUploads } from '../../../selectors/stickerUploads';
import useStickerUploadsPolling from './useStickerUploadsPolling';

function StickerLinkInboxCountBadge() {
  const count = useSelector(selectPendingStickerUploads).length;
  useStickerUploadsPolling();

  return <>{count > 0 && <Badge variant="success">{count}</Badge>}</>;
}

export default StickerLinkInboxCountBadge;
