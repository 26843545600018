import React from 'react';
import { string } from 'prop-types';
import { useSelector } from 'react-redux';

import SymbolText from './SymbolText';
import FreeText from './FreeText';
import { getLocalStyleMap, getStyleMap } from '../../../../selectors/legacy';
import { RawDraftContentStateShape } from '../../../shapes';
import BlockWrapper from './BlockWrapper';

function blockRendererFn(block) {
  return {
    component: BlockWrapper,
    props: {
      block,
    },
  };
}

const Text = props => {
  const { symbol, text } = props;
  const styleMap = useSelector(getStyleMap);
  const localStyles = getLocalStyleMap(text);
  const customStyleMap = { ...styleMap, ...localStyles };
  const Component = symbol ? SymbolText : FreeText;
  return (
    <Component
      {...props}
      customStyleMap={customStyleMap}
      blockRendererFn={blockRendererFn}
    />
  );
};

Text.defaultProps = {
  symbol: null,
};

Text.propTypes = {
  symbol: string,
  text: RawDraftContentStateShape.isRequired,
};

export default Text;
