import React, { useCallback, useEffect, useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import {
  selectCurrentAlbum,
  selectHasUnsavedChanges,
} from '../../../../selectors/albums';
import useApi from '../../../../hooks/useApi';
import Icon from '../../../Icon';

const SyncStatusIndicator = () => {
  const [updatedAt, setUpdatedAt] = useState(null);
  const albumId = useSelector(selectCurrentAlbum);
  const hasUnsavedChanges = useSelector(selectHasUnsavedChanges);
  const api = useApi();

  const fetchUpdatedAt = useCallback(async () => {
    if (!albumId) {
      return;
    }

    const response = await api.get(`/albums/${albumId}`);
    const { album } = response.data;
    setUpdatedAt(album.updated_at);
  }, [api, albumId]);

  useEffect(() => {
    fetchUpdatedAt();
  }, [albumId, fetchUpdatedAt]);

  const tooltip = `Zuletzt gespeichert am ${format(
    new Date(updatedAt),
    'd.M.yyyy'
  )} um ${format(new Date(updatedAt), 'HH:mm')}`;

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip className="qa-last-saved-tooltip">{tooltip}</Tooltip>}
      onEnter={fetchUpdatedAt}
    >
      <Icon
        name="cloud_check"
        className={`sync-status-indicator qa-sync-status-indicator ${
          hasUnsavedChanges ? 'syncing qa-syncing' : ''
        }`}
      />
    </OverlayTrigger>
  );
};

export default SyncStatusIndicator;
