// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';

export default class Page extends React.PureComponent {
  static defaultProps = {
    width: 100,
    height: 100,
    bleed: 2,
    trim: 8,
    marks: 'crop cross',
  };

  render() {
    const { children, id, width, height, bleed, trim, marks } = this.props;

    const bleedWidth = width + bleed * 2;
    const bleedHeight = height + bleed * 2;

    return (
      <section
        key={id}
        className={id}
        style={{
          position: 'relative',
          width: `${bleedWidth}mm`,
          height: `${bleedHeight}mm`,
          left: `${-bleed}mm`,
          top: `${-bleed}mm`,
        }}
      >
        <style>
          {`
                    @page ${id} {
                        size: ${width}mm ${height}mm;
                        margin: 0;
                        padding: 0;
                        marks: ${marks};
                        prince-bleed: ${bleed}mm;
                        prince-trim: ${trim}mm;
                        prince-mark-offset: ${bleed}mm;
                        prince-mark-length: ${trim}mm;
                        prince-mark-width: 0.2mm;
                    }

                    .${id} { page: ${id} }

                    `}
        </style>
        {children}
      </section>
    );
  }
}
