import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

import useApi from '../../hooks/useApi';

function AccountActivation() {
  const api = useApi();
  const [errors, setErrors] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        await api.patch(`/users/activate`);
      } catch (err) {
        setErrors(err);
      }

      setShowAlert(true);
    })();
  }, [api, setErrors]);

  if (!showAlert) {
    return null;
  }

  return errors ? (
    <Alert variant="danger" className="border mt-2 qa-error-alert">
      Bei der Account-Aktivierung ist ein Fehler aufgetreten. Bitte versuche es
      erneut.
    </Alert>
  ) : (
    <Alert variant="success" className="border mt-2 qa-success-alert">
      Account-Aktivierung erfolgreich abgeschlossen.
    </Alert>
  );
}

export default AccountActivation;
