import React from 'react';
import uniqWith from 'lodash/uniqWith';
import isEqual from 'lodash/isEqual';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';

import useStylesStateMachine from './useStylesStateMachine';
import * as views from './views';
import { fontCombinations, colorCombinations } from '../../../../../constants';
import useScreenSize from '../../../../../hooks/useScreenSize';
import WizardStylesPanelMobile from './WizardStylesPanelMobile';
import { getCurrentBlueprintData } from '../../../../../selectors/blueprints';
import { getColors, getFonts } from '../../../../../selectors/colorsAndFonts';

function StylesPanel({ isWizard }) {
  const stateMachine = useStylesStateMachine();
  const { isMobile } = useScreenSize();
  const isMobileWizard = isMobile && isWizard;

  const { current } = stateMachine;
  const Component = views[current.name];

  const currentBlueprintData = useSelector(getCurrentBlueprintData);
  const blueprintColors = currentBlueprintData?.colors;
  const blueprintFonts = currentBlueprintData?.fonts;

  const albumColors = useSelector(getColors);
  const albumFonts = useSelector(getFonts);

  const availableColors = uniqWith(
    [blueprintColors, ...colorCombinations, albumColors].filter(Boolean),
    isEqual
  );
  const availableFonts = uniqWith(
    [blueprintFonts, ...fontCombinations, albumFonts].filter(Boolean),
    isEqual
  );

  return (
    <div className="sidebar-panel qa-styles-panel scrollable px-1">
      {isMobileWizard ? (
        <WizardStylesPanelMobile
          availableColors={availableColors}
          availableFonts={availableFonts}
          stateMachine={stateMachine}
        />
      ) : (
        <div className="py-3">
          <Component
            stateMachine={stateMachine}
            availableColors={availableColors}
            availableFonts={availableFonts}
            isWizard={isWizard}
          />
        </div>
      )}
    </div>
  );
}

StylesPanel.defaultProps = {
  isWizard: false,
};

StylesPanel.propTypes = {
  isWizard: bool,
};

export default StylesPanel;
