export const getPan = state => state.viewport.pan;

export const getZoom = state => state.viewport.zoom;

export const getAnimatedZoom = state =>
  // Animated value overrides regular value
  state.viewport.animatedZoom || state.viewport.zoom;

export const getAnimatedPan = state =>
  // Animated value overrides regular value
  state.viewport.animatedPan || state.viewport.pan;

export const getZoomToSelectionInitialBounds = state =>
  state.viewport.zoomToSelectionInitialBounds;

export const getViewportHeight = state => state.viewport.viewportHeight;
