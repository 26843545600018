import React from 'react';
import { bool, func } from 'prop-types';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';

import { AvailableFeatureShape } from './shapes';
import checkoutImages from './checkoutImages';

function FeatureCheckbox({ feature, onChange, isChecked, isDisabled }) {
  const { product, title, amount_gross_human: amountGross, text } = feature;

  return (
    <Col md={12} lg={6} className="mb-4">
      <label className="h-100" htmlFor={product}>
        <Card
          key={product}
          className={`enhanced-checkbox${
            isChecked
              ? ' enhanced-checkbox--checked qa-active-order-feature'
              : ''
          } h-100`}
        >
          <Card.Body>
            <div className="d-flex align-items-start mb-2">
              <input
                className={`mt-1 qa-feature-checkbox qa-feature-checkbox-${product}`}
                type="checkbox"
                name={product}
                id={product}
                checked={isChecked}
                onChange={onChange}
                disabled={isDisabled}
              />
              <div className="pl-2 m-0 d-flex flex-column w-100">
                <span>{title}</span>
                <span className={`text-primary qa-amount-${product}`}>
                  {amountGross}
                </span>
              </div>
            </div>
            <img
              className="w-100 enhanced-checkbox__image"
              src={checkoutImages[product]}
              alt={title}
            />
            <Card.Text className="pt-2">{text}</Card.Text>
          </Card.Body>
        </Card>
      </label>
    </Col>
  );
}

FeatureCheckbox.defaultProps = {
  isDisabled: false,
};

FeatureCheckbox.propTypes = {
  feature: AvailableFeatureShape.isRequired,
  onChange: func.isRequired,
  isChecked: bool.isRequired,
  isDisabled: bool,
};

export default FeatureCheckbox;
