/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { number, string } from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Link } from 'react-router-dom';

import { generateSpread } from '../../util/generators';
import { insertElements } from '../../actions/workspace';
import { historyAnchor } from '../../modules/history';
import { selectAlbumData } from '../../selectors/albums';
import useAnalytics from '../../containers/app/useAnalytics';
import { resetViewToSpreadIndex } from '../../modules/controls';
import { clearSelection } from '../../modules/selection';

const InsertSpreadButton = ({ spreadIndex, sectionId, indexInSection }) => {
  const { id: albumId } = useSelector(selectAlbumData);
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const handleClick = () => {
    dispatch(clearSelection());
    const spread = generateSpread();

    dispatch(insertElements([spread], sectionId, indexInSection + 1));
    dispatch(historyAnchor());

    analytics.track('Spread Created');
    dispatch(resetViewToSpreadIndex(spreadIndex + 1));
  };

  const size = 20;
  return (
    <OverlayTrigger overlay={<Tooltip>Leere Seite einfügen</Tooltip>}>
      <Link to={`/album/${albumId}/layouts`}>
        <g
          className="insert-spread-button qa-insert-spread-button"
          onClick={handleClick}
        >
          <rect width={size} height={size} fill="none" />
          <path
            className="icon"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 0.88916C4.47715 0.88916 0 5.36631 0 10.8892C0 16.412 4.47715 20.8892 10 20.8892C15.5228 20.8892 20 16.412 20 10.8892C20 8.237 18.9464 5.69346 17.0711 3.81809C15.1957 1.94273 12.6522 0.88916 10 0.88916ZM10 18.8892C5.58172 18.8892 2 15.3074 2 10.8892C2 6.47088 5.58172 2.88916 10 2.88916C14.4183 2.88916 18 6.47088 18 10.8892C18 13.0109 17.1571 15.0457 15.6569 16.546C14.1566 18.0463 12.1217 18.8892 10 18.8892ZM11 9.88916H13.5C13.7761 9.88916 14 10.113 14 10.3892V11.3892C14 11.6653 13.7761 11.8892 13.5 11.8892H11V14.3892C11 14.6653 10.7761 14.8892 10.5 14.8892H9.5C9.22386 14.8892 9 14.6653 9 14.3892V11.8892H6.5C6.22386 11.8892 6 11.6653 6 11.3892V10.3892C6 10.113 6.22386 9.88916 6.5 9.88916H9V7.38916C9 7.11302 9.22386 6.88916 9.5 6.88916H10.5C10.7761 6.88916 11 7.11302 11 7.38916V9.88916Z"
            fill="#919191"
          />
        </g>
      </Link>
    </OverlayTrigger>
  );
};

InsertSpreadButton.defaultProps = {
  sectionId: null,
};

InsertSpreadButton.propTypes = {
  spreadIndex: number.isRequired,
  indexInSection: number.isRequired,
  sectionId: string,
};

export default InsertSpreadButton;
