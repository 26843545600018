import React from 'react';
import { bool, objectOf } from 'prop-types';

import Page from './Page';
import Node from './Node';
import Content from './Content';
import { dimensions } from '../../constants';
import { IdListShape, NodeShape } from '../shapes';

const pageWidth = 240;
const pageHeight = 78;
const contentPadding = 4;
const contentTop = 19;
const textOffset = 1.7;
const textLabel = 'Hier aufreissen!';

function StickerPack({ sectionNodes, spreadIds, isFullyPainted }) {
  const [firstSpreadId] = spreadIds;
  const [lastSpreadId] = spreadIds.slice(-1);

  if (!firstSpreadId) {
    return null;
  }

  const { bleed, trim } = Page.defaultProps;
  const contentWidth = pageHeight - contentPadding * 2;
  const scale = contentWidth / dimensions.pageWidth;

  const commonTextStyle = {
    width: `${pageHeight + bleed * 2}mm`,
    transformOrigin: 'top left',
    textAlign: 'center',
    fontFamily: 'Rubik',
    textTransform: 'uppercase',
    fontSize: '9pt',
    color: '#000',
    position: 'absolute',
  };

  const leftTextStyle = {
    ...commonTextStyle,
    transform: `translate(${textOffset + bleed}mm,${pageHeight +
      bleed * 2}mm) rotate(-90deg)`,
  };

  const rightTextStyle = {
    ...commonTextStyle,
    transform: `translate(${pageWidth -
      textOffset * 2 +
      bleed * 2}mm,0mm) rotate(90deg)`,
  };

  const renderingSpreadPosition = {
    x: 0,
    y: 0,
  };

  return (
    <div>
      <Page
        width={pageWidth}
        height={pageHeight}
        bleed={bleed}
        trim={trim}
        marks="crop"
        id="stickerpack"
      >
        <Content
          x={contentTop + bleed}
          y={pageHeight - contentPadding + bleed}
          width={dimensions.pageWidth}
          height={dimensions.pageHeight}
          spine={0}
          bleed={0}
          scale={scale}
          rotation={-90}
        >
          <Node
            id={firstSpreadId}
            nodeIndex={0}
            nodeSiblingCount={spreadIds.length}
            renderingSpreadPosition={renderingSpreadPosition}
            sectionNode={sectionNodes[firstSpreadId]}
            sectionId={sectionNodes[firstSpreadId]?.props?.id}
          />
        </Content>
        <div style={leftTextStyle}>{textLabel}</div>

        <Content
          x={pageWidth - contentTop + bleed * 2}
          y={contentPadding + bleed}
          bleed={0}
          width={dimensions.pageWidth}
          height={dimensions.pageHeight}
          scale={scale}
          rotation={90}
        >
          <Node
            id={lastSpreadId}
            nodeIndex={0}
            nodeSiblingCount={spreadIds.length}
            renderingSpreadPosition={renderingSpreadPosition}
            sectionNode={sectionNodes[lastSpreadId]}
            sectionId={sectionNodes[lastSpreadId]?.props?.id}
          />
        </Content>
        <div style={rightTextStyle}>{textLabel}</div>
      </Page>
      {isFullyPainted && <div className="render-complete" />}
    </div>
  );
}

StickerPack.propTypes = {
  sectionNodes: objectOf(NodeShape).isRequired,
  spreadIds: IdListShape.isRequired,
  isFullyPainted: bool.isRequired,
};

export default StickerPack;
