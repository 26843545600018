import { bool, node, string } from 'prop-types';
import React, { useState } from 'react';
import Collapse from 'react-bootstrap/Collapse';

import Icon from '../../../Icon';

function CollapsibleCard({ children, title, initialOpen, className }) {
  const [open, setOpen] = useState(initialOpen);

  return (
    <>
      <div
        role="button"
        className={`border-bottom p-3 d-flex justify-content-between align-items-center ${className}`}
        onClick={() => setOpen(!open)}
        onKeyPress={() => setOpen(!open)}
        tabIndex={0}
        style={{ fontWeight: 500 }}
      >
        {title}
        <Icon name={open ? 'chevron_up' : 'chevron_down'} />
      </div>
      <Collapse in={open}>
        <div>
          <div className="p-3">{children}</div>
        </div>
      </Collapse>
    </>
  );
}

CollapsibleCard.defaultProps = {
  initialOpen: true,
  className: '',
};

CollapsibleCard.propTypes = {
  children: node.isRequired,
  title: string.isRequired,
  initialOpen: bool,
  className: string,
};

export default CollapsibleCard;
