import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AlbumPreviewNavigator from '../../components/ui/AlbumWizard/Content/AlbumPreviewNavigator';
import { receiveAlbumPreview } from '../../modules/albums';
import SvgDefs from '../editor/SvgDefs';
import useScreenSize from '../../hooks/useScreenSize';
import useApi from '../../hooks/useApi';
import { getWorkspace } from '../../selectors/legacy';
import { ReactRouterMatchShape } from '../../components/shapes';

function PublicAlbumPreview(props) {
  const dispatch = useDispatch();
  const workspace = useSelector(getWorkspace);

  const history = useHistory();
  const api = useApi();
  const { isMobile } = useScreenSize();

  const {
    match: {
      params: { token },
    },
  } = props;

  useEffect(() => {
    (async () => {
      try {
        const {
          data: { album },
        } = await api.get(`/public/album_previews/${token}`);
        dispatch(receiveAlbumPreview(album));
      } catch (error) {
        history.push('/');
      }
    })();
  }, [dispatch, token, api, history]);

  return (
    <div className="album-preview-container p-3 vh-100 d-flex">
      <AlbumPreviewNavigator
        style={{ width: `${isMobile ? '100%' : '100vw'}`, height: '100%' }}
        flatButtons={isMobile}
        workspace={workspace}
        preview
      />
      <SvgDefs />
    </div>
  );
}

PublicAlbumPreview.propTypes = {
  match: ReactRouterMatchShape.isRequired,
};

export default PublicAlbumPreview;
