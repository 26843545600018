import { v4 } from 'uuid';
import mapKeys from 'lodash/mapKeys';

export function camelToSnake(string) {
  return string
    .replace(/[\w]([A-Z])/g, m => {
      return `${m[0]}_${m[1]}`;
    })
    .toLowerCase();
}

export function snakeify(object) {
  return mapKeys(object, (_, k) => camelToSnake(k));
}

export function capitalize(string) {
  return string[0].toUpperCase() + string.slice(1);
}

export function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : {
        r: 127,
        g: 127,
        b: 127,
      };
}

// TODO: move to generators.js?
export const generateId = () => v4();

export function objectMap(object, mapFn) {
  return Object.keys(object).reduce(
    (result, key) => ({
      ...result,
      [key]: mapFn(object[key], key),
    }),
    {}
  );
}

export const isLegacyWindowsFirefox = () => {
  try {
    const ua = navigator.userAgent.toLowerCase();
    const isWindowsFirefox =
      ua.indexOf('firefox') !== -1 && ua.indexOf('windows') !== -1;
    const majorVersion = parseInt(ua.split('/').pop(), 10);

    return isWindowsFirefox && majorVersion < 90;
  } catch (e) {
    return false;
  }
};

export function inputElementFocused() {
  const node = document.activeElement;
  if (!node || !node.tagName) {
    return false;
  }
  const tag = node.tagName.toLowerCase();
  const type = (node.getAttribute('type') || '').toLowerCase();
  const editable = node.getAttribute('contenteditable');
  return (tag === 'input' && type === 'text') || editable || tag === 'textarea';
}

export const isTextFrame = ({ type }) => type === 'Text';

export const isComment = ({ type }) => type === 'Comment';

export const valueBetween = (value, min, max) =>
  Math.max(Math.min(value, max), min);

export function onlyUnique(value, index, self) {
  return self.indexOf(value) === index;
}

export function fileNameToStickerName(fileName) {
  return fileName?.split('.')[0] || 'Neuer Sticker';
}
