import React from 'react';
import { useSelector } from 'react-redux';

import Root from '../elements/Root';
import Selector from '../Selector';
import Viewport from '../Viewport';
import { selectIsAlbumEditable } from '../../../selectors/albums';
import { selectIsEditingSticker } from '../../../selectors/controls';
import HistoryControls from './HistoryControls';

function MobileWorkspace() {
  const isAlbumEditable = useSelector(selectIsAlbumEditable);
  const isEditingSticker = useSelector(selectIsEditingSticker);

  const shouldRenderHistoryControls = isAlbumEditable && !isEditingSticker;

  return (
    <div className="workspace mobile qa-mobile-workspace">
      <Viewport>
        <Root preview={!isAlbumEditable} />
        <Selector />
      </Viewport>

      {shouldRenderHistoryControls && <HistoryControls />}
    </div>
  );
}

MobileWorkspace.propTypes = {};

export default MobileWorkspace;
