import React from 'react';

import { actions } from '../../../../constants';
import ActionButton from '../ActionButton';

export { default as ElementOrderButton } from './ElementOrderButton';
export { default as FillInput } from './FillInput';
export { default as StrokeInput } from './StrokeInput';
export { default as OpacityInput } from './OpacityInput';
export { default as ImageUploadButton } from './ImageUploadButton';
export { default as StickerImageUploadButton } from './StickerImageUploadButton';
export { default as GroupButtons } from './GroupButtons';
export { default as UnlinkStickerButton } from './UnlinkStickerButton';
export { default as TextSymbolInput } from './TextSymbolInput';
export { default as TextAlignAndLineHeightInput } from './TextAlignAndLineHeightInput';
export { default as TextColorInput } from './TextColorInput';
export { default as TextFontAndStyleInput } from './TextFontAndStyleInput';
export { default as TextEditButton } from './TextEditButton';
export { default as StickerEditButton } from './StickerEditButton';
export { default as ImageEditButton } from './ImageEditButton';

export const DuplicateButton = props => (
  <ActionButton
    {...props}
    actionName={actions.duplicateItems}
    tooltip="Duplizieren"
    icon="duplicate"
  />
);

export const DeleteButton = props => (
  <ActionButton
    {...props}
    actionName={actions.deleteElementItems}
    tooltip="Löschen"
    icon="trash"
  />
);

export const ImageFlipButton = props => (
  <ActionButton
    {...props}
    actionName={actions.flipImage}
    tooltip="Bild spiegeln"
    icon="arrows"
  />
);
