/*
 * Recursively map over an array or an object's children and execute a callback on all the nodes.
 *
 * This comes handy when we need to transform nested elements within one or multiple spreads, for instance,
 * to set `LINEHEIGHT-MIN` for all text blocks in old albums at once.
 */

export default function recursiveMap(element, callback, index = -1) {
  if (Array.isArray(element)) {
    return element
      .map((item, i) => recursiveMap(item, callback, i))
      .filter(Boolean);
  }

  // Call the callback only if `element` is not an array
  const updatedElement = element && callback(element, index);

  if (updatedElement && Array.isArray(updatedElement.children)) {
    return {
      ...updatedElement,
      children: recursiveMap(updatedElement.children, callback),
    };
  }

  return updatedElement;
}
