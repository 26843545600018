import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from 'react-bootstrap/Alert';
import { Route, useRouteMatch, Switch } from 'react-router';

import AlbumTitle from '../AlbumTitle';
import { TabPane } from '../TabContent';
import useScopedSidebarTabs from '../useScopedSidebarTabs';
import editorTabs from './editorTabs';
import DesktopOnlyWarning from '../../../../containers/app/DesktopOnlyWarning';
import { selectIsAlbumEditable } from '../../../../selectors/albums';
import FadeInWhenAlbumLoaded from '../../../generic/FadeInWhenAlbumLoaded';
import { selectMobileUIState } from '../../../../selectors/controls';
import { setShouldShowWarningAlert } from '../../../../modules/controls';

function MobileEditorSidebar() {
  const { tabs, activeTab } = useScopedSidebarTabs(editorTabs);
  const { path } = useRouteMatch();
  const sidebarRef = useRef();

  const dispatch = useDispatch();
  const { shouldShowWarningAlert } = useSelector(selectMobileUIState);
  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  /**
   * In order not to crowd the UI too much and because its content is
   * meaningless in locked albums, we render the desktop-only alert
   * in editable albums only.
   */
  const showAlert = isAlbumEditable && shouldShowWarningAlert;
  const [showDesktopLinkModal, setShowDesktopLinkModal] = useState(false);

  /**
   * When accessing an unscoped or non-existing tab, `activeTab` will
   * be undefined before the redirect.
   */
  const activeKey = activeTab?.key;

  function handleClose() {
    dispatch(setShouldShowWarningAlert(false));
  }

  useEffect(() => {
    if (sidebarRef.current) {
      sidebarRef.current.scrollTo({ top: 0 });
    }
  }, [activeKey]);

  if (!isAlbumEditable) {
    return null;
  }

  return (
    <FadeInWhenAlbumLoaded>
      <div
        className="mobile-sidebar qa-mobile-sidebar px-3 pt-2"
        ref={sidebarRef}
      >
        <div className="mobile-sidebar-content d-flex flex-column h-100 pt-2">
          {!(activeTab?.albumTitle === false) && (
            <AlbumTitle className="mb-2" />
          )}
          <Alert
            show={showAlert}
            onClose={handleClose}
            className="mt-2 border qa-mobile-alert"
            dismissible
          >
            <Alert.Heading>
              Hey{' '}
              <span role="img" aria-label="Wave emoji">
                👋
              </span>
            </Alert.Heading>
            Du benutzt die mobile Version des Designers – hier kannst du deine
            Sticker und Fotos verwalten. Sobald du damit beginnen willst, dein
            Album zu gestalten,{' '}
            <Alert.Link onClick={() => setShowDesktopLinkModal(true)}>
              wechsle bitte an einen Desktop-PC
            </Alert.Link>
            .
          </Alert>
          <DesktopOnlyWarning
            show={showDesktopLinkModal}
            onClose={() => setShowDesktopLinkModal(false)}
          />
          <Switch>
            {tabs.map(tab => (
              <Route key={tab.key} path={`${path}(${tab.key})`}>
                {tab.renderContent ? (
                  tab.renderContent()
                ) : (
                  <TabPane component={tab.component} sidebarRef={sidebarRef} />
                )}
              </Route>
            ))}
          </Switch>
        </div>
      </div>
    </FadeInWhenAlbumLoaded>
  );
}

MobileEditorSidebar.defaultProps = {};

MobileEditorSidebar.propTypes = {};

export default MobileEditorSidebar;
