export const ADD_PHOTOS_TO_CACHE = 'pexels/ADD_PHOTOS_TO_CACHE';

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_PHOTOS_TO_CACHE:
      return action.payload.reduce((acc, cur) => {
        acc[cur.id] = cur;
        return acc;
      }, state);
    default:
      return state;
  }
};
