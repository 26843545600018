import { bool, string } from 'prop-types';
import React from 'react';

import { resolutions } from '../../../constants';
import { computeNativeImageSize, resolveImage } from '../../../util/images';
import resolvePexelsObject from '../../../util/pexels';
import MoveOperation from '../../operations/MoveOperation';
import { ImageObjectShape, StaticResolutionShape } from '../../shapes';
import FaceBox from './FaceBox';
import Flip from './Flip';

function resolveObjects(imageObject, pexelsObject, resolution) {
  if (imageObject) {
    const { src } = resolveImage(imageObject, resolution);
    const { width, height } = computeNativeImageSize(imageObject);
    return { src, width, height };
  }
  if (pexelsObject) {
    const src = resolvePexelsObject(pexelsObject, resolution);
    const { width, height } = computeNativeImageSize({
      details: pexelsObject,
    });
    return { src, width, height };
  }
  return { src: null, width: 100, height: 100 };
}

function SvgImageBase({
  id,
  imageObject,
  pexelsObject,
  resolution,
  flip,
  withFaceBox,
  isSelectedInside,
  filter,
  className,
}) {
  const { src, width, height } = resolveObjects(
    imageObject,
    pexelsObject,
    resolution,
    resolvePexelsObject
  );

  const { face } = imageObject?.details || {};

  return (
    <Flip flip={flip} width={width}>
      <image
        width={width}
        height={height}
        xlinkHref={src}
        filter={filter}
        preserveAspectRatio="none"
        className={`ph-no-capture ${className}`}
      />
      {isSelectedInside && (
        <MoveOperation id={id} width={width} height={height} />
      )}
      {face && withFaceBox && (
        <FaceBox face={face} width={width} height={height} />
      )}
    </Flip>
  );
}

SvgImageBase.defaultProps = {
  id: null,
  isSelectedInside: false,
  imageObject: null,
  pexelsObject: null,
  resolution: resolutions.small,
  flip: false,
  withFaceBox: false,
  filter: null,
  className: null,
};

SvgImageBase.propTypes = {
  id: string,
  isSelectedInside: bool,
  imageObject: ImageObjectShape,
  pexelsObject: ImageObjectShape,
  resolution: StaticResolutionShape,
  flip: bool,
  withFaceBox: bool,
  filter: string,
  className: string,
};

export default SvgImageBase;
