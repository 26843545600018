import React from 'react';
import { useSelector } from 'react-redux';

import RotateOperation from '../../operations/RotateOperation';
import ResizeOperation from '../../operations/ResizeOperation';
import ScaleOperation from '../../operations/ScaleOperation';
import { getSingleSelectedElement } from '../../../selectors/legacy';
import { getShouldHideHandles } from '../../../selectors/controls';
import {
  getApplicableOperations,
  getSelectInside,
} from '../../../selectors/selection';
import { operations } from '../../../constants';
import HandlesGroup from './HandlesGroup';
import useHandlesBounds from '../../../hooks/useHandlesBounds';

const operationComponents = {
  [operations.rotate]: RotateOperation,
  [operations.resize]: ResizeOperation,
  [operations.scale]: ScaleOperation,
};

function Handles() {
  const applicableOperations = useSelector(getApplicableOperations);
  const selectInside = useSelector(getSelectInside);
  const singleSelectedElement = useSelector(getSingleSelectedElement);
  const shouldHideHandles = useSelector(getShouldHideHandles);
  const { elementBounds, imageBounds } = useHandlesBounds();

  if (
    applicableOperations.length === 0 ||
    !elementBounds ||
    shouldHideHandles
  ) {
    return null;
  }

  // Derive operation and frame bounds
  const operationBounds = imageBounds || elementBounds;
  const frameBounds = imageBounds ? elementBounds : null;

  // Map operation names to their react-elements
  const { width, height } = operationBounds;

  const operationInstances = applicableOperations.map(operation =>
    React.createElement(operationComponents[operation], {
      key: operation,
      width,
      height,
    })
  );

  const commonClass = `${selectInside ? 'inside' : ''} type-${
    singleSelectedElement?.type
  }`;
  const frameClass = `handles frame ${commonClass} qa-handles-frame`;
  const operationClass = `handles operations ${commonClass} qa-handles-operation`;

  return (
    <g className="js-handles qa-js-handles">
      {operationBounds && (
        <HandlesGroup className={operationClass} {...operationBounds}>
          {operationInstances}
        </HandlesGroup>
      )}
      {frameBounds && <HandlesGroup className={frameClass} {...frameBounds} />}
    </g>
  );
}

export default Handles;
