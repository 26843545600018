import { domMatrix, getAxisAlignedBounds } from '../../util/geometry';

export const pivotNames = {
  center: 'center',
  top: 'top',
  topRight: 'topRight',
  right: 'right',
  bottomRight: 'bottomRight',
  bottom: 'bottom',
  bottomLeft: 'bottomLeft',
  left: 'left',
  topLeft: 'topLeft',
};

export const clockwiseCornerPivotNames = [
  pivotNames.topLeft,
  pivotNames.topRight,
  pivotNames.bottomRight,
  pivotNames.bottomLeft,
];

export const clockwiseEdgePivotNames = [
  pivotNames.top,
  pivotNames.topRight,
  pivotNames.right,
  pivotNames.bottomRight,
  pivotNames.bottom,
  pivotNames.bottomLeft,
  pivotNames.left,
  pivotNames.topLeft,
];

export function getPivot(pivotName, { x = 0, y = 0, width, height }) {
  switch (pivotName) {
    default:
    case pivotNames.center:
      return { x: x + width / 2, y: y + height / 2 };
    case pivotNames.top:
      return { x: x + width / 2, y };
    case pivotNames.topRight:
      return { x: x + width, y };
    case pivotNames.right:
      return { x: x + width, y: y + height / 2 };
    case pivotNames.bottomRight:
      return { x: x + width, y: y + height };
    case pivotNames.bottom:
      return { x: x + width / 2, y: y + height };
    case pivotNames.bottomLeft:
      return { x, y: y + height };
    case pivotNames.left:
      return { x, y: y + height / 2 };
    case pivotNames.topLeft:
      return { x, y };
  }
}

const getClientPivot = (name, size, pivotDomNode) => {
  const point = getPivot(name, size);
  return domMatrix(pivotDomNode.getScreenCTM()).transformPoint(point);
};

const getClientPivots = (names, size, pivotDomNode) =>
  names.map(name => getClientPivot(name, size, pivotDomNode));

export const getClientPivotsAndDomNode = (names, size, sourceSelector) => {
  const pivotDomNode = document.querySelector(sourceSelector);
  const clientPivots = getClientPivots(names, size, pivotDomNode);
  return { clientPivots, pivotDomNode };
};

export const getPivotsFromInitialValues = (names, initialValues) => {
  if (!initialValues) {
    return null;
  }

  const { selectedElements, imageSize } = initialValues;
  const [singleSelectedElement] =
    selectedElements.length === 1 ? selectedElements : [];
  const { props: nodeProps } = singleSelectedElement || {};

  // Image inside frame selected
  if (imageSize) {
    const { id } = nodeProps;
    return getClientPivotsAndDomNode(
      names,
      imageSize,
      `[data-id='${id}'] .inside`
    );
  }

  // A single element selected
  if (singleSelectedElement) {
    const { id, width: nodeWidth, height: nodeHeight } = nodeProps;
    return getClientPivotsAndDomNode(
      names,
      { width: nodeWidth, height: nodeHeight },
      `[data-id='${id}']`
    );
  }

  // Multiple elements selected
  return getClientPivotsAndDomNode(
    names,
    getAxisAlignedBounds(selectedElements, '.viewport'),
    '.viewport'
  );
};
