import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useAuth from '../../hooks/useAuth';
import { selectCurrentAlbum } from '../../selectors/albums';
import { helpscoutClientId } from '../../constants';

window.Beacon('config', {
  hideAvatars: false,
  chatEnabled: true,
  display: {
    style: 'icon',
  },
});

function useHelpscoutBeacon({ position } = {}) {
  const {
    user: { sub, email },
  } = useAuth();

  const currentAlbum = useSelector(selectCurrentAlbum);

  useEffect(() => {
    window.Beacon('identify', {
      email,
    });
    window.Beacon('session-data', {
      albumId: currentAlbum,
      sub,
    });
    window.Beacon('config', {
      display: {
        position,
      },
    });
    window.Beacon('init', helpscoutClientId);

    return () => window.Beacon('destroy');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { beacon: window.Beacon };
}

export default useHelpscoutBeacon;
