import React from 'react';

import ColorTiles from '../ColorTiles';
import DropoutSection, { DropoutTitle } from '../DropoutSection';
import { colorCombinations } from '../../../../../../constants';
import { StylesPanelStateMachineShape } from '../../../../../shapes';

function ColorsSelector({ stateMachine }) {
  const { send } = stateMachine;
  const { colors: currentColors } = stateMachine;

  return (
    <div>
      <DropoutTitle label="Farbstil ändern" onBack={() => send('back')} />
      <DropoutSection
        className="d-flex flex-column cursor-pointer qa-color-presets-list-link"
        onClick={() => send('colorPresetsClick')}
        title="Vorlagen ausprobieren"
      >
        <div className="d-flex justify-content-start mb-2">
          <ColorTiles colors={colorCombinations[0].slice(2)} className="w-80" />
        </div>
        <div className="d-flex justify-content-center mb-2">
          <ColorTiles colors={colorCombinations[1].slice(2)} className="w-80" />
        </div>
        <div className="d-flex justify-content-end">
          <ColorTiles colors={colorCombinations[2].slice(2)} className="w-80" />
        </div>
      </DropoutSection>
      <DropoutSection
        className="cursor-pointer qa-custom-color-selector-link"
        onClick={() => send('customColorClick')}
        title="Eigene Farben eingeben"
      >
        <ColorTiles
          colors={currentColors.slice(2)}
          className="mt-5"
          magnifier
        />
      </DropoutSection>
    </div>
  );
}

ColorsSelector.propTypes = {
  stateMachine: StylesPanelStateMachineShape.isRequired,
};

export default ColorsSelector;
