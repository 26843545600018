import React from 'react';
import { number, node, bool } from 'prop-types';

function Flip({ flip, width, children }) {
  if (!flip) {
    return children;
  }
  return <g transform={`scale(-1,1) translate(${-width},0)`}>{children}</g>;
}

Flip.defaultProps = {
  flip: false,
};

Flip.propTypes = {
  flip: bool,
  width: number.isRequired,
  children: node.isRequired,
};

export default Flip;
