import React from 'react';
import { useSelector } from 'react-redux';

import ClipPaths from '../../components/svg/ClipPaths';
import Gradients from '../../components/svg/Gradients';
import { getAlbumStatisticsForPreflight } from '../../selectors/legacy';

/**
 * Extracting our svg defs into their own container allows
 * us to render svgs elements with equal ids into the DOM (same spreads
 * in the Sidebar _and_ Workspace components, for example).
 */
function SvgDefs() {
  const { spine } = useSelector(getAlbumStatisticsForPreflight);

  return (
    <svg className="svg-defs">
      <defs>
        <ClipPaths spine={spine} />
        <Gradients />
      </defs>
    </svg>
  );
}

export default SvgDefs;
