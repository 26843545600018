import { useRef } from 'react';

/**
 * Calls `callback` for the first
 * time `condition` is true.
 *
 * @param {*} callback The callback function to call
 * @param {*} condition Expression defining when the callback will be called
 */
export default function useFirstTime(callback, condition) {
  const waiting = useRef(true);

  if (waiting.current && condition) {
    waiting.current = false;
    callback();
  }
}
