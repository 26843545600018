import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';

import AdminOnly from '../../../generic/AdminOnly';
import DropdownItemWithIcon from '../../../generic/DropdownItemWithIcon';
import Icon from '../../../Icon';
import {
  toggleGridEnabled,
  toggleShowElementsTree,
  toggleCropPreview,
  toggleShowStickers,
} from '../../../../modules/controls';
import {
  getCropPreview,
  getGridEnabled,
  getShowElementsTree,
  getShowStickers,
} from '../../../../selectors/controls';

function Checkmark() {
  return <Icon className="d-inline-block ml-2" name="checkmark" />;
}

function ViewSettingsControl() {
  const dispatch = useDispatch();
  const gridEnabled = useSelector(getGridEnabled);
  const showElementsTree = useSelector(getShowElementsTree);
  const cropPreview = useSelector(getCropPreview);
  const printPreview = !useSelector(getShowStickers);

  function handleFocus(event) {
    event.preventDefault();
    event.target.blur();
  }

  return (
    <Dropdown drop="up">
      <Dropdown.Toggle
        variant="light"
        className="bg-white shadow qa-view-settings-control"
        onFocus={handleFocus}
      >
        <Icon className="d-inline-block" name="settings" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="qa-view-control-menu">
        <DropdownItemWithIcon
          className={`qa-toggle-grid-enabled ${
            gridEnabled ? 'text-primary' : ''
          }`}
          iconName="grid"
          onClick={() => dispatch(toggleGridEnabled())}
        >
          Raster
          {gridEnabled && <Checkmark />}
        </DropdownItemWithIcon>
        <AdminOnly>
          <DropdownItemWithIcon
            className={`qa-toggle-show-elements-tree ${
              showElementsTree ? 'text-primary' : ''
            }`}
            iconName="list"
            onClick={() => dispatch(toggleShowElementsTree())}
          >
            Seitenelemente
            {showElementsTree && <Checkmark />}
          </DropdownItemWithIcon>
        </AdminOnly>
        <DropdownItemWithIcon
          className={cropPreview ? 'text-primary' : ''}
          iconName="bleed"
          onClick={() => dispatch(toggleCropPreview())}
        >
          Endformat anzeigen
          {cropPreview && <Checkmark />}
        </DropdownItemWithIcon>
        <DropdownItemWithIcon
          className={printPreview ? 'text-primary' : ''}
          iconName="printer_fill"
          onClick={() => dispatch(toggleShowStickers())}
        >
          Druckvorschau
          {printPreview && <Checkmark />}
        </DropdownItemWithIcon>
      </Dropdown.Menu>
    </Dropdown>
  );
}

ViewSettingsControl.propTypes = {};

export default ViewSettingsControl;
