import React from 'react';

import SectionPanel from '../../SectionPanel/index.container';
import ImagesPanel from '../../ImagesPanel/index.container';
import JobsPanel from '../../JobsPanel';
import StockImagesPanel from '../../StockImagesPanel';
import StickerLinkPanel from '../../StickerLinkPanel';
import LayoutsPanel from '../../LayoutsPanel';
import StickerLinkInboxCountBadge from '../StickerLinkInboxCountBadge';
import ReviewPanel from '../../ReviewPanel';
import BackgroundPanel from '../../BackgroundPanel';
import StylesPanel from '../../AlbumWizard/Sidebar/StylesPanel';
import EditorOnboarding from '../../../../containers/onboarding';

export default [
  {
    title: 'Start',
    key: 'onboarding',
    image: 'checklist',
    component: () => null, // rendering full-width EditorOnboarding via route in Editor component
    desktopOnly: true,
    albumTitle: false,
    minimized: true,
  },
  {
    title: 'Start',
    key: 'onboarding',
    image: 'checklist',
    component: () => <EditorOnboarding />,
    mobileOnly: true,
  },
  {
    title: 'Sticker',
    key: 'stickers',
    image: 'sticker',
    component: SectionPanel,
    renderTabIconMeta: () => <StickerLinkInboxCountBadge />,
  },
  {
    title: 'Stickerlink',
    key: 'stickerlink',
    image: 'stickerlink',
    component: StickerLinkPanel,
  },
  {
    title: 'Hintergrund',
    key: 'background',
    image: 'background',
    component: () => (
      <BackgroundPanel
        tabs={[
          {
            title: 'Eigene Bilder',
            key: 'images',
            component: ImagesPanel,
          },
          {
            title: 'Bibliothek',
            key: 'stock',
            component: StockImagesPanel,
            desktopOnly: true,
          },
        ]}
      />
    ),
  },
  {
    title: 'Layouts',
    key: 'layouts',
    image: 'layouts',
    component: LayoutsPanel,
  },
  {
    title: 'Design',
    key: 'styles',
    image: 'brushFill',
    component: StylesPanel,
  },
  {
    title: 'Export',
    key: 'jobs',
    image: 'images',
    component: JobsPanel,
    allowedRoles: ['admin'],
  },
  {
    title: 'Feedback',
    key: 'review',
    image: 'comment',
    component: ReviewPanel,
    withOrderOnly: true,
    desktopOnly: true,
  },
];
