import React from 'react';
import { number } from 'prop-types';

const highlightWidth = 20;

function Spread3DEffect({ x, y, height }) {
  return (
    <>
      <rect
        style={{ pointerEvents: 'none' }}
        x={x - highlightWidth}
        y={y}
        width={highlightWidth}
        height={height}
        fill="url(#spread_3d_highlight)"
      />
      <rect
        style={{ pointerEvents: 'none' }}
        x={x}
        y={y}
        width={highlightWidth}
        height={height}
        fill="url(#spread_3d_shadow)"
      />
    </>
  );
}

Spread3DEffect.propTypes = {
  x: number.isRequired,
  y: number.isRequired,
  height: number.isRequired,
};

export default Spread3DEffect;
