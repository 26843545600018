import React from 'react';
import { number, bool, func, string } from 'prop-types';
import { Editor, getDefaultKeyBinding, RichUtils } from 'draft-js';
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent';
import { connect } from 'react-redux';

import { setEditorState } from '../../../../modules/selection';
import { ContentContext } from '../../Content';
import { getElementTransform } from '../../../../util/geometry';
import GuideRect from '../GuideRect';
import { HANDLED, NOT_HANDLED, handleBlockSplit } from './TextConstants';
import {
  DraftStyleMapShape,
  EditorStateShape,
  RawDraftContentStateShape,
} from '../../../shapes';
import BaseElement from '../BaseElement';
import { fontSizeScaleFactor } from '../../../../constants';
import { historyAnchor } from '../../../../modules/history';

class BaseText extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: false,
      overlayDone: false,
    };
    this.editorRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentDidUpdate(prevProps) {
    const { mounted, overlayDone } = this.state;
    const { readOnly } = this.props;

    if (mounted && this.contentContext && !overlayDone) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ overlayDone: true });
      this.createOverlay();
    }

    if (!readOnly && prevProps.readOnly) {
      // Changed from non-editing to editing: set the focus manually, since selection.hasFocus does not seem
      // to capture the focus when set (https://draftjs.org/docs/api-reference-selection-state/#hasfocus)
      this.editorRef.current.focus();

      /**
       * This is to prevent the chrome native browser feature to
       * drag selected text, for example into another line.
       */
      const { editor: editorDomNode } = this.editorRef.current;
      editorDomNode.ondragstart = e => e.preventDefault();
    }
  }

  change = editorState => {
    // eslint-disable-next-line no-shadow
    const { setEditorState } = this.props;
    setEditorState(editorState);
  };

  handleChange = editorState => {
    this.change(editorState);
  };

  handleBlur = () => {
    // eslint-disable-next-line no-shadow
    const { historyAnchor } = this.props;
    historyAnchor();
  };

  handleKeyCommand = command => {
    const { editorState } = this.props;
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      this.change(newState);
      return HANDLED;
    }
    return NOT_HANDLED;
  };

  handleReturn = event => {
    const { editorState } = this.props;
    if (isSoftNewlineEvent(event)) {
      return NOT_HANDLED;
    }
    const splitState = handleBlockSplit(editorState);
    this.change(splitState);
    return HANDLED;
  };

  keyBindingFn = event => {
    if (event.key === 'Escape') {
      /** we have to refocus editor, because first escape
       * in this component sets focus on body, and there
       * isn't any keyhandler
       * TODO: find solution without manual setting of focus
       */
      document.querySelector('.editor').focus();
      return HANDLED;
    }
    const result = getDefaultKeyBinding(event);
    event.stopPropagation();
    return result;
  };

  handleStopPropagation = event => {
    event.stopPropagation();
  };

  createOverlay = () => {
    const { width, height } = this.props;

    const transform = getElementTransform(
      this.foreignObject,
      width / fontSizeScaleFactor,
      height / fontSizeScaleFactor
    );
    const html = this.foreignObject.innerHTML;
    this.contentContext.createTextOverlay({ transform, html });
  };

  render() {
    const {
      editorState,
      height,
      opacity,
      readOnly,
      width,
      customStyleMap,
      blockRendererFn,
    } = this.props;

    const content = (
      <Editor
        key={JSON.stringify(customStyleMap)}
        customStyleMap={customStyleMap}
        blockRendererFn={blockRendererFn}
        editorState={editorState}
        readOnly={readOnly}
        onChange={this.handleChange}
        handleKeyCommand={this.handleKeyCommand}
        handleReturn={this.handleReturn}
        keyBindingFn={this.keyBindingFn}
        onBlur={this.handleBlur}
        ref={this.editorRef}
        preserveSelectionOnBlur
      />
    );

    return (
      <BaseElement {...this.props}>
        <ContentContext.Consumer>
          {contentContext => {
            this.contentContext = contentContext;
            return (
              <g opacity={opacity} className="inside">
                <rect width={width} height={height} fill="none" />
                <GuideRect width={width} height={height} />
                <foreignObject
                  style={{
                    transform: `scale(${fontSizeScaleFactor})`,
                    transformOrigin: 'left top',
                  }}
                  ref={node => {
                    this.foreignObject = node;
                  }}
                  className={`draft-root ${
                    !readOnly ? 'editing qa-editing' : ''
                  } qa-draft-root`}
                  width={width / fontSizeScaleFactor}
                  height={height / fontSizeScaleFactor}
                >
                  {content}
                </foreignObject>
              </g>
            );
          }}
        </ContentContext.Consumer>
      </BaseElement>
    );
  }
}

BaseText.defaultProps = {
  opacity: null,
};

BaseText.propTypes = {
  editorState: EditorStateShape.isRequired,
  height: number.isRequired,
  id: string.isRequired,
  opacity: number,
  readOnly: bool.isRequired,
  width: number.isRequired,
  text: RawDraftContentStateShape.isRequired,
  customStyleMap: DraftStyleMapShape.isRequired,
  blockRendererFn: func.isRequired,
  setEditorState: func.isRequired,
  historyAnchor: func.isRequired,
};

const mapDispatchToProps = {
  setEditorState,
  historyAnchor,
};

export default connect(null, mapDispatchToProps)(BaseText);
