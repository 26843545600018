import { EditorState, convertFromRaw } from 'draft-js';
import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import uniq from 'lodash/uniq';

import {
  getIsolation,
  getSelectedElementIds,
  getSelectedElements,
  getSelectedTextElements,
  getSingleSelectedElement,
  getSpreadIds,
  getTargetNode,
  getTargetNodeId,
  getWorkspace,
} from './legacy';
import {
  createSelectionStateWithAllTextSelected,
  extractTextPropsFromEditorState,
} from '../util/draftjs';
import {
  actions,
  elementSortTiers,
  operations,
  actionsByActionButton,
  actionHotkeys,
} from '../constants';
import { getAxisAlignedBounds } from '../util/geometry';
import { selectCurrentUser } from './user';

export const getSelectInside = state => state.selection.selectInside;

export const getEditorState = state => state.selection.editorState;

export const getLassoSpreadIndex = state => state.selection.lassoSpreadIndex;

export const getTextEditActive = state => state.selection.textEditActive;

export const getToolbarEditorState = state => {
  const editorState = getEditorState(state);
  if (editorState) {
    return editorState;
  }
  const selectedTextElements = getSelectedTextElements(state);
  const [firstSelectedTextElement] = selectedTextElements;
  if (!firstSelectedTextElement) {
    return null;
  }
  const contentState = convertFromRaw(firstSelectedTextElement.props.text);
  const selectionState = createSelectionStateWithAllTextSelected(contentState);
  const toolbarEditorState = EditorState.createWithContent(contentState);
  return EditorState.acceptSelection(toolbarEditorState, selectionState);
};

/**
 * Elements have different applicable operations defined,
 * depending on their type and the `selectInside` flag.
 */
export function applicableOperationsByType(type, inside) {
  if (type === 'Image' && inside) {
    return [operations.rotate, operations.scale];
  }
  if (['StickerCell', 'Comment'].includes(type)) {
    return [];
  }
  if (type === 'Text' && inside) {
    return [operations.resize];
  }
  return [operations.rotate, operations.resize];
}

/**
 * Takes a an array of element ids and the workspace nodes to recursively
 * entangle the element and all its children.
 */
export function extractNestedElements(nodeIds, nodes) {
  return flatten(
    nodeIds.map(id => {
      if (nodes[id].type === 'Group') {
        return extractNestedElements(nodes[id].children, nodes);
      }
      return nodes[id];
    })
  );
}

export const getSelectedNestedElementTypes = createSelector(
  [getSelectedElementIds, getWorkspace],
  (selectedIds, { nodes }) =>
    uniq(extractNestedElements(selectedIds, nodes).map(element => element.type))
);

export const getApplicableOperations = createSelector(
  [getSingleSelectedElement, getSelectedNestedElementTypes, getSelectInside],
  (singleSelectedElement, selectedElementTypes, inside) => {
    // We get an array of applicable operations for each element in the
    // selection here and return their intersection.
    let intersectionOfOperations = intersection(
      ...selectedElementTypes.map(elementType =>
        applicableOperationsByType(elementType, inside)
      )
    );

    // There is no resizing in multi-selection or `Group` elements, only scaling.
    if (
      isEmpty(singleSelectedElement) ||
      singleSelectedElement?.type === 'Group'
    ) {
      intersectionOfOperations = intersectionOfOperations.map(el =>
        el.replace(operations.resize, operations.scale)
      );
    }

    return intersectionOfOperations;
  }
);

export const getOperationParentId = createSelector(
  [getSingleSelectedElement, getTargetNodeId, getSelectInside],
  (singleSelectedElement, targetNodeId, selectInside) => {
    // Todo: why is singleSelectedElement is sometimes undefined?
    return selectInside && singleSelectedElement
      ? singleSelectedElement.props.id
      : targetNodeId;
  }
);

const textElementTools = [
  'TextEditButton',
  'TextFontAndStyleInput',
  'TextColorInput',
  'TextAlignAndLineHeightInput',
  'TextSymbolInput',
];

const commonTools = [
  'GroupButtons',
  'ElementOrderButton',
  'DuplicateButton',
  'DeleteButton',
];

const toolsByElementType = {
  Rectangle: ['FillInput', 'StrokeInput', 'OpacityInput', ...commonTools],
  Circle: ['FillInput', 'StrokeInput', 'OpacityInput', ...commonTools],
  Line: ['StrokeInput', 'OpacityInput', ...commonTools],
  Group: ['OpacityInput', ...commonTools],
  Image: [
    'ImageEditButton',
    'ImageUploadButton',
    'ImageFlipButton',
    'OpacityInput',
    ...commonTools,
  ],
  Text: [...textElementTools, ...commonTools],
  StickerCell: [
    'StickerEditButton',
    'StickerImageUploadButton',
    'UnlinkStickerButton',
    'GroupButtons',
    'DeleteButton',
  ],
  Comment: ['DuplicateButton', 'DeleteButton'],
};

/**
 * We need a rudimentary form of scoping for some element type / applicable action
 * combinations (users should not be able to destroy other users' comments, f. e.).
 *
 * Maps action name to action name if the rule is met, else maps it to null.
 */
const scopeActionsForElement = (element, user) => {
  const {
    type,
    props: { userId },
  } = element;

  const isAdmin = user?.roles?.includes('admin');
  const isAuthor = userId === user.id;

  return {
    [actions.duplicateItems]:
      type !== 'Comment' || isAdmin || isAuthor ? actions.duplicateItems : null,
    [actions.deleteElementItems]:
      type !== 'Comment' || isAdmin || isAuthor
        ? actions.deleteElementItems
        : null,
  };
};

export const getSelectedElementTypes = createSelector(
  [getSelectedElements],
  selectedElements => selectedElements.map(({ type }) => type)
);

export const getSelectedElementProps = createSelector(
  [getSelectedElements],
  selectedElements => selectedElements.map(({ props }) => props)
);

/**
 * Applies `allowedActionRules` to each element in the selection and spreads
 * scoped actions into the global actions object (thus overrides all properties that
 * have a scoping rule defined).
 *
 * Returns an array of strings with all actions allowed to perform on the selection.
 */
export const selectAllowedActions = createSelector(
  [getSelectedElements, selectCurrentUser],
  (selectedElements, currentUser) => {
    const scopedActions = selectedElements.reduce((acc, element) => {
      return {
        ...acc,
        ...scopeActionsForElement(element, currentUser),
      };
    }, {});

    return Object.values({ ...actions, ...scopedActions });
  }
);

/**
 * Uses the `selectAllowedActions` selector to get an array of actions
 * that are allowed for the user and returns only hotkeys for allowed
 * actions.
 */
export const selectAllowedHotkeys = createSelector(
  [selectAllowedActions],
  allowedActions => {
    return actionHotkeys.filter(([action]) => {
      return allowedActions.includes(action);
    });
  }
);

const selectApplicableTools = createSelector(
  [getSelectedElementTypes, getTextEditActive],
  (selectedTypes, textEditActive) => {
    return intersection(
      ...selectedTypes.map(type => toolsByElementType[type]),
      ...(textEditActive ? [textElementTools] : []) // during text edit, we show only the core-text tools, no common tools
    );
  }
);

/**
 * Selects applicable tools, checks for whether all of them map to actions
 * that are currently allowed, and only returns the subset.
 */
export const selectAllowedApplicableTools = createSelector(
  [selectApplicableTools, selectAllowedActions],
  (applicableTools, allowedActions) =>
    applicableTools.filter(tool => {
      const scopedActions = actionsByActionButton[tool] || [];
      const toolActionsAreAllowed = scopedActions.every(action =>
        allowedActions.includes(action)
      );

      return toolActionsAreAllowed;
    })
);

export const getCommonIdenticalProps = createSelector(
  [getSelectedElementProps],
  selectedPropsList => {
    // Determine props that are identical through all selected elements
    const [firstProps, ...remainingPropsList] = selectedPropsList || [];
    return pickBy(firstProps, (value, key) =>
      remainingPropsList.every(otherProps => otherProps[key] === value)
    );
  }
);

export const getApplicableSendAction = createSelector(
  [getWorkspace, getTargetNode, getSelectedElements],
  ({ nodes }, { children }, selectedElements) => {
    // Map the selected elements to their respective sort-tiers
    const sortTiers = selectedElements.map(
      element => elementSortTiers[element.type]
    );

    // If elements from different sort-tiers are selected, do not offer a send action
    if (new Set(sortTiers).size !== 1) {
      return null;
    }

    // Determine the children in the same sort-tier as the selection
    const [sortTier] = sortTiers;
    const relevantChildren = children.filter(
      id => elementSortTiers[nodes[id].type] === sortTier
    );

    // If all elements are selected, there is no point in sending them
    if (relevantChildren.length === selectedElements.length) {
      return null;
    }

    // Determine if all elements are already in the foreground
    const allOnTop = selectedElements.every(
      element =>
        relevantChildren.indexOf(element.props.id) >=
        relevantChildren.length - selectedElements.length
    );

    // If they are all in the foreground, offer send-to-back, otherwise always to front
    return allOnTop ? actions.sendItemsBack : actions.sendItemsFront;
  }
);

export const getLassoSelectableElementIds = createSelector(
  [getWorkspace, getIsolation, getSpreadIds, getLassoSpreadIndex],
  ({ nodes }, isolationId, spreadIds, lassoSpreadIndex) => {
    /**
     *  Limit available ids to all elements the same spread (or inside the
     *  isolated group), to prevent cross-spread selections
     */
    const parentId = isolationId || spreadIds[lassoSpreadIndex];
    return parentId ? nodes[parentId].children : [];
  }
);

export const getLassoSelectableElementAreas = createSelector(
  [getWorkspace, getLassoSelectableElementIds],
  ({ nodes }, selectableIds) => {
    // Convert available ids to their bounding boxes
    return selectableIds.reduce((acc, id) => {
      const result = getAxisAlignedBounds([nodes[id]], '.viewport');
      if (result) {
        acc[id] = result;
      }
      return acc;
    }, {});
  }
);

export const getSelectedTextProps = createSelector(
  [getToolbarEditorState],
  editorState =>
    editorState ? extractTextPropsFromEditorState(editorState) : null
);

export const getSelectedImageIds = createSelector(
  [getSelectedElements],
  selectedElements =>
    selectedElements
      .filter(item => item.type === 'Image')
      .map(item => item.props.id)
);

export const getSectionIdForSelection = createSelector(
  [getWorkspace, getSelectedElements],
  ({ nodes }, selectedElements) => {
    function findParentSection(item) {
      if (item.type === 'Section') {
        return item.props.id;
      }
      return findParentSection(nodes[item.parent]);
    }

    /**
     * We only need the parent section for one of the selected
     * elements, as selection across different sections is not
     * currently supported.
     */
    return findParentSection(selectedElements[0]);
  }
);
