import { removeFromQueue } from './loading';
import { loadWorkspace } from '../actions/workspace';
import { replaceImages } from './images';
import { replaceColors, replaceFonts } from './colorsAndFonts';
import { historyAnchor, albumChanged } from './history';
import { fetchPexelsImagesForWorkspace } from '../actions/pexels';
import { setStickerUploads } from '../actions/stickerUploads';
import { replaceStickers } from '../actions/stickers';
import { selectBlueprint } from './blueprints';

/**
 * Action-Types.
 */
export const SET_ALBUM = 'album/set';
export const DELETE_ALBUM = 'album/delete';
export const SET_ALBUM_DATA = 'album/data';
export const SET_ALBUMS = 'album/set_list';
export const ADD_ALBUM = 'album/add';
export const ALBUM_SAVING = 'album/saving';
export const UPDATE = 'album/update';

const initialState = {
  albums: [],
  currentAlbum: null,
  loaded: false,
  hasUnsavedChanges: false,
  albumData: {
    title: 'Titel des Albums',
    releaseDate: undefined,
    bookSpine: true,
    stickerUploadEnabled: false,
    stickerUploadWhiteLabel: false,
    stickerUploadContent: '',
    stickerUploadCustomDomain: null,
    stickerUploadRequirePosition: false,
    hasOrder: true,
    locked: false,
    emailNotificationsEnabled: true,
    albumPreviewPath: '',
    stickerTemplateId: null,
    organization: {},
    ownerIdentifier: null,
  },
  saving: false,
  showingCrashSave: false,
  orders: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ALBUM:
      return {
        ...state,
        currentAlbum: action.payload.id,
      };
    case SET_ALBUMS:
      return {
        ...state,
        albums: action.payload,
      };
    case SET_ALBUM_DATA:
      return {
        ...state,
        albumData: {
          ...state.albumData,
          ...action.payload,
        },
      };
    case ADD_ALBUM:
      return {
        ...state,
        albums: [...state.albums, action.payload],
      };
    case DELETE_ALBUM:
      return {
        ...state,
        albums: state.albums.filter(({ id }) => id !== action.payload.id),
      };
    case ALBUM_SAVING:
      return {
        ...state,
        saving: action.payload,
      };

    case UPDATE:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export const updateAlbums = payload => dispatch =>
  dispatch({ type: UPDATE, payload });

/**
 * Set the user's albums.
 */
export const setAlbums = albums => ({
  type: SET_ALBUMS,
  payload: albums,
});

/**
 * We need the `loaded` flag to mark an album
 * as "ready for autosave".
 * @param {*} loaded
 */
export const setAlbumLoaded = (loaded = true) => dispatch => {
  dispatch({ type: UPDATE, payload: { loaded } });
};

/**
 * Set the current album.
 */
export const setCurrentAlbum = id => dispatch =>
  dispatch({ type: SET_ALBUM, payload: { id } });

/**
 * When unmounting an editor component, we have to
 * reset the album store for the current album.
 */
export const unsetCurrentAlbum = () => dispatch => {
  dispatch({
    type: UPDATE,
    payload: {
      currentAlbum: null,
      loaded: false,
      albumData: initialState.albumData,
      saving: false,
      showingCrashSave: false,
    },
  });
};

export const setUnsavedChanges = value => dispatch => {
  dispatch({ type: UPDATE, payload: { hasUnsavedChanges: value } });
};

export const setAlbumData = albumData => ({
  type: SET_ALBUM_DATA,
  payload: albumData,
});

export const deleteAlbum = id => ({
  type: DELETE_ALBUM,
  payload: { id },
});

/**
 * Add an album to the store.
 */
export const addAlbum = album => ({ type: ADD_ALBUM, payload: album });

/**
 * Hydrate the store with album data from a server response.
 * @param {*} album album server response
 */
export const receiveAlbum = album => async dispatch => {
  dispatch(replaceImages(album.images));

  const {
    id,
    colors,
    fonts,
    stickers,
    workspace,
    title,
    release_date: releaseDate,
    blueprint_album_id: blueprintAlbumId,
    sticker_uploads_path: stickerUploadsPath,
    sticker_upload_content: stickerUploadContent,
    sticker_upload_enabled: stickerUploadEnabled,
    sticker_upload_white_label: stickerUploadWhiteLabel,
    sticker_upload_custom_domain: stickerUploadCustomDomain,
    sticker_upload_require_position: stickerUploadRequirePosition,
    sticker_uploads: stickerUploads,
    blueprint_category: blueprintCategory,
    'ordered?': hasOrder,
    locked,
    last_order_id: lastOrderId,
    email_notifications_enabled: emailNotificationsEnabled,
    album_preview_path: albumPreviewPath,
    sticker_template_settings: stickerTemplateSettings,
    owner_identifier: ownerIdentifier,
    organization,
  } = album;

  dispatch({
    type: SET_ALBUM_DATA,
    payload: {
      id,
      title,
      releaseDate,
      stickerUploadsPath,
      stickerUploadContent,
      stickerUploadEnabled,
      stickerUploadWhiteLabel,
      stickerUploadCustomDomain,
      stickerUploadRequirePosition,
      blueprintCategory,
      hasOrder,
      locked,
      lastOrderId,
      emailNotificationsEnabled,
      albumPreviewPath,
      stickerTemplateSettings,
      ownerIdentifier,
      organization,
    },
  });

  dispatch(replaceStickers(stickers));
  dispatch(replaceColors(colors));
  dispatch(replaceFonts(fonts));
  await dispatch(fetchPexelsImagesForWorkspace(workspace));
  dispatch(loadWorkspace(workspace));
  dispatch(selectBlueprint(blueprintAlbumId));
  dispatch(setStickerUploads(stickerUploads));
  dispatch(historyAnchor());
  dispatch(albumChanged(false));

  dispatch(removeFromQueue(`fetchAlbum-${id}`));
  dispatch(setAlbumLoaded());
};

/**
 * Hydrate the store with album preview data from a server response.
 * @param {*} album album preview server response
 */
export const receiveAlbumPreview = album => async dispatch => {
  const {
    id,
    colors,
    fonts,
    stickers,
    images,
    workspace,
    title,
    album_preview_path: albumPreviewPath,
    sticker_template_settings: stickerTemplateSettings,
    release_date: releaseDate,
  } = album;

  dispatch({
    type: SET_ALBUM_DATA,
    payload: {
      id,
      title,
      albumPreviewPath,
      stickerTemplateSettings,
      releaseDate,
    },
  });

  dispatch(replaceImages(images));
  dispatch(replaceStickers(stickers));
  dispatch(replaceColors(colors));
  dispatch(replaceFonts(fonts));
  await dispatch(fetchPexelsImagesForWorkspace(workspace));
  dispatch(loadWorkspace(workspace));
};
