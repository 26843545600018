import { bool, func, node } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import { useSelector } from 'react-redux';

import FadeInWhenAlbumLoaded from '../../components/generic/FadeInWhenAlbumLoaded';
import Icon from '../../components/Icon';
import useScreenSize from '../../hooks/useScreenSize';
import { selectIsAlbumEditable } from '../../selectors/albums';

function NotificationSnackbar({
  children,
  closeButton,
  isVisible,
  onClose,
  autoHide,
}) {
  useEffect(() => {
    if (!autoHide) {
      return undefined;
    }

    const timeout = setTimeout(onClose, 10000);
    return () => clearTimeout(timeout);
  }, [onClose, autoHide]);

  return (
    <Fade in={isVisible} mountOnEnter unmountOnExit>
      <div className="notification-snackbar d-flex">
        <div>{children}</div>
        {closeButton && (
          <Button
            onClick={onClose}
            variant="light"
            size="sm"
            className="close notification-snackbar__close-button qa-notification-snackbar"
          >
            <Icon name="closeThin" />
          </Button>
        )}
      </div>
    </Fade>
  );
}

NotificationSnackbar.propTypes = {
  children: node.isRequired,
  onClose: func,
  closeButton: bool,
  isVisible: bool,
  autoHide: bool,
};

NotificationSnackbar.defaultProps = {
  onClose: null,
  closeButton: false,
  isVisible: false,
  autoHide: false,
};

function Notifications() {
  const { isMobile } = useScreenSize();
  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  const [isAutosaveVisible, setIsAutosaveVisible] = useState(
    isAlbumEditable && !isMobile
  );

  return (
    <FadeInWhenAlbumLoaded>
      <div className="notifications">
        <NotificationSnackbar isVisible={!isAlbumEditable}>
          <span role="img" aria-label="Lock emoji">
            🔒
          </span>
          Dein Album befindet sich im Vorschau-Modus, Änderungen sind aktuell
          leider nicht möglich.
        </NotificationSnackbar>
        <NotificationSnackbar
          closeButton
          isVisible={isAutosaveVisible}
          onClose={() => setIsAutosaveVisible(false)}
          autoHide
        >
          Deine Änderungen werden <strong>automatisch</strong> gespeichert.
        </NotificationSnackbar>
      </div>
    </FadeInWhenAlbumLoaded>
  );
}

export default Notifications;
