import { useCallback, useState } from 'react';
import { useInterval } from 'react-use';

/**
 * Executes the `callback` every `interval` and waits for its execution
 */
// eslint-disable-next-line func-names
export default function(callback, interval) {
  const [waiting, setWaiting] = useState(false);
  const poll = useCallback(() => {
    (async () => {
      setWaiting(true);
      await callback();
      setWaiting(false);
    })();
  }, [callback, setWaiting]);
  useInterval(poll, waiting ? null : interval);
}
