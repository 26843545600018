/**
 * Action-types.
 */
export const ADD_TO_LOADING_QUEUE = 'loading/add';
export const REMOVE_FROM_LOADING_QUEUE = 'loading/remove';

/**
 * An array holding all async operations currently running. We show a spinner unless state.length === 0.
 */
const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_LOADING_QUEUE:
      return [...state, action.payload];
    case REMOVE_FROM_LOADING_QUEUE:
      return state.filter(item => item.id !== action.payload);
    default:
      return state;
  }
};

/**
 * Adds a job to the currently running operations.
 * @param {} jobId A unique jobId.
 * @param blocking: used to determine where to show the spinner
 */
export const addToQueue = (jobId, blocking = true) => dispatch => {
  return dispatch({
    type: ADD_TO_LOADING_QUEUE,
    payload: { id: jobId, blocking },
  });
};

/**
 * Removes a job from currently running operations.
 * @param {*} jobId JobId to remove.
 */
export const removeFromQueue = jobId => dispatch => {
  return dispatch({ type: REMOVE_FROM_LOADING_QUEUE, payload: jobId });
};
