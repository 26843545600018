import React, { useCallback } from 'react';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import { useDispatch } from 'react-redux';

import useImageUpload from '../../../hooks/useImageUpload';
import ImagesPanel from '.';
import { createWarning } from '../../../actions/notifications';
import { maxImageUploads } from '../../../constants';
import useAnalytics from '../../../containers/app/useAnalytics';

function ImagesPanelWithDnd(props) {
  const { createWorkspaceImage } = useImageUpload();
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  // When dropping a file, we're uploading it.
  const handleDrop = useCallback(
    ({ monitor }) => {
      if (monitor.getItemType() === NativeTypes.FILE) {
        const { files } = monitor.getItem();
        if (files.length > maxImageUploads) {
          dispatch(
            createWarning(
              `Es können bis zu ${maxImageUploads} Bilder gleichzeitig hochgeladen werden.`
            )
          );
          return;
        }
        Array.from(files).forEach(file => {
          createWorkspaceImage(file);

          analytics.track('Image Uploaded', {
            drop: true,
            sidebar: true,
          });
        });
      }
    },
    [createWorkspaceImage, dispatch, analytics]
  );

  const [{ isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(_, monitor) {
      if (handleDrop) {
        handleDrop({ monitor });
      }
    },
    collect: el => ({
      isOver: el.isOver() && el.getItemType() === NativeTypes.FILE,
    }),
  });

  return <ImagesPanel {...props} dropRef={drop} isOver={isOver} />;
}

export default ImagesPanelWithDnd;
