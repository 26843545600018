import { dimensions } from '../../../../constants';
import { createInitialContentStateFromText } from '../../../../util/draftjs';

export default [
  {
    id: 'Text',
    title: 'Textfeld',
    template: {
      type: 'Text',
      props: {
        width: 100,
        height: 20,
        x: 0,
        y: 0,
        text: createInitialContentStateFromText('Neuer Text...'),
      },
    },
  },
  {
    id: 'Circle',
    title: 'Kreis',
    template: {
      type: 'Circle',
      props: {
        width: 100,
        height: 100,
        x: 0,
        y: 0,
        fill: 2,
      },
    },
  },
  {
    id: 'Rectangle',
    title: 'Rechteck',
    template: {
      type: 'Rectangle',
      props: {
        width: 100,
        height: 100,
        x: 0,
        y: 0,
        fill: 2,
      },
    },
  },
  {
    id: 'Line',
    title: 'Linie',
    template: {
      type: 'Line',
      props: {
        width: 100,
        x: 0,
        y: 0,
        height: 1,
        fill: 'none',
        stroke: 2,
        strokeWidth: 1,
      },
    },
  },
  {
    id: 'StickerCell',
    title: 'Stickerfeld',
    template: {
      type: 'StickerCell',
      props: {
        x: 0,
        y: 0,
        height: dimensions.stickerHeight,
        width: dimensions.stickerWidth,
      },
    },
  },
  {
    id: 'Comment',
    title: 'Kommentar',
    adminOnly: true,
    template: {
      type: 'Comment',
      props: {
        text: '',
        x: 0,
        y: 0,
        height: 0,
        width: 0,
      },
    },
  },
];
