import React, { useEffect } from 'react';
import { string, func, object, bool } from 'prop-types';
import {
  Switch,
  Route,
  useRouteMatch,
  useHistory,
  useParams,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ImageContext } from '../../components/ImageContext';
import ErrorBoundary from '../../components/ErrorBoundary';
import Workspace from '../../components/svg/Workspace';
import Autosaver from './Autosaver';
import SvgDefs from './SvgDefs';
import useApi from '../../hooks/useApi';
import useStoreReset from './useStoreReset';
import useHelpscoutBeacon from './useHelpscoutBeacon';
import AppMetaMenu from './AppMetaMenu';
import useLoading, { commonTypes } from '../../hooks/useLoading';
import { resolutions } from '../../constants';
import EditorSidebar from '../../components/ui/Sidebar/EditorSidebar';
import useScreenSize from '../../hooks/useScreenSize';
import { selectIsAlbumEditable } from '../../selectors/albums';
import MobileWorkspace from '../../components/svg/Workspace/index.mobile';
import MobileTabNav from '../../components/ui/Sidebar/EditorSidebar/TabNav.mobile';
import MobileToolbar from '../../components/svg/Toolbar/Toolbar.mobile';
import { getSelectedElementIds } from '../../selectors/legacy';
import SettingsModal from './SettingsModal/SettingsModal';
import RedirectWithSearchParams from '../app/withSearchParams';
import useScopedSidebarTabs from '../../components/ui/Sidebar/useScopedSidebarTabs';
import editorTabs from '../../components/ui/Sidebar/EditorSidebar/editorTabs';
import Notifications from './Notifications';
import { selectIsEditingSticker } from '../../selectors/controls';
import EditorOnboarding from '../onboarding';

function DesktopEditor() {
  useHelpscoutBeacon();

  return (
    <>
      <EditorSidebar />
      <Switch>
        <Route path="/album/:albumId/onboarding">
          <EditorOnboarding />
        </Route>
        <Route>
          <Workspace />
        </Route>
      </Switch>
      <AppMetaMenu />
    </>
  );
}

function MobileEditor() {
  const isAlbumEditable = useSelector(selectIsAlbumEditable);
  const selectedElementIds = useSelector(getSelectedElementIds);
  const isEditingSticker = useSelector(selectIsEditingSticker);

  const { key: activeKey } = useParams();

  /**
   * We never render the toolbar when our current route has an
   * active key, thus requesting a sidebar tab.
   */
  const shouldRenderToolbar =
    isAlbumEditable && selectedElementIds.length > 0 && !activeKey;

  /**
   * We hide the meta menu on mobile in certain cases in order
   * to not clutter the UI too much.
   */
  const shouldRenderMetaMenu = !isEditingSticker;

  return (
    <>
      <MobileWorkspace />
      {shouldRenderToolbar ? <MobileToolbar /> : <MobileTabNav />}
      {shouldRenderMetaMenu && <AppMetaMenu />}
    </>
  );
}

function Editor({
  cropPreview: cropPreviewFromStore,
  modal,
  showStickers,
  title,
  handleKeyboardEvent,
  setCurrentAlbum,
  unsetCurrentAlbum,
  receiveAlbum,
  resetViewToSpreadIndex,
  receiveBlueprintData,
}) {
  const api = useApi();
  const { isMobile } = useScreenSize();
  const history = useHistory();

  const { startLoading, stopLoading } = useLoading(commonTypes.fetchingAlbum);
  const {
    params: { albumId, key },
    path,
    url,
  } = useRouteMatch();

  // We need to redirect when accessing any non-scoped, not-settings keys.
  const { keys: sidebarKeys } = useScopedSidebarTabs(editorTabs);
  const availableKeys = [...sidebarKeys, 'settings'];
  const shouldRedirect = key && !availableKeys.includes(key);

  /**
   * On initial mount, reset the parts of the redux
   * store that should be local to a single editor
   * instance (= a single album).
   */
  const storeReset = useStoreReset();
  useEffect(() => {
    storeReset();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = title ? `${title} - Stickerstars Designer` : 'Laden...';
  }, [title]);

  useEffect(() => {
    if (!albumId) {
      return;
    }

    setCurrentAlbum(albumId);

    async function fetchBlueprintData(id) {
      const {
        data: { blueprint },
      } = await api.get(`/blueprints/${id}`);

      receiveBlueprintData(id, blueprint);
    }

    (async () => {
      startLoading();

      const {
        data: { album },
      } = await api.get(`/albums/${albumId}`);

      const { blueprint_album_id: blueprintAlbumId } = album;

      /**
       * In addition to the user album, we fetch its blueprint
       * album from the API in order to populate the layouts and
       * styles panels with blueprint data.
       */
      if (blueprintAlbumId) {
        fetchBlueprintData(blueprintAlbumId);
      }

      await receiveAlbum(album);
      resetViewToSpreadIndex(0);

      stopLoading();
    })();
  }, [
    api,
    albumId,
    setCurrentAlbum,
    receiveAlbum,
    resetViewToSpreadIndex,
    startLoading,
    stopLoading,
    receiveBlueprintData,
  ]);

  useEffect(() => {
    return () => {
      /**
       * When unmounting an editor component, we'll
       * set the current album to !loaded.
       */
      unsetCurrentAlbum();
    };
  }, [unsetCurrentAlbum]);

  const handleSettingsModalClose = () => {
    const nextUrl = url.split('/settings')[0];
    history.push(nextUrl);
  };

  return (
    <ErrorBoundary albumId={albumId}>
      <ImageContext.Provider
        value={{
          resolution: resolutions.auto,
          cropPreview: cropPreviewFromStore,
          showGuides: cropPreviewFromStore,
          rendering: false,
          showStickers,
        }}
      >
        {/* We render the settings modal whenever /settings is added to the URL */}
        <Route path="*/settings/:settingsTab?">
          <SettingsModal onHide={handleSettingsModalClose} />
        </Route>

        {modal}
        <div
          className={`editor ${isMobile &&
            'mobile'} qa-editor d-flex flex-row vw-100 vh-100`}
          onKeyDown={handleKeyboardEvent}
          tabIndex="0"
        >
          {isMobile ? <MobileEditor /> : <DesktopEditor />}
        </div>

        {/* Redirect on non-scoped, non-settings key. */}
        {shouldRedirect && <RedirectWithSearchParams from={path} to="." />}
      </ImageContext.Provider>
      <Autosaver albumId={albumId} />
      <SvgDefs />
      <Notifications />
    </ErrorBoundary>
  );
}

Editor.defaultProps = {
  cropPreview: false,
  modal: null,
  showStickers: true,
  title: '',
};

Editor.propTypes = {
  cropPreview: bool,
  modal: object, // eslint-disable-line react/forbid-prop-types
  showStickers: bool,
  title: string,
  handleKeyboardEvent: func.isRequired,
  unsetCurrentAlbum: func.isRequired,
  setCurrentAlbum: func.isRequired,
  receiveAlbum: func.isRequired,
  resetViewToSpreadIndex: func.isRequired,
  receiveBlueprintData: func.isRequired,
};

export default Editor;
