import { bool, number, string } from 'prop-types';
import React from 'react';

import { colorValues, dimensions } from '../../../constants';

function SmartGuide({ id, value, dimension, type, isCover }) {
  let x1 = 0;
  let y1 = 0;
  let x2 = dimensions.pageWidth * (isCover ? 1 : 2);
  let y2 = dimensions.pageHeight;
  if (dimension === 'x') {
    x1 = value;
    x2 = value;
  }
  if (dimension === 'y') {
    y1 = value;
    y2 = value;
  }
  const stroke =
    type === 'spread' ? colorValues.guideSpread : colorValues.selection;
  return (
    <line
      key={id}
      x1={x1}
      y1={y1}
      x2={x2}
      y2={y2}
      style={{ stroke, strokeWidth: 1, opacity: 0.3 }}
      vectorEffect="non-scaling-stroke"
    />
  );
}

SmartGuide.propTypes = {
  id: string.isRequired,
  value: number.isRequired,
  dimension: string.isRequired,
  type: string.isRequired,
  isCover: bool.isRequired,
};

export default SmartGuide;
