import React from 'react';
import { func } from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { countryOptions } from '../../../constants';
import { AddressShape } from './shapes';
import useEditRecord from './useEditRecord';

function EditDeliveryAddressForm({ address, onClose, refetchOrder }) {
  const {
    editedRecord: editedAddress,
    createHandler,
    submitting,
    handleSubmit,
  } = useEditRecord({
    initialRecord: address,
    url: `/addresses/${address.id}`,
    pickKeys: [
      'organization',
      'first_name',
      'last_name',
      'address1',
      'address2',
      'zip_code',
      'city',
      'country_code',
    ],
    onSuccess: () => {
      refetchOrder();
      onClose();
    },
  });

  /* eslint-disable camelcase */
  const {
    organization,
    first_name,
    last_name,
    address1,
    address2,
    zip_code,
    city,
    country_code,
  } = editedAddress;

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="Organisation"
            className="mb-2"
            value={organization}
            onChange={createHandler('organization')}
            name="address[organization]"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="Vorname"
            className="mb-2"
            value={first_name}
            onChange={createHandler('first_name')}
            name="address[first_name]"
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            placeholder="Nachname"
            className="mb-2"
            value={last_name}
            onChange={createHandler('last_name')}
            name="address[last_name]"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="Adresse 1"
            className="mb-2"
            value={address1}
            onChange={createHandler('address1')}
            name="address[address1]"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="Adresse 2"
            className="mb-2"
            value={address2}
            onChange={createHandler('address2')}
            name="address[address2]"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="PLZ"
            className="mb-2"
            value={zip_code}
            onChange={createHandler('zip_code')}
            name="address[zip_code]"
          />
        </Col>
        <Col>
          <Form.Control
            type="text"
            placeholder="Stadt"
            className="mb-2"
            value={city}
            onChange={createHandler('city')}
            name="address[city]"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            as="select"
            value={country_code}
            className="qa-countryCode-input"
            onChange={createHandler('country_code')}
            name="address[country_code]"
          >
            {countryOptions.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Col>
      </Row>
      <Button
        variant="primary"
        type="submit"
        className="mt-3 qa-submit-address-btn"
        disabled={submitting}
      >
        Speichern
      </Button>
      <Button variant="link" className="mt-3" onClick={onClose}>
        Abbrechen
      </Button>
    </Form>
  );
  /* eslint-enable camelcase */
}

EditDeliveryAddressForm.defaultProps = {};

EditDeliveryAddressForm.propTypes = {
  address: AddressShape.isRequired,
  onClose: func.isRequired,
  refetchOrder: func.isRequired,
};

export default EditDeliveryAddressForm;
