import React from 'react';
import { useSelector } from 'react-redux';
import { number, node, elementType, func, oneOfType } from 'prop-types';

import { getZoom } from '../../selectors/viewport';

function UnzoomedContent({
  x,
  y,
  width,
  height,
  hideBelowZoom,
  hideBelowSize,
  children,
  as,
  scale,
}) {
  const zoom = useSelector(getZoom) * scale;

  const size = Math.min(width, height) * zoom;
  const visible =
    (!hideBelowZoom || zoom > hideBelowZoom) &&
    (!hideBelowSize || size > hideBelowSize);

  if (!visible) {
    return null;
  }

  const invertedZoom = 1 / zoom;

  const rX = typeof x === 'function' ? x(zoom) : x;
  const rY = typeof y === 'function' ? y(zoom) : y;
  const rWidth = typeof width === 'function' ? width(zoom) : width * zoom;

  const props = {
    style: { overflow: 'visible' },
    transform: `translate(${rX},${rY}), scale(${invertedZoom})`,
    width: rWidth,
    height,
  };
  return React.createElement(as, props, children);
}

UnzoomedContent.defaultProps = {
  x: 0,
  y: 0,
  width: 0,
  height: 1, // Firefox seems to need a height of at least 1px in order to display the element
  hideBelowZoom: null,
  hideBelowSize: null,
  as: 'foreignObject',
  scale: 1,
};

UnzoomedContent.propTypes = {
  x: oneOfType([number, func]),
  y: oneOfType([number, func]),
  width: oneOfType([number, func]),
  height: number,
  hideBelowZoom: number,
  hideBelowSize: number,
  children: node.isRequired,
  as: elementType,
  scale: number,
};

export default UnzoomedContent;
