import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import useApi from './useApi';
import useLoading from './useLoading';

/**
 * A hook to fetch data from an API endpoint.
 * Dependencies will be passed to useEffect.
 *
 * @param {String} query
 * @param {Array} deps
 * @returns
 */
function useQuery(query, deps) {
  const api = useApi();
  const { loadWhile, isLoading } = useLoading(query);
  const [data, setData] = useState();
  const [error, setError] = useState();

  const request = useCallback(
    async cancelToken => {
      try {
        const res = await api.get(query, undefined, { cancelToken });
        setData(res.data);
        setError(null);
      } catch (err) {
        setError(err);
      }
    },
    [api, query]
  );

  useEffect(() => {
    const source = axios.CancelToken.source();

    loadWhile(async () => {
      await request(source.token);
    });

    return () => source.cancel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    isLoading,
    data,
    error,
    pagination: data?.meta,
    refetch: () => request(),
  };
}

export default useQuery;
