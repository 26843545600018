import React, { memo } from 'react';
import { connect } from 'react-redux';

import BaseText from './BaseText';
import {
  getStickersCount,
  getTocSections,
  getTocPages,
  getSections,
} from '../../../../selectors/legacy';
import { makeGetTransformationMatrix } from '../../../../selectors/nodes';
import {
  selectAlbumReleaseDate,
  selectAlbumTitle,
} from '../../../../selectors/albums';
import {
  applyAutoSize,
  getEditorStateFromSymbolProps,
} from '../../../../util/draftjs';
import { xPositionFromMatrixString } from '../../../../util/geometry';

const SymbolText = props => {
  const { editorState, actualText } = getEditorStateFromSymbolProps(props);
  const nextCustomStyleMap = applyAutoSize(props, actualText);
  return (
    <BaseText
      {...props}
      editorState={editorState}
      customStyleMap={nextCustomStyleMap}
      readOnly
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const getTransformationMatrix = makeGetTransformationMatrix();
  return {
    sections: getSections(state),
    album: selectAlbumTitle(state),
    totalstickers: getStickersCount(state),
    releaseDate: selectAlbumReleaseDate(state),
    toc_sections: getTocSections(state),
    toc_pages: getTocPages(state),
    xPos: xPositionFromMatrixString(
      getTransformationMatrix(state, ownProps.id)
    ),
  };
};

export default connect(mapStateToProps)(memo(SymbolText));
