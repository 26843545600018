import clamp from 'lodash/clamp';

import { dimensions } from '../constants';
import getTransformToElement from './getTransformToElement';

export function vectorToAngle({ x, y }) {
  return (Math.atan2(y, x) * 180) / Math.PI;
}

export function getElementTransform(element, width, height) {
  const svg = element.closest('svg');

  function localToGlobal(x, y) {
    let p = svg.createSVGPoint();
    p.x = x;
    p.y = y;
    p = p.matrixTransform(element.getCTM());
    p = p.matrixTransform(svg.getCTM().inverse());
    return p;
  }

  const topLeft = localToGlobal(0, 0);
  const topRight = localToGlobal(1, 0);

  const xUnitVec = {
    x: topRight.x - topLeft.x,
    y: topRight.y - topLeft.y,
  };

  const rotation = (Math.atan2(xUnitVec.y, xUnitVec.x) * 180) / Math.PI;
  const scale = Math.sqrt(xUnitVec.x * xUnitVec.x + xUnitVec.y * xUnitVec.y);
  const { x, y } = topLeft;

  return { x, y, rotation, scale, width, height };
}

export function rectsIntersectOnXAxis(rect1, rect2) {
  return !(rect2.x > rect1.x + rect1.width || rect2.x + rect2.width < rect1.x);
}

export function rectsIntersectOnYAxis(rect1, rect2) {
  return !(
    rect2.y > rect1.y + rect1.height || rect2.y + rect2.height < rect1.y
  );
}

export function rectsIntersect(rect1, rect2) {
  return (
    rectsIntersectOnXAxis(rect1, rect2) && rectsIntersectOnYAxis(rect1, rect2)
  );
}

export function satisfyGrid({ x, y }) {
  const { gridSize } = dimensions;
  return {
    x: Math.round(x / gridSize) * gridSize,
    y: Math.round(y / gridSize) * gridSize,
  };
}

export function axisAlignedBounds(points) {
  if (points.length === 0) {
    return null;
  }
  const xs = points.map(p => p.x);
  const ys = points.map(p => p.y);
  const xMin = Math.min(...xs);
  const yMin = Math.min(...ys);
  const xMax = Math.max(...xs);
  const yMax = Math.max(...ys);
  return { x: xMin, y: yMin, width: xMax - xMin, height: yMax - yMin };
}

export function fitRectIntoArea(rect, area, coverWholeArea = false) {
  const scaleStrategy = coverWholeArea ? Math.max : Math.min;
  const scale = scaleStrategy(
    area.width / rect.width,
    area.height / rect.height
  );
  const x = rect.x - (area.width / scale - rect.width) / 2;
  const y = rect.y - (area.height / scale - rect.height) / 2;
  return { x, y, scale };
}

export const boundsToRect = bounds => ({
  x: bounds.left,
  y: bounds.top,
  width: bounds.right - bounds.left,
  height: bounds.bottom - bounds.top,
});

export const rectToBounds = rect => ({
  left: rect.x,
  top: rect.y,
  right: rect.x + rect.width,
  bottom: rect.y + rect.height,
});

export const fitBoundsIntoArea = (rect, area, coverWholeArea = false) =>
  fitRectIntoArea(boundsToRect(rect), area, coverWholeArea);

export function transformRectCorners(matrix, width, height, padding = 0) {
  if (!matrix) {
    return [];
  }
  const corners = [
    { x: padding, y: padding },
    { x: padding, y: height + padding },
    { x: width + padding, y: padding },
    { x: width + padding, y: height + padding },
  ];
  return corners.map(p => {
    const { x, y } = matrix.transformPoint(p);
    return { x, y };
  });
}

export function applyPaddingToRectOrBounds(rect, padding) {
  return {
    x: rect.x - padding,
    y: rect.y - padding,
    width: rect.width + padding * 2,
    height: rect.height + padding * 2,
    left: rect.left - padding,
    top: rect.top - padding,
    bottom: rect.bottom + padding,
    right: rect.right + padding,
  };
}

export function decomposeMatrix({ a, b, e, f }) {
  // Adapted from https://github.com/deoxxa/transformation-matrix-js/blob/5d0391a169e938c31da6c09f5d4e7dc836fd0ec2/src/matrix.js
  const x = e;
  const y = f;
  const scale = Math.sqrt(a * a + b * b);
  const radians = b > 0 ? Math.acos(a / scale) : -Math.acos(a / scale);
  const rotation = (radians * 180) / Math.PI;
  return { x, y, scale, rotation };
}

export function domMatrix(transform) {
  const { a, b, c, d, e, f } = transform;
  return new DOMMatrixReadOnly([a, b, c, d, e, f]);
}

export const selectorMatrix = (source, target) => {
  const sourceNode = document.querySelector(source);
  const transform = target
    ? getTransformToElement(sourceNode, document.querySelector(target))
    : sourceNode?.getScreenCTM();
  return transform ? domMatrix(transform) : null;
};

export const elementToElementMatrix = (sourceId, targetId) =>
  selectorMatrix(
    `.workspace [data-id='${sourceId}']`,
    `.workspace [data-id='${targetId}']`
  );

export const nodeToNodeMatrix = (sourceNode, targetNode) =>
  domMatrix(getTransformToElement(sourceNode, targetNode));

export function restrictPanAndZoomToContentArea(
  pan,
  zoom,
  limits,
  clientWidth
) {
  const { minZoom, maxZoom, minPan, maxPan } = limits;

  const allowedZoom = clamp(zoom, minZoom, maxZoom);

  // Pan: For each dimension the bounds are calculated indepently. The content-area
  // can move further to the edges, but not completely out of the viewport.
  const allowedPan = {
    x: clamp(pan.x, minPan.x, maxPan.x),
    y: clamp(pan.y, minPan.y, maxPan.y),
  };

  // Lean towards x-center when zooming out
  const contentWidth = dimensions.pageWidth * 2;
  const viewportWidth = clientWidth / allowedZoom;
  if (viewportWidth > contentWidth) {
    allowedPan.x = contentWidth / 2 - viewportWidth / 2;
  }

  return [allowedPan, allowedZoom];
}

export const viewportToStickerImageMatrix = (viewport, stickerId) => {
  const element = viewport.querySelector(`[data-id='${stickerId}'] image`);
  return domMatrix(getTransformToElement(element, viewport));
};

export const clientToStickerImageCoordinates = (viewport, stickerId, point) => {
  const viewportMatrix = domMatrix(viewport.getScreenCTM().inverse());
  const pointInSvg = viewportMatrix.transformPoint(point);
  const element = viewport.querySelector(`[data-id='${stickerId}'] image`);
  const matrix = domMatrix(getTransformToElement(viewport, element));
  return matrix.transformPoint(pointInSvg);
};

const elementMatrix = (id, target) =>
  selectorMatrix(`.workspace [data-id='${id}']`, target);

const elementCorners = (element, target, padding = 0) => {
  const { id, width, height } = element.props;
  const matrix = elementMatrix(id, target);
  return transformRectCorners(matrix, width, height, padding);
};

const orientedBounds = (matrix, size) => {
  if (!matrix || !size) {
    return null;
  }
  const { width, height } = size;
  const { x, y, scale, rotation } = decomposeMatrix(matrix);
  return {
    rotation,
    x,
    y,
    width: width * scale,
    height: height * scale,
  };
};

export const getOrientedBounds = (element, target) =>
  orientedBounds(elementMatrix(element.props.id, target), element.props);

const imageMatrix = (id, target) =>
  selectorMatrix(`.workspace [data-id='${id}'] .inside`, target);

export const getOrientedImageBounds = (element, imageSize, target) =>
  orientedBounds(imageMatrix(element.props.id, target), imageSize);

export const getAxisAlignedBounds = (elements, target, padding = 0) =>
  axisAlignedBounds(
    elements.flatMap(element => elementCorners(element, target, padding))
  );

export const getItemTransformInGroup = (childId, parentId) =>
  decomposeMatrix(elementToElementMatrix(childId, parentId));

export const spreadTransformToGroupTransform = (props, sourceId, targetId) => {
  const matrix = elementToElementMatrix(sourceId, targetId);
  const transform = decomposeMatrix(matrix);
  const { x, y } = matrix.transformPoint(props);
  return {
    ...props,
    rotation: props.rotation + transform.rotation,
    x,
    y,
    scale: props.scale * transform.scale,
  };
};

export function calculateViewLimits(
  clientWidth,
  clientHeight,
  spreadsCount,
  zoom
) {
  // Calculate content-size
  const { pageWidth, pageHeight, pagePadding } = dimensions;
  const contentWidth = pageWidth * 2;
  const contentHeight = (pageHeight + pagePadding) * spreadsCount - pagePadding;

  // Zoom: minZoom fits all pages twice within the viewport. maxZoom is abitrarily
  // limited.
  const minZoom =
    Math.min(clientWidth / contentWidth, clientHeight / contentHeight) / 2;
  const maxZoom = 100;

  // Pan: For each dimension the bounds are calculated indepently. The content-area
  // can move further to the edges, but not completely out of the viewport.
  const edge = 30 / zoom;
  const viewportWidth = clientWidth / zoom;
  const viewportHeight = clientHeight / zoom;
  const minPan = { x: -viewportWidth + edge, y: -viewportHeight + edge };
  const maxPan = { x: contentWidth - edge, y: contentHeight - edge };
  return { minZoom, maxZoom, minPan, maxPan };
}

export const xPositionFromMatrixString = matrix => {
  /**
   * This is a bit hacky, but it replaces all this code, since the
   * 13th element of the matrix is the x-coord we're looking for
   *
   * DOMMatrix.fromFloat32Array(Float32Array.from(matrix.split(',')))
   * const point = new DOMPoint();
   * return point.matrixTransform(matrix);
   */
  const xPos = parseFloat(matrix.split(',')[12]);
  return xPos;
};
