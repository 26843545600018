import at from 'lodash/at';

import useAppEnvironment from '../useAppEnvironment';
import de from './de';

/**
 * Localization hook that looks up strings based on
 *
 * 1. app environment (`wedding` | `business` | ...)
 * (2. browser language)
 */
function useLocale() {
  const { environment } = useAppEnvironment();

  function t(id) {
    const [env, fallback] = at(de, `${environment}.${id}`, `default.${id}`);

    return env || fallback;
  }

  return {
    t,
  };
}

export default useLocale;
