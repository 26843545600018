import { useAuth0 } from '@auth0/auth0-react';
import mapKeys from 'lodash/mapKeys';

const namespace = process.env.REACT_APP_AUTH0_AUDIENCE;

function useAuth() {
  const auth = useAuth0();
  const user = mapKeys(auth.user, (_, key) => key.replace(`${namespace}/`, ''));

  const isAdmin = user?.roles?.includes('admin');

  return {
    ...auth,
    user,
    isAdmin,
  };
}

export default useAuth;
