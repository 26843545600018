import partition from 'lodash/partition';

const guideSnappiness = 5;
const distanceErrorTolerance = 0.03;

const filterByDistance = (guides, maxDistance) =>
  guides.filter(({ distance }) => distance < maxDistance);

function snapToCoordinate(coordinate, guides, zoom) {
  // Step 1: determine the closest guide value within snapping range
  const distances = guides.map(({ value }) => ({
    value,
    distance: Math.abs(coordinate - value),
  }));
  const matchtingDistances = filterByDistance(
    distances,
    guideSnappiness / zoom
  );
  const sortedDistances = matchtingDistances.sort(
    (a, b) => a.distance - b.distance
  );
  const [closest] = sortedDistances;
  if (!closest) {
    // No snapping guide found, return original coordinate
    return [coordinate, []];
  }
  const { value: guideCoordinate } = closest;

  /**
   * Step 2: determine all guides that are also on the new coordinate,
   * using some tolerance to counteract conversion errors
   */
  const distancesToActiveGuide = guides.map(({ id, value }) => ({
    id,
    distance: Math.abs(guideCoordinate - value),
  }));
  const equalToFirstDistance = filterByDistance(
    distancesToActiveGuide,
    distanceErrorTolerance
  );
  const ids = equalToFirstDistance.map(({ id }) => id);

  return [guideCoordinate, ids];
}

export default function snapToPoint(point, guides, zoom) {
  const [guidesX, guidesY] = partition(
    guides,
    guide => guide.dimension === 'x'
  );
  const [x, activeGuideIdsX] = snapToCoordinate(point.x, guidesX, zoom);
  const [y, activeGuideIdsY] = snapToCoordinate(point.y, guidesY, zoom);
  const activeGuideIds = [...activeGuideIdsX, ...activeGuideIdsY];
  return [{ x, y }, activeGuideIds];
}
