import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import { useFormState } from '../FormStateProvider';

function Result() {
  const { error, reset } = useFormState();

  return (
    <>
      {error ? (
        <Alert variant="danger">
          Irgendwas ist schiefgelaufen: {error.toString()}
        </Alert>
      ) : (
        <div>
          <h3 className="font-weight-bold">Hat geklappt</h3>
          <p>
            Dein Upload war erfolgreich, vielen Dank dafür! Jetzt ist Zeit für
            Vorfreude.
          </p>
        </div>
      )}
      <div className="py-3">
        <Button
          className="qa-reset"
          onClick={reset}
          variant="outline-primary"
          block
        >
          von vorne
        </Button>
      </div>
    </>
  );
}

export default Result;
