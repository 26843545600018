import { func, string } from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';

import { OrderShape } from '../../../components/shapes';
import useApi from '../../../hooks/useApi';
import useLoading from '../../../hooks/useLoading';
import useNotifications from '../../../hooks/useNotifications';

function CreateInvoiceButton({ order, refetch, className }) {
  const api = useApi();
  const { createError } = useNotifications();
  const { loadWhile, isLoading } = useLoading('createInvoice');

  if (order.user_invoice) {
    return null;
  }

  async function handleClick() {
    loadWhile(async () => {
      try {
        await api.post(`/orders/${order.id}/invoice`);
        await refetch();
      } catch (err) {
        createError(err.toString());
      }
    });
  }

  return (
    <Button
      onClick={handleClick}
      className={`qa-create-invoice-btn ${className}`}
      disabled={isLoading}
      size="sm"
      variant="outline-primary"
    >
      {!isLoading ? 'Rechnung erstellen' : 'Lade...'}
    </Button>
  );
}

CreateInvoiceButton.defaultProps = {
  className: '',
};

CreateInvoiceButton.propTypes = {
  order: OrderShape.isRequired,
  refetch: func.isRequired,
  className: string,
};

export default CreateInvoiceButton;
