import React, { useContext, useLayoutEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Hint from '../../../generic/Hint';
import { selectDangerZoneElementsOnTargetSpread } from '../../../../selectors/workspace';
import { dimensions } from '../../../../constants';
import { ImageContext } from '../../../ImageContext';
import {
  getAxisAlignedBounds,
  rectsIntersect,
} from '../../../../util/geometry';
import { getPan } from '../../../../selectors/viewport';

function DangerZone() {
  const { pageHeight, pageWidth, pageBleed, dangerZoneWidth } = dimensions;

  // We select the current pan to force a `Hint` re-render on scroll
  const pan = useSelector(getPan);
  const dangerZoneElementsOnTargetSpread = useSelector(
    selectDangerZoneElementsOnTargetSpread
  );
  const { cropPreview } = useContext(ImageContext);
  const [hasIntersectingElements, setHasIntersectingElements] = useState(false);

  // `foreignObject` parent positioning might include bleed
  const optionalBleed = cropPreview ? 0 : pageBleed;
  const parentX = 0 - optionalBleed;
  const parentY = 0 - optionalBleed;
  const parentWidth = pageWidth * 2 + optionalBleed * 2;

  const text =
    'Um die Heftmitte sollten keine wichtigen Elemente platziert werden.';

  /**
   * Marks an area between two pages that should not be used for important
   * content. It spans a `dangerZoneWidth` constant over the entire
   * `height` of the spread (potentially including bleed).
   */
  const area = useMemo(
    () => ({
      x: pageWidth + optionalBleed - dangerZoneWidth / 2,
      y: 0,
      height: pageHeight + optionalBleed * 2,
      width: dangerZoneWidth,
    }),
    [pageWidth, optionalBleed, pageHeight, dangerZoneWidth]
  );

  /**
   * We loop through all elements on the target spread and return the first element
   * that intersects with the danger zone. If none do, we return an empty render.
   */
  useLayoutEffect(() => {
    const intersectingElements = dangerZoneElementsOnTargetSpread.find(node => {
      /**
       * The top left corner of the spread is 0/0 for the absolutely-positioned danger
       * zone, but might be -2/-2 (`optionalBleed`) for the element. In order to bring
       * both to the same coordinate system, we apply an `optionalBleed` padding
       * to the element.
       */
      const childBounds = getAxisAlignedBounds(
        [node],
        `[data-id='${node.parent}']`,
        optionalBleed
      );

      /**
       * Quick fix: In some cases (e. g. pasting multiple elements), the DOM nodes
       * are not yet available. In this case, we return false to prevent the `rectsIntersect`
       * function from throwing an error. This is a temporary fix until we find a better
       * solution; currently, when pasting into the danger zone, the warning is not shown
       * until the next render.
       */
      if (!childBounds) {
        return false;
      }

      return rectsIntersect(childBounds, area);
    });

    setHasIntersectingElements(!!intersectingElements);
  }, [dangerZoneElementsOnTargetSpread, area, optionalBleed]);

  if (!hasIntersectingElements) {
    return null;
  }

  return (
    <foreignObject
      x={parentX}
      y={parentY}
      height={area.height}
      width={parentWidth}
      style={{ pointerEvents: 'none' }}
    >
      <Hint
        text={text}
        placement="top"
        toggle={{ onDelay: 0, offDelay: 5000 }}
        key={pan.y}
      >
        {({ target }) => (
          <div
            className="danger-zone qa-danger-zone"
            ref={target}
            style={{
              top: area.y,
              left: area.x,
              width: area.width,
              height: area.height,
            }}
          />
        )}
      </Hint>
    </foreignObject>
  );
}

DangerZone.defaultProps = {};

DangerZone.propTypes = {};

export default DangerZone;
