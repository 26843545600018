import assert from 'assert';

import update from 'immutability-helper';

import {
  LOAD_WORKSPACE,
  REPLACE_SPREADS,
  INSERT_ELEMENTS,
  UPDATE_ELEMENTS,
  REPLACE_ELEMENTS,
  DELETE_ELEMENTS,
  GROUP_ELEMENTS,
  UNGROUP_ELEMENTS,
  SWAP_ELEMENTS,
  REORDER_SECTIONS,
  SEND_ELEMENTS_TO_FRONT,
  SEND_ELEMENTS_TO_BACK,
  MOVE_ELEMENTS_TO_SPREAD,
} from '../../actions/workspace';
import { normalizeElement, buildWorkspace } from '../../util/workspace';

function insertIntoArray(arr, index, items) {
  if (index === -1) {
    return [...arr, ...items];
  }
  return [...arr.slice(0, index), ...items, ...arr.slice(index)];
}

function descendants(id, nodes) {
  const children = nodes[id].children || [];
  return [id, ...children.flatMap(childId => descendants(childId, nodes))];
}

/**
 * A function that takes an array of node IDs and removes them from a workspace.
 * This is used outside of the reducer, e.g. to remove nodes from sticker design workspaces.
 */
export function removeNodes(ids, workspace) {
  // All nodes of a single selection have the same parent.
  const { parent } = workspace.nodes[ids[0]];
  const oldNodes = ids.flatMap(id => descendants(id, workspace.nodes));

  return update(workspace, {
    nodes: {
      $unset: oldNodes,
      [parent]: {
        children: list => list.filter(id => !ids.includes(id)),
      },
    },
  });
}

// Swap items in a list
function swapItems(item1, item2, list) {
  const listClone = list.slice(0); // clone the original list to not mutate it

  const firstIndex = listClone.indexOf(item1);
  const secondIndex = listClone.indexOf(item2);

  [listClone[firstIndex], listClone[secondIndex]] = [
    listClone[secondIndex],
    listClone[firstIndex],
  ];

  return listClone;
}

export default (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOAD_WORKSPACE: {
      const { initialState: newState } = payload;
      return newState;
    }

    case REPLACE_SPREADS: {
      const { spreads } = payload;
      return buildWorkspace(spreads);
    }

    case INSERT_ELEMENTS: {
      const { elements, parentId, index } = payload;
      assert(!!parentId, 'The parentId cannot be null.');

      const parentNode = state.nodes[parentId];

      const newNodes = elements.reduce((acc, element) => {
        const { root, nodes } = normalizeElement(element);
        return {
          ...acc,
          ...nodes,
          [root]: { ...nodes[root], parent: parentId },
        };
      }, {});

      const newChildren = insertIntoArray(
        parentNode.children,
        index,
        elements.map(element => element.props.id)
      );

      return update(state, {
        nodes: {
          $merge: newNodes,
          [parentId]: { children: { $set: newChildren } },
        },
      });
    }

    case UPDATE_ELEMENTS: {
      const { propsDeltaMap } = payload;

      return update(state, {
        nodes: {
          ...Object.keys(propsDeltaMap).reduce((acc, id) => {
            acc[id] = {
              props: { $merge: propsDeltaMap[id] },
            };
            return acc;
          }, {}),
        },
      });
    }

    // Replace the specified elements (by `ids`) with a single new element
    case REPLACE_ELEMENTS: {
      const { ids, element } = payload;

      assert(Array.isArray(ids), 'ids must be an array of ids.');

      // All the children have the same parent
      const firstChildId = ids[0];
      const parentId = state.nodes[firstChildId].parent;
      const parentNode = state.nodes[parentId];

      const { root, nodes } = normalizeElement(element);
      const newNodes = {
        ...nodes,
        [root]: { ...nodes[root], parent: parentId },
      };

      // First insert the new element id `root` at the same position of the first element that needs to be replaced,
      // then remove these elements (`ids`) from the list
      const newChildren = insertIntoArray(
        parentNode.children.filter(id => !ids.includes(id)),
        parentNode.children.indexOf(firstChildId),
        [root]
      );

      const oldNodes = ids.flatMap(id => descendants(id, state.nodes));

      return update(state, {
        nodes: {
          $unset: oldNodes,
          $merge: newNodes,
          [parentId]: { children: { $set: newChildren } },
        },
      });
    }

    case DELETE_ELEMENTS: {
      const { ids } = payload;

      assert(Array.isArray(ids), 'ids must be an array of ids.');

      // If nothing is selected, don't change the state
      if (ids.length === 0) {
        return state;
      }

      return removeNodes(ids, state);
    }

    case GROUP_ELEMENTS: {
      const { ids, groupProps, propsDeltaMap } = payload;
      const { id: groupId } = groupProps;

      assert(Array.isArray(ids), 'ids must be an array of ids.');

      const { parent: originalParentId } = state.nodes[ids[0]];

      const propsMergeMap = Object.keys(propsDeltaMap).reduce((acc, id) => {
        acc[id] = {
          props: { $merge: propsDeltaMap[id] },
          parent: { $set: groupId },
        };
        return acc;
      }, {});

      // Maintain the original z-order within the new group
      const { children: originalOrder } = state.nodes[originalParentId];
      const children = originalOrder.filter(id => ids.includes(id));

      return update(state, {
        nodes: {
          ...propsMergeMap,
          [groupId]: {
            $set: {
              type: 'Group',
              props: groupProps,
              children,
              parent: originalParentId,
            },
          },
          [originalParentId]: {
            children: list =>
              list.filter(id => !ids.includes(id)).concat([groupId]),
          },
        },
      });
    }

    // Ungroup the specified groups (by ids)
    case UNGROUP_ELEMENTS: {
      const { ids, propsDeltaMap } = payload;
      const groupChildren = ids.flatMap(id => state.nodes[id].children);

      // All the children have the same parent
      const { parent: originalParentId } = state.nodes[ids[0]];
      const propsMergeMap = Object.keys(propsDeltaMap).reduce((acc, id) => {
        acc[id] = {
          props: { $merge: propsDeltaMap[id] },
          parent: { $set: originalParentId },
        };
        return acc;
      }, {});

      return update(state, {
        nodes: {
          $unset: ids,
          ...propsMergeMap,
          [originalParentId]: {
            children: list =>
              list.filter(id => !ids.includes(id)).concat(groupChildren),
          },
        },
      });
    }

    case SWAP_ELEMENTS: {
      const { parentId, firstChildId, secondChildId } = payload;

      return update(state, {
        nodes: {
          [parentId]: {
            children: children =>
              swapItems(firstChildId, secondChildId, children),
          },
        },
      });
    }

    case REORDER_SECTIONS: {
      const { sectionIds } = payload;
      const { root } = state;

      return update(state, {
        nodes: {
          [root]: {
            children: { $set: sectionIds },
          },
        },
      });
    }

    case SEND_ELEMENTS_TO_FRONT:
    case SEND_ELEMENTS_TO_BACK: {
      const { ids } = payload;

      assert(Array.isArray(ids), 'ids must be an array of ids.');

      const index = action.type === SEND_ELEMENTS_TO_BACK ? 0 : -1;

      const { parent: parentId } = state.nodes[ids[0]];
      const parentNode = state.nodes[parentId];

      const newChildren = insertIntoArray(
        parentNode.children.filter(id => !ids.includes(id)),
        index,
        ids
      );

      return update(state, {
        nodes: {
          [parentId]: { children: { $set: newChildren } },
        },
      });
    }

    case MOVE_ELEMENTS_TO_SPREAD: {
      const { ids, newSpreadId, propsDeltaMap } = payload;

      assert(Array.isArray(ids), 'ids must be an array of ids.');

      // All the nodes have the same parent
      const { parent: oldSpreadId } = state.nodes[ids[0]];

      const propsMergeMap = ids.reduce((acc, id) => {
        acc[id] = {
          parent: { $set: newSpreadId },
        };
        if (propsDeltaMap[id]) {
          acc[id].props = { $merge: propsDeltaMap[id] };
        }
        return acc;
      }, {});

      return update(state, {
        nodes: {
          ...propsMergeMap,
          [oldSpreadId]: {
            children: list => list.filter(id => !ids.includes(id)),
          },
          [newSpreadId]: { children: { $push: ids } },
        },
      });
    }

    default:
      return state;
  }
};
