import { createSelector } from 'reselect';
import toPairs from 'lodash/toPairs';
import map from 'lodash/fp/map';
import merge from 'lodash/merge';
import head from 'lodash/head';
import flow from 'lodash/flow';

import {
  getAllNodes,
  getSections,
  getStickers,
  createMemoizedSelectorWithDeepComparison,
} from './legacy';
import { orderNodesBySortParams } from '../util/workspace';
import { selectSortRelevantPositions } from './workspace';

/**
 * Returns a map of sections by their ids
 */
export const getSectionsById = createSelector([getSections], sections => {
  return sections.reduce((acc, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});
});

export const makeGetStickersForSection = () =>
  createSelector(
    getStickers,
    (_, sectionId) => sectionId,
    (stickers, sectionId) =>
      stickers.filter(sticker => sticker.sectionId === sectionId)
  );

export const getAllStickerCellNodes = createMemoizedSelectorWithDeepComparison(
  [getAllNodes],
  nodes => {
    return nodes.filter(item => item.type === 'StickerCell');
  }
);

export const getPlacedStickerIds = createSelector(
  [getAllStickerCellNodes],
  nodes => nodes.map(({ props: { stickerId } }) => stickerId).filter(Boolean)
);

export const getStickerCellIdsByStickerId = createMemoizedSelectorWithDeepComparison(
  [getAllStickerCellNodes],
  nodes => {
    return nodes.reduce((acc, node) => {
      if (node.props.stickerId) {
        acc[node.props.stickerId] = node.props.id;
      }
      return acc;
    }, {});
  }
);

export const getStickersById = createSelector([getStickers], stickers => {
  return stickers.reduce((acc, sticker) => {
    acc[sticker.id] = sticker;
    return acc;
  }, {});
});

export const getStickersBySectionId = createSelector(
  [getSections, getStickers],
  (sections, stickers) => {
    return sections.reduce((acc, section) => {
      acc[section.id] = stickers.filter(
        sticker => sticker.sectionId === section.id
      );
      return acc;
    }, {});
  }
);

/**
 * This selector returns _all_ (placed and unplaced) sticker cells in global order.
 */
export const selectOrderedStickerCells = createMemoizedSelectorWithDeepComparison(
  [getAllStickerCellNodes, selectSortRelevantPositions],
  (stickerCellNodes, sortRelevantPositions) => {
    return orderNodesBySortParams(stickerCellNodes, sortRelevantPositions);
  }
);

/**
 * Returns a map of stickerIds to stickerNumbers for all placed stickers.
 */
export const getPlacedStickerNumbers = createMemoizedSelectorWithDeepComparison(
  [selectOrderedStickerCells, getStickersById],
  (orderedStickerCells, stickersById) => {
    return orderedStickerCells.reduce(
      (acc, stickerCell) => {
        const { number, map: resultMap } = acc;
        const { stickerId } = stickerCell.props;

        // Do not add sticker numbers for empty sticker cells
        if (!stickerId) {
          return acc;
        }

        return {
          map: { ...resultMap, [stickerId]: number },
          number: number + (stickersById[stickerId].doubleSticker ? 2 : 1),
        };
      },
      { number: 1, map: {} }
    ).map;
  }
);

/**
 * This selector returns all _placed_ stickers in their correct order
 * along with their respective `section` node.
 */
export const getPlacedStickersSortedWithSections = createSelector(
  [getStickersById, getPlacedStickerNumbers, getSectionsById],
  (stickersById, placedStickerNumbers, sectionsById) => {
    // `placedStickerNumbers` tuples look like this: [stickerId, stickerNumber]
    const sortPairs = pairs => pairs.sort(([, a], [, b]) => a - b);

    const mergeSection = flow(
      stickerId => stickersById[stickerId],
      sticker => merge(sticker, { section: sectionsById[sticker.sectionId] })
    );

    return flow(
      toPairs,
      sortPairs,
      map(head), // pick ID
      map(mergeSection)
    )(placedStickerNumbers);
  }
);

export const getSectionsAvailiable = createSelector([getSections], sections => {
  return sections.filter(section => !section.static).length > 0;
});

export default getSectionsById;
