import {
  CREATE,
  UPDATE,
  UPDATES_BY_OBJECT,
  DELETE,
  REPLACE,
  RESET,
} from '../modules/stickers';
import { getSelectedStickerIds, getStickers } from '../selectors/legacy';
import {
  getStickerCellIdsByStickerId,
  getStickersBySectionId,
} from '../selectors/stickers';
import { getStickerPositionFromFace } from '../util/faceapi';
import { computeNativeImageSize } from '../util/images';
import { updateElements } from './workspace';

export { createSection, updateSection, moveSection } from './workspace';

export const resetStickers = () => ({ type: RESET });

export const createSticker = payload => ({
  type: CREATE,
  payload: { createdAt: new Date().getTime(), ...payload },
});

export const updateSticker = (id, payload) => ({ type: UPDATE, id, payload });

export const updateSelectedStickers = payload => (dispatch, getState) => {
  const selectedStickerIds = getSelectedStickerIds(getState());
  selectedStickerIds.forEach(id => {
    dispatch(updateSticker(id, payload));
  });
};

export const updateStickersByObject = deltas => ({
  type: UPDATES_BY_OBJECT,
  deltas,
});

export const deleteSticker = id => ({ type: DELETE, id });

export const replaceStickers = (
  payload,
  undoableIrreversibleCheckpoint = true
) => ({
  type: REPLACE,
  payload,
  undoableIrreversibleCheckpoint,
});

export const moveSticker = (id, newSectionId = null) => ({
  type: UPDATES_BY_OBJECT,
  deltas: { [id]: { sectionId: newSectionId } },
});

export const unlinkStickerCell = id => dispatch => {
  dispatch(updateElements({ [id]: { stickerId: null } }));
};

export const unlinkStickerCellForStickerId = stickerId => (
  dispatch,
  getState
) => {
  const stickerCellIdsByStickerId = getStickerCellIdsByStickerId(getState());
  const stickerCellId = stickerCellIdsByStickerId[stickerId];
  if (stickerCellId) {
    dispatch(unlinkStickerCell(stickerCellId));
  }
};

export const deleteStickersForSection = sectionId => (dispatch, getState) => {
  const stickers = getStickersBySectionId(getState());
  stickers[sectionId].forEach(sticker => {
    dispatch(unlinkStickerCellForStickerId(sticker.id));
    dispatch(deleteSticker(sticker.id));
  });
};

/**
 * Replaces occurences of `imageId` by `nextImageId` in all stickers.
 */
export const updateStickersByImageId = (imageId, nextImageId) => (
  dispatch,
  getState
) => {
  getStickers(getState()).forEach(sticker => {
    if (sticker.image !== imageId) {
      return;
    }
    dispatch(updateSticker(sticker.id, { image: nextImageId }));
  });
};

export const uploadStickerImage = ({
  id,
  createStickerImage,
  file,
}) => async dispatch => {
  const image = await createStickerImage(file);
  const size = computeNativeImageSize(image);

  dispatch(
    updateSticker(id, {
      image: image.id,
      ...getStickerPositionFromFace(image.details.face, size),
    })
  );
};
