import React, { useState } from 'react';
import { func, bool } from 'prop-types';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';

import { CheckoutFormDataShape } from '../../components/shapes';
import { CheckoutShape } from './shapes';

const invalidCodeMessages = {
  invalid: 'Dieser Code ist leider nicht gültig',
  inactive: 'Dieser Code ist nicht mehr aktiv',
  taken: 'Dieser Code wurde bereits genutzt',
};

function CheckoutVoucherInput({
  formData: { voucherCode },
  setFormData,
  isFetchingCheckout,
  checkout,
}) {
  const [localVoucherCode, setLocalVoucherCode] = useState('');
  const {
    voucher: { status },
  } = checkout;

  const setVoucherCode = value =>
    setFormData(prevFormData => ({ ...prevFormData, voucherCode: value }));

  const handleSubmit = () => {
    setVoucherCode(localVoucherCode);
  };

  const handleReset = () => {
    setLocalVoucherCode('');
    setVoucherCode('');
  };

  const invalidCodeSupplied = ['invalid', 'inactive', 'taken'].includes(status);
  const validCodeSupplied = status === 'valid';

  if (validCodeSupplied) {
    return (
      <Alert
        className="mt-3 qa-voucher-success"
        variant="success"
        onClose={handleReset}
        dismissible
      >
        Dein Gutschein <b>{voucherCode}</b> wurde angewendet! 🎉
      </Alert>
    );
  }

  return (
    <Alert className="mt-3 border" variant={invalidCodeSupplied && 'secondary'}>
      <Form.Label>Hast du einen Gutschein?</Form.Label>
      <InputGroup className="mb-2" hasValidation>
        <Form.Control
          className="qa-voucher-code-input"
          name="voucher_code"
          placeholder="Gutschein-Code"
          value={localVoucherCode}
          onChange={({ target: { value } }) => setLocalVoucherCode(value)}
          isInvalid={invalidCodeSupplied}
        />
        <Form.Control.Feedback type="invalid" tooltip>
          {invalidCodeMessages[status]}
        </Form.Control.Feedback>
        <Button
          className="qa-voucher-submit-btn"
          variant="outline-primary"
          disabled={
            localVoucherCode.length === 0 ||
            isFetchingCheckout ||
            localVoucherCode === voucherCode
          }
          onClick={handleSubmit}
        >
          OK
        </Button>
      </InputGroup>
    </Alert>
  );
}

CheckoutVoucherInput.defaultProps = {};

CheckoutVoucherInput.propTypes = {
  formData: CheckoutFormDataShape.isRequired,
  setFormData: func.isRequired,
  isFetchingCheckout: bool.isRequired,
  checkout: CheckoutShape.isRequired,
};

export default CheckoutVoucherInput;
