import React from 'react';
import { useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import usePexel from '../../../hooks/usePexel';
import { getImage } from '../../../selectors/images';
import { ImageIdShape } from '../../shapes';

const withImageResolver = WrappedComponent => {
  const Component = props => {
    const { image: imageId, pexelsId } = props;

    // Resolve imageId to imageObject
    const imageObject = useSelector(state => getImage(state, imageId));

    // Resolve pexelsId to pexelsObject
    const { resolvePexelsId } = usePexel();
    const { value: pexelsObject } = useAsync(
      async () => resolvePexelsId(pexelsId),
      [pexelsId]
    );

    return (
      <WrappedComponent
        {...props}
        imageObject={imageObject}
        pexelsObject={pexelsObject}
      />
    );
  };

  Component.defaultProps = {
    image: null,
    pexelsId: null,
  };

  Component.propTypes = {
    image: ImageIdShape,
    pexelsId: ImageIdShape,
  };

  return Component;
};

export default withImageResolver;
