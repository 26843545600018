import { number } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { getAnimatedZoom } from '../../selectors/viewport';
import { clockwiseCornerPivotNames } from './pivots';
import RotateHandle from './RotateHandle';

function RotateOperation({ width, height }) {
  const zoom = useSelector(getAnimatedZoom);
  const handleSize = 40 / zoom;
  return clockwiseCornerPivotNames.map(pivot => {
    return (
      <RotateHandle
        key={pivot}
        pivotName={pivot}
        width={width}
        height={height}
        handleSize={handleSize}
      />
    );
  });
}

RotateOperation.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
};

export default RotateOperation;
