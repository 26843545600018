import React from 'react';
import { bool, node, string } from 'prop-types';

const ghostOpacity = 0.2;

/**
 * This renders two otherwise identical children: the element itself, which
 * is clipped, and the "ghost" copy (not clipped, faded out) to show the
 * full extend of the selected element to the user
 */
function ClipGhost({
  shouldRenderUnclipped,
  clipPath,
  children,
  transform,
  ...rest
}) {
  return (
    <>
      <g clipPath={clipPath}>
        <g transform={transform} {...(shouldRenderUnclipped ? null : rest)}>
          {children}
        </g>
      </g>
      {shouldRenderUnclipped && (
        <g opacity={ghostOpacity} transform={transform} {...rest}>
          {children}
        </g>
      )}
    </>
  );
}

ClipGhost.defaultProps = {
  clipPath: null,
  transform: null,
};

ClipGhost.propTypes = {
  shouldRenderUnclipped: bool.isRequired,
  children: node.isRequired,
  clipPath: string,
  transform: string,
};

export default ClipGhost;
