import { bool, func, node, number, oneOfType, string } from 'prop-types';
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';

import SwatchColorPicker from '../../ui/SwatchColorPicker';
import DropdownWithTooltip from './DropdownWithTooltip';

function ColorDropdown({
  onChange,
  color,
  fieldName,
  tooltip,
  allowNull,
  children,
  ...rest
}) {
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const hasColor = color !== null;

  return (
    <DropdownWithTooltip
      {...rest}
      show={isOpen}
      onToggle={nextIsOpen => setIsOpen(isColorPickerOpen || nextIsOpen)}
      tooltip={tooltip}
    >
      <Dropdown.Toggle
        className={`color-input qa-${fieldName}-input`}
        tabIndex={-1}
      >
        {hasColor ? (
          children
        ) : (
          <span
            className={`preview background-none qa-${fieldName}-disabled`}
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu className="color-picker qa-swatch-color-picker">
        <SwatchColorPicker
          allowCustom
          allowNull={allowNull}
          color={color}
          onChange={onChange}
          onOpen={() => setIsColorPickerOpen(true)}
          onClose={() => setIsColorPickerOpen(false)}
        />
      </Dropdown.Menu>
    </DropdownWithTooltip>
  );
}

ColorDropdown.defaultProps = {
  color: null,
  allowNull: true,
};

ColorDropdown.propTypes = {
  onChange: func.isRequired,
  fieldName: string.isRequired,
  tooltip: string.isRequired,
  color: oneOfType([string, number]),
  allowNull: bool,
  children: node.isRequired,
};

export default ColorDropdown;
