import React from 'react';
import { func, node, object, oneOfType } from 'prop-types';
import Tab from 'react-bootstrap/Tab';
import { useRouteMatch } from 'react-router-dom';

import useLoading, { commonTypes } from '../../../hooks/useLoading';
import SidebarLoader from './SidebarLoader';
import { RefShape } from '../../shapes';

export function TabPane({ component: Component, sidebarRef, ...rest }) {
  const match = useRouteMatch();
  const { isLoading } = useLoading(commonTypes.fetchingAlbum);

  if (isLoading) {
    return <SidebarLoader />;
  }

  return (
    <Tab.Pane
      active
      className={`qa-${match.params.key}-content flex-grow-1`}
      eventKey={match.params.key}
    >
      <div className="d-flex flex-column w-100">
        <Component sidebarRef={sidebarRef} {...rest} />
      </div>
    </Tab.Pane>
  );
}

TabPane.defaultProps = {
  component: undefined,
};

TabPane.propTypes = {
  component: oneOfType([func, object]),
  sidebarRef: RefShape.isRequired,
};

function TabContent({ children }) {
  return (
    <Tab.Content className="px-3 pt-3 d-flex flex-column w-100">
      {children}
    </Tab.Content>
  );
}

TabContent.propTypes = {
  children: node.isRequired,
};

export default TabContent;
