import React, { useState } from 'react';
import { arrayOf, func } from 'prop-types';
import { useEffectOnce, useMethods } from 'react-use';
import { useDispatch } from 'react-redux';

import { StickerFormProvider } from '../StickerForm/useStickerForm';
import { StickerShape } from '../../../shapes';
import StickerUploadsModal from '../../StickerUploadsModal';
import { computeNativeImageSize } from '../../../../util/images';
import {
  detectFaceFromUrl,
  getStickerPositionFromFace,
} from '../../../../util/faceapi';
import { updateImage } from '../../../../modules/images';

const createMethods = stickers => ({
  updateSticker: nextSticker =>
    // nextSticker-props are merged into the existing sticker
    stickers.map(sticker =>
      sticker.id === nextSticker.id ? { ...sticker, ...nextSticker } : sticker
    ),
  deleteSticker: ({ id }) => stickers.filter(sticker => id !== sticker.id),
});

function LocalStickerUploadsModal({ onClose, onSubmit, initialStickers }) {
  const dispatch = useDispatch();

  const [stickers, { updateSticker, deleteSticker }] = useMethods(
    createMethods,
    initialStickers
  );

  const [ready, setReady] = useState(false);

  useEffectOnce(() => {
    let cancelled = false;
    (async () => {
      async function recognizeFace(sticker) {
        // Run face detection
        const { clientImage } = sticker;
        const face = await detectFaceFromUrl(clientImage.blob.full);
        if (cancelled) {
          return;
        }

        const nextDetails = { ...clientImage.details, face };
        dispatch(updateImage(clientImage.id, { details: nextDetails }));

        // Face-recognition. This merges with changes the user might have done meanwhile
        const size = computeNativeImageSize(clientImage);
        updateSticker({
          id: sticker.id,
          ...getStickerPositionFromFace(face, size),
          clientImage: { ...clientImage, details: nextDetails },
          showImageLoadingSpinner: false,
        });
      }

      await Promise.all(initialStickers.map(recognizeFace));
      if (cancelled) {
        return;
      }

      // Allow submitting the modal only when all stickers are ready
      setReady(true);
    })();
    return () => {
      cancelled = true;
    };
  });

  const handleDelete = sticker => {
    deleteSticker(sticker);
    sticker.cancelUpload();
  };

  return (
    <StickerFormProvider>
      <StickerUploadsModal
        onSubmit={() => onSubmit(stickers)}
        onClose={() => onClose(stickers)}
        onDelete={handleDelete}
        requireAllValid
        updateSticker={updateSticker}
        stickers={stickers}
        disabled={!ready}
      />
    </StickerFormProvider>
  );
}

LocalStickerUploadsModal.propTypes = {
  initialStickers: arrayOf(StickerShape).isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
};

export default LocalStickerUploadsModal;
