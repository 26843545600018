import { createSelector } from 'reselect';

import { orderNodesBySortParams, reduceSortParams } from '../util/workspace';
import {
  createMemoizedSelectorWithDeepComparison,
  getAllNodes,
  getSectionNodesById,
  getSimplifiedImageNodes,
  getSpreadIds,
  getTargetNode,
  getTargetSpreadId,
  getWorkspace,
  getStickers,
} from './legacy';
import { extractNestedElements } from './selection';
import { elementTypesWithDangerZone } from '../constants';

export const getSpreadNodes = createMemoizedSelectorWithDeepComparison(
  [getWorkspace, getSpreadIds],
  ({ nodes }, spreadIds) => spreadIds.map(id => nodes[id])
);

export const getSectionNodesBySpreadId = createSelector(
  [getSpreadNodes, getSectionNodesById],
  (spreadNodes, sectionNodes) =>
    spreadNodes.reduce(
      (acc, cur) => ({ ...acc, [cur.props.id]: sectionNodes[cur.parent] }),
      {}
    )
);

export const getNodeIdsByImageId = createSelector(
  [getSimplifiedImageNodes],
  imageNodes => {
    return imageNodes.reduce(
      (acc, { props: { id: nodeId, image: imageId } }) => {
        const curr = acc[imageId] || [];
        acc[imageId] = [...curr, nodeId];
        return acc;
      },
      {}
    );
  }
);

export const getTargetSpreadEmpty = createSelector(
  [getTargetNode],
  ({ children }) => children.length === 0
);

export const selectSortRelevantPositions = createSelector(
  [getWorkspace, getSpreadIds],
  ({ nodes }, spreadIds) => {
    return reduceSortParams(nodes, spreadIds, new DOMMatrixReadOnly());
  }
);

export const selectCommentNodes = createSelector([getAllNodes], nodes => {
  return nodes.filter(node => node.type === 'Comment');
});

export const selectOrderedComments = createMemoizedSelectorWithDeepComparison(
  [selectCommentNodes, selectSortRelevantPositions],
  (commentNodes, sortRelevantPositions) => {
    return orderNodesBySortParams(commentNodes, sortRelevantPositions);
  }
);

// Returns an array of IDs for all workspace nodes with `node['props']['locked'] === true`
export const selectLockedNodeIds = createSelector([getAllNodes], nodes =>
  nodes.filter(node => node.props.locked).map(node => node.props.id)
);

// Returns an array of all workspace nodes whose (potentially nested) parents are locked
export const selectLockedNodesWithChildren = createMemoizedSelectorWithDeepComparison(
  [selectLockedNodeIds, getWorkspace],
  (lockedNodeIds, { nodes }) => extractNestedElements(lockedNodeIds, nodes)
);

/**
 * Returns an array of image IDs whose corresponding workspace nodes are either
 * locked themselves or belong to locked parents.
 */
export const selectImageIdsFromLockedNodesWithChildren = createSelector(
  [selectLockedNodesWithChildren],
  lockedNodesWithChildren =>
    lockedNodesWithChildren
      .filter(node => node.type === 'Image')
      .map(node => node.props.image)
);

export const selectWorkspaceNodesWithActualWidth = createSelector(
  [getWorkspace, getStickers],
  ({ nodes }, stickers) =>
    Object.values(nodes).reduce((acc, curr) => {
      const { stickerId } = curr.props;

      if (stickerId) {
        const sticker = stickers.find(s => s.id === stickerId);

        /**
         * Sticker cells have, by default, width 50, which holds true for empty
         * and single sticker cells. Double sticker cells use twice the space
         * in the workspace, so we double their width.
         */
        if (sticker.doubleSticker) {
          acc[curr.props.id] = {
            ...curr,
            props: {
              ...curr.props,
              width: curr.props.width * 2,
            },
          };

          return acc;
        }
      }

      acc[curr.props.id] = curr;

      return acc;
    }, {})
);

export const selectElementsOnTargetSpread = createSelector(
  [selectWorkspaceNodesWithActualWidth, getTargetSpreadId],
  (nodes, targetSpreadId) =>
    nodes[targetSpreadId].children.map(childId => nodes[childId])
);

export const selectDangerZoneElementsOnTargetSpread = createSelector(
  [selectElementsOnTargetSpread],
  elementsOnTargetSpread =>
    elementsOnTargetSpread.filter(node =>
      elementTypesWithDangerZone.includes(node.type)
    )
);

export default getSpreadNodes;
