import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';

import {
  selectStickerUploadsPath,
  selectStickerUploadConfig,
} from '../../../selectors/albums';
import { selectStickerUploads } from '../../../selectors/stickerUploads';
import StickerLinkSettings from './StickerLinkSettings';
import useTutorial from '../../../hooks/useTutorial';
import useAnalytics from '../../../containers/app/useAnalytics';
import ButtonWithTooltip from '../../menu/ButtonWithTooltip';

function StickerLinkPanel() {
  const { linkTo } = useTutorial();
  const analytics = useAnalytics();

  const stickerUploads = useSelector(selectStickerUploads);
  const uploadsPath = useSelector(selectStickerUploadsPath);
  const { enabled, whiteLabel, domain } = useSelector(
    selectStickerUploadConfig
  );

  const fullUploadsUrl = `https://${
    whiteLabel && domain ? domain : window.location.host
  }${uploadsPath}`;

  const uploadsUrlRef = useRef();

  function handleCopy() {
    uploadsUrlRef.current.select();
    navigator.clipboard.writeText(uploadsUrlRef.current.value);
    analytics.track('StickerLink URL Copied');
  }

  return (
    <div className="sidebar-panel scrollable py-3">
      <Card
        bg={enabled ? 'primary' : 'secondary'}
        text="light"
        className="mb-3"
      >
        <Card.Header>Status</Card.Header>
        <Card.Body>
          <Card.Title>
            <b>{stickerUploads.length} Sticker</b> eingesammelt
          </Card.Title>
          <Card.Text>
            Dein Link ist <b>{enabled ? 'aktiv' : 'inaktiv'}</b>.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card bg="light" text="dark" className="mb-3">
        <Card.Header>Dein Stickerlink</Card.Header>
        <Card.Body>
          <Card.Text>
            Mit dem Stickerlink kannst du ganz bequem und schnell die Fotos
            aller Personen einsammeln! Einfach kopieren und verschicken.{' '}
            <a
              href={linkTo('stickerlink')}
              target="_BLANK"
              rel="noopener noreferrer"
            >
              Mehr erfahren
            </a>
          </Card.Text>
          <InputGroup className="mb-3">
            <FormControl
              type="text"
              className="qa-sticker-uploads-url"
              value={fullUploadsUrl}
              ref={uploadsUrlRef}
              readOnly
            />
            <ButtonWithTooltip
              icon="clipboard"
              tooltip="Link in die Zwischenablage kopieren"
              onClick={handleCopy}
              variant="dark"
            />
          </InputGroup>
        </Card.Body>
      </Card>
      <StickerLinkSettings uploadsUrl={fullUploadsUrl} />
    </div>
  );
}

export default StickerLinkPanel;
