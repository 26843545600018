import React, { createContext, useContext, useRef, useState } from 'react';
import { node } from 'prop-types';

import ConfirmModal from './ConfirmModal';

const ConfirmationServiceContext = createContext();

export function useConfirmation() {
  return useContext(ConfirmationServiceContext);
}

export function ConfirmationServiceProvider({ children }) {
  /**
   * `confirmationState` is an object with only one key for now, `body`,
   * representing the description displayed in the body part of the
   * confirmation modal.
   */
  const [confirmationState, setConfirmationState] = useState(null);
  const awaitingPromiseRef = useRef();

  const openConfirmation = options => {
    setConfirmationState(options);

    return new Promise((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>

      <ConfirmModal
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
}

ConfirmationServiceProvider.propTypes = {
  children: node.isRequired,
};

export default ConfirmationServiceContext;
