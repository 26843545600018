import { connect } from 'react-redux';

import SpreadComponent from './index';
import {
  getOperationActive,
  makeSelectIsSpreadVisible,
} from '../../../../selectors/controls';
import {
  getAlbumStatisticsForPreflight,
  getTargetSpreadId,
} from '../../../../selectors/legacy';

const makeMapStateToProps = () => {
  const selectIsSpreadVisible = makeSelectIsSpreadVisible();
  const mapStateToProps = (state, { id, nodeIndex }) => ({
    visible: selectIsSpreadVisible(state, nodeIndex),
    isActiveSpread: getTargetSpreadId(state) === id,
    operationActive: getOperationActive(state),
    spine: getAlbumStatisticsForPreflight(state).spine,
  });

  return mapStateToProps;
};

export default connect(makeMapStateToProps)(SpreadComponent);
