export default function getTransformToElement(sourceElement, targetElement) {
  // "Polyfill" for removed DOM function: https://github.com/xosystem/SVG-getTransformToElement-FIX
  const sourceTransform = sourceElement?.getScreenCTM();
  return sourceTransform
    ? targetElement
        ?.getScreenCTM()
        .inverse()
        .multiply(sourceTransform)
    : null;
}
