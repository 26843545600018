import recursiveMap from './recursiveMap';
import { generateId } from './index';
import { computeNativeImageSize } from './images';

export const svgTransformationsForSvgImage = (image, element, isCover) => {
  const { width: nativeWidth, height: nativeHeight } = computeNativeImageSize(
    image
  );
  const { width, height } = element.props;
  // NOTE: will eventually overflow the spread
  const scaleStrategy = isCover ? Math.max : Math.min;
  const cscale = scaleStrategy(width / nativeWidth, height / nativeHeight);
  const cx = (width - nativeWidth * cscale) / 2;
  const cy = (height - nativeHeight * cscale) / 2;
  return { cx, cy, cscale, crotation: 0 };
};

export const uniquifyIds = element =>
  recursiveMap(element, item => ({
    ...item,
    props: { ...item.props, id: generateId() },
  }));

export const resetStickerCellStickerIds = element =>
  recursiveMap(element, item =>
    item.type === 'StickerCell'
      ? {
          ...item,
          props: { ...item.props, stickerId: null },
        }
      : item
  );

export function findSpreadIndexRecursive(spreads, elementId) {
  function recurse(element) {
    if (element.props.id === elementId) {
      return true;
    }
    if (element.children) {
      return element.children.find(element => recurse(element));
    }
    return false;
  }

  return spreads.findIndex(spread => {
    return recurse(spread);
  });
}
