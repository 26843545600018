import React from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { func, string } from 'prop-types';
import { useSelector } from 'react-redux';

import Icon from '../../../../Icon';
import withTextInputProps from '../withTextInputProps';
import DropdownWithTooltip from '../../DropdownWithTooltip';
import { getTextEditActive } from '../../../../../selectors/selection';
import TextAlignButtons from './TextAlignButtons';
import LineHeightButtons from './LineHeightButtons';

function TextAlignAndLineHeightInput(props) {
  const textEditActive = useSelector(getTextEditActive);
  const expandDropdowns = textEditActive;

  return (
    <>
      {!expandDropdowns && (
        <DropdownWithTooltip
          {...props}
          className="align-text panel-container"
          tooltip="Zeilenhöhe und Ausrichtung"
        >
          <Dropdown.Toggle className="qa-align-text">
            <Icon name="alignleft" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <ButtonGroup className="panel">
              <TextAlignButtons {...props} />
            </ButtonGroup>
            <ButtonGroup className="panel">
              <LineHeightButtons {...props} />
            </ButtonGroup>
          </Dropdown.Menu>
        </DropdownWithTooltip>
      )}
      {expandDropdowns && (
        <>
          <div className="seperator" />
          <TextAlignButtons {...props} />
          <div className="seperator" />
          <LineHeightButtons {...props} />
          <div className="seperator" />
        </>
      )}
    </>
  );
}

TextAlignAndLineHeightInput.defaultProps = {
  lineHeight: '100%',
  align: 'left',
};

TextAlignAndLineHeightInput.propTypes = {
  lineHeight: string,
  align: string,
  dropDirection: string.isRequired,
  makeHandleChange: func.isRequired,
};

export default withTextInputProps(TextAlignAndLineHeightInput);
