// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';

import { dimensions } from '../../constants';
import ClipPaths from './ClipPaths';

export const ContentContext = React.createContext(null);

class Content extends React.PureComponent {
  imageOverlays = [];

  textOverlays = [];

  state = {
    imageOverlays: this.imageOverlays,
    textOverlays: this.textOverlays,
  };

  createTextOverlay = overlay => {
    this.textOverlays = [...this.textOverlays, overlay];
    this.setState({ textOverlays: this.textOverlays });
  };

  createImageOverlay = baseOverlay => {
    const { width, x, y } = this.props;
    const overlay = { ...baseOverlay };

    // TODO: The center should be calculated considering the rotation and scale
    // save overlay only when its center is within the "viewport". this is prevents overlays from appearing in the wrong place during cover-rendering
    const center = overlay.transform.x + overlay.transform.width / 2;

    if (
      (center > 0 && center < width) ||
      overlay.transform.width > dimensions.pageWidth
    ) {
      overlay.transform = {
        ...overlay.transform,
        x: overlay.transform.x + x,
        y: overlay.transform.y + y,
      };
      overlay.transformContent = {
        ...overlay.transformContent,
        x: overlay.transformContent.x + x,
        y: overlay.transformContent.y + y,
      };

      this.imageOverlays = [...this.imageOverlays, overlay];
      this.setState({ imageOverlays: this.imageOverlays });
    } else {
      console.debug('skippingOverlay', overlay);
    }
  };

  render() {
    const {
      children,
      x,
      y,
      width,
      height,
      bleed,
      spine,
      rotation,
      scale,
    } = this.props;
    const { imageOverlays, textOverlays } = this.state;

    const bleedWidth = width + bleed * 2;
    const bleedHeight = height + bleed * 2;
    const style = {
      position: 'absolute',
      left: 0,
      top: 0,
      width: `${bleedWidth}mm`,
      height: `${bleedHeight}mm`,
    };

    const style2 = {
      position: 'absolute',
      width: `${bleedWidth}mm`,
      height: `${bleedHeight}mm`,
      overflow: 'hidden',
      transform: `translate(${x}mm,${y}mm) rotate(${rotation}deg) scale(${scale})`,
      transformOrigin: 'left top',
    };

    return (
      <ContentContext.Provider value={this}>
        <div
          className="content"
          style={style2}
          data-image-overlays={JSON.stringify(imageOverlays)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.2"
            style={style}
            viewBox={[-bleed, -bleed, bleedWidth, bleedHeight].join(' ')}
          >
            <defs>
              <ClipPaths spine={spine} />
            </defs>
            {children}
          </svg>
          <div
            className="overlay"
            style={{
              position: 'absolute',
              width: `${bleedWidth}mm`,
              height: `${bleedHeight}mm`,
              left: `${bleed}mm`,
              top: `${bleed}mm`,
            }}
          >
            {textOverlays.map((overlay, key) => {
              const {
                x,
                y,
                rotation,
                scale,
                width,
                height,
              } = overlay.transform;
              const { opacity } = overlay;
              const transform = `translate(${x}mm,${y}mm) rotate(${rotation}deg) scale(${scale *
                3.78})`; // 3.78 = px to mm
              return (
                <div
                  key={key}
                  className="text"
                  style={{
                    opacity,
                    position: 'absolute',
                    transformOrigin: 'top left',
                    transform,
                    width,
                    height,
                  }}
                  dangerouslySetInnerHTML={{ __html: overlay.html }}
                />
              );
            })}
          </div>
        </div>
      </ContentContext.Provider>
    );
  }
}

Content.defaultProps = {
  width: 100,
  height: 100,
  bleed: 2,
  skipCoverOverlays: false,
  rotation: 0,
  scale: 1,
};

export default Content;
