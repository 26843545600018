import React from 'react';
import { shape, string } from 'prop-types';
import { format } from 'date-fns';
import Button from 'react-bootstrap/Button';
import isEmpty from 'lodash/isEmpty';

import { OrderShape, MetadataShape } from '../../../components/shapes';

const signalToColor = signal => {
  switch (signal) {
    case 'accepted':
    case 'review_completed':
    case 'export_completed':
    case 'transfer_completed':
    case 'invoice_created':
    case 'production_started':
    case 'shipped':
      return 'success';
    case 'rejected':
    case 'export_failed':
    case 'transfer_failed':
      return 'danger';
    case 'review_feedback':
    case 'export_started':
    case 'transfer_started':
    case 'shipment_created':
      return 'warning';
    default:
      return 'secondary';
  }
};

function OrderTimeline({ order }) {
  const { order_updates: orderUpdates } = order;

  return (
    <div className="timeline">
      <div className="card-body">
        <div className="timeline">
          {orderUpdates.map(orderUpdate => {
            const createdAt = new Date(orderUpdate.created_at);
            const metadata = orderUpdate.metadata || {};
            return (
              <div
                key={orderUpdate.id}
                className={`timeline-element qa-order-update-${orderUpdate.signal} qa-order-update-${orderUpdate.id}`}
              >
                <div>
                  <span className="timeline-element-icon">
                    <i
                      className={`badge badge-dot badge-dot-xl badge-${signalToColor(
                        orderUpdate.signal
                      )}`}
                    >
                      {' '}
                    </i>
                  </span>
                  <div className="timeline-element-content">
                    <h4 className="timeline-title">{orderUpdate.title}</h4>
                    <p className="text-muted">{orderUpdate.message}</p>
                    {!isEmpty(metadata) && (
                      <OrderUpdateMetadata orderUpdate={orderUpdate} />
                    )}
                    <span className="timeline-element-date">
                      {format(createdAt, 'dd. MMM')}
                      <br />
                      {format(createdAt, 'HH:mm')}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

OrderTimeline.propTypes = {
  order: OrderShape.isRequired,
};

function OrderUpdateMetadata({
  orderUpdate: { metadata, target_url: targetUrl },
}) {
  return Object.entries(metadata).map(([key, value]) => {
    if (isEmpty(value)) return null;
    switch (key) {
      case 'tracking_code':
      case 'dhl_tracking_code':
        return (
          <Button
            key={key}
            size="sm"
            variant="light"
            href={targetUrl}
            target="_blank"
          >
            Paket verfolgen
          </Button>
        );
      default:
        return (
          <span key={key} className="text-muted ml-2">
            {value}
          </span>
        );
    }
  });
}

OrderUpdateMetadata.propTypes = {
  orderUpdate: shape({
    metadata: MetadataShape.isRequired,
    target_url: string,
  }).isRequired,
};

export default OrderTimeline;
