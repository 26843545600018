import React from 'react';
import { useSelector } from 'react-redux';

import PageControl from './PageControl';
import ZoomControl from './ZoomControl';
import FadeInWhenAlbumLoaded from '../../../generic/FadeInWhenAlbumLoaded';
import ViewSettingsControl from './ViewSettingsControl';
import useScreenSize from '../../../../hooks/useScreenSize';
import { selectMobileFooterOpen } from '../../../../selectors/controls';
import { selectIsAlbumEditable } from '../../../../selectors/albums';

function ViewControlMenu() {
  const { isMobile } = useScreenSize();
  const mobileFooterOpen = useSelector(selectMobileFooterOpen);
  const isAlbumEditable = useSelector(selectIsAlbumEditable);

  return (
    <FadeInWhenAlbumLoaded>
      <div
        className={`view-control-menu${
          mobileFooterOpen && isAlbumEditable
            ? ' view-control-menu--moveUp '
            : ''
        } qa-view-control form-inline`}
      >
        <PageControl />
        {!isMobile && (
          <>
            <ZoomControl />
            <ViewSettingsControl />
          </>
        )}
      </div>
    </FadeInWhenAlbumLoaded>
  );
}

ViewControlMenu.propTypes = {};

export default ViewControlMenu;
