import React, { useState } from 'react';
import clamp from 'lodash/clamp';
import { bool, func, number } from 'prop-types';

import useMouseDragCoordinates from '../../../../hooks/useMouseDragCoordinates';

// This should match the CSS-value for rx/ry
const borderRadius = 4;

// Padding between the scroll-button and the viewport
const padding = 4;

function ViewportScrollbar({
  min,
  max,
  value,
  expansion,
  clientSize,
  onChange,
  horizontal,
}) {
  const [initialValue, setInitialValue] = useState(null);

  const position = (value - min) / (max - min);
  const length = (expansion - min) / (max - min);

  const clientButtonSize = length * clientSize;
  const clientLength = clientButtonSize + borderRadius * 2;
  const clientAvailiableSize = clientSize - clientLength - padding * 2;
  const clientPosition = position * clientAvailiableSize + padding;

  function handleStart() {
    setInitialValue(value);
  }

  function handleUpdate({ initialPointer, currentPointer }) {
    const clientDelta =
      (horizontal ? currentPointer.x : currentPointer.y) -
      (horizontal ? initialPointer.x : initialPointer.y);
    const percentage = clientDelta / clientAvailiableSize;
    const uncheckedNextValue = initialValue + (max - min) * percentage;
    const nextValue = clamp(uncheckedNextValue, min, max);
    onChange(nextValue);
  }

  function handleEnd() {
    setInitialValue(null);
  }

  const { handleMouseDown } = useMouseDragCoordinates({
    onStart: handleStart,
    onUpdate: handleUpdate,
    onEnd: handleEnd,
  });

  // The whole content is already visible - no need to scroll
  if (clientButtonSize > clientSize) {
    return null;
  }

  const props = horizontal
    ? {
        x: clientPosition,
        width: clientLength,
      }
    : {
        y: clientPosition,
        height: clientLength,
      };

  const direction = horizontal ? 'horizontal' : 'vertical';

  return (
    <svg className={`viewport-scrollbar ${direction}`}>
      <rect
        {...props}
        className={`button qa-scrollbar-button-${direction}`}
        onMouseDown={handleMouseDown}
      />
    </svg>
  );
}

ViewportScrollbar.defaultProps = {
  horizontal: false,
};

ViewportScrollbar.propTypes = {
  min: number.isRequired,
  max: number.isRequired,
  value: number.isRequired,
  expansion: number.isRequired,
  clientSize: number.isRequired,
  onChange: func.isRequired,
  horizontal: bool,
};

export default ViewportScrollbar;
