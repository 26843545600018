import React from 'react';
import ContentLoader from 'react-content-loader';

const GridLoader = props => {
  return (
    <ContentLoader
      speed={2}
      width={590}
      height={310}
      viewBox="0 0 590 310"
      {...props}
    >
      {}
      <rect x="0" y="1" rx="0" ry="0" width="389" height="152" />
      <rect x="398" y="1" rx="0" ry="0" width="208" height="152" />
      <rect x="0" y="158" rx="0" ry="0" width="191" height="152" />
      <rect x="201" y="159" rx="0" ry="0" width="389" height="152" />
    </ContentLoader>
  );
};

export default GridLoader;
