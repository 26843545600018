import React, { useEffect, useMemo } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

import FullScreenSpinner from './FullScreenSpinner';
import { setCurrentUser, setOrganizations } from '../../modules/user';
import useAuth from '../../hooks/useAuth';
import NotFound from '../../components/NotFound';
import { emailFromQueryParamsKey } from '../album_wizard/useWizardAutosave';
import useApi from '../../hooks/useApi';

export const onRedirecting = () => <FullScreenSpinner />;

const withAuthentication = (
  WrappedComponent,
  { adminOnly } = { adminOnly: false }
) => props => {
  const dispatch = useDispatch();
  const { user, isAdmin } = useAuth();
  const api = useApi();

  /**
   * We are constantly syncing the auth0 user to the store.
   */
  useEffect(() => {
    dispatch(setCurrentUser(user));
  }, [dispatch, user]);

  /**
   * We scope some of the app content by whether the user is
   * part of an organization or not. This effect fetches user
   * organizations and pushes them to the store.
   */
  useEffect(() => {
    async function fetchOrganizations() {
      const { data: organizations } = await api.get('/organizations');
      dispatch(setOrganizations(organizations));
    }

    if (user.sub) {
      fetchOrganizations();
    }
  }, [api, dispatch, user]);

  const { signup, prefillEmail } = props; // eslint-disable-line react/prop-types

  const loginOptions = useMemo(
    () => ({
      screen_hint:
        signup || window.location.href.includes('hint=signup')
          ? 'signup'
          : undefined,
      login_hint: prefillEmail
        ? localStorage.getItem(emailFromQueryParamsKey) || ''
        : undefined,
    }),
    [signup, prefillEmail]
  );

  const AuthenticatedWrappedComponent = useMemo(
    () =>
      withAuthenticationRequired(WrappedComponent, {
        onRedirecting,
        loginOptions,
      }),
    [loginOptions]
  );

  if (adminOnly && !isAdmin) {
    return <NotFound />;
  }

  return <AuthenticatedWrappedComponent {...props} />;
};

export default withAuthentication;
