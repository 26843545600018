// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React from 'react';
import { useSelector } from 'react-redux';

import {
  getAlbumStatisticsForPreflight,
  getSpreadIds,
} from '../../selectors/legacy';
import Page from './Page';
import Node from './Node';
import Content from './Content';
import { dimensions, spineOffset } from '../../constants';
import { getSectionNodesBySpreadId } from '../../selectors/workspace';
import StickerPack from './StickerPack';
import { addLineBreaks } from './addLineBreaks';
import useMutationObserver from '../../hooks/useMutationObserver';

function SpreadContainerRenderer(props) {
  const { includeBleed, includeCover, mode, range } = props;
  const spreadIds = useSelector(getSpreadIds);
  const { spine } = useSelector(getAlbumStatisticsForPreflight);
  const sectionNodes = useSelector(getSectionNodesBySpreadId);

  /**
   * Prince renders text slightly different from the browser, mainly a tidbit smaller.
   * This might lead to, especially in justified text, lines breaking at different
   * words, resulting in a layout mismatch between browser and PDF.
   *
   * We fix this by reading the length of lines from the DOM and setting break points
   * manually (cf. `addLineBreaks`).
   */
  const addLineBreaksCallback = () => {
    document
      .querySelectorAll('.overlay .public-DraftStyleDefault-block')
      .forEach(item => {
        addLineBreaks(item);
      });
  };

  const { isFullyPainted } = useMutationObserver({
    callback: addLineBreaksCallback,
  });

  function renderCover() {
    const { bleed, trim, marks } = includeBleed
      ? Page.defaultProps
      : { bleed: 0, trim: 0, marks: 'none' };

    let pages;

    if (spreadIds.length < 2) {
      return null;
    }
    if (spreadIds.length < 4) {
      pages = [
        spreadIds[spreadIds.length - 1], // u4
        spreadIds[0], // u1
      ];
    } else {
      pages = [
        spreadIds[spreadIds.length - 1], // u4,
        spreadIds[0], // u1

        spreadIds[1], // u2
        spreadIds[spreadIds.length - 2], // u3
      ];
    }

    const result = [];

    for (let i = 0; i < pages.length / 2; i += 1) {
      const contents = [];
      for (let page = 0; page < 2; page += 1) {
        const key = `spread${i}_page${page}`;

        const index = i * 2 + page;

        let contentX = 0;
        const contentY = 0;
        let contentWidth = dimensions.pageWidth;
        const contentHeight = dimensions.pageHeight;

        let spreadX = 0;
        const spreadY = 0;

        if (index === 1) {
          contentX += dimensions.pageWidth;
          spreadX += spine;
          contentWidth += spine;
        } else if (index === 3) {
          contentX += dimensions.pageWidth + bleed + spine / 2;
          spreadX -= dimensions.pageWidth + bleed - spine / 2;
        }

        const spreadId = pages[index];
        const spreadIndex = spreadIds.indexOf(spreadId);
        const sectionNode = sectionNodes[spreadId];
        const {
          props: { id: sectionId },
        } = sectionNode;

        const content = (
          <Content
            key={key}
            id={key}
            x={contentX}
            y={contentY}
            width={contentWidth}
            height={contentHeight}
            bleed={bleed}
            skipCoverOverlays
            spine={spine}
          >
            <Node
              id={spreadId}
              nodeIndex={spreadIndex}
              nodeSiblingCount={spreadIds.length}
              renderingSpreadPosition={{
                x: spreadX,
                y: spreadY,
              }}
              sectionNode={sectionNode}
              sectionId={sectionId}
            />
          </Content>
        );

        contents.push(content);
      }
      const key = `cover${i}`;
      const pageWidth = dimensions.pageWidth * 2 + spine;
      const { pageHeight } = dimensions;
      result.push(
        <Page
          key={key}
          id={key}
          width={pageWidth}
          height={pageHeight}
          bleed={bleed}
          trim={trim}
          marks={marks}
        >
          {contents}
          {/* We're drawing a full-height white rect onto the middle of the spread as the glueing area */}
          {i === 1 && (
            <Content
              x={pageWidth / 2 - (spine + spineOffset) / 2}
              y={-bleed}
              width={spine + spineOffset}
              height={pageHeight + 2 * bleed}
            >
              <rect
                fill="white"
                width={spine + spineOffset}
                height={pageHeight + 2 * bleed}
              />
            </Content>
          )}
        </Page>
      );
    }

    return (
      <div>
        {result}
        {spreadIds.length && isFullyPainted && (
          <div className="render-complete" />
        )}
      </div>
    );
  }

  function renderAlbum() {
    if (!spreadIds.length) return null;

    const pages = [];

    const { bleed, trim, marks } = includeBleed
      ? Page.defaultProps
      : { bleed: 0, trim: 0, marks: 'none' };

    if (mode !== 'spread') {
      // `album` mode
      spreadIds.forEach((spreadId, i) => {
        const isFront = i === 0;
        const isBack = i === spreadIds.length - 1;

        const pageOffset = isFront ? 1 : 0;
        const pageLimit = isBack ? 1 : 2;
        const sectionNode = sectionNodes[spreadId];

        for (let page = pageOffset; page < pageLimit; page += 1) {
          let include = true;

          if (!includeCover) {
            if (isFront || isBack) {
              include = false;
            } else if (i === 1 && page === 0) {
              // u3
              include = false;
            } else if (i === spreadIds.length - 2 && page === 1) {
              // u4
              include = false;
            }
          }

          if (include) {
            const key = `spread${i}_page${page}`;

            const contentX = 0;
            const contentY = 0;
            const contentWidth = dimensions.pageWidth;
            const contentHeight = dimensions.pageHeight;

            let spreadX;
            if (i === 0) {
              spreadX = 0;
            } else {
              spreadX = page === 0 ? 0 : -dimensions.pageWidth;
            }

            const spreadY = 0;

            const { pageWidth } = dimensions;
            const { pageHeight } = dimensions;

            const content = (
              <Content
                key={key}
                id={key}
                x={contentX}
                y={contentY}
                width={contentWidth}
                height={contentHeight}
                bleed={bleed}
                skipCoverOverlays={false}
                spine={0}
              >
                <Node
                  id={spreadId}
                  nodeIndex={i}
                  nodeSiblingCount={spreadIds.length}
                  renderingSpreadPosition={{
                    x: spreadX,
                    y: spreadY,
                  }}
                  sectionId={sectionNode.props.id}
                  sectionNode={sectionNode}
                />
              </Content>
            );

            const num = i * 2 + page;
            if (!range || range.indexOf(num) !== -1) {
              pages.push(
                <Page
                  key={key}
                  id={key}
                  width={pageWidth}
                  height={pageHeight}
                  bleed={bleed}
                  trim={trim}
                  marks={marks}
                >
                  {content}
                </Page>
              );
            }
          }
        }
      });
    } else {
      // `spread` mode
      spreadIds.forEach((spreadId, i) => {
        const num = i + 1;
        if (!range || range.indexOf(num) !== -1) {
          const cover = i === 0 || i === spreadIds.length - 1;
          const page = 0;
          const key = `spread${i}_page${page}`;

          const contentX = 0;
          const contentY = 0;
          const contentWidth = dimensions.pageWidth * (cover ? 1 : 2);
          const contentHeight = dimensions.pageHeight;

          const spreadX = 0;
          const spreadY = 0;

          const pageWidth = contentWidth;
          const pageHeight = contentHeight;

          const sectionNode = sectionNodes[spreadId];

          const content = (
            <Content
              key={key}
              id={key}
              x={contentX}
              y={contentY}
              width={contentWidth}
              height={contentHeight}
              bleed={bleed}
            >
              <Node
                id={spreadId}
                nodeIndex={i}
                nodeSiblingCount={spreadIds.length}
                renderingSpreadPosition={{
                  x: spreadX,
                  y: spreadY,
                }}
                sectionNode={sectionNode}
              />
            </Content>
          );

          pages.push(
            <Page
              key={key}
              id={key}
              width={pageWidth}
              height={pageHeight}
              bleed={bleed}
              trim={trim}
              marks={marks}
            >
              {content}
            </Page>
          );
        }
      });
    }

    return (
      <div>
        {pages}
        {spreadIds.length && isFullyPainted && (
          <div className="render-complete" />
        )}
      </div>
    );
  }

  // TODO: Make `renderCover()` and `renderAlbum()` components

  if (mode === 'album_softcover') {
    return renderCover();
  }

  if (mode === 'stickerpack') {
    return (
      <StickerPack
        spreadIds={spreadIds}
        sectionNodes={sectionNodes}
        isFullyPainted={isFullyPainted}
      />
    );
  }

  return renderAlbum();
}

export default SpreadContainerRenderer;
