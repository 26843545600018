import React from 'react';
import { number, node } from 'prop-types';

import BaseElement from './BaseElement';

function Group(props) {
  const { children, opacity } = props;
  return (
    <BaseElement {...props}>
      <g opacity={opacity}>{children}</g>
    </BaseElement>
  );
}

Group.defaultProps = {
  opacity: 1,
  children: null,
};

Group.propTypes = {
  children: node,
  opacity: number,
};

export default Group;
