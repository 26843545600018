import React, { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import { format } from 'date-fns';

import EditDeliveryAddressForm from './EditDeliveryAddressForm';
import Spinner from '../../../components/generic/Spinner';
import useQuery from '../../../hooks/useQuery';
import LineItemsTable from './LineItemsTable';
import DeliveryNoteDownload from './DeliveryNoteDownload';
import OrderTimeline from './OrderTimeline';
import AdminOnly from '../../../components/generic/AdminOnly';
import EsvecoJsonDownload from './EsvecoJsonDownload';
import OrderEventForm from './OrderEventForm';
import OrderExport from './OrderExport';
import { InvoiceShape } from '../../../components/shapes';
import CreateInvoiceButton from './CreateInvoiceButton';
import Icon from '../../../components/Icon';
import EditOrderForm from './EditOrderForm';
import OrderComments from './OrderComments/OrderComments';

const invoiceStatusToBootstrapVariant = {
  draft: 'secondary',
  open: 'primary',
  paid: 'success',
  uncollectible: 'danger',
  void: 'danger',
};

function InvoiceStatusBadge({ invoice }) {
  const { status, hosted_invoice_url: url } = invoice;

  const StatusBadge = () => (
    <Badge variant={invoiceStatusToBootstrapVariant[status]}>{status}</Badge>
  );

  if (url) {
    return (
      <a href={url} target="_BLANK" rel="noopener noreferrer">
        <StatusBadge />
      </a>
    );
  }

  return <StatusBadge />;
}

InvoiceStatusBadge.propTypes = {
  invoice: InvoiceShape.isRequired,
};

function OrderContent() {
  const { id } = useParams();

  const [editingDeliveryAddress, setEditingDeliveryAddress] = useState(false);
  const [editingOrder, setEditingOrder] = useState(false);

  const { isLoading, data, error, refetch } = useQuery(`/orders/${id}`, []);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <Alert variant="danger">
        Fehler beim Laden der Bestellung: {error.toString()}
      </Alert>
    );
  }

  if (!data) {
    return null;
  }

  const { order } = data;
  const { shipping_address: address, album } = order;

  return (
    <div className="order-content">
      <div className="d-flex mb-4 justify-content-end">
        <AdminOnly>
          <DeliveryNoteDownload orderId={id} />
          <CreateInvoiceButton
            order={order}
            className="ml-2"
            refetch={refetch}
          />
          <EsvecoJsonDownload className="ml-2" order={order} />
          <OrderExport className="ml-2" orderId={id} refetch={refetch} />
        </AdminOnly>
      </div>
      <Row>
        <Col md={2} lg={2}>
          {album.cover_preview_url && (
            <img
              src={album.cover_preview_url}
              alt="Album cover preview"
              className="w-100 rounded shadow qa-order-cover-preview"
            />
          )}
        </Col>
        <Col md={5} lg={5}>
          <Card>
            <Card.Header>
              Details
              <AdminOnly>
                {!editingOrder && (
                  <div className="float-right">
                    <Icon
                      className="cursor-pointer qa-edit-order-btn"
                      onClick={() => setEditingOrder(true)}
                      name="edit"
                    />
                  </div>
                )}
              </AdminOnly>
            </Card.Header>
            <Card.Body>
              {editingOrder ? (
                <EditOrderForm
                  order={order}
                  onClose={() => setEditingOrder(false)}
                  refetchOrder={refetch}
                />
              ) : (
                <>
                  <div>
                    <b>Best.-Nr.:</b> {order.name}
                    <br />
                    <b>Erstellt am:</b>{' '}
                    {format(new Date(order.created_at), 'dd. LLLL, yyyy')}
                    <br />
                    <b>Tag des Events:</b>{' '}
                    {format(
                      new Date(order.album.release_date),
                      'dd. LLLL, yyyy'
                    )}
                    <br />
                    <b>Album:</b>{' '}
                    <Link
                      to={`/album/${order.album.id}/stickers`}
                      className="qa-order-album-link"
                    >
                      {order.album.title}
                    </Link>
                    {order.invoice && (
                      <>
                        <br />
                        <b>Rechnungsstatus:</b>{' '}
                        <InvoiceStatusBadge invoice={order.invoice} />
                      </>
                    )}
                  </div>
                  <div className="mt-2">
                    {order.email}
                    <br />
                    {address.phone}
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={5} lg={5}>
          <Card>
            <Card.Header>
              Versand
              <AdminOnly>
                {!editingDeliveryAddress && (
                  <div className="float-right">
                    <Icon
                      className="cursor-pointer qa-edit-shipping-address-btn"
                      onClick={() => setEditingDeliveryAddress(true)}
                      name="edit"
                    />
                  </div>
                )}
              </AdminOnly>
            </Card.Header>
            <Card.Body>
              {editingDeliveryAddress ? (
                <EditDeliveryAddressForm
                  refetchOrder={refetch}
                  onClose={() => setEditingDeliveryAddress(false)}
                  address={address}
                />
              ) : (
                <>
                  {address.organization && (
                    <>
                      {address.organization}
                      <br />
                    </>
                  )}
                  {address.first_name} {address.last_name}
                  <br />
                  {address.address1}
                  <br />
                  {address.address2}
                  <br />
                  {address.zip_code} {address.city}
                  <br />
                  {address.country_code}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={7} lg={7}>
          <Card>
            <Card.Header>Artikel</Card.Header>
            <LineItemsTable order={order} refetchOrder={refetch} />
          </Card>
          {order.comment && (
            <Card>
              <Card.Header>Kommentar</Card.Header>
              <Card.Body>
                <blockquote className="text-muted">{order.comment}</blockquote>
              </Card.Body>
            </Card>
          )}
        </Col>
        <Col md={5} lg={5}>
          <Card>
            <Card.Header>Timeline</Card.Header>
            <Card.Body>
              <OrderTimeline order={order} />
            </Card.Body>
          </Card>
          <AdminOnly>
            <Card>
              <Card.Header>Events</Card.Header>
              <Card.Body>
                <OrderEventForm order={order} onDispatch={refetch} />
              </Card.Body>
            </Card>
            <Card>
              <Card.Header>Kommentare</Card.Header>
              <Card.Body>
                <OrderComments orderId={order.id} />
              </Card.Body>
            </Card>
          </AdminOnly>
        </Col>
      </Row>
    </div>
  );
}

export default OrderContent;
