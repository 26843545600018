import { connect } from 'react-redux';

import { getImageUsage } from '../../selectors/legacy';
import { handleKeyboardEvent } from '../../actions/handleKeyboardEvent';
import {
  setCurrentAlbum,
  unsetCurrentAlbum,
  receiveAlbum,
} from '../../modules/albums';
import { resetViewToSpreadIndex } from '../../modules/controls';
import { receiveBlueprintData } from '../../modules/blueprints';
import Editor from './index';
import { getCropPreview, getShowStickers } from '../../selectors/controls';

const mapStateToProps = state => ({
  showStickers: getShowStickers(state),
  previewResolution: state.controls.previewResolution,
  cropPreview: getCropPreview(state),
  images: state.images,
  imageUsage: getImageUsage(state),
  title: state.albums.albumData.title,
});

const mapDispatchToProps = {
  handleKeyboardEvent,
  unsetCurrentAlbum,
  setCurrentAlbum,
  receiveAlbum,
  resetViewToSpreadIndex,
  receiveBlueprintData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Editor);
