import { connect } from 'react-redux';

import { getStyleMap } from '../../../../../selectors/legacy';
import BlockWrapper from './BlockWrapper';

const mapStateToProps = state => ({
  styleMap: getStyleMap(state),
});

export default connect(mapStateToProps)(BlockWrapper);
