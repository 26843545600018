export const types = {
  error: 'error',
  warning: 'warning',
  info: 'info',
};

export const PUSH = 'notifications/push';
export const CLOSE = 'notifications/close';

const initialState = [];

// eslint-disable-next-line func-names
export default function(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case PUSH: {
      return [...state, payload];
    }
    case CLOSE: {
      return state.filter(({ id }) => id !== payload);
    }
    default: {
      return state;
    }
  }
}
