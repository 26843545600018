import React from 'react';
import Button from 'react-bootstrap/Button';

function NotFound() {
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div className="text-center w-25">
        <h1>Oh no :(</h1>
        <p>Unter der angeforderten Adresse ist nichts zu finden.</p>
        <p>
          <code>404</code>
        </p>
        <Button href="/">Zur Startseite</Button>
      </div>
    </div>
  );
}

NotFound.defaultProps = {};

NotFound.propTypes = {};

export default NotFound;
