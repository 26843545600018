import React from 'react';
import { func, string } from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import { symbolTypes } from '../../../../constants';
import Icon from '../../../Icon';
import withInputProps from './withInputProps';
import DropdownWithTooltip from '../DropdownWithTooltip';

function TextSymbolInput({ onChange, symbol, ...rest }) {
  return (
    <DropdownWithTooltip
      {...rest}
      className="text-symbol qa-text-symbol-dropdown"
      tooltip="Automatischer Text"
    >
      <Dropdown.Toggle>
        <Icon name="link" />
      </Dropdown.Toggle>
      <Dropdown.Menu className="scrollable">
        {[null, ...Object.keys(symbolTypes)].map(someSymbol => (
          <Dropdown.Item
            key={someSymbol}
            onClick={() => onChange({ symbol: someSymbol })}
            className={`qa-option-${someSymbol || 'none'}`}
            active={someSymbol === symbol}
          >
            {symbolTypes[someSymbol] || '(Freier Text)'}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </DropdownWithTooltip>
  );
}

TextSymbolInput.defaultProps = {
  symbol: null,
};

TextSymbolInput.propTypes = {
  onChange: func.isRequired,
  symbol: string,
};

export default withInputProps(TextSymbolInput);
