import base from './base';
import drawOrder from './drawOrder';
import validator from './validator';
import reduceReducers from '../../util/reduceReducers';

export const initialState = {
  nodes: { root: { type: 'Root', props: { id: 'root' }, children: [] } },
  root: 'root',
};

const workspaceReducer = reduceReducers(
  initialState,
  base,
  drawOrder,
  validator
);

export default workspaceReducer;
