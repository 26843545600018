import React from 'react';
import { useParams } from 'react-router';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import Logo from '../../assets/icons/logo.svg';
import useInvitationFromParams from './useInvitation';

function AcceptInvitation() {
  const { token } = useParams();

  const { invitation, error, isLoading } = useInvitationFromParams();

  if (isLoading) {
    return null;
  }

  return (
    <div className="w-100 vh-100 d-flex align-items-center justify-content-center bg-light">
      <div className="accept-invitation-form">
        <div className="bg-white rounded p-5">
          <img
            src={Logo}
            className="logo my-4"
            alt="stickerstars logo"
            width={100}
          />
          {error && (
            <Alert variant="danger" className="my-4">
              Das hat leider nicht funktioniert. Bitte versuche es später noch
              einmal.
            </Alert>
          )}
          {invitation && (
            <>
              <p className="my-1">
                <b>{invitation.inviter_email}</b> hat dich eingeladen, dem
                Stickerprojekt <b>{invitation.album_title}</b> beizutreten. Um
                fortzufahren, klicke bitte auf den Button und melde dich an oder
                erstelle ein Konto:
              </p>
              <Button
                variant="primary"
                className="my-4 qa-accept-invitation-button"
                href={`/invitations/${token}/confirm?email=${invitation.email}&hint=signup`}
              >
                Einladung annehmen
              </Button>
              <p>
                Nachdem du dich angemeldet hast, erhältst du Zugriff auf Album
                und Sticker und kannst mit der Bearbeitung beginnen.
              </p>
              <p>
                <a
                  href="https://www.stickerstars.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Hier
                </a>{' '}
                findest du weitere Informationen zu STICKERSTARS und eurem
                Projekt.
              </p>
            </>
          )}
        </div>
        <div className="text-center text-secondary my-4">
          <small>
            <a
              href="https://www.stickerstars.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              STICKERSTARS Technologies GmbH
            </a>
            , Wilhelm-Kabus-Str. 42/44, 10829 Berlin, Deutschland
          </small>
        </div>
      </div>
    </div>
  );
}

AcceptInvitation.defaultProps = {};

AcceptInvitation.propTypes = {};

export default AcceptInvitation;
