import { Splide, SplideSlide } from '@splidejs/react-splide';
import isEqual from 'lodash/isEqual';
import { arrayOf } from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import { wizardMobileSplideOptions } from '../../../../../../constants';
import useAnalytics from '../../../../../../containers/app/useAnalytics';
import { replaceColors } from '../../../../../../modules/colorsAndFonts';
import { ColorsShape } from '../../../../../shapes';
import ColorTiles from '../ColorTiles';

import '@splidejs/react-splide/dist/css/splide.min.css';

function ColorsCarousel({ options, value }) {
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  function handleChange(nextColors) {
    dispatch(replaceColors(nextColors));

    analytics.track('Wizard Colors Updated', {
      colorsValue: value,
    });
  }

  return (
    <div className="bg-white pt-4">
      <div className="wizard-styles-panel-mobile__carousels__label text-muted mb-2">
        Farbstil
      </div>
      <Splide
        options={{
          ...wizardMobileSplideOptions,
          fixedWidth: '5rem',
        }}
      >
        {options.map(option => (
          <SplideSlide key={option.join('')}>
            <ColorTiles
              square
              onClick={() => handleChange(option)}
              colors={option?.slice(2)}
              active={isEqual(value, option)}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
}

ColorsCarousel.propTypes = {
  options: arrayOf(ColorsShape).isRequired,
  value: ColorsShape.isRequired,
};

export default ColorsCarousel;
