import {
  arrayOf,
  bool,
  instanceOf,
  number,
  object,
  oneOf,
  oneOfType,
  shape,
  string,
  objectOf,
  func,
} from 'prop-types';
import { EditorState } from 'draft-js';

import { itemTypes, actions, resolutions } from '../constants';
import { pivotNames } from './operations/pivots';

export const IdListShape = arrayOf(string);

export const StickerShape = shape({
  id: string,
  name: string,
  logo: string,
  position: string,
  sectionName: string,
  src: string,
  stickerPlacing: string,
  tags: IdListShape,
  x: number,
  y: number,
});

export const SectionShape = shape({
  id: string,
  name: string,
  stickers: arrayOf(StickerShape),
  type: string,
});

export const TagShape = shape({
  id: string,
  title: string,
});

export const FilterValueShape = shape({
  min: number,
  mid: number,
  max: number,
});

export const FilterValuesShape = shape({
  blue: FilterValueShape,
  con: number,
  green: FilterValueShape,
  hue: number,
  red: FilterValueShape,
  rgb: FilterValueShape,
  sat: number,
  tone: FilterValueShape,
  tone_blue: FilterValueShape,
  tone_green: FilterValueShape,
  tone_red: FilterValueShape,
});

export const FilterPresetShape = shape({
  filter: FilterValuesShape,
  id: oneOfType([string, number]),
  title: string,
});

/* 'fill' props can contains both strings (for local colors, e.g. '#00FF00') or
   numbers (for swatch-colors: index of the color in the store) */
export const FillShape = oneOfType([string, number]);

export const ElementPropsShape = shape({
  id: string.isRequired,
  x: number,
  y: number,
  width: number,
  height: number,
  rotation: number,
  scale: number,
  fill: FillShape,
  sectionId: string,
  symbol: string,
  text: oneOfType([string, object]),
});

export const SpreadPropsShape = shape({
  id: string,
  nodeSiblingCount: number,
  nodeIndex: number,
  sectionId: string,
});

export const ElementTypeShape = oneOf([
  'Root',
  'Circle',
  'Group',
  'Image',
  'Line',
  'Rectangle',
  'Section',
  'Spread',
  'Sticker',
  'StickerCell',
  'Text',
  'Comment',
]);

export const WorkspaceShape = shape({
  nodes: object, // eslint-disable-line react/forbid-prop-types
  root: string,
});

export const PointShape = shape({
  x: number.isRequired,
  y: number.isRequired,
});

export const RectShape = shape({
  x: number.isRequired,
  y: number.isRequired,
  width: number.isRequired,
  height: number.isRequired,
});

export const RefShape = shape({
  current: object, // eslint-disable-line react/forbid-prop-types
});

export const LassoShape = shape({
  position: PointShape.isRequired,
  size: PointShape.isRequired,
});

export const EditorStateShape = instanceOf(EditorState);

export const ImageObjectBlobShape = shape({
  filename: string,
  full: string,
  large: string,
  medium: string,
  original: string,
  small: string,
});

export const ImageObjectShape = shape({
  id: oneOfType([number, string]),
  blob: ImageObjectBlobShape,
  model: string,
  meta: object, // eslint-disable-line react/forbid-prop-types
  details: oneOfType([object, string]),
  dimensions: object, // eslint-disable-line react/forbid-prop-types
  tags: arrayOf(TagShape),
});

export const AlbumStatisticsForPreflightShape = shape({
  inlaySpreadsCount: number.isRequired,
  spreadsNeeded: number.isRequired,
  spine: number.isRequired,
});

export const HotkeyShape = shape({
  key: string,
  ctrlKey: bool,
  shiftKey: bool,
});

export const FileUploadsShape = objectOf(
  shape({
    progress: number,
    fileName: string,
    metaData: object, // eslint-disable-line react/forbid-prop-types
  })
);

export const NodeShape = shape({
  type: ElementTypeShape.isRequired,
  props: ElementPropsShape.isRequired,
  children: IdListShape.isRequired,
});

export const ColorsShape = arrayOf(oneOfType([number, string]));

export const ResolutionShape = oneOf(Object.values(resolutions));

export const StaticResolutionShape = oneOf([
  resolutions.small,
  resolutions.medium,
  resolutions.full,
  resolutions.large,
  resolutions.original,
]);

export const ImageContextShape = shape({
  showStickers: bool.isRequired,
  rendering: bool.isRequired,
  cropPreview: bool.isRequired,
  showGuides: bool.isRequired,
  resolution: ResolutionShape,
  stickerRendering: bool,
});
export const ItemTypeShape = oneOf(Object.keys(itemTypes));

export const ActionShape = oneOf([...Object.keys(actions)]);

export const ApplicableActionsShape = arrayOf(ActionShape);

export const DropdownOptionsShape = arrayOf(
  shape({
    key: oneOfType([number, string]),
    value: string,
    text: string,
  })
);

export const ReactRouterMatchShape = shape({
  isExact: bool,
  params: object.isRequired, // eslint-disable-line react/forbid-prop-types
  path: string.isRequired,
  url: string.isRequired,
});

export const StickerPositionsShape = arrayOf(
  shape({
    x: number,
    y: number,
    id: string,
  })
);

export const BlueprintPreviewShape = shape({
  id: string.isRequired,
  title: string,
  cover: object, // eslint-disable-line react/forbid-prop-types
  colors: arrayOf(string),
});

export const FontsShape = shape({
  title: string,
  sub_title: string,
  paragraph: string,
});

export const BlueprintDataShape = shape({
  id: string,
  workspace: WorkspaceShape,
  stickers: arrayOf(StickerShape),
  colors: ColorsShape,
  images: arrayOf(ImageObjectShape),
  title: string,
  fonts: FontsShape,
  blueprintCategory: string,
});

export const LocalTextsShape = shape({
  line: string,
  line1: string,
  line2: string,
  position: string,
  number: string,
  nextnumber: string,
});

export const CheckoutFormDataShape = shape({
  organization: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  address1: string,
  address2: string,
  zipCode: string,
  city: string,
  countryCode: string,
  noAlbums: string,
  comment: string,
});

export const StylesPanelContextShape = shape({
  colors: ColorsShape,
  fonts: FontsShape,
});

export const StylesPanelStateMachineShape = shape({
  current: object.isRequired, // eslint-disable-line react/forbid-prop-types
  send: func.isRequired,
  color: ColorsShape,
  fonts: FontsShape.isRequired,
  stickerTemplateId: string.isRequired,
  setColors: func.isRequired,
  setFonts: func.isRequired,
  setStickerTemplateId: func.isRequired,
});

export const PivotNameShape = oneOf(Object.keys(pivotNames));

export const AreaShape = shape({
  x: number,
  y: number,
  width: number.isRequired,
  height: number.isRequired,
});

const GuideShape = shape({
  id: string.isRequired,
  type: string.isRequired,
  dimension: string.isRequired,
  value: number.isRequired,
});

const GuidesShape = arrayOf(GuideShape);

export const InitialOperationValuesShape = shape({
  selectedElements: arrayOf(NodeShape).isRequired,
  selectionBounds: AreaShape,
  guides: GuidesShape.isRequired,
  imageSize: AreaShape,
  selectInside: bool.isRequired,
});

export const RawDraftContentStateShape = shape({
  blocks: arrayOf(object), // eslint-disable-line react/forbid-prop-types
  entityMap: objectOf(object), // eslint-disable-line react/forbid-prop-types
});

export const DraftStyleMapShape = objectOf(object);

export const DraftBlockShape = shape({
  getType: func,
  getData: func,
});

export const TextPropsShape = shape({
  font: string.isRequired,
  size: string.isRequired,
  lineHeight: string.isRequired,
  align: string,
  bold: bool,
  italic: bool,
  autosize: bool,
  uppercase: bool,
});

export const LineItemShape = shape({
  id: string,
  title: string,
  product: string,
  quantity: number,
});

export const OrderShape = shape({
  id: string,
  line_items: arrayOf(LineItemShape),
  amount_net_human: string,
  amount_gross_human: string,
  user_invoice: shape({
    status: string.isRequired,
  }),
});

export const ImageIdShape = oneOfType([string, number]);

export const TabShape = shape({
  title: string.isRequired,
  key: string.isRequired,
  image: string.isRequired,
  component: oneOfType([func, object]),
  renderContent: func,
  renderTabIconMeta: func,
});

export const InvoiceShape = shape({
  status: string,
  hosted_invoice_url: string,
});

export const ToolbarNotificationShape = shape({
  position: shape({
    left: oneOfType([number, string]),
    top: oneOfType([number, string]),
  }),
});

export const MetadataShape = shape({ tracking_code: string });

export const OrderUpdateShape = shape({
  id: string.isRequired,
  signal: string.isRequired,
  created_at: string,
  title: string.isRequired,
  message: string,
  metadata: MetadataShape,
});

export const OrderSignalShape = shape({
  title: string.isRequired,
  human_title: string.isRequired,
});
