import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { func, string, bool, number } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Sticker from './Sticker';
import { StickerShape } from '../../shapes';
import useStickerForm from '../SectionPanel/StickerForm/useStickerForm';
import stickerFormRules from '../SectionPanel/StickerForm/stickerFormRules';
import FormValidationErrors from '../../generic/FormValidationErrors';
import { getImage } from '../../../selectors/images';
import { setEditingStickerId } from '../../../modules/controls';

function SidebarSticker({
  placeholder,
  sectionId,
  sticker,
  resolution,
  stickerNumber,
  isStickerUpload,
  ...flippedProps
}) {
  const nodeRef = useRef();
  const dispatch = useDispatch();
  const {
    setStickerFormData,
    stickerFormData: { stickerId: activeStickerId },
  } = useStickerForm();

  const stickerId = sticker?.id;
  const currentEditingStickerId = useSelector(
    state => state.controls.editingStickerId
  );

  const handleSelection = useCallback(() => {
    /**
     * The sticker form state is derived from the `editingStickerId`
     * value in the store, i. e. when we click a sidebar sticker, we push
     * the new ID to the store and open a form.
     *
     * However, we also use the `SidebarSticker` component in our
     * `StickerUploadsModal` where a click should not toggle open the form.
     * What follows is a lazy way to prevent this.
     */
    if (!isStickerUpload && activeStickerId !== currentEditingStickerId) {
      dispatch(setEditingStickerId(activeStickerId));
    }

    setStickerFormData({
      stickerId,
      nodeRef,
      sectionId,
      placeholder,
    });
  }, [
    dispatch,
    activeStickerId,
    currentEditingStickerId,
    isStickerUpload,
    setStickerFormData,
    nodeRef,
    stickerId,
    sectionId,
    placeholder,
  ]);

  useEffect(() => {
    if (activeStickerId === stickerId) {
      handleSelection();
    }
  }, [activeStickerId, stickerId, handleSelection]);

  const slug = sticker?.name?.replace(/ /g, '-').toLowerCase();

  const imageObject = useSelector(state => getImage(state, sticker?.image));

  const formData = useMemo(() => ({ ...sticker, imageObject }), [
    sticker,
    imageObject,
  ]);

  return (
    <div className="sidebar-sticker" {...flippedProps}>
      {!placeholder && (
        <FormValidationErrors rules={stickerFormRules} formData={formData} />
      )}

      <div
        className={`sticker-container m-1 shadow ${
          sticker?.doubleSticker ? ' double' : ''
        } qa-sticker-${slug} qa-sticker-${sticker?.id}${
          !placeholder ? ' qa-sticker-item' : ''
        } ${activeStickerId === stickerId ? 'active' : ''}`}
        ref={nodeRef}
        onClick={handleSelection}
        role="presentation"
        style={{
          transform: placeholder ? 'scale(0.8)' : undefined,
          opacity: placeholder ? 0.5 : 1,
        }}
      >
        <Sticker
          {...sticker}
          placeholder={placeholder}
          resolution={resolution}
          stickerNumber={stickerNumber}
        />
      </div>
    </div>
  );
}

SidebarSticker.defaultProps = {
  placeholder: false,
  sectionId: null,
  onClick: () => {},
  selected: false,
  sticker: null,
  resolution: undefined,
  stickerNumber: null,
  isStickerUpload: false,
};

SidebarSticker.propTypes = {
  sectionId: string,
  placeholder: bool,
  onClick: func,
  selected: bool,
  sticker: StickerShape,
  resolution: string,
  stickerNumber: number,
  isStickerUpload: bool,
};

export default SidebarSticker;
