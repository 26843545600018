import { func, number } from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import BPagination from 'react-bootstrap/Pagination';

function Pagination({ active, total, onChange }) {
  const history = useHistory();
  const location = useLocation();

  function handleChange(nextPage) {
    if (onChange) {
      onChange(nextPage);
    } else {
      history.push(`${location.pathname}?page=${nextPage}`);
    }
  }

  return (
    <BPagination className="mt-4 d-flex justify-content-center qa-pagination">
      {active > 1 && (
        <>
          <BPagination.First
            className="qa-go-to-first-page"
            onClick={() => handleChange(1)}
          />
          <BPagination.Prev onClick={() => handleChange(active - 1)} />
        </>
      )}
      {[
        ...new Array(total).fill(0).map((_, idx) => (
          <BPagination.Item
            key={`page-${idx + 1}`}
            active={active === idx + 1}
            className={`qa-page qa-page-${idx + 1}`}
            onClick={() => handleChange(idx + 1)}
          >
            {idx + 1}
          </BPagination.Item>
        )),
      ]}
      {active < total && (
        <>
          <BPagination.Next onClick={() => handleChange(active + 1)} />
          <BPagination.Last onClick={() => handleChange(total)} />
        </>
      )}
    </BPagination>
  );
}

Pagination.defaultProps = {
  onChange: null,
};

Pagination.propTypes = {
  active: number.isRequired,
  total: number.isRequired,
  onChange: func,
};

export default Pagination;
