/* eslint-disable no-console */

export const CREATE = 'sticker/CREATE';
export const UPDATE = 'sticker/UPDATE';
export const UPDATES_BY_OBJECT = 'sticker/UPDATES_BY_OBJECT';
export const DELETE = 'sticker/DELETE';
export const MOVE = 'sticker/MOVE';
export const REPLACE = 'sticker/REPLACE';
export const RESET = 'sticker/RESET';

const initialState = [];

/* The default value for action is needed because `redux-live-undo` calls 
   reducers without any parameters initially. */
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case CREATE: {
      if (!action.payload.id) {
        console.error('id not set during create');
        return state;
      }

      return [...state, action.payload];
    }

    case UPDATE: {
      const updateIndex = state.findIndex(item => item.id === action.id);
      if (updateIndex === -1) {
        console.error(
          'id not found during update!: action.id',
          action.payload.id
        );
        return state;
      }
      const nextItem = { ...state[updateIndex], ...action.payload };
      return [
        ...state.slice(0, updateIndex),
        nextItem,
        ...state.slice(updateIndex + 1),
      ];
    }

    case UPDATES_BY_OBJECT: {
      const { deltas } = action;
      const deltaIds = Object.keys(deltas);
      return state.map(sticker => {
        if (deltaIds.indexOf(sticker.id) !== -1) {
          return { ...sticker, ...deltas[sticker.id] };
        }
        return sticker;
      });
    }

    case DELETE: {
      const deleteIndex = state.findIndex(item => item.id === action.id);
      if (deleteIndex === -1) {
        console.error('id not found during delete');
        return state;
      }
      return [...state.slice(0, deleteIndex), ...state.slice(deleteIndex + 1)];
    }

    case REPLACE:
      return action.payload;

    case RESET:
      return initialState;

    default:
      return state;
  }
};
