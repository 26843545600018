import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import { bool, func, string } from 'prop-types';
import { format } from 'date-fns';

import OrderCommentForm from './OrderCommentForm';
import useQuery from '../../../../hooks/useQuery';
import Spinner from '../../../../components/generic/Spinner';
import { useConfirmation } from '../../../../components/ui/ConfirmModal/ConfirmationService';
import useApi from '../../../../hooks/useApi';
import useNotifications from '../../../../hooks/useNotifications';
import Icon from '../../../../components/Icon';

export function OrderComment({
  id,
  order_id: orderId,
  completed,
  content,
  email,
  created_at: createdAt,
  refetchComments,
}) {
  const formattedDate = format(new Date(createdAt), 'dd.M.yyyy');
  const confirm = useConfirmation();
  const api = useApi();
  const { createError } = useNotifications();

  async function toggleComplete() {
    try {
      await api.patch(`/orders/${orderId}/comments/${id}`, {
        completed: !completed,
      });
      refetchComments();
    } catch (err) {
      createError(`Fehler beim Aktualisieren: ${err.toString()}`);
    }
  }

  async function handleDestroy() {
    try {
      await confirm({
        body: <>Kommentar wirklich löschen?</>,
      });
    } catch (_err) {
      return;
    }

    try {
      await api.delete(`/orders/${orderId}/comments/${id}`);
      refetchComments();
    } catch (err) {
      createError(`Fehler beim Löschen: ${err.toString()}`);
    }
  }

  return (
    <Card
      className={`order-comment qa-order-comment ${
        completed ? 'text-muted qa-completed-order-comment' : ''
      }`}
    >
      <Card.Header className="d-flex align-items-center justify-content-between">
        <Badge>{email}</Badge>
        <Badge className="text-muted">{formattedDate}</Badge>
        <Button
          className="qa-destroy-order-comment"
          variant="danger"
          size="sm"
          onClick={handleDestroy}
        >
          <Icon name="trash" />
        </Button>
      </Card.Header>
      <Card.Body>
        <Card.Text className="qa-order-comment-content">{content}</Card.Text>
        <Form.Group className="w-100 text-right">
          <Form.Check
            className="qa-complete-order-comment"
            checked={completed}
            onChange={toggleComplete}
            type="checkbox"
            label="abgeschlossen?"
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
}

OrderComment.propTypes = {
  id: string.isRequired,
  order_id: string.isRequired,
  completed: bool.isRequired,
  content: string.isRequired,
  created_at: string.isRequired,
  email: string.isRequired,
  refetchComments: func.isRequired,
};

function OrderComments({ orderId }) {
  const {
    data: orderComments,
    error,
    isLoading,
    refetch,
  } = useQuery(`/orders/${orderId}/comments`, [orderId]);

  if (!orderComments || isLoading) {
    return <Spinner />;
  }

  return (
    <div className="qa-order-comments">
      {error && (
        <Alert variant="danger">
          Kommentare konnten nicht geladen werden, bitte lade die Seite neu.
        </Alert>
      )}

      {orderComments.map(comment => (
        <OrderComment key={comment.id} refetchComments={refetch} {...comment} />
      ))}

      <OrderCommentForm orderId={orderId} refetchComments={refetch} />
    </div>
  );
}

OrderComments.propTypes = {
  orderId: string.isRequired,
};

export default OrderComments;
