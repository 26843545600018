import { connect } from 'react-redux';

import { operationEnd, operationStart } from '../../actions/operations';
import { fitContentCover, updateElements } from '../../actions/workspace';
import { updateGuides } from '../../modules/smartGuides';
import { getAnimatedZoom } from '../../selectors/viewport';
import {
  getOperationInitialValues,
  getOperationMoveNodeId,
} from '../../selectors/operations';

const mapStateToProps = state => {
  const props = {
    zoom: getAnimatedZoom(state),
  };
  const operationMoveNodeId = getOperationMoveNodeId(state);
  if (operationMoveNodeId) {
    return props;
  }

  return {
    ...props,
    initialValues: getOperationInitialValues(state),
  };
};

const mapDispatchToProps = {
  operationStart,
  updateElements,
  operationEnd,
  updateGuides,
  fitContentCover,
};

const withOperationProps = Component =>
  connect(mapStateToProps, mapDispatchToProps)(Component);

export default withOperationProps;
