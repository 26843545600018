import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import AppLayout from '../../../components/ui/AppLayout';
import OrdersList from '../OrdersList';

function OrdersIndex() {
  return (
    <AppLayout>
      <Row className="my-5">
        <Col sm={12} md={6} lg={6}>
          <h1 className="font-weight-bold">Bestellungen</h1>
          <p>
            Hier findest du deine vergangenen Bestellungen in der Übersicht.
          </p>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12}>
          <OrdersList />
        </Col>
      </Row>
    </AppLayout>
  );
}

export default OrdersIndex;
