import assert from 'assert';

import flow from 'lodash/flow';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

import recursiveMap from './recursiveMap';
import { resetStickerCellStickerIds, uniquifyIds } from './spreads';
import { dimensions } from '../constants';

function flatElementTree(element, list = [], parent = null) {
  let children;
  if (Array.isArray(element.children)) {
    list.push(
      ...element.children.reduce(
        (acc, child) => flatElementTree(child, acc, element.props.id),
        []
      )
    );
    children = element.children.map(child => child.props.id);
  } else {
    children = [];
  }
  list.push({ ...element, children, parent });
  return list;
}

export function normalizeElement(element) {
  const nodes = flatElementTree(element).reduce((acc, node) => {
    acc[node.props.id] = node;
    return acc;
  }, {});

  return {
    nodes,
    root: element.props.id,
  };
}

// Converts a spreads/elements-structure into a normalized workspace
export function buildWorkspace(spreads) {
  const rootElement = {
    type: 'Root',
    props: { id: 'root' },
    children: spreads,
  };
  return normalizeElement(rootElement);
}

export function denormalizeElement({ nodes, root }) {
  assert(!!root, 'The key for the root node is missing');

  // Freeze the given `nodes` to avoid accidental mutations
  const frozenNodes = Object.freeze(nodes);

  function buildElement(id) {
    const node = frozenNodes[id];
    return {
      ...node,
      children: node.children.map(childId => buildElement(childId)),
    };
  }
  return buildElement(root);
}

// Converts a normalized workspace-structure into a tree (it returns only the children)
export function denormalizeWorkspace({ nodes, root }) {
  return denormalizeElement({ nodes, root }).children;
}

/** Set a workspace's root key to `root`. */
export function setRoot(workspace) {
  return {
    root: 'root',
    nodes: omit(
      {
        ...mapValues(workspace.nodes, node => ({
          ...node,
          parent: node.parent === workspace.root ? 'root' : node.parent,
        })),
        root: {
          ...workspace.nodes[workspace.root],
          props: {
            ...workspace.nodes[workspace.root].props,
            id: 'root',
          },
        },
      },
      workspace.root
    ),
  };
}

export function cloneElement(id, nodes) {
  return flow([denormalizeElement, uniquifyIds, resetStickerCellStickerIds])({
    nodes,
    root: id,
  });
}

export const makeMapPropsToDeltaMap = elements => applyDelta => {
  return elements.reduce((acc, cur) => {
    acc[cur.props.id] = applyDelta(cur.props);
    return acc;
  }, {});
};

export const mergeUserId = (elements, userId) =>
  elements.map(nestedElement => {
    return recursiveMap(nestedElement, element => ({
      ...element,
      props: { ...element.props, userId },
    }));
  });

/**
 * For _some_ node types – sticker cells and comments – we need a hierarchical (global) order,
 * determined by the position of their parent spread and x/y coordinates within their parent spread.
 *
 * `reduceSortParams` recursively entangles all workspace nodes and returns sort params objects (`x`, `y`, `pageIndex`)
 * by node ID for all comment and sticker cell nodes. Subsetting/ordering is left to the consumers.
 *
 * @param {Object} nodes - Normalized workspace to be sorted
 * @param {Array.<string>} ids - Node IDs for first (or next) level elements to be (recursively) sorted
 * @param {DOMMatrix} parentMatrix - Matrix used to translate points to x/y coords in spread space
 * @param {Number} spreadIndex - Current (parent) spread index, used to calculate returned `pageIndex`
 * @returns {{ [id]: { x: Number, y: Number, pageIndex: Number } }}
 */
export function reduceSortParams(nodes, ids, parentMatrix, spreadIndex = null) {
  return ids.reduce((sortParams, id, index) => {
    const { type, props, children } = nodes[id];

    // We basically only update the `spreadIndex` counter here, later to be translated to a sortable `pageIndex`.
    if (type === 'Spread') {
      return {
        ...sortParams,
        ...reduceSortParams(nodes, children, parentMatrix, index),
      };
    }

    // Nodes inside groups have x/y coordinates in *group* space. We translate these to *spread* space here.
    if (type === 'Group') {
      const groupMatrix = parentMatrix.translate(props.x, props.y);
      return {
        ...sortParams,
        ...reduceSortParams(nodes, children, groupMatrix, spreadIndex),
      };
    }

    // Return sort params for comment and sticker cell nodes
    if (['Comment', 'StickerCell'].includes(type)) {
      const { x, y } = parentMatrix.transformPoint(props);
      const pageIndex = spreadIndex * 2 + (x < dimensions.pageWidth ? 0 : 1);

      return { ...sortParams, [id]: { x, y, pageIndex } };
    }

    return sortParams;
  }, {});
}

/**
 * Applies `sortParams` returned by `reduceSortParams` to an array of workspace nodes
 * and returns the initial nodes array in correct global order, as determined by their
 * relative parent spread index and x/y position (in spread space).
 *
 * Returns an ordered list.
 */
export function orderNodesBySortParams(nodes, sortParams) {
  return nodes.sort((nodeA, nodeB) => {
    const a = sortParams[nodeA.props.id];
    const b = sortParams[nodeB.props.id];

    // A lower pageIndex always results in a lower index
    if (a.pageIndex !== b.pageIndex) {
      return a.pageIndex - b.pageIndex;
    }

    // We define all sortable elements within a buffer-y range as in one "row",
    // lower x value then results in a lower index.
    // The constant is pretty magic for now and might need some tweaking.
    const rowYBuffer = 0.75 * dimensions.stickerHeight;
    if (Math.abs(a.y - b.y) < rowYBuffer) {
      return a.x - b.x;
    }

    // If on the same page, but in different "rows", a higher y value
    // results in a lower index.
    return a.y - b.y;
  });
}
