import {
  SET_STICKER_UPLOADS,
  SET_MERGED_AT,
  DELETE,
  RESET,
} from '../actions/stickerUploads';

const initialState = [];

export default (state = initialState, action) => {
  const { type } = action;

  switch (type) {
    case SET_STICKER_UPLOADS: {
      return action.payload;
    }
    case DELETE: {
      const { ids } = action.payload;
      return state.filter(({ id }) => !ids.includes(id));
    }
    case SET_MERGED_AT: {
      const { ids, mergedAt } = action.payload;

      return state.map(stickerUpload => ({
        ...stickerUpload,
        merged_at: ids.includes(stickerUpload.id)
          ? mergedAt
          : stickerUpload.merged_at,
      }));
    }
    case RESET: {
      return initialState;
    }
    default:
      return state;
  }
};
