import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import OrderContent from './OrderContent';
import AppLayout from '../../../components/ui/AppLayout';

function OrderDetails() {
  return (
    <AppLayout>
      <Row className="my-5">
        <Col sm={12} md={6} lg={6}>
          <h1 className="font-weight-bold">Bestelldetails</h1>
          <p>Hier findest du alle Informationen zu deinem Auftrag.</p>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <OrderContent />
        </Col>
      </Row>
    </AppLayout>
  );
}

OrderDetails.propTypes = {};

export default OrderDetails;
