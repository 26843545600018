import React, { useEffect, useState } from 'react';
import { func } from 'prop-types';
import { usePopper } from 'react-popper';
import { useDispatch } from 'react-redux';

import { StickerShape, RefShape } from '../../../shapes';
import StickerForm from './StickerForm';
import useStickerForm from './useStickerForm';
import useScreenSize from '../../../../hooks/useScreenSize';
import { setMobileFooterOpen } from '../../../../modules/controls';

function StickerFormPopup({ sidebarRef, onSubmit, onClose, sticker }) {
  const dispatch = useDispatch();
  const [ref, setRef] = useState();
  const {
    stickerFormData: { nodeRef },
    selectedSticker,
  } = useStickerForm();

  const { isMobile } = useScreenSize();

  const hasImage = !!selectedSticker?.image;
  const positionOnSidebar = hasImage;

  const { styles, attributes } = usePopper(
    positionOnSidebar ? sidebarRef.current : nodeRef.current,
    ref,
    {
      placement: positionOnSidebar ? 'right' : 'bottom',
      strategy: 'fixed',
    }
  );

  useEffect(() => {
    dispatch(setMobileFooterOpen(false));

    return () => {
      dispatch(setMobileFooterOpen(isMobile));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isMobile) {
    return (
      <div className="sticker-form-mobile">
        <StickerForm
          onSubmit={onSubmit}
          onClose={onClose}
          autoSubmit={false}
          initialSticker={sticker}
          isExistingSticker={!!sticker}
          deleteButton
        />
      </div>
    );
  }

  return (
    <div
      className="sticker-form-popup shadow-lg position-fixed rounded popover border-0"
      ref={setRef}
      style={styles.popper}
      {...attributes.popper}
    >
      <StickerForm
        onSubmit={onSubmit}
        onClose={onClose}
        autoSubmit={!hasImage}
        initialSticker={sticker}
        isExistingSticker={!!sticker}
      />
    </div>
  );
}

StickerFormPopup.defaultProps = {
  sticker: null,
};

StickerFormPopup.propTypes = {
  onSubmit: func.isRequired,
  onClose: func.isRequired,
  sidebarRef: RefShape.isRequired,
  sticker: StickerShape,
};

export default StickerFormPopup;
