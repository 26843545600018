import { bool, node, number, string } from 'prop-types';
import React, { useContext } from 'react';

import { colorValues, dimensions } from '../../../../constants';
import useScreenSize from '../../../../hooks/useScreenSize';
import { ImageContext } from '../../../ImageContext';
import InsertSpreadButton from '../../../menu/InsertSpreadButton';
import { NodeShape, PointShape } from '../../../shapes';
import SmartGuides from '../../SmartGuides';
import UnzoomedContent from '../../UnzoomedContent';
import Spread3DEffect from './Spread3DEffect';
import SpreadGuides from './SpreadGuides';
import SpreadMeta from './SpreadMeta';
import SpreadShadow from './SpreadShadow';
import DangerZone from './DangerZone';

function calculateBleedOffset({ isFrontCover, cropPreview, spine }) {
  const { pageBleed } = dimensions;
  const optionalPageBleed = cropPreview ? 0 : pageBleed;
  return {
    x: isFrontCover ? -spine : -optionalPageBleed,
    y: -optionalPageBleed,
  };
}

export function calculateSpreadRect({
  spreadIndex,
  spreadCount,
  cropPreview = false,
  spine = 0,
}) {
  const { pagePadding, pageWidth, pageHeight, pageBleed } = dimensions;

  const isFrontCover = spreadIndex === 0;
  const isBackCover = spreadIndex === spreadCount - 1;
  const isCover = isFrontCover || isBackCover;

  const optionalPageBleed = cropPreview ? 0 : pageBleed;

  return {
    x: spreadIndex === 0 ? pageWidth : 0,
    y: (pageHeight + pagePadding) * spreadIndex,
    width:
      (isCover ? pageWidth : pageWidth * 2) +
      optionalPageBleed * (isFrontCover ? 1 : 2) +
      (isFrontCover ? spine : 0),
    height: pageHeight + optionalPageBleed * 2,
  };
}

export function Spread(props) {
  const {
    id,
    children,
    nodeIndex,
    nodeSiblingCount,
    spine,
    renderingSpreadPosition,
    preview,
    visible,
    sectionNode,
    isActiveSpread,
    operationActive,
    ...rest
  } = props;

  const flippedProps = {
    'data-flip-config': rest['data-flip-config'],
    'data-flip-id': rest['data-flip-id'],
    'data-portal-key': rest['data-portal-key'],
  };

  const { rendering, cropPreview, showGuides } = useContext(ImageContext);
  const { isMobile } = useScreenSize();

  if (!preview && !visible && !rendering) {
    return null;
  }

  const isFrontCover = nodeIndex === 0;
  const isBackCover = nodeIndex === nodeSiblingCount - 1;
  const isCover = isFrontCover || isBackCover;
  const { pageWidth } = dimensions;

  /**
   * The `area` of the spread is only relevant during editing, as it affects
   * only "decorative" elements (like shadow) and the position of UI elements.
   */
  const area = calculateSpreadRect({
    spreadIndex: nodeIndex,
    spreadCount: nodeSiblingCount,
    cropPreview,
    spine,
  });

  /**
   * The `position` is used for two different purposes:
   * - during rendering, it is used to move the spread-contents within the
   *   crop-area, allowing to compose e.g. covers-pages from multiple
   *   different spreads next to each other
   * - during editing, the spreads are displayed vertically, each with a
   *   position related to their index
   */
  const position = renderingSpreadPosition || area;

  /**
   * Since we are including the bleed area by default, the "decorative"
   * elements need to be moved accordingly.
   */
  const offset = calculateBleedOffset({ isFrontCover, cropPreview, spine });

  return (
    <>
      <g
        id={id}
        data-id={id}
        transform={`translate(${position.x},${position.y})`}
        className={`spread container qa-spread-root qa-spread-${nodeIndex}`}
        {...flippedProps}
      >
        {!rendering && (
          <>
            <SpreadShadow
              x={offset.x}
              y={offset.y}
              width={area.width}
              height={area.height}
              showSpreadOutline={isActiveSpread}
            />
            <rect
              className="qa-spread-rect"
              x={offset.x}
              y={offset.y}
              width={area.width}
              height={area.height}
              fill={colorValues.spread}
            />
            <rect
              className="spread-grid"
              x={offset.x}
              y={offset.y}
              width={area.width}
              height={area.height}
              fill="url(#grid)"
            />
          </>
        )}

        {children}

        {!isCover && isActiveSpread && <DangerZone />}

        {!rendering && (
          <>
            {showGuides && (
              <SpreadGuides
                x={0}
                y={0}
                height={area.height}
                width={area.width}
                spine={isFrontCover ? spine : null}
                isFrontCover={isFrontCover}
                isBackCover={isBackCover}
              />
            )}
            {!isCover && !showGuides && (
              <Spread3DEffect x={pageWidth} y={offset.y} height={area.height} />
            )}
            {!preview && (
              <>
                <UnzoomedContent
                  x={offset.x}
                  y={offset.y}
                  width={area.width}
                  hideBelowZoom={isMobile ? 0.75 : 1}
                >
                  <SpreadMeta
                    spreadId={id}
                    spreadIndex={nodeIndex}
                    sectionNode={sectionNode}
                  />
                </UnzoomedContent>
                {/* Disable the add spread button for static sections */}
                {!sectionNode.props.static && (
                  <UnzoomedContent
                    as="g"
                    x={zoom => (area.width - 20 / zoom) / 2}
                    y={area.height + 4}
                    hideBelowZoom={isMobile ? 0.5 : 1}
                  >
                    <InsertSpreadButton
                      spreadIndex={nodeIndex}
                      indexInSection={sectionNode.children.indexOf(id)}
                      sectionId={sectionNode.props.id}
                    />
                  </UnzoomedContent>
                )}
                {isActiveSpread && operationActive && (
                  <SmartGuides isCover={isCover} />
                )}
              </>
            )}
          </>
        )}
      </g>
    </>
  );
}

Spread.defaultProps = {
  spine: 0,
  children: null,
  renderingSpreadPosition: null,
  preview: false,
  visible: true,
};

Spread.propTypes = {
  // React-element children, not ids
  children: node,

  // from Node
  id: string.isRequired,
  nodeIndex: number.isRequired,
  nodeSiblingCount: number.isRequired,
  preview: bool,

  // mapStateToProps
  spine: number,
  visible: bool,

  // From Root
  sectionNode: NodeShape.isRequired,

  // from SpreadContainerRenderer
  renderingSpreadPosition: PointShape,

  isActiveSpread: bool.isRequired,
  operationActive: bool.isRequired,
};

export default Spread;
