import React, { memo } from 'react';
import { arrayOf, func, oneOfType, string } from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Icon from '../Icon';
import { CheckoutFormDataShape, StickerShape } from '../shapes';

export const levelError = 'errors';
export const levelWarning = 'warnings';

export function validate(rules, formData) {
  return rules.reduce(
    (acc, [predicate, message, level = levelError]) => {
      acc[level] = predicate(formData) ? [...acc[level], message] : acc[level];
      return acc;
    },
    { errors: [], warnings: [] }
  );
}

export function isValid(rules, formData) {
  const { errors } = validate(rules, formData);
  return errors.length === 0;
}

export function filterValidItems(rules, items) {
  return items.filter(item => isValid(rules, item));
}

function FormValidationErrors({ rules, formData }) {
  const { warnings, errors } = validate(rules, formData);

  const hasWarning = warnings.length > 0;
  const hasError = errors.length > 0;
  if (!hasWarning && !hasError) {
    return null;
  }

  const hasOnlyWarning = hasWarning && !hasError;

  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip>
          {[...errors, ...warnings].map(error => (
            <div key={error}>{error}</div>
          ))}
        </Tooltip>
      }
    >
      <Icon
        className={`${
          hasOnlyWarning ? 'text-warning' : 'text-danger'
        } qa-error-icon`}
        name="exclamation_triangle"
      />
    </OverlayTrigger>
  );
}

FormValidationErrors.propTypes = {
  rules: arrayOf(arrayOf(oneOfType([func, string]))).isRequired,
  formData: oneOfType([StickerShape, CheckoutFormDataShape]).isRequired,
};

export default memo(FormValidationErrors);
