import { useWindowSize } from 'react-use';

import { minimumSupportedWindowWidth } from '../constants';

function useScreenSize() {
  const windowSize = useWindowSize();
  const isMobile = windowSize.width < minimumSupportedWindowWidth;

  return { isMobile, isDesktop: !isMobile, clientWidth: windowSize };
}

export default useScreenSize;
