import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'react-bootstrap/Toast';
import formatDistance from 'date-fns/formatDistance';

import { closeNotification } from '../../../actions/notifications';
import { selectNotifications } from '../../../selectors/notifications';
import { types } from '../../../modules/notifications';

import './Notifications.scss';

const typeToHeader = {
  [types.warning]: 'Warnung',
  [types.error]: 'Fehler',
  [types.info]: 'Info',
};

/**
 * This component will list notifications (errors, failed requests, later: external sticker creations, ...)
 * as bootstrap toasts in the top right corner of our app.
 */
function Notifications() {
  const notifications = useSelector(selectNotifications);
  const dispatch = useDispatch();

  function handleClose(id) {
    dispatch(closeNotification(id));
  }

  return (
    <div className="notifications">
      {notifications.map(({ id, timestamp, message, type }) => (
        <Toast
          onClose={() => handleClose(id)}
          key={id}
          autohide
          delay={1000 * 10}
          className={`toast qa-notification-${id}`}
        >
          <Toast.Header>
            <strong className="mr-auto">{typeToHeader[type]}</strong>
            <small>{formatDistance(new Date(timestamp), Date.now())}</small>
          </Toast.Header>
          <Toast.Body>{message}</Toast.Body>
        </Toast>
      ))}
    </div>
  );
}

export default Notifications;
