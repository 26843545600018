import React from 'react';
import { useSelector } from 'react-redux';

import { dimensions } from '../../../constants';
import { getGridEnabled } from '../../../selectors/controls';
import useViewport from './useViewport';

export const zoomThresholdNoGrid = 1;
export const zoomThresholdCoarseGrid = 2;

function GridPattern() {
  const gridEnabled = useSelector(getGridEnabled);
  const { zoom } = useViewport();

  /**
   * Don't show the grid when too zoomed out.
   *
   * Apparently, safari will break when the `grid` id is
   * missing, so we're returning an empty pattern here.
   */
  if (!gridEnabled || zoom < zoomThresholdNoGrid) {
    return <pattern id="grid" />;
  }

  /**
   * Set the grid size based on the zoom:
   * draw every grid line when zoomed in
   * draw every 2nd grid line when zoomed out
   */
  const gridSize =
    dimensions.gridSize * (zoom > zoomThresholdCoarseGrid ? 1 : 2);

  return (
    <pattern
      id="grid"
      width={gridSize}
      height={gridSize}
      patternUnits="userSpaceOnUse"
    >
      <rect
        fill="none"
        stroke="#000"
        strokeWidth={0.25 / zoom}
        strokeOpacity="0.25"
        width={gridSize}
        height={gridSize}
      />
    </pattern>
  );
}

export default GridPattern;
