import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { bool, func } from 'prop-types';

import useAnalytics from './useAnalytics';

function DesktopOnlyWarning({ show, onClose }) {
  const [linkSent, setLinkSent] = useState(false);
  const analytics = useAnalytics();

  function handleClick() {
    const { href } = window.location;

    analytics.track('Desktop Link Requested', {
      href,
    });
    setLinkSent(true);
  }

  return (
    <Modal
      className="desktop-modal"
      centered
      show={show}
      keyboard={false}
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title>Fenster zu klein?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Ein tolles Album sieht auf einem größeren Bildschirm gleich noch viel
          besser aus.
        </p>
        <p>
          Wechsle lieber an deinen <b>Laptop/PC</b> und gestalte dort weiter! Du
          wirst überrascht sein, was alles möglich ist.
        </p>
        <p>
          Klick auf den Button{' '}
          <span aria-label="Point down" role="img">
            👇
          </span>{' '}
          und wir schicken dir sofort eine Mail, damit du an deinem anderen
          Gerät weitermachen kannst!
        </p>
        {!linkSent ? (
          <Button
            className="qa-request-link"
            variant="primary"
            block
            onClick={handleClick}
          >
            Link zuschicken
          </Button>
        ) : (
          <Alert className="border">
            Alles klar, check deinen Posteingang!
          </Alert>
        )}
        <Button
          onClick={onClose}
          variant="link"
          block
          className="mt-2 qa-close-modal"
        >
          zurück
        </Button>
      </Modal.Body>
    </Modal>
  );
}

DesktopOnlyWarning.propTypes = {
  onClose: func.isRequired,
  show: bool.isRequired,
};

export default DesktopOnlyWarning;
