import { func, number, oneOfType, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { makeGetColorByIndexOrValue } from '../../../../selectors/colorsAndFonts';
import ColorDropdown from '../ColorDropdown';
import withInputProps from './withInputProps';

const colorPreviewRectSize = 20;

function FillInput({ onChange, fill, ...rest }) {
  const getColorByIndexOrValue = makeGetColorByIndexOrValue();
  const fillValue = useSelector(state => getColorByIndexOrValue(state, fill));

  function handleChange(color) {
    onChange({ fill: color });
  }

  return (
    <ColorDropdown
      {...rest}
      color={fill}
      onChange={handleChange}
      fieldName="fill"
      tooltip="Füllung"
      allowNull
    >
      <span className="preview shadow-sm">
        <svg width={colorPreviewRectSize} height={colorPreviewRectSize}>
          <rect
            x={1}
            y={1}
            width={colorPreviewRectSize - 2}
            height={colorPreviewRectSize - 2}
            fill={fillValue}
          />
        </svg>
      </span>
    </ColorDropdown>
  );
}

FillInput.defaultProps = {
  fill: null,
};

FillInput.propTypes = {
  onChange: func.isRequired,
  fill: oneOfType([string, number]),
};

export default withInputProps(FillInput);
