import React from 'react';

import FadeInWhenAlbumLoaded from '../../components/generic/FadeInWhenAlbumLoaded';
import OrderButton from './OrderButton';
import UserMenu from './UserMenu';
import TutorialButton from './TutorialButton';

function AppMetaMenu() {
  return (
    <div className="app-meta-menu qa-app-meta-menu">
      <FadeInWhenAlbumLoaded>
        <div>
          <OrderButton />
        </div>
      </FadeInWhenAlbumLoaded>
      <TutorialButton />
      <UserMenu />
    </div>
  );
}

export default AppMetaMenu;
