import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useKeyPress, useEvent } from 'react-use';

import { updateControls } from '../../../modules/controls';
import { getOperationActive } from '../../../selectors/controls';
import useViewport from './useViewport';

export default function useSpacebarPan() {
  const { pan, zoom, setPan, viewportRef, restrictPanAndZoom } = useViewport();
  const [spacebarPressed] = useKeyPress(event => event.key === ' ');
  const [mouseDownPan, setMouseDownPan] = useState(null);
  const [mouseDownPointer, setMouseDownPointer] = useState(null);
  const spacebarPanActive = !!mouseDownPointer;
  const dispatch = useDispatch();
  const operationActive = useSelector(getOperationActive);

  useEffect(() => {
    dispatch(updateControls({ spacebarPressed }));
  }, [dispatch, spacebarPressed]);

  const handleMouseDown = ({ clientX, clientY, button }) => {
    // Return early if not left click
    if (button !== 0) {
      return;
    }
    setMouseDownPan(pan);
    setMouseDownPointer({ clientX, clientY });
  };

  const handleMouseMove = ({ clientX, clientY }) => {
    if (!mouseDownPan || !mouseDownPointer) {
      return;
    }
    const nextPan = {
      x: mouseDownPan.x + (mouseDownPointer.clientX - clientX) / zoom,
      y: mouseDownPan.y + (mouseDownPointer.clientY - clientY) / zoom,
    };

    const [finalPan] = restrictPanAndZoom(nextPan, zoom);
    setPan(finalPan);
  };

  const handleMouseUp = () => {
    setMouseDownPan(null);
    setMouseDownPointer(null);
  };

  useEvent(
    'mousedown',
    spacebarPressed && !operationActive && handleMouseDown,
    viewportRef.current
  );
  useEvent('mousemove', mouseDownPointer && handleMouseMove);
  useEvent('mouseup', mouseDownPointer && handleMouseUp);

  return {
    spacebarPanActive,
    spacebarPressed,
  };
}
