import { string } from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

import Icon from '../../../components/Icon';
import useApi from '../../../hooks/useApi';
import useLoading from '../../../hooks/useLoading';
import useNotifications from '../../../hooks/useNotifications';

function DeliveryNoteDownload({ orderId }) {
  const { loadWhile, isLoading } = useLoading('delivery-note');
  const api = useApi();
  const { createError } = useNotifications();

  async function downloadDeliveryNote() {
    loadWhile(async () => {
      try {
        const {
          data: { pdf_url: url },
        } = await api.get(`/orders/${orderId}/delivery_note`);
        window.open(url);
      } catch (err) {
        createError(err.toString());
      }
    });
  }

  return (
    <Button
      disabled={isLoading}
      onClick={downloadDeliveryNote}
      variant="outline-primary"
      className="qa-download-delivery-note"
      size="sm"
    >
      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
          className="qa-loading"
        />
      ) : (
        <>
          <Icon className="d-inline-block mr-2" name="list" /> Lieferschein
        </>
      )}
    </Button>
  );
}

DeliveryNoteDownload.propTypes = {
  orderId: string.isRequired,
};

export default DeliveryNoteDownload;
