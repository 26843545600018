import React from 'react';
import { Helmet } from 'react-helmet-async';

export default function MetaTags() {
  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <link rel="shortcut icon" href="/favicon.ico" />
      <title>Mein Album - Stickerstars Designer</title>
      <meta
        name="description"
        content="Mit dem Stickerstars-Designer gestaltest du dein Stickeralbum spielend. Entfache das Sammelfieber!"
      />
      <meta property="og:title" content="Mein Album - Stickerstars Designer" />
      <meta property="og:site_name" content="Stickerstars Designer" />
      <meta property="og:url" content="https://designer.stickerstars.de/" />
      <meta
        property="og:description"
        content="Mit dem Stickerstars-Designer gestaltest du dein Stickeralbum spielend. Entfache das Sammelfieber!"
      />
      <meta property="og:type" content="website" />
      <meta
        property="og:image"
        content={`${process.env.PUBLIC_URL}/assets/images/designer.png`}
      />
    </Helmet>
  );
}
