import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateSelectedElements } from '../../../../actions/workspace';
import { getCommonIdenticalProps } from '../../../../selectors/selection';

const withInputProps = WrappedComponent => props => {
  const dispatch = useDispatch();
  const commonIdenticalProps = useSelector(getCommonIdenticalProps);

  function onChange(delta) {
    dispatch(updateSelectedElements(delta));
  }

  return (
    <WrappedComponent
      {...commonIdenticalProps}
      {...props}
      onChange={onChange}
    />
  );
};

export default withInputProps;
