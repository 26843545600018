import React from 'react';

import useViewport from '../useViewport';
import ViewportScrollbar from './ViewportScrollbar';

function ViewportScrollbars() {
  const {
    pan,
    zoom,
    limits,
    setPan,
    clientWidth,
    clientHeight,
  } = useViewport();

  function setPanX(x) {
    setPan({ x, y: pan.y });
  }
  function setPanY(y) {
    setPan({ x: pan.x, y });
  }

  return (
    <>
      <ViewportScrollbar
        value={pan.x}
        min={limits.minPan.x}
        max={limits.maxPan.x}
        expansion={clientWidth / zoom}
        clientSize={clientWidth}
        onChange={setPanX}
        horizontal
      />
      <ViewportScrollbar
        value={pan.y}
        min={limits.minPan.y}
        max={limits.maxPan.y}
        expansion={clientHeight / zoom}
        clientSize={clientHeight}
        onChange={setPanY}
      />
    </>
  );
}

export default ViewportScrollbars;
