export default {
  default: {
    editor: {
      stockPanel: {
        defaultQuery: 'Textur',
      },
    },
    stickerTemplate: {
      name: 'Lisa Lässig',
      position: 'Schwester der Braut',
    },
  },
  business: {
    editor: {
      stockPanel: {
        defaultQuery: 'Teamwork',
      },
    },
    stickerTemplate: {
      name: 'Agathe Anklam',
      position: 'CFO',
    },
  },
  sports: {
    editor: {
      stockPanel: {
        defaultQuery: 'Sport',
      },
      stickerTemplate: {
        name: 'Jutta Juist',
        position: 'Rechtes Mittelfeld',
      },
    },
  },
};
