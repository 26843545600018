import cc from '../util/cc';

export const SET_USER = 'user/set';
export const SET_COOKIE_CONSENT = 'user/COOKIE_CONSENT';
export const SET_ORGANIZATIONS = 'user/ORGANIZATIONS';

const { accepted_categories: acceptedCategories } = cc.getUserPreferences();
const getCookieConsent = () => acceptedCategories.includes('necessary');

const initialState = {
  currentUser: {},
  cookieConsent: getCookieConsent(),
  organizations: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        currentUser: action.payload,
      };
    case SET_COOKIE_CONSENT:
      return {
        ...state,
        cookieConsent: action.payload,
      };
    case SET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    default:
      return state;
  }
};

// Set the current user
export const setCurrentUser = payload => dispatch =>
  dispatch({ type: SET_USER, payload });

export const setCookieConsent = payload => dispatch =>
  dispatch({ type: SET_COOKIE_CONSENT, payload });

export const setOrganizations = payload => dispatch =>
  dispatch({ type: SET_ORGANIZATIONS, payload });
