import { func, string } from 'prop-types';
import React from 'react';

import Icon from '../../../Icon';

function Emoji({ title, symbol }) {
  return (
    <span className="emoji" role="img" aria-label={title}>
      {symbol}
    </span>
  );
}

Emoji.propTypes = {
  title: string.isRequired,
  symbol: string.isRequired,
};

function TabIcon({ image, title, renderTabIconMeta, symbol }) {
  return (
    <div className="text-center sidebar-tab-icon">
      {symbol ? <Emoji title={symbol} symbol={image} /> : <Icon name={image} />}
      <div className="small mt-1">{title}</div>
      {renderTabIconMeta?.()}
    </div>
  );
}

TabIcon.defaultProps = {
  renderTabIconMeta: null,
  symbol: null,
};

TabIcon.propTypes = {
  title: string.isRequired,
  image: string.isRequired,
  renderTabIconMeta: func,
  symbol: string,
};

export default TabIcon;
