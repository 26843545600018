import { useSelector } from 'react-redux';
import { useSearchParam } from 'react-use';

import { selectAlbumData } from '../selectors/albums';
import { getCurrentBlueprintData } from '../selectors/blueprints';

const symbols = {
  business: 'business',
  entrypoint: 'entrypoint',
  sports: 'sports',
  wedding: 'wedding',
  birthday: 'birthday',
  school: 'school',
  community: 'community',
};

export const environmentsLabel = {
  [symbols.business]: 'Unternehmen',
  [symbols.wedding]: 'Hochzeit',
  [symbols.sports]: 'Sport',
  [symbols.birthday]: 'Geburtstag',
  [symbols.school]: 'Schule',
  [symbols.community]: 'Gemeinde',
};

export const validEnvironments = [
  symbols.wedding,
  symbols.business,
  symbols.sports,
  symbols.birthday,
  symbols.school,
  symbols.community,
];

/**
 * Our app may run in different "environments" (product layer) that
 * may alter the UI and/or (pre-)set state. The `entrypoint` represents a
 * pre-set value from URL params.
 */
function useAppEnvironment() {
  const entrypointParam = useSearchParam(symbols.entrypoint);

  const albumData = useSelector(selectAlbumData);
  const blueprintData = useSelector(getCurrentBlueprintData);

  // may be `undefined`
  const entrypoint = validEnvironments.find(env => env === entrypointParam);

  /**
   * The `environment` may be different from the `entrypoint`, which
   * only ever represents a GET param value. A user might visit
   * a page at `/?entrypoint=wedding` but perform a UI action that
   * effectively changes the environment (such as clicking on a blueprint
   * of another category), resulting in two different values.
   *
   * To avoid more imperative state "setters" in event handlers, we're
   * attempting to derive the environment from existing values here.
   *
   * Attenzione: This may very well be `undefined`! Don't rely on it
   * for UI modification / always provide a fallback.
   */
  const environment =
    albumData?.blueprintCategory || blueprintData?.blueprintCategory;

  return {
    entrypoint,
    environment,
  };
}

export default useAppEnvironment;
