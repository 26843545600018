import React from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function MultiplayerHighlight({
  width,
  height,
  multiplayerUsersWithSelection,
}) {
  return (
    <>
      <foreignObject width={width} y={-11} className="multiplayer-highlight">
        <div className="d-flex">
          {multiplayerUsersWithSelection.map(multiplayerUser => (
            <OverlayTrigger
              overlay={<Tooltip>{multiplayerUser.email}</Tooltip>}
              placement="right"
            >
              <img
                src={multiplayerUser.picture}
                alt="User Avatar"
                style={{
                  borderColor: multiplayerUser.color,
                }}
                className="avatar"
              />
            </OverlayTrigger>
          ))}
        </div>
      </foreignObject>

      <rect
        className="highlight-box qa-element-highlight-box"
        width={width}
        height={height}
        stroke={`${multiplayerUsersWithSelection[0].color}`}
        fill="none"
        vectorEffect="non-scaling-stroke"
        strokeWidth="1"
      />
    </>
  );
}

MultiplayerHighlight.defaultProps = {
  multiplayerUsersWithSelection: [],
};

MultiplayerHighlight.propTypes = {
  multiplayerUsersWithSelection: arrayOf(
    shape({
      email: string.isRequired,
      picture: string.isRequired,
      color: string.isRequired,
    })
  ),
  width: number.isRequired,
  height: number.isRequired,
};

export default MultiplayerHighlight;
