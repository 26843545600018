import flow from 'lodash/flow';

import store from '../store';
import {
  getIsolation,
  getSelectedElementIds,
  getTargetNodeId,
  getTargetSpreadId,
  getWorkspace,
} from '../selectors/legacy';
import { elementSelect } from '../modules/selection';
import { insertElements, deleteElements } from '../actions/workspace';
import { inputElementFocused } from './index';
import { historyAnchor } from '../modules/history';
import {
  getItemTransformInGroup,
  spreadTransformToGroupTransform,
} from './geometry';
import { uniquifyIds, resetStickerCellStickerIds } from './spreads';
import { denormalizeElement } from './workspace';
import recursiveMap from './recursiveMap';

// *** CLIPBOARD ***

const clipboardSignature = 'sst-editor-public/1.0';
const clipboardSignatureInternalEditor = 'sts-you-client/1.0';

export const ClipboardActions = {};

function convertElementsFromInternalEditor(elements) {
  return recursiveMap(elements, element => {
    if (element.type !== 'Text') {
      return element;
    }
    try {
      // The text-prop is a (unparsed) JSON-string in the other format,
      // we need to parse it
      const text = JSON.parse(element.props.text);
      return {
        ...element,
        props: { ...element.props, text },
      };
    } catch (e) {
      return element;
    }
  });
}

ClipboardActions.copyItems = event => {
  if (inputElementFocused()) return;
  event.preventDefault();
  const state = store.getState();
  const ids = getSelectedElementIds(state);
  const { nodes } = getWorkspace(state);

  const elements = ids.map(id => {
    const element = denormalizeElement({ nodes, root: id });
    const spreadId = getTargetSpreadId(state);
    return {
      ...element,
      props: {
        ...element.props,
        ...getItemTransformInGroup(id, spreadId),
      },
    };
  });

  const wrapper = { format: clipboardSignature, elements };
  const svgCode = ids
    .map(id => document.getElementById(id)?.outerHTML)
    .join(' ');
  const svg = `<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve">${svgCode}</svg>`;

  event.clipboardData.setData('text/plain', svg);
  event.clipboardData.setData('application/json', JSON.stringify(wrapper));
};

ClipboardActions.cutItems = event => {
  if (inputElementFocused()) return;
  ClipboardActions.copyItems(event);
  const selectedElementIds = getSelectedElementIds(store.getState());
  store.dispatch(deleteElements(selectedElementIds));
  store.dispatch(historyAnchor());
};

ClipboardActions.pasteItems = event => {
  if (inputElementFocused()) {
    return;
  }

  const state = store.getState();
  const targetNodeId = getTargetNodeId(state);
  const targetSpreadId = getTargetSpreadId(state);
  const isolation = getIsolation(state);
  const itemList = (event.clipboardData || event.originalEvent.clipboardData)
    .items;

  const item = Array.from(itemList)
    .filter(({ type }) => type === 'application/json')
    .shift();
  if (!item) {
    // No JSON-content in clipboard - abort
    return;
  }

  event.preventDefault();

  item.getAsString(json => {
    let data;
    try {
      data = JSON.parse(json);
    } catch (e) {
      console.error(e.message);
      // Invalid JSON-content in clipboard - abort
      return;
    }
    let elements;
    if (data.format === clipboardSignatureInternalEditor) {
      elements = convertElementsFromInternalEditor(data.elements);
    } else if (data.format === clipboardSignature) {
      elements = data.elements;
    } else {
      console.error('wrong json format');
      return;
    }

    if (isolation) {
      /**
       * Elements in the clipboard are stored in the spreads coordinate space
       * and need to be converted in isolation mode
       */
      elements = elements.map(item => ({
        ...item,
        props: spreadTransformToGroupTransform(
          item.props,
          targetSpreadId,
          isolation
        ),
      }));
    }

    const uniquifyIdsAndUnlinkStickerCells = flow([
      uniquifyIds,
      resetStickerCellStickerIds,
    ]);
    elements = elements.map(uniquifyIdsAndUnlinkStickerCells);
    store.dispatch(insertElements(elements, targetNodeId));
    store.dispatch(elementSelect(elements.map(item => item.props.id)));
    store.dispatch(historyAnchor());
  });
};
