import { func, bool } from 'prop-types';
import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import differenceInDays from 'date-fns/differenceInDays';
import Alert from 'react-bootstrap/Alert';
import format from 'date-fns/format';

import { CheckoutFormDataShape } from '../../components/shapes';
import CheckoutVoucherInput from './CheckoutVoucherInput';
import { CheckoutShape } from './shapes';

function CheckoutSummary({
  checkout,
  isFetchingCheckout,
  formData,
  setFormData,
}) {
  const {
    line_items: lineItems,
    amount_human: amount,
    amount_gross_human: amountGross,
    tax_value_human: taxValue,
    estimated_delivery_date: estimatedDeliveryDate,
  } = checkout;

  const shouldRenderShippingWarning =
    differenceInDays(
      new Date(formData.releaseDate),
      new Date(estimatedDeliveryDate)
    ) < 1;

  return (
    <>
      <ListGroup
        className="checkout-summary qa-checkout-summary p-4"
        style={{ opacity: isFetchingCheckout ? 0.5 : 1 }}
      >
        <h3 className="checkout-summary__header">Bestellübersicht</h3>
        {lineItems.map(item => (
          <div
            key={item.product}
            className="d-flex justify-content-between mb-2"
          >
            <div>
              <span className="checkout-summary__title font-weight-bold">
                {item.title}
              </span>
              <p className="checkout-summary__text">{item.description}</p>
            </div>
            <span
              className={`checkout-summary__quantity qa-summary-quantity-${item.product}`}
            >
              {item.quantity}
            </span>
          </div>
        ))}
        <div className="d-flex justify-content-between border-top checkout-summary__additional mt-3 pt-4 mb-2">
          <span>Preis inkl. Versand</span>
          <span className="qa-summary-total-price-net">{amount}</span>
        </div>
        <div className="d-flex justify-content-between mb-2 checkout-summary__additional">
          <span>MwSt. / VAT</span>
          <span className="qa-summary-total-tax-value">{taxValue}</span>
        </div>
        <div className="d-flex justify-content-between text-primary">
          <span>Gesamtkosten</span>
          <span className="qa-summary-total-price-gross">{amountGross}</span>
        </div>
      </ListGroup>
      <CheckoutVoucherInput
        checkout={checkout}
        formData={formData}
        setFormData={setFormData}
        isFetchingCheckout={isFetchingCheckout}
      />
      {formData.releaseDate ? (
        <>
          <p className="mt-4 qa-estimated-delivery-date text-center">
            vsl. Zustelltermin:{' '}
            <b className="text-center">
              {format(new Date(estimatedDeliveryDate), 'dd.MM.yyyy')}
            </b>
          </p>
          {shouldRenderShippingWarning && (
            <Alert
              variant="warning"
              className="qa-estimated-delivery-date-warning"
            >
              Das könnte knapp werden. Bitte melde dich bei uns, bevor du die
              Bestellung aufgibst.{' '}
              <a target="__blank" href="mailto:support@stickerstars.de">
                support@stickerstars.de
              </a>
            </Alert>
          )}
        </>
      ) : null}
    </>
  );
}

CheckoutSummary.propTypes = {
  checkout: CheckoutShape.isRequired,
  isFetchingCheckout: bool.isRequired,
  formData: CheckoutFormDataShape.isRequired,
  setFormData: func.isRequired,
};

export default CheckoutSummary;
