import { connect } from 'react-redux';

import Content from './index';
import { getWorkspace } from '../../../../selectors/legacy';

const mapStateToProps = state => ({
  workspace: getWorkspace(state),
});

export default connect(mapStateToProps)(Content);
