import DraftEditorBlock from 'draft-js/lib/DraftEditorBlock.react';
import React from 'react';

import { DraftBlockShape, DraftStyleMapShape } from '../../../../shapes';
import { blockAlignMap, defaultBlockStyle } from '../TextConstants';

const BlockWrapper = props => {
  const { styleMap, block } = props;
  const data = defaultBlockStyle.merge(block.getData());

  const blockStyleReducer = (acc, blockStyleValue) =>
    Object.assign(
      acc,
      styleMap[blockStyleValue],
      blockAlignMap[blockStyleValue]
    );

  const style = data.reduce(blockStyleReducer, {});

  return (
    <div className="draft-block-wrapper" style={style}>
      <DraftEditorBlock {...props} />
    </div>
  );
};

BlockWrapper.propTypes = {
  styleMap: DraftStyleMapShape.isRequired,
  block: DraftBlockShape.isRequired,
};

export default BlockWrapper;
