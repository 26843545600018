import React, { useState } from 'react';
import Color from 'color';
import { arrayOf, bool, func, number, oneOfType, string } from 'prop-types';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';

import { swatchTypeIndexes } from '../../constants';
import { updateColor } from '../../modules/colorsAndFonts';
import ColorInput from './ColorInput';

const SwatchColorPicker = ({
  allowCustom,
  allowNull,
  color: initialColor,
  colors,
  onChange,
  onClose,
  onOpen,
  overwritePreset,
}) => {
  /**
   * We need to parse a given `color` value for its corresponding
   * index in the array of swatch buttons.
   */
  const parseSwatchIndex = color => {
    let parsedIndex;
    if (typeof color === 'number') {
      parsedIndex = color; // already has the correct index
    } else if (typeof color === 'string') {
      parsedIndex = swatchTypeIndexes.CUSTOM; // custom color
    } else {
      parsedIndex = swatchTypeIndexes.NONE; // no color
    }
    return parsedIndex;
  };

  const [openPickerIndex, setOpenPickerIndex] = useState(null);
  const [selectedSwatchIndex, setSelectedSwatchIndex] = useState(
    parseSwatchIndex(initialColor)
  );

  /**
   * Handles clicks on one of the preset color swatch buttons.
   *
   * `index` refers to the index inside the `allColors` array and will, once resolved
   * here, be used (via the `onChange` handler passed via props) either
   *   - to update an elements color, dispatched in the `ElementToolbar` component
   *   - as an inline style, dispatched in the `RichTextToolbar` component.
   */
  const handleClick = (color, index) => {
    let nextColor = index; // color from redux, set index value
    if (index === swatchTypeIndexes.CUSTOM) {
      nextColor = color; // custom color
    } else if (index === swatchTypeIndexes.NONE) {
      nextColor = null; // no color
    }

    // Update the selected index to highlight the corresponding swatch button
    const nextSwatchIndex = parseSwatchIndex(nextColor);
    setSelectedSwatchIndex(nextSwatchIndex);

    // Actually update the element
    onChange(nextColor);
  };

  /**
   * The color picker can be used to either assign a custom
   * color to a given element (dispatching `onChange`) or overwrite
   * a preset swatch color (dispatch `overwritePreset`).
   */
  const handleInput = (color, index) => {
    if (index === swatchTypeIndexes.CUSTOM) {
      onChange(color);
    } else {
      overwritePreset(index, color);
    }
  };

  const handleOpen = index => {
    setOpenPickerIndex(index);
    onOpen();
  };

  const handleClose = () => {
    setOpenPickerIndex(null);
    onClose();
  };

  /**
   * If a selected element has been assigned a custom color,
   * `color` will resolve to a hex string. If not, i.e., the element
   * gets its color from a preset, `color` will resolve to the
   * index of the selected swatch and a gray field will be added
   * to the list of swatch colors (`allColors` defined below).
   */
  const color = typeof initialColor !== 'string' ? '#888' : initialColor;
  const allColors = allowCustom ? [color, null, ...colors] : [...colors];

  return (
    <>
      {allColors.map((c, i) => {
        let colorIndex = i;
        if (allowCustom) {
          colorIndex -= 2;
        }

        const classNames = ['button-swatch'];
        if (typeof c === 'string') {
          const lum = Color(c).luminosity();
          if (lum > 0.7) classNames.push('highlight-shadow');
        }

        if (colorIndex === selectedSwatchIndex) {
          classNames.push('highlight-selected');
        } else {
          classNames.push('highlight-none');
        }

        let style = null;
        if (colorIndex === swatchTypeIndexes.CUSTOM) {
          style = { background: color }; // custom color
        } else if (colorIndex === swatchTypeIndexes.NONE) {
          if (!allowNull) return null;
          classNames.push('background-none'); // no color
        } else {
          style = { background: colors[colorIndex] }; // preset color
        }

        return (
          <div
            key={`${colorIndex}-${color}`}
            style={{ display: 'inline-block', verticalAlign: 'top' }}
          >
            <Button
              className={`${classNames.join(
                ' '
              )} qa-swatch-color-option qa-swatch-${i}`}
              style={style}
              onClick={() => handleClick(c, colorIndex)}
            />

            <ColorInput
              index={colorIndex}
              selected={colorIndex === selectedSwatchIndex}
              color={c}
              onChange={newColor => handleInput(newColor, colorIndex)}
              open={colorIndex === openPickerIndex}
              onOpen={() => handleOpen(colorIndex)}
              onClose={handleClose}
            />
          </div>
        );
      })}
    </>
  );
};

SwatchColorPicker.defaultProps = {
  allowNull: true,
  color: swatchTypeIndexes.UNSELECTED,
  onClose: () => {},
  onOpen: () => {},
};

SwatchColorPicker.propTypes = {
  allowCustom: bool.isRequired,
  allowNull: bool,
  color: oneOfType([number, string]),
  colors: arrayOf(string).isRequired,
  onChange: func.isRequired,
  onClose: func,
  onOpen: func,
  overwritePreset: func.isRequired,
};

const mapStateToProps = state => {
  return {
    colors: state.colorsAndFonts.colors,
  };
};

const mapDispatchToProps = {
  overwritePreset: updateColor,
};

export default connect(mapStateToProps, mapDispatchToProps)(SwatchColorPicker);
