import React from 'react';

import Logo from '../../assets/icons/logo.svg';
import { configShape } from './shapes';

function Header({ config: { whiteLabel } }) {
  return (
    <div className="header">
      {!whiteLabel && (
        <img
          src={Logo}
          className="logo qa-stickerstars-logo"
          alt="stickerstars logo"
        />
      )}
    </div>
  );
}

Header.propTypes = {
  config: configShape.isRequired,
};

export default Header;
