import { string, shape } from 'prop-types';

export const orderStatusShape = shape({
  status: string.isRequired,
  date: string.isRequired,
});

export const dashboardOrderShape = shape({
  id: string.isRequired,
  albums_status: orderStatusShape.isRequired,
  shipment_status: orderStatusShape.isRequired,
  transfer_status: orderStatusShape.isRequired,
});
