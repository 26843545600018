import React from 'react';
import { useDispatch } from 'react-redux';
import { node, bool, object, string } from 'prop-types';

import { exitIsolation } from '../../../modules/controls';
import { RefShape } from '../../shapes';

function AlbumSvg({
  viewportRef,
  viewBox,
  style,
  isolationActive,
  children,
  preview,
  className,
  ...props
}) {
  const dispatch = useDispatch();
  const dispatchExitIsolation = () => dispatch(exitIsolation());
  const shouldBeClickable = isolationActive && !preview;

  return (
    <svg
      {...props}
      ref={viewportRef}
      className={className}
      shapeRendering="optimizeSpeed"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      onDoubleClick={shouldBeClickable ? dispatchExitIsolation : null}
    >
      {children}
    </svg>
  );
}

AlbumSvg.defaultProps = {
  viewportRef: null,
  style: {},
  isolationActive: false,
  children: null,
  preview: false,
  className: '',
};

AlbumSvg.propTypes = {
  viewportRef: RefShape,
  viewBox: string.isRequired,
  style: object, // eslint-disable-line react/forbid-prop-types
  isolationActive: bool,
  children: node,
  preview: bool,
  className: string,
};

export default AlbumSvg;
