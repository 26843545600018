import { createSelector } from 'reselect';

export const getCurrentBlueprintId = createSelector(
  [state => state.blueprints.currentBlueprintId],
  currentBlueprintId => currentBlueprintId
);

export const getBlueprintData = createSelector(
  [state => state.blueprints.blueprintDataByBlueprintId],
  blueprintDataByBlueprintId => blueprintDataByBlueprintId
);

export const getCurrentBlueprintData = createSelector(
  [getCurrentBlueprintId, getBlueprintData],
  (currentBlueprintId, blueprintDataByBlueprintId) => {
    return blueprintDataByBlueprintId[currentBlueprintId];
  }
);

export const selectCurrentBlueprintCategory = createSelector(
  [state => state.blueprints.currentBlueprintCategory],
  currentBlueprintCategory => currentBlueprintCategory
);

export default getCurrentBlueprintData;
