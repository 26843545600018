import { moveSticker } from './stickers';
import { deleteElements, moveElementsToSpread } from './workspace';
import {
  getIsolation,
  getSelectedElementIds,
  getSelectedElements,
  getWorkspace,
} from '../selectors/legacy';
import { getSpreadNodes } from '../selectors/workspace';
import { pointToSpreadIndex } from '../util/generators';
import {
  elementToElementMatrix,
  getAxisAlignedBounds,
  rectsIntersect,
} from '../util/geometry';
import { getOperationMoveNodeId } from '../selectors/operations';
import { calculateSpreadRect } from '../components/svg/elements/Spread';

const moveBeyondSpreadBounds = viewportPoint => (dispatch, getState) => {
  const state = getState();
  const isolationActive = getIsolation(state);
  const spreadNodes = getSpreadNodes(state);
  const selectedElementIds = getSelectedElementIds(state);
  const moveNodeId = getOperationMoveNodeId(state);
  const moveNodeIds = [...new Set([...selectedElementIds, moveNodeId])];
  const { nodes } = getWorkspace(state);

  if (isolationActive) {
    return;
  }

  // Determine the spread where the elements were moved
  const spreadIndex = pointToSpreadIndex(viewportPoint, spreadNodes.length);
  const nextSpread = spreadNodes[spreadIndex];
  const nextSpreadId = nextSpread.props.id;

  // Check if the selection was moved outside the spread
  const selectedElements = getSelectedElements(state);
  const selectedElementsWithoutComments = selectedElements.filter(
    element => element.type !== 'Comment'
  );

  if (selectedElementsWithoutComments.length > 0) {
    const selectionBounds = getAxisAlignedBounds(
      selectedElementsWithoutComments,
      `.viewport`
    );
    const spreadBounds = calculateSpreadRect({
      spreadIndex,
      spreadCount: spreadNodes.length,
      cropPreview: true,
    });
    const isSelectionOnSpread = rectsIntersect(selectionBounds, spreadBounds);

    if (!isSelectionOnSpread) {
      const deletableElementIds = selectedElementsWithoutComments.map(
        element => element.props.id
      );
      dispatch(deleteElements(deletableElementIds));
      return;
    }
  }

  const propsDeltaMap = moveNodeIds.reduce((acc, id) => {
    const { type, parent: currentSpreadId, props } = nodes[id];

    if (currentSpreadId === nextSpreadId) {
      return acc;
    }

    // Translate position to new spread
    const matrix = elementToElementMatrix(currentSpreadId, nextSpreadId);
    const { x, y } = matrix.transformPoint(props);
    acc[props.id] = { x, y };

    /**
     * If the dropped element is a `StickerCell` and the target spread
     * belongs to another section, we need to move the linked sticker to
     * that section.
     */
    const nextSectionId = nextSpread.parent;
    if (type === 'StickerCell') {
      dispatch(moveSticker(props.stickerId, nextSectionId));
    }
    return acc;
  }, {});

  const movedIds = Object.keys(propsDeltaMap);

  if (movedIds.length === 0) {
    return;
  }
  /**
   * Todo: if an element in isolation mode is dragged onto another spread, it should
   * be removed from the group and the groups size should be updated accordingly.
   */
  dispatch(moveElementsToSpread(movedIds, nextSpreadId, propsDeltaMap));
};

export default moveBeyondSpreadBounds;
