import React from 'react';
import { number, string, object, func } from 'prop-types';
import { useDrag } from 'react-dnd';

import AlbumSvg from '../../svg/Viewport/AlbumSvg';
import Node from '../../svg/Node';
import { dimensions, itemTypes } from '../../../constants';
import { WorkspaceShape } from '../../shapes';

function SpreadPreview({
  spreadId,
  sectionNode,
  spreadsCount,
  spreadIndex,
  workspace,
  onClick,
}) {
  const { pageHeight, pageWidth } = dimensions;
  const isCover = spreadIndex === 0 || spreadIndex === spreadsCount - 1;
  const viewBox = `0 0 ${isCover ? pageWidth : pageWidth * 2} ${pageHeight}`;

  const [, drag] = useDrag({
    item: {
      data: {
        blueprintSpreadId: spreadId,
      },
      type: itemTypes.spread,
    },
  });

  return (
    <div
      className="shadow spread-preview qa-spread-preview"
      onClick={onClick}
      style={{ width: `${isCover ? '50%' : '100%'}` }}
      ref={drag}
    >
      <AlbumSvg viewBox={viewBox} preview>
        <Node
          workspace={workspace}
          id={spreadId}
          nodeIndex={spreadIndex}
          nodeSiblingCount={spreadsCount}
          renderingSpreadPosition={{
            x: 0,
            y: 0,
          }}
          sectionNode={sectionNode}
          preview
        />
      </AlbumSvg>
    </div>
  );
}

SpreadPreview.defaultProps = {
  onClick: () => {},
};

SpreadPreview.propTypes = {
  spreadId: string.isRequired,
  spreadIndex: number.isRequired,
  workspace: WorkspaceShape.isRequired,
  spreadsCount: number.isRequired,
  sectionNode: object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: func,
};

export default SpreadPreview;
