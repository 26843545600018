export const UPDATE = 'smartGuides/UPDATE';

const initialState = {
  activeGuideIds: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

/**
 * Updates the guides
 * @param {} delta An object to merge into the state.
 */
export const updateGuides = delta => dispatch => {
  return dispatch({
    type: UPDATE,
    payload: delta,
  });
};
