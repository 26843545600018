import { connect } from 'react-redux';

import { applyImage } from '../../../actions/workspace';
import { getSelectedElements } from '../../../selectors/legacy';
import { getNonStickerImages } from '../../../selectors/images';
import ImagesPanelWithDnd from './index.dnd';

const mapDispatchToProps = {
  applyImage,
};

const mapStateToProps = state => ({
  tags: state.tags,
  selectedElements: getSelectedElements(state),
  imageFilterString: state.controls.imageFilterString,
  spreads: state.spreads,
  autoFit: state.controls.autoFit,
  images: getNonStickerImages(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagesPanelWithDnd);
