import React from 'react';
import Button from 'react-bootstrap/Button';
import isEmpty from 'lodash/isEmpty';

import Welcome from './steps/Welcome';
import Data from './steps/Data';
import Section from './steps/Section';
import Photo from './steps/Photo';
import Submit from './steps/Submit';
import Result from './steps/Result';
import Icon from '../../components/Icon';

export default [
  {
    component: Welcome,
    config: () => ({
      renderFooter: () => (
        <Button
          className="qa-photo-doc"
          href="/assets/docs/photo_guide.pdf"
          target="_blank"
          rel="noopener noreferrer"
          variant="light"
          block
          size="lg"
        >
          <Icon className="d-inline-block mr-3" name="textfile" /> Anleitung
          ansehen
        </Button>
      ),
    }),
  },
  {
    component: Data,
    config: ({ name }) => ({ nextDisabled: isEmpty(name) }),
  },
  {
    component: Section,
  },
  {
    component: Photo,
    config: ({ file, fileDetails }) => ({
      nextDisabled: !file || !fileDetails.width,
    }),
  },
  {
    component: Submit,
    config: () => ({ preventNavigation: true }),
  },
  {
    component: Result,
    config: () => ({ preventNavigation: true }),
  },
];
