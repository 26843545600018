import React, { useCallback, useMemo } from 'react';
import flow from 'lodash/flow';
import { func, number, string } from 'prop-types';

import useMouseDragCoordinates from '../../hooks/useMouseDragCoordinates';
import { domMatrix, vectorToAngle } from '../../util/geometry';
import { makeMapPropsToDeltaMap } from '../../util/workspace';
import { InitialOperationValuesShape } from '../shapes';
import { getPivot, getPivotsFromInitialValues, pivotNames } from './pivots';
import withOperationProps from './withOperationProps';

function rotateAroundPivot(pivot, { x, y }, radians) {
  const cos = Math.cos(radians);
  const sin = Math.sin(radians);
  return {
    x: cos * (x - pivot.x) + sin * (y - pivot.y) + pivot.x,
    y: cos * (y - pivot.y) - sin * (x - pivot.x) + pivot.y,
  };
}

const unprefixProps = ({ cx, cy, crotation }) => ({
  x: cx,
  y: cy,
  rotation: crotation,
});

const prefixProps = ({ x, y, rotation }) => ({
  cx: x,
  cy: y,
  crotation: rotation,
});

function RotateHandle({
  handleSize,
  pivotName,
  width,
  height,
  initialValues,
  operationStart,
  updateElements,
  operationEnd,
}) {
  const pivots = useMemo(() => {
    // Rotation is always around the center
    return getPivotsFromInitialValues([pivotNames.center], initialValues);
  }, [initialValues]);

  const handleUpdate = useCallback(
    ({ initialPointer, currentPointer, shiftKey }) => {
      const {
        selectedElements,
        operationTargetNodeId,
        selectInside,
      } = initialValues;
      const {
        clientPivots: [clientPivot],
      } = pivots;

      const parentDomNode = document.querySelector(
        `[data-id='${operationTargetNodeId}']`
      );

      const initialVector = {
        x: initialPointer.x - clientPivot.x,
        y: initialPointer.y - clientPivot.y,
      };
      const currentVector = {
        x: currentPointer.x - clientPivot.x,
        y: currentPointer.y - clientPivot.y,
      };
      const deltaRotation =
        vectorToAngle(currentVector) - vectorToAngle(initialVector);

      const clientToParent = domMatrix(parentDomNode.getScreenCTM()).inverse();
      const parentPivot = clientToParent.transformPoint(clientPivot);

      const applyRotation = ({ x = 0, y = 0, rotation = 0 }) => {
        let nextRotation = rotation + deltaRotation;
        if (shiftKey) {
          // Pressing shift locks roation to 45° steps
          nextRotation = Math.round(nextRotation / 45) * 45;
        }
        const relativeDeltaRotation = nextRotation - rotation;
        const relativeDeltaRadians = (relativeDeltaRotation / -180) * Math.PI;

        return {
          rotation: nextRotation,
          ...rotateAroundPivot(parentPivot, { x, y }, relativeDeltaRadians),
        };
      };
      const mapPropsToDeltaMap = makeMapPropsToDeltaMap(
        selectedElements,
        selectInside
      );

      const applyRotationNormalized = selectInside
        ? flow(unprefixProps, applyRotation, prefixProps)
        : applyRotation;

      const propsDeltaMap = mapPropsToDeltaMap(applyRotationNormalized);
      updateElements(propsDeltaMap);
    },
    [pivots, updateElements, initialValues]
  );

  const { x, y } = getPivot(pivotName, {
    width: width + handleSize,
    height: height + handleSize,
  });

  const { handleMouseDown } = useMouseDragCoordinates({
    onStart: operationStart,
    onUpdate: initialValues && handleUpdate,
    onEnd: operationEnd,
  });

  return (
    <rect
      onMouseDown={handleMouseDown}
      width={handleSize}
      height={handleSize}
      x={x - handleSize}
      y={y - handleSize}
      className={`handle rotate ${pivotName} qa-handle-rotate-${pivotName}`}
    />
  );
}

RotateHandle.defaultProps = {
  initialValues: null,
};

RotateHandle.propTypes = {
  handleSize: number.isRequired,
  pivotName: string.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  initialValues: InitialOperationValuesShape,
  operationStart: func.isRequired,
  updateElements: func.isRequired,
  operationEnd: func.isRequired,
};

export default withOperationProps(RotateHandle);
