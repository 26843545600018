import { createSelector } from 'reselect';

import { selectCurrentUser } from './user';

export const selectCurrentAlbum = state => state.albums.currentAlbum;

export const selectAlbumData = state => state.albums.albumData;

export const selectAlbumTitle = state => state.albums.albumData.title;

export const selectAlbumReleaseDate = state =>
  state.albums.albumData.releaseDate;

export const selectHasUnsavedChanges = state => state.albums.hasUnsavedChanges;

export const selectStickerUploadsPath = state =>
  state.albums.albumData.stickerUploadsPath;

export const selectStickerUploadConfig = state => ({
  enabled: state.albums.albumData.stickerUploadEnabled,
  whiteLabel: state.albums.albumData.stickerUploadWhiteLabel,
  content: state.albums.albumData.stickerUploadContent,
  domain: state.albums.albumData.stickerUploadCustomDomain,
  requirePosition: state.albums.albumData.stickerUploadRequirePosition,
});

export const selectAlbumLoaded = state => state.albums.loaded;

export const selectAlbumHasOrder = state => state.albums.albumData.hasOrder;

export const selectIsAlbumEditable = createSelector(
  [selectCurrentAlbum, selectAlbumData, selectCurrentUser],
  (currentAlbumId, { locked }, { roles }) => {
    return !currentAlbumId || !locked || roles?.includes('admin');
  }
);

export const selectStickerTemplateSettings = state =>
  state.albums.albumData.stickerTemplateSettings;

export const selectAlbumOrganization = state =>
  state.albums.albumData.organization;
