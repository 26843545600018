import React, { useEffect, useRef, useCallback } from 'react';
import { bool, func, string } from 'prop-types';
import Form from 'react-bootstrap/Form';

function CommentForm({ value, handleChange, initialFocus, disabled }) {
  const inputRef = useRef(null);

  const handleFocus = useCallback(() => {
    if (!initialFocus) {
      return;
    }
    inputRef.current.select();
  }, [initialFocus]);

  useEffect(() => {
    handleFocus();
  }, [initialFocus, handleFocus]);

  return (
    <Form>
      <Form.Group>
        <Form.Control
          as="textarea"
          ref={inputRef}
          name="comment_text"
          className="qa-comment-text-input"
          rows="3"
          value={value}
          onChange={handleChange}
          disabled={disabled}
        />
      </Form.Group>
    </Form>
  );
}

CommentForm.defaultProps = {
  initialFocus: false,
};

CommentForm.propTypes = {
  value: string.isRequired,
  handleChange: func.isRequired,
  initialFocus: bool,
  disabled: bool.isRequired,
};

export default CommentForm;
