import React, { useState } from 'react';
import { string } from 'prop-types';
import mapKeys from 'lodash/mapKeys';
import { useSelector, useDispatch } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Fade from 'react-bootstrap/Fade';

import { camelToSnake } from '../../../util/index';
import { setAlbumData } from '../../../modules/albums';
import {
  selectCurrentAlbum,
  selectStickerUploadConfig,
} from '../../../selectors/albums';
import useApi from '../../../hooks/useApi';
import { stickerLinkCustomDomains } from '../../../constants';
import useAnalytics from '../../../containers/app/useAnalytics';

const [firstStickerLinkCustomDomain] = stickerLinkCustomDomains;

function StickerLinkSettings({ uploadsUrl }) {
  const [changed, setChanged] = useState(true);
  const initialConfig = useSelector(selectStickerUploadConfig);
  const albumId = useSelector(selectCurrentAlbum);
  const [config, setConfig] = useState(
    Object.assign(initialConfig, {
      domain: initialConfig.domain || firstStickerLinkCustomDomain,
    })
  );
  const api = useApi();
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  async function handleSubmit(event) {
    event.preventDefault();

    const nextConfig = {
      stickerUploadEnabled: config.enabled,
      stickerUploadWhiteLabel: config.whiteLabel,
      stickerUploadContent: config.content,
      stickerUploadCustomDomain: config.domain,
      stickerUploadRequirePosition: config.requirePosition,
    };

    dispatch(setAlbumData(nextConfig));
    await api.patch(
      `/albums/${albumId}`,
      mapKeys(nextConfig, (_, k) => camelToSnake(k))
    );

    analytics.track('Stickerlink Config Updated', {
      config: nextConfig,
    });

    setChanged(false);
  }

  function update(key, value) {
    setChanged(true);
    setConfig({ ...config, [key]: value });

    analytics.track('StickerLink Updated', {
      attribute: key,
    });
  }

  const whiteLabelDescription = (
    <OverlayTrigger
      overlay={<Tooltip>Entfernt alle Hinweise auf Sticker oder Album</Tooltip>}
      placement="bottom"
    >
      <Form.Label>Überraschungsmodus</Form.Label>
    </OverlayTrigger>
  );

  const enabledDescription = (
    <OverlayTrigger
      overlay={<Tooltip>Foto-Upload aktivieren/deaktivieren</Tooltip>}
      placement="bottom"
    >
      <Form.Label>Stickerlink aktiv</Form.Label>
    </OverlayTrigger>
  );

  const requirePositionDescription = (
    <OverlayTrigger
      overlay={
        <Tooltip>
          Zeigt ein Feld an, über das der &quot;Titel&quot; der Person
          ausgefüllt werden kann
        </Tooltip>
      }
      placement="bottom"
    >
      <Form.Label>Titel abfragen</Form.Label>
    </OverlayTrigger>
  );

  return (
    <Card bg="light">
      <Card.Header>Einstellungen</Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit} className="qa-sticker-link-form">
          <Form.Group controlId="enabled">
            <Form.Check
              type="switch"
              label={enabledDescription}
              className="qa-sticker-upload-enabled"
              checked={config.enabled}
              onChange={() => update('enabled', !config.enabled)}
            />
          </Form.Group>
          <Form.Group controlId="requirePosition">
            <Form.Check
              className="qa-sticker-upload-require-position"
              type="switch"
              disabled={!config.enabled}
              label={requirePositionDescription}
              checked={config.requirePosition}
              onChange={() =>
                update('requirePosition', !config.requirePosition)
              }
            />
          </Form.Group>
          <Form.Group controlId="whitelabel">
            <Form.Check
              className="qa-sticker-upload-white-label"
              type="switch"
              disabled={!config.enabled}
              label={whiteLabelDescription}
              checked={config.whiteLabel}
              onChange={() => update('whiteLabel', !config.whiteLabel)}
            />
          </Form.Group>
          <Fade in={config.whiteLabel} mountOnEnter unmountOnExit>
            <Form.Group>
              <Form.Label>Anonyme URL</Form.Label>
              <Form.Control
                as="select"
                className="qa-sticker-upload-custom-domain"
                name="sticker_upload_custom_domain"
                disabled={!config.enabled}
                value={config.domain}
                onChange={({ target: { value } }) => update('domain', value)}
              >
                {stickerLinkCustomDomains.map(domain => (
                  <option value={domain} key={domain}>
                    {domain}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Fade>
          <Form.Group>
            <Form.Label>Persönliche Nachricht</Form.Label>
            <Form.Control
              as="textarea"
              disabled={!config.enabled}
              name="sticker_upload_content"
              className="qa-sticker-link-personal-note"
              rows="5"
              value={config.content}
              onChange={({ target: { value } }) => update('content', value)}
              required
            />
          </Form.Group>
          {changed && (
            <Button className="qa-save-sticker-upload-btn" type="submit">
              Speichern
            </Button>
          )}
          {!changed && (
            <Button
              variant="outline-primary"
              as="a"
              href={uploadsUrl}
              target="_BLANK"
            >
              Link Ansehen
            </Button>
          )}
        </Form>
      </Card.Body>
    </Card>
  );
}

StickerLinkSettings.propTypes = {
  uploadsUrl: string.isRequired,
};

export default StickerLinkSettings;
