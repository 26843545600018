import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setInlineStyle } from '../../../../modules/selection';
import {
  getCommonIdenticalProps,
  getSelectedTextProps,
} from '../../../../selectors/selection';

const withTextInputProps = WrappedComponent => props => {
  const dispatch = useDispatch();
  const textProps = useSelector(getSelectedTextProps);
  const { symbol } = useSelector(getCommonIdenticalProps);
  const isSymbol = !!symbol;

  const makeHandleChange = (styleName, value = true) => () => {
    const styleValue = value === true ? styleName : `${styleName}-${value}`;
    dispatch(setInlineStyle(styleValue.toUpperCase()));
  };

  return (
    <WrappedComponent
      {...textProps}
      {...props}
      isSymbol={isSymbol}
      makeHandleChange={makeHandleChange}
    />
  );
};

export default withTextInputProps;
