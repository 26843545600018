import { UNDO, REDO } from 'redux-live-undo';

export const HISTORY_ANCHOR = 'history/ANCHOR';
export const HISTORY_SET_CHANGED = 'history/HISTORY_SET_CHANGED';

export const initialState = { changed: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case HISTORY_SET_CHANGED:
      return {
        ...state,
        changed: action.payload,
      };
    default:
      return state;
  }
};

/**
 * Tell the store that the album has been updated.
 */
export const albumChanged = (changed = true) => dispatch => {
  dispatch({ type: HISTORY_SET_CHANGED, payload: changed });
};

export const historyAnchor = () => dispatch => {
  dispatch(albumChanged()); // TODO: Check why this is here
  dispatch({ type: HISTORY_ANCHOR, undoableHistoryCheckpoint: true });
};

export const historyUndo = () => (dispatch, getState) => {
  /**
   * On initial load, the the first element of `past` is a reference to the `present` state
   * (this is related to the undox-internal mechanisms) and should not be targetable as an
   * undoable state. A similar check is done in the `HistoryControls` component.
   */
  const canUndo = getState().workspaceAndStickers.past.length > 1;
  if (!canUndo) {
    return;
  }
  dispatch({ type: UNDO });
  dispatch(albumChanged());
};

export const historyRedo = () => (dispatch, getState) => {
  const canRedo = getState().workspaceAndStickers.future.length > 0;
  if (!canRedo) {
    return;
  }
  dispatch({ type: REDO });
  dispatch(albumChanged());
};
