import { connect } from 'react-redux';

import { updateElements } from '../../../actions/workspace';
import { setIsolation } from '../../../modules/controls';
import { clearSelection, setSelectInside } from '../../../modules/selection';
import { updateGuides } from '../../../modules/smartGuides';
import { getGridEnabled } from '../../../selectors/controls';
import { getAnimatedZoom } from '../../../selectors/viewport';
import {
  getOperationInitialValues,
  getOperationMoveNodeId,
} from '../../../selectors/operations';
import MoveOperation from './MoveOperation';

const mapStateToProps = (state, ownProps) => {
  const props = {
    zoom: getAnimatedZoom(state),
    gridEnabled: getGridEnabled(state),
  };
  const operationMoveNodeId = getOperationMoveNodeId(state);
  if (operationMoveNodeId !== ownProps.id) {
    return props;
  }

  return {
    ...props,
    initialValues: getOperationInitialValues(state),
  };
};

const mapDispatchToProps = {
  updateElements,
  setSelectInside,
  clearSelection,
  setIsolation,
  updateGuides,
};

export default connect(mapStateToProps, mapDispatchToProps)(MoveOperation);
