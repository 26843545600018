import React from 'react';

import { configShape } from '../shapes';

function Welcome({ config: { content, title } }) {
  return (
    <>
      <h5>{title}</h5>
      <h2 className="font-weight-bold mb-3">Willkommen im Foto-Uploader</h2>
      <p style={{ whiteSpace: 'pre-line' }}>{content}</p>
    </>
  );
}

Welcome.propTypes = {
  config: configShape.isRequired,
};

export default Welcome;
