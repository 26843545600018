import flow from 'lodash/flow';
import intersection from 'lodash/intersection';
import isEqual from 'lodash/isEqual';
import sortBy from 'lodash/sortBy';
import xor from 'lodash/xor';

export default function performSelection({
  deltaIds,
  selectableIds,
  initialIds,
  currentIds,
  shiftKey,
  elementSelect,
}) {
  // Toggle elements within existing selection using the shift-key
  const applyXor = ids => (shiftKey ? xor(ids, initialIds) : ids);

  // Prevent cross-spread selection
  const limitToSelectable = ids => intersection(ids, selectableIds);

  /**
   * Only update when the result is different from the current state.
   * `sortBy` ensures that different orders are ignored.
   */
  const selectIfDifferent = ids =>
    !isEqual(sortBy(ids), sortBy(currentIds)) && elementSelect(ids);

  flow([applyXor, limitToSelectable, selectIfDifferent])(deltaIds);
}
