import React from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import { useFormState } from '../FormStateProvider';
import Icon from '../../../components/Icon';
import { computeImageDimensions } from '../../../util/images';
import { detectFaceFromUrl } from '../../../util/faceapi';
import FileInputButton from '../../../components/generic/FileInputButton';

function Photo() {
  const { dataUrl, setState } = useFormState();

  function handleFileSelect({ target: { files } }) {
    const [blob] = files;

    setState('file', blob);

    const reader = new window.FileReader();

    const handler = async () => {
      const { result } = reader;
      setState('dataUrl', result);

      const { width, height } = await computeImageDimensions(result);
      const face = await detectFaceFromUrl(result);
      setState('fileDetails', { width, height, face });
    };

    reader.onload = handler;
    reader.readAsDataURL(blob);
  }

  function handleReset() {
    setState('file', null);
    setState('dataUrl', '');
  }

  return dataUrl ? (
    <div>
      <img
        className="photo-preview qa-photo-preview"
        src={dataUrl}
        alt="uploaded file"
      />
      <div>
        <Button
          onClick={handleReset}
          variant="outline-danger"
          size="lg"
          block
          className="mt-2"
        >
          ändern
        </Button>
      </div>
    </div>
  ) : (
    <Form>
      <Form.Group>
        <Form.Label>Dein Foto</Form.Label>
        <FileInputButton
          className="d-block border file-input rounded bg-light p-4 text-center qa-file-input"
          onChange={handleFileSelect}
          accept="image/png, image/jpeg"
          name="image"
        >
          <Icon className="m-3" name="upload" />
          Wähle ein Bild von der Festplatte oder benutze dein Smartphone, um
          direkt eins zu schießen!
        </FileInputButton>
      </Form.Group>
    </Form>
  );
}

export default Photo;
