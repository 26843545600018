import React from 'react';

import { resolutions } from '../constants';

// TODO: these params can be simplified and/or retrieved from the store instead
// eslint-disable-next-line import/prefer-default-export
export const ImageContext = React.createContext({
  // used by resolveImage
  resolution: resolutions.full,

  // this is basically an alias for store/controls/showStickers
  showStickers: true,

  // show the spread cut to the final format
  cropPreview: false,

  // show guide lines
  showGuides: false,

  // this lets elements behave differently when they are rendered to pdf. e.g. PDF-images don't show up in the rendered SVG, since they are patched in afterwards
  rendering: false,

  // TODO: this is only used once in Sticker.js to help with pagination of double-stickers. maybe there is a better way...
  stickerRendering: false,
});
