import React, { useContext, useEffect } from 'react';
import { arrayOf, number, string, bool, oneOfType } from 'prop-types';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'react-use';

import { resolutions } from '../../../../constants';
import { getAppearanceFromProps } from '../../../../selectors/legacy';
import { fitContent } from '../../../../actions/workspace';
import { historyAnchor } from '../../../../modules/history';
import { buildFilters } from '../../../../util/filters';
import GuideRect from '../GuideRect';
import { FilterValuesShape, ImageObjectShape } from '../../../shapes';
import ClipGhost from '../ClipGhost';
import { ImageContext } from '../../../ImageContext';
import SvgImage from '../../SvgImage/SvgImage';
import SvgImageBase from '../../SvgImage/SvgImageBase';
import MoveInsideIcon from './MoveInsideIcon';
import MoveOperation from '../../../operations/MoveOperation';
import BaseElement from '../BaseElement';

function Image(props) {
  const {
    blend,
    colors,
    fillOpacity,
    filter,
    gradientMap,
    gradientMapIntensity,
    grayscale,
    width,
    height,
    id,
    image: imageId,
    pexelsId,
    isSelected,
    selectInside,
    crotation,
    cscale,
    scale,
    workspaceScale,
    cx,
    cy,
    flip,
    dropIndication,
    imageObject,
    pexelsObject,
  } = props;
  const dispatch = useDispatch();
  const prevImageId = usePrevious(imageId);

  /**
   * This effect fits a new image dropped from the sideabar onto an existing
   * Image element into the frame (`object-fit: cover`).
   * Only dispatching the action if `prevImageId` is not undefined ensures
   * we keep the original fit when cutting and pasting.
   */
  useEffect(() => {
    if (prevImageId !== undefined) {
      const imageItem = {
        props: { id, image: imageId, pexelsId, width, height },
      };
      dispatch(fitContent(imageItem, true));
      dispatch(historyAnchor());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageId, pexelsId]);

  const { fill, stroke, strokeWidth, opacity } = getAppearanceFromProps(props);

  const filters = buildFilters(
    {
      blend,
      fill,
      fillOpacity,
      filter,
      gradientMap,
      gradientMapIntensity,
      grayscale,
      id,
    },
    colors
  );
  const filterUrl = filters ? `url(#filter-${id})` : '';

  const isSelectedInside = isSelected && selectInside;

  const { rendering, resolution } = useContext(ImageContext);

  const showLowResolutionProxy = !rendering && resolution !== resolutions.small;

  return (
    <>
      {/* Temporary fix for duplicate IDs / missing clip paths bug in Safari */}
      <defs>
        <clipPath id={`clip-${id}`}>
          <rect x={0} y={0} width={width} height={height} />
        </clipPath>
        {filters}
      </defs>
      <BaseElement {...props}>
        <g opacity={opacity}>
          {stroke !== 'none' && (
            <rect
              width={width}
              height={height}
              stroke={stroke}
              strokeWidth={strokeWidth}
              fill="none"
              strokeLinecap="square"
            />
          )}
          <rect width={width} height={height} fill={fill} />
          <ClipGhost
            clipPath={`url(#clip-${id})`}
            shouldRenderUnclipped={isSelectedInside}
            className={`inside qa-raw-image qa-image-element-imageId-${imageId}`}
            transform={`translate(${cx},${cy}) scale(${cscale}) rotate(${crotation})`}
          >
            {showLowResolutionProxy && (
              <SvgImageBase
                imageObject={imageObject}
                pexelsObject={pexelsObject}
                resolution={resolutions.small}
                filter={filterUrl}
                flip={flip}
              />
            )}
            <SvgImage
              id={id}
              imageObject={imageObject}
              pexelsObject={pexelsObject}
              filter={filterUrl}
              flip={flip}
              resolution={resolution}
              isSelectedInside={isSelectedInside}
              scale={scale}
              cscale={cscale}
              workspaceScale={workspaceScale}
              className="qa-main-svg-image"
            />
          </ClipGhost>
          <GuideRect width={width} height={height} />
          {dropIndication && (
            <rect className="drop-indication" width={width} height={height} />
          )}
        </g>
        {isSelectedInside && (
          <>
            <MoveInsideIcon width={width} height={height} />
            <MoveOperation id={id} width={width} height={height} />
          </>
        )}
      </BaseElement>
    </>
  );
}

Image.defaultProps = {
  blend: null,
  fillOpacity: null,
  filter: null,
  gradientMap: false,
  gradientMapIntensity: 0,
  grayscale: false,
  image: null,
  pexelsId: null,
  dropIndication: false,
  crotation: 0,
  cscale: 1,
  scale: 1,
  workspaceScale: 1,
  cx: 0,
  cy: 0,
  flip: false,
  imageObject: null,
  pexelsObject: null,
};

Image.propTypes = {
  blend: string,
  fillOpacity: number,
  filter: FilterValuesShape,
  gradientMap: bool,
  gradientMapIntensity: number,
  grayscale: bool,
  height: number.isRequired,
  width: number.isRequired,
  id: string.isRequired,
  colors: arrayOf(string).isRequired,
  image: oneOfType([string, number]),
  pexelsId: number,
  dropIndication: bool,
  isSelected: bool.isRequired,
  selectInside: bool.isRequired,
  crotation: number,
  cscale: number,
  scale: number,
  workspaceScale: number,
  cx: number,
  cy: number,
  flip: bool,
  imageObject: ImageObjectShape,
  pexelsObject: ImageObjectShape,
};

export default Image;
