import { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getSelectedElements,
  getSingleSelectedElement,
} from '../selectors/legacy';
import { getSelectInside } from '../selectors/selection';
import {
  getAxisAlignedBounds,
  getOrientedBounds,
  getOrientedImageBounds,
} from '../util/geometry';
import { getImageSize } from '../selectors/images';

/**
 * Returns the bounds needed by <Handles/> to draw the Handle-Elements.
 * There are 3 cases:
 * - A single element is selected: the handles should rotate with the
 *   element (oriented bounds)
 * - Multiple elements are selected: the handles be unrotated and cover the
 *   area of all selected elements (axis-aligned bounds)
 * - A single image element is selected via select-inside (aka inside its
 *   frame): here handles needs to draw two frames, for the element and the
 *   image inside the element (both oriented bounds)
 * All returned bounds are in client-coordinates
 */
export default function useHandlesBounds() {
  const selectedElements = useSelector(getSelectedElements);
  const singleSelectedElement = useSelector(getSingleSelectedElement);
  const selectInside = useSelector(getSelectInside);
  const [bounds, setBounds] = useState({});
  const targetSelector = '.viewport';

  const imageSize = useSelector(state => {
    return singleSelectedElement?.type === 'Image' && selectInside
      ? getImageSize(state, singleSelectedElement)
      : null;
  });

  const imageSizeDefined = !!imageSize;
  const { width, height } = imageSize || {};

  useLayoutEffect(() => {
    const axisAlignedBounds = getAxisAlignedBounds(
      selectedElements,
      targetSelector
    );
    const elementBounds = singleSelectedElement
      ? getOrientedBounds(singleSelectedElement, targetSelector)
      : axisAlignedBounds;
    const imageBounds = imageSizeDefined
      ? getOrientedImageBounds(
          singleSelectedElement,
          { width, height },
          targetSelector
        )
      : null;
    setBounds({ elementBounds, imageBounds });
  }, [
    imageSizeDefined,
    width,
    height,
    selectedElements,
    singleSelectedElement,
  ]);

  return bounds;
}
