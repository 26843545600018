import React from 'react';
import { arrayOf, func, string } from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { selectBlueprint } from '../../../../../modules/blueprints';
import {
  getCurrentBlueprintData,
  getBlueprintData,
  getCurrentBlueprintId,
} from '../../../../../selectors/blueprints';
import BlueprintsPanel from './index';
import {
  BlueprintDataShape,
  BlueprintPreviewShape,
  ColorsShape,
  FontsShape,
  WorkspaceShape,
} from '../../../../shapes';
import { useConfirmation } from '../../../ConfirmModal/ConfirmationService';
import { getColors, getFonts } from '../../../../../selectors/colorsAndFonts';
import { getWorkspace } from '../../../../../selectors/legacy';
import useWizardBlueprints from '../../../../../containers/album_wizard/useWizardBlueprints';
import { resetWorkspace } from '../../../../../containers/editor/useStoreReset';
import useAnalytics from '../../../../../containers/app/useAnalytics';

export function ConnectedBlueprintsPanel(props) {
  const confirm = useConfirmation();
  const dispatch = useDispatch();
  const { categories, fetchAndCache, isLoading } = useWizardBlueprints();
  const analytics = useAnalytics();

  const {
    currentBlueprintData,
    filteredBlueprints,
    categoryFilter,
    onCategoryChange,
  } = props;

  function setCurrentBlueprint(blueprintId) {
    dispatch(selectBlueprint(blueprintId));

    if (!props.blueprintData[blueprintId]) {
      fetchAndCache(blueprintId);
    }
  }

  function applyBlueprintChange(blueprintId) {
    dispatch(resetWorkspace()); // reset the workspace to avoid flickering render of old one
    setCurrentBlueprint(blueprintId);

    analytics.track('Blueprint Selected', {
      id: blueprintId,
    });
  }

  function handleBlueprintChange(blueprintId) {
    const { currentBlueprintId } = props;

    if (blueprintId === currentBlueprintId) {
      return;
    }

    const { workspace, colors, fonts } = props;

    if (
      !isEqual(
        {
          workspace: currentBlueprintData.workspace,
          colors: currentBlueprintData.colors,
          fonts: currentBlueprintData.fonts,
        },
        { workspace, colors, fonts }
      )
    ) {
      confirm({
        closeButton: false,
        headerTitle: 'Hinweis',
        body:
          'Du bist im Begriff, deine bisherigen Änderungen zu überschreiben. Möchtest du fortfahren?',
        confirmText: 'Vorlage wechseln',
        declineText: 'Nein',
        backdrop: 'static',
        keyboard: false,
      })
        .then(() => {
          applyBlueprintChange(blueprintId);
        })
        .catch(() => {});
      return;
    }

    applyBlueprintChange(blueprintId);
  }

  function handleCategoryChange({ target: { value: category } }) {
    onCategoryChange(category);

    analytics.track('Blueprint Category Selected', {
      category,
    });
  }

  return (
    <BlueprintsPanel
      {...props}
      blueprints={filteredBlueprints}
      categories={categories}
      categoryFilter={categoryFilter}
      onCategoryChange={handleCategoryChange}
      onBlueprintChange={handleBlueprintChange}
      isLoading={isLoading}
    />
  );
}

ConnectedBlueprintsPanel.defaultProps = {
  blueprintData: {},
  currentBlueprintData: {},
  colors: [],
  fonts: {},
  workspace: {},
  currentBlueprintId: undefined,
};

ConnectedBlueprintsPanel.propTypes = {
  blueprintData: BlueprintDataShape,
  currentBlueprintData: BlueprintDataShape,
  colors: ColorsShape,
  fonts: FontsShape,
  workspace: WorkspaceShape,
  filteredBlueprints: arrayOf(BlueprintPreviewShape).isRequired,
  categoryFilter: string.isRequired,
  onCategoryChange: func.isRequired,
  currentBlueprintId: string,
};

const mapDispatchToProps = {
  selectBlueprint,
};

const mapStateToProps = state => ({
  currentBlueprintData: getCurrentBlueprintData(state),
  blueprintData: getBlueprintData(state),
  colors: getColors(state),
  fonts: getFonts(state),
  workspace: getWorkspace(state),
  currentBlueprintId: getCurrentBlueprintId(state),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConnectedBlueprintsPanel);
