import React from 'react';

/**
 * Global SVG definitions:
 * spread_3d_highlight: linear gradient
 * spread_3d_shadow: linear gradient
 */
function Gradients() {
  return (
    <>
      <linearGradient
        id="spread_3d_highlight"
        x1="0%"
        y1="0%"
        x2="100%"
        y2="0%"
      >
        <stop
          offset="0%"
          style={{ stopColor: 'rgb(255,255,255)', stopOpacity: 0 }}
        />
        <stop
          offset="100%"
          style={{ stopColor: 'rgb(255,255,255)', stopOpacity: 0.2 }}
        />
      </linearGradient>
      <linearGradient id="spread_3d_shadow" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset="0%"
          style={{ stopColor: 'rgb(0,0,0)', stopOpacity: 0.06 }}
        />
        <stop offset="100%" style={{ stopOpacity: 0 }} />
      </linearGradient>
    </>
  );
}

export default Gradients;
