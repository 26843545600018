import React from 'react';
import { string } from 'prop-types';
import { useDrop } from 'react-dnd';
import { useDispatch } from 'react-redux';

import { updateElements } from '../../../../actions/workspace';
import { historyAnchor } from '../../../../modules/history';
import { itemTypes } from '../../../../constants';
import Image from './Image';
import useAnalytics from '../../../../containers/app/useAnalytics';

function ImageWithDnd(props) {
  const { id } = props;
  const dispatch = useDispatch();
  const analytics = useAnalytics();

  const [collectedProps, drop] = useDrop({
    accept: [itemTypes.image, itemTypes.stock],
    collect(monitor) {
      return {
        dropIndication: monitor.isOver(),
      };
    },
    drop(item) {
      const { image, pexelsId } = item.data.props;
      dispatch(updateElements({ [id]: { image, pexelsId } }));
      dispatch(historyAnchor());

      analytics.track('Image Replaced', {
        id,
        imageId: image,
        pexelsId,
        drop: true,
      });
    },
  });

  const { dropIndication } = collectedProps;
  return (
    <g ref={drop}>
      <Image {...props} dropIndication={dropIndication} />
    </g>
  );
}

ImageWithDnd.propTypes = {
  id: string.isRequired,
};

export default ImageWithDnd;
