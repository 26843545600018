import React from 'react';
import { string } from 'prop-types';
import { Redirect } from 'react-router-dom';

function RedirectWithSearchParams({ to, ...rest }) {
  const redirectPath = `${to}${window.location.search}`;

  return <Redirect to={redirectPath} {...rest} />;
}

RedirectWithSearchParams.propTypes = {
  to: string.isRequired,
};

export default RedirectWithSearchParams;
