import { string } from 'prop-types';
import React from 'react';
import Button from 'react-bootstrap/Button';

import Icon from '../../../components/Icon';
import { OrderShape } from '../../../components/shapes';
import useApi from '../../../hooks/useApi';
import useNotifications from '../../../hooks/useNotifications';

function EsvecoJsonDownload({ className, order: { id, name } }) {
  const api = useApi();
  const { createError } = useNotifications();

  async function download() {
    try {
      const { data } = await api.get(`/orders/${id}/esveco_json`);

      const a = document.createElement('a');
      const file = new Blob([JSON.stringify(data, undefined, 2)], {
        type: 'application/json',
      });
      a.href = URL.createObjectURL(file);
      a.download = `${name}_order_data.json`;

      a.click();
      a.remove();
    } catch (err) {
      createError(err.toString());
    }
  }

  return (
    <Button
      className={`${className} qa-download-esveco-json`}
      onClick={download}
      variant="outline-primary"
      size="sm"
    >
      <Icon name="printer_fill" className="d-inline-block mr-2" />
      JSON
    </Button>
  );
}

EsvecoJsonDownload.defaultProps = {
  className: '',
};

EsvecoJsonDownload.propTypes = {
  className: string,
  order: OrderShape.isRequired,
};

export default EsvecoJsonDownload;
