import React, { useRef, useEffect, useState } from 'react';
import { Button, Form, FormControl, InputGroup } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import rangeParser from 'parse-numeric-range';

import Icon from '../../../Icon';
import {
  getSpreadsCount,
  getTargetSpreadIndex,
} from '../../../../selectors/legacy';
import { resetViewToSpreadIndex } from '../../../../modules/controls';
import { clearSelection } from '../../../../modules/selection';

function spreadIndexToPages(spreadIndex, spreadsCount) {
  const firstPage = spreadIndex * 2;
  const secondPage = firstPage + 1;
  if (spreadIndex === -1) {
    return '';
  }
  if (spreadIndex === 0) {
    return `${secondPage}`;
  }
  if (spreadIndex === spreadsCount - 1) {
    return `${firstPage}`;
  }
  return `${firstPage}-${secondPage}`;
}

function pageToSpreadIndex(page, spreadsCount) {
  if (Number.isNaN(Number(page))) {
    return null;
  }
  if (page < 1 || page >= spreadsCount * 2) {
    return null;
  }
  if (page === 1) {
    return 0;
  }
  return Math.floor(page / 2);
}

function parseInput(range) {
  const [firstPage] = rangeParser.parse(range);
  return firstPage;
}

function PageControl() {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const targetSpreadIndex = useSelector(getTargetSpreadIndex);
  const spreadsCount = useSelector(getSpreadsCount);
  const [value, setValue] = useState('');

  function resetValue() {
    const nextValue = spreadIndexToPages(targetSpreadIndex, spreadsCount);
    setValue(nextValue);
    if (inputRef.current) {
      inputRef.current.blur();
    }
  }

  useEffect(resetValue, [targetSpreadIndex, spreadsCount]);

  function handleFocus() {
    inputRef.current.setSelectionRange(0, inputRef.current.value.length);
    inputRef.current.select();
  }

  function handleButtonFocus(event) {
    event.preventDefault();
    event.target.blur();
  }

  function handleSubmit(event) {
    event.preventDefault();
    const page = parseInput(value);
    const nextSpreadIndex = pageToSpreadIndex(page, spreadsCount);
    if (nextSpreadIndex === null) {
      resetValue();
      return;
    }
    dispatch(clearSelection());
    dispatch(resetViewToSpreadIndex(nextSpreadIndex));
  }

  function handleChange(event) {
    setValue(event.target.value);
  }

  function handleScroll(delta) {
    const page = parseInput(value);
    const nextSpreadIndex = pageToSpreadIndex(page, spreadsCount) + delta;
    if (nextSpreadIndex < 0 || nextSpreadIndex >= spreadsCount) {
      return;
    }
    dispatch(clearSelection());
    dispatch(resetViewToSpreadIndex(nextSpreadIndex));
  }

  return (
    <Form onSubmit={handleSubmit}>
      <InputGroup className="page-control mr-2 shadow">
        <InputGroup.Prepend>
          <Button
            onClick={() => handleScroll(-1)}
            disabled={targetSpreadIndex <= 0}
            onFocus={handleButtonFocus}
            className="qa-previous-page-button"
          >
            <Icon name="chevron_left" />
          </Button>
        </InputGroup.Prepend>
        <FormControl
          ref={inputRef}
          placeholder="Seite"
          aria-label="Seite"
          className="page"
          type="text"
          onChange={handleChange}
          onBlur={handleSubmit}
          value={value}
          onFocus={handleFocus}
        />
        <InputGroup.Append>
          <Button
            onClick={() => handleScroll(+1)}
            disabled={targetSpreadIndex >= spreadsCount - 1}
            onFocus={handleButtonFocus}
            className="qa-next-page-button"
          >
            <Icon name="chevron_right" />
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </Form>
  );
}

export default PageControl;
