import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';
import { EditorState, convertFromRaw } from 'draft-js';

import BaseText from './BaseText';
import { getSelectedElementIds } from '../../../../selectors/legacy';
import {
  getSelectInside,
  getEditorState,
} from '../../../../selectors/selection';
import { RawDraftContentStateShape } from '../../../shapes';

function FreeText(props) {
  const { id, text } = props;
  const selectInside = useSelector(getSelectInside);
  const selectedElementIds = useSelector(getSelectedElementIds);
  const editing = selectedElementIds.includes(id) && selectInside;
  const editingEditorState = useSelector(getEditorState);
  const editorState = editing
    ? editingEditorState
    : EditorState.createWithContent(convertFromRaw(text));
  return <BaseText editorState={editorState} readOnly={!editing} {...props} />;
}

FreeText.propTypes = {
  id: string.isRequired,
  text: RawDraftContentStateShape.isRequired,
};

export default FreeText;
