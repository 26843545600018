import { useCallback, useMemo } from 'react';
import { batch, useSelector } from 'react-redux';
import { useGesture } from '@use-gesture/react';
import throttle from 'lodash/throttle';

import { getOperationActive } from '../../../selectors/controls';
import { isLegacyWindowsFirefox } from '../../../util';
import useViewport from './useViewport';
import useScreenSize from '../../../hooks/useScreenSize';

export const zoomSensitivity = 0.0075;
export const windowsFirefoxScrollWheelFactor = 20;

const pinchSamplingRate = 50; // Handle max. 50 events per second
const deltaScaleFactor = 300; // Magic number to scale pinch event's `deltaX`

function parseWheelDeltas(delta) {
  const [deltaX, deltaY] = delta;

  if (isLegacyWindowsFirefox()) {
    return [
      deltaX * windowsFirefoxScrollWheelFactor,
      deltaY * windowsFirefoxScrollWheelFactor,
    ];
  }

  return delta;
}

/**
 * Uses the current zoom level and the event to determine the
 * next zoom value.
 *
 * Wheel and pinch events use different event properties to
 * proxy a zoom factor:
 *
 *   - `deltaY` for wheel events (- if moving wheel down, + if up)
 *   - `deltaX` for pinch events (- if moving fingers together, + if apart)
 *
 * We empirically define a delta unit that holds a somewhat similar baseline
 * zoom factor for both event types.
 *
 * https://github.com/pmndrs/use-gesture/issues/401#issuecomment-982380714
 */
export function calculateZoom({ zoom, type, delta, offset }) {
  const [deltaX, deltaY] = parseWheelDeltas(delta);
  const [offsetX] = offset || [];

  /**
   * The pinch `deltaX` will not reset on gesture end and instead
   * grow to 0/Inf. In order to keep zoom responsive,
   * we scale it by the inverse of the current offset.
   */
  const scaledDeltaX = deltaX / offsetX;
  const nextDelta = type === 'wheel' ? deltaY : scaledDeltaX * deltaScaleFactor;

  // Zooming in
  let zoomFactor = 1 + Math.abs(nextDelta) * zoomSensitivity;

  // Zooming out
  const isWheelZoomOut = type === 'wheel' && deltaY > 0;
  const isPinchZoomOut = type !== 'wheel' && deltaX < 0;

  if (isWheelZoomOut || isPinchZoomOut) {
    zoomFactor = 1 / zoomFactor;
  }

  return zoom * zoomFactor;
}

export function zoomToPivot({
  wantedZoom,
  clientPivot,
  pan,
  clientToViewport,
  restrictPanAndZoom,
}) {
  const viewportPivot = clientToViewport(clientPivot);
  const [, nextZoom] = restrictPanAndZoom(pan, wantedZoom);

  // This ensures that the pivot stays fixed after the operation
  const updatedPivot = clientToViewport(clientPivot, pan, nextZoom);

  const nextPan = {
    x: pan.x - (updatedPivot.x - viewportPivot.x),
    y: pan.y - (updatedPivot.y - viewportPivot.y),
  };

  // => [pan, zoom]
  return restrictPanAndZoom(nextPan, nextZoom);
}

export default function useViewportGestures() {
  const operationActive = useSelector(getOperationActive);
  const { isMobile } = useScreenSize();
  const {
    pan,
    zoom,
    setPan,
    setZoom,
    restrictPanAndZoom,
    clientToViewport,
    viewportRef,
  } = useViewport();

  function handlePan({ event, delta }) {
    const { altKey, type } = event;
    let [deltaX, deltaY] = parseWheelDeltas(delta);

    if (altKey) {
      [deltaX, deltaY] = [deltaY, deltaX]; // Invert scroll direction
    }

    // Calculate next pan
    const panDirection = type === 'wheel' ? 1 : -1;
    const wantedPan = {
      x: pan.x + (deltaX / zoom) * panDirection,
      y: pan.y + (deltaY / zoom) * panDirection,
    };

    const [nextPan] = restrictPanAndZoom(wantedPan, zoom);
    setPan(nextPan);
  }

  const handleZoom = useCallback(
    ({ event, delta, offset }) => {
      const { clientX, clientY, type } = event;

      const wantedZoom = calculateZoom({
        zoom,
        type,
        offset,
        delta,
      });

      const [nextPan, nextZoom] = zoomToPivot({
        wantedZoom,
        clientPivot: {
          x: clientX,
          y: clientY,
        },
        pan,
        clientToViewport,
        restrictPanAndZoom,
      });

      batch(() => {
        setPan(nextPan);
        setZoom(nextZoom);
      });
    },
    [clientToViewport, pan, restrictPanAndZoom, setPan, setZoom, zoom]
  );

  const handleDrag = state => {
    const { event, delta } = state;
    handlePan({ event, delta });
  };

  const handlePinch = useCallback(
    state => {
      const { event, delta, offset } = state;

      event.preventDefault(); // Prevent built-in browser pinch zoom

      handleZoom({ event, delta, offset });
    },
    [handleZoom]
  );

  /**
   * Wheel events are handled depending on which modifier key is used:
   *  - `ctrlKey`: zooming in/out
   *  - `altKey`: scrolling (panning) horizontally
   *
   * Without either of these modifier keys, wheel events trigger the default
   * vertical scrolling (panning).
   */
  const handleWheel = state => {
    const { event, active: activeGesture, delta } = state;
    const { ctrlKey } = event;

    event.preventDefault(); // prevent browser history navigation on swipe

    if (!activeGesture || operationActive) {
      return;
    }

    if (ctrlKey) {
      handleZoom({ event, delta });
    } else {
      handlePan({ event, delta });
    }
  };

  /**
   * Pinch events can fire 100 times a second easily.
   * For performance reason, we only handle them every n ms.
   */
  const handlePinchThrottled = useMemo(
    () => throttle(handlePinch, 1000 / pinchSamplingRate),
    [handlePinch]
  );

  /**
   * By default, React Use Gesture hooks register `passive` event listeners.
   * Passive event listeners must never call `event.preventDefault()`.
   * Some browsers, e.g. Google Chrome, use built-in gestures like:
   *
   *   - Swipe to navigate ("overscroll history navigation")
   *   - Pinch zoom
   *
   * To prevent these default browser features while interacting with the
   * viewport we need events to be active, not passive.
   *
   * https://developer.mozilla.org/docs/Web/API/EventTarget/addEventListener
   * https://use-gesture.netlify.com/docs/options
   */
  const genericOptions = {
    target: viewportRef,
    eventOptions: { passive: false },
  };

  const dragOptions = {
    enabled: isMobile,
  };

  useGesture(
    {
      /**
       * The first event of a pinch gesture will alyways have a 0 `deltaX`
       * and thus not affect the zoom level. Because we throttle our
       * pinch handler, we need to skip the first event, else zooming would
       * always feel delayed.
       */
      onPinch: state => !state.first && handlePinchThrottled(state),
      onWheel: handleWheel,
      onDrag: handleDrag,
    },
    {
      ...genericOptions,
      drag: dragOptions,
    }
  );
}
