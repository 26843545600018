import React from 'react';
import { number } from 'prop-types';

import { getAppearanceFromProps } from '../../../selectors/legacy';
import BaseElement from './BaseElement';

function Rectangle(props) {
  const { height, width } = props;

  return (
    <BaseElement {...props}>
      <rect {...getAppearanceFromProps(props)} width={width} height={height} />
    </BaseElement>
  );
}

Rectangle.propTypes = {
  height: number.isRequired,
  width: number.isRequired,
};

export default Rectangle;
