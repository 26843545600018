import { useDispatch } from 'react-redux';
import flow from 'lodash/flow';

import { clearSelection } from '../../modules/selection';
import { resetControls } from '../../modules/controls';
import { initialState as initialWorkspace } from '../../modules/workspace';
import { loadWorkspace } from '../../actions/workspace';
import { resetStickers } from '../../actions/stickers';
import { albumChanged } from '../../modules/history';
import { resetStickerUploads } from '../../actions/stickerUploads';
import { resetImages } from '../../actions/images';
import { setAlbumLoaded } from '../../modules/albums';

export const resetWorkspace = () => loadWorkspace(initialWorkspace);
const resetAlbumChanged = () => albumChanged(false);
const resetAlbumLoaded = () => setAlbumLoaded(false);

/**
 * These will be called in the defined order:
 */
const actionCreators = [
  resetAlbumLoaded,
  resetWorkspace,
  resetImages,
  clearSelection,
  resetControls,
  resetStickers,
  resetStickerUploads,
  resetAlbumChanged,
];

/**
 * A hook that resets certain parts of the redux store.
 * By default, only album-local parts will be reset on
 * initial mount, you may pass additional dependencies
 * that will cause a reset.
 *
 * @param {Array} deps array of dependencies
 */
function useStoreReset() {
  const dispatch = useDispatch();
  const injectDispatch = fn => () => dispatch(fn());
  const storeReset = flow(...actionCreators.map(injectDispatch));

  return storeReset;
}

export default useStoreReset;
