import { spring } from 'react-flip-toolkit';
import { batch } from 'react-redux';

import { UPDATE } from '../modules/viewport';
import { getSelectedElements } from '../selectors/legacy';
import {
  getAnimatedPan,
  getAnimatedZoom,
  getPan,
  getZoom,
  getZoomToSelectionInitialBounds,
} from '../selectors/viewport';
import {
  applyPaddingToRectOrBounds,
  fitBoundsIntoArea,
  getAxisAlignedBounds,
  rectToBounds,
} from '../util/geometry';

const update = payload => ({
  type: UPDATE,
  payload,
});

export const getViewportClientRect = () =>
  document.querySelector('.viewport')?.getBoundingClientRect();

export const setPanAndZoom = (pan, zoom) => update({ pan, zoom });

const setAnimatedPanAndZoom = (animatedPan, animatedZoom) =>
  update({ animatedPan, animatedZoom });

/**
 * Used only internally by `animatePanAndZoom`: Save the last animated
 * pan/zoom to the actual pan/zoom and clear the animated values.
 */
const completeAnimatedPanAndZoom = () => (dispatch, getState) => {
  const state = getState();
  const zoom = getAnimatedZoom(state);
  const pan = getAnimatedPan(state);

  batch(() => {
    dispatch(setPanAndZoom(pan, zoom));
    dispatch(setAnimatedPanAndZoom(null, null));
  });
};

/**
 * Used only internally by `zoomToSelection` and `exitZoomToSelection`:
 * Starts a pan/zoom animation to `targetBounds` (workspace-coordinates).
 * "bounds" vs. "rect": bounds contains left/top/right/bottom, while rect
 * contains x/y/width/height, making bounds better suited for animation.
 */
const animatePanAndZoom = (targetBounds, viewportClientRect) => (
  dispatch,
  getState
) => {
  const state = getState();
  const pan = getPan(state);
  const zoom = getZoom(state);

  const currentBounds = rectToBounds({
    x: pan.x,
    y: pan.y,
    width: viewportClientRect.width / zoom,
    height: viewportClientRect.height / zoom,
  });

  spring({
    config: 'noWobble',
    // Start an animation of the bounds-values
    values: {
      top: [currentBounds.top, targetBounds.top],
      left: [currentBounds.left, targetBounds.left],
      bottom: [currentBounds.bottom, targetBounds.bottom],
      right: [currentBounds.right, targetBounds.right],
    },
    onUpdate: bounds => {
      /**
       * The parameters include all fields from the `values` parameter,
       * making it a complete "bounds" structure
       */
      const { x, y, scale } = fitBoundsIntoArea(bounds, viewportClientRect);
      dispatch(setAnimatedPanAndZoom({ x, y }, scale));
    },
    onComplete: () => {
      dispatch(completeAnimatedPanAndZoom());
    },
  });

  // Return the current bounds, used for restoring the initial viewport
  return currentBounds;
};

// Animate the viewport to the selected elements
export const zoomToSelection = () => (dispatch, getState) => {
  const state = getState();
  const selectedElements = getSelectedElements(state);

  const viewportClientRect = getViewportClientRect();

  const bounds = rectToBounds(
    getAxisAlignedBounds(selectedElements, '.viewport')
  );
  const { scale } = fitBoundsIntoArea(bounds, viewportClientRect);

  // Padding in pixels, to have enough space for the toolbar
  const padding = 200;

  const targetBounds = applyPaddingToRectOrBounds(bounds, padding / scale);

  batch(() => {
    /**
     * Start animation: animatePanAndZoom starts an animation and
     * returns the current viewport bounds, so it can be used later
     * to restore the initial pan/zoom when exiting the zoomed state.
     */
    const zoomToSelectionInitialBounds = dispatch(
      animatePanAndZoom(targetBounds, viewportClientRect)
    );

    // Save the initial pan/zoom state, used in `exitZoomToSelection`
    dispatch(update({ zoomToSelectionInitialBounds }));
  });
};

// Animate the viewport to the initial state (before the selection was zoomed)
export const exitZoomToSelection = () => (dispatch, getState) => {
  const state = getState();
  const zoomToSelectionInitialBounds = getZoomToSelectionInitialBounds(state);
  if (!zoomToSelectionInitialBounds) {
    return;
  }

  const viewportClientRect = getViewportClientRect();

  batch(() => {
    // Start animation to the initial pan/zoom state
    dispatch(
      animatePanAndZoom(zoomToSelectionInitialBounds, viewportClientRect)
    );

    // Clear the initial pan/zoom state
    dispatch(update({ zoomToSelectionInitialBounds: false }));
  });
};

export const setViewportHeight = viewportHeight => update({ viewportHeight });
