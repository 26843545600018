export const UPDATE = 'viewport/UPDATE';

const initialState = {
  pan: {
    x: 0,
    y: 0,
  },
  zoom: 1,

  /**
   * `animatedPan` and `animatedZoom` are structually identical to their
   * regular counterparts, but override them in their respective selectors
   * if truthy. They are indirectly set by the `spring` instance created
   * in `animatePanAndZoom` (and its callback `completeAnimatedPanAndZoom`).
   */
  animatedPan: null,
  animatedZoom: null,

  /**
   * This is set in `zoomToSelection` and stores the initial pan/zoom state
   * (in a "bounds" format). It is cleared in `exitZoomToSelection`, which
   * animates the viewport back to this value.
   */
  zoomToSelectionInitialBounds: false,

  // Moved here from `controls` module
  viewportHeight: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
