import useAuth from '../../hooks/useAuth';

function AdminOnly({ children }) {
  const { isAdmin } = useAuth();

  if (!isAdmin) {
    return null;
  }

  return children;
}

AdminOnly.propTypes = {};

export default AdminOnly;
