import React, { createContext, useContext, useState } from 'react';
import { node } from 'prop-types';

const initialState = {
  step: 0,
  name: '',
  lastName: '',
  position: '',
  sectionId: '',
  file: null,
  dataUrl: '',
  fileDetails: {},
  error: undefined,
};

export const FormStateContext = createContext(initialState);

export default function FormStateProvider({ children }) {
  const [formState, setFormState] = useState(initialState);

  function setState(key, value) {
    setFormState(previousState => ({
      ...previousState,
      [key]: value,
    }));
  }

  function setStep(nextStep) {
    setFormState(previousState => ({
      ...previousState,
      step: nextStep,
    }));
  }

  function reset() {
    setFormState(initialState);
  }

  return (
    <FormStateContext.Provider
      value={{ ...formState, setState, setStep, reset }}
    >
      {children}
    </FormStateContext.Provider>
  );
}

FormStateProvider.propTypes = {
  children: node.isRequired,
};

export function useFormState() {
  return useContext(FormStateContext);
}
