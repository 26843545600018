import { bool, func, string } from 'prop-types';
import React from 'react';

import { fontSwatches } from '../../../../../constants';
import { FontsShape } from '../../../../shapes';

function FontTiles({ fonts, onClick, active, className, square }) {
  const { title, sub_title: subTitle, paragraph } = fonts;

  return (
    <div
      className={`font-tiles${
        square ? ' font-tiles--square' : ''
      } qa-font-tiles d-flex align-items-center rounded cursor-pointer border border-muted ${className}${
        active ? ' active qa-active' : ''
      }`}
      onClick={onClick}
    >
      <div
        className="tile qa-font-tile qa-font-title font-weight-bold"
        style={{ fontFamily: title }}
      >
        {fontSwatches.Title.label}
      </div>
      <div
        className="tile qa-font-tile qa-font-subtitle"
        style={{ fontFamily: subTitle }}
      >
        {fontSwatches.Subtitle.label}
      </div>
      <div
        className="tile qa-font-tile qa-font-paragraph font-weight-light"
        style={{ fontFamily: paragraph }}
      >
        {fontSwatches.Paragraph.label}
      </div>
    </div>
  );
}

FontTiles.defaultProps = {
  active: false,
  onClick: () => {},
  className: '',
  square: false,
};

FontTiles.propTypes = {
  active: bool,
  fonts: FontsShape.isRequired,
  onClick: func,
  className: string,
  square: bool,
};

export default FontTiles;
