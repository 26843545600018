import { useCallback, useState } from 'react';
import { useTimeoutFn } from 'react-use';

/**
 * A hook that provides a `value` that will by default switch from
 * false -> true -> false within a few seconds as well as imperative handles
 * to manually set it.
 */
function useTimeSwitch({
  initialValue = false,
  onDelay,
  offDelay,
  autoOn,
  autoOff,
}) {
  const [value, setValue] = useState(initialValue);

  const [, cancelOn] = useTimeoutFn(
    () => autoOn && setValue(!initialValue),
    onDelay
  );
  const [, cancelOff] = useTimeoutFn(
    () => autoOff && setValue(initialValue),
    onDelay + offDelay
  );

  const setOn = useCallback(() => {
    setValue(true);
    cancelOff();
  }, [setValue, cancelOff]);

  const setOff = useCallback(() => {
    setValue(false);
    cancelOn();
  }, [setValue, cancelOn]);

  return {
    setOn,
    setOff,
    value,
  };
}

export default useTimeSwitch;
