import React, { useEffect } from 'react';
import { object } from 'prop-types';
import { useLocalStorage } from 'react-use';

import useApi from '../../hooks/useApi';
import useStoreReset from '../editor/useStoreReset';
import FullScreenSpinner from '../app/FullScreenSpinner';
import useWizardAutosave from './useWizardAutosave';
import { capitalize } from '../../util';
import useAnalytics from '../app/useAnalytics';

function AuthConfirmation({ history }) {
  const api = useApi();
  const storeReset = useStoreReset();
  const analytics = useAnalytics();

  const [albumFromLocalStorage] = useLocalStorage('wizardAlbumState');
  const { removeWizardAutosave } = useWizardAutosave();

  // Foward all users that target `/album/new/confirm` by accident
  if (!albumFromLocalStorage) {
    history.push('/start');
  }

  const handleRedirect = async () => {
    const {
      data: { album },
    } = await api.post('/albums', {
      analytics_metadata: analytics.params,
      ...albumFromLocalStorage,
    });

    /**
     * We do not use the remove function returned by useLocalStorage here
     * because it sets state on an (at this point) unmounted component, triggering
     * a memory leak warning that makes some of our tests flaky. This is a known
     * false positive and no longer part of React.
     * See https://github.com/facebook/react/pull/22114 for a thorough discussion.
     */
    localStorage.removeItem('wizardAlbumState');
    storeReset();
    removeWizardAutosave();

    if (album) {
      analytics.track('Album Created', {
        id: album.id,
        blueprintId: album.blueprint_album_id,
        blueprintTitle: album.blueprint_title,
        blueprintCategory: album.blueprint_category,
        releaseDate: album.release_date,
      });

      analytics.track(`${capitalize(album.blueprint_category)} Album Created`, {
        id: album.id,
      });

      if (album.blueprint_category === 'business') {
        // eslint-disable-next-line no-unused-expressions
        window.lintrk?.('track', { conversion_id: 12292281 });

        // eslint-disable-next-line no-unused-expressions
        window.gtag?.('event', 'conversion', {
          send_to: 'AW-10842516600/DSfNCLrXuIsYEPjYjrIo',
        });
      }

      history.push(`/album/${album.id}`);
    }
  };

  useEffect(() => {
    handleRedirect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <FullScreenSpinner />;
}

AuthConfirmation.propTypes = {
  history: object.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default AuthConfirmation;
