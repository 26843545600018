import React from 'react';
import { arrayOf, string } from 'prop-types';

import SidebarSticker from '../SidebarSticker/SidebarSticker';
import { resolutions } from '../../../constants';
import { StickerShape } from '../../shapes';

function StickerUploadsList({ stickers, selectedStickerId }) {
  return (
    <>
      <h4>Uploads ({stickers.length})</h4>
      <div className="stickers-list d-flex flex-wrap py-3 my-3 border-top border-bottom">
        {stickers.map((sticker, index) => (
          <SidebarSticker
            key={sticker.id}
            id={sticker.id}
            sticker={sticker}
            index={index}
            selected={sticker.id === selectedStickerId}
            resolution={resolutions.medium}
            isStickerUpload
          />
        ))}
      </div>
    </>
  );
}

StickerUploadsList.propTypes = {
  stickers: arrayOf(StickerShape).isRequired,
  selectedStickerId: string.isRequired,
};

export default StickerUploadsList;
