import React, { useCallback } from 'react';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useDispatch, useSelector } from 'react-redux';

import ImageItem from '../ImageItem';
import { PexelsPhotoShape } from './shapes';
import { getSelectedImageIds } from '../../../selectors/selection';
import { historyAnchor } from '../../../modules/history';
import { updateElements } from '../../../actions/workspace';
import useAnalytics from '../../../containers/app/useAnalytics';

function PhotoResult({ photo }) {
  const {
    id: pexelsId,
    photographer,
    landscape,
    src: { tiny },
  } = photo;

  const selectedImageIds = useSelector(getSelectedImageIds);
  const analytics = useAnalytics();
  const dispatch = useDispatch();

  const handleImageClick = useCallback(() => {
    const propsDeltaMap = selectedImageIds.reduce((acc, id) => {
      acc[id] = { image: null, pexelsId };
      return acc;
    }, {});
    dispatch(updateElements(propsDeltaMap));
    dispatch(historyAnchor());

    selectedImageIds.forEach(id => {
      analytics.track('Image Replaced', {
        id,
        pexelsId,
        drop: false,
      });
    });
  }, [dispatch, pexelsId, selectedImageIds, analytics]);

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={<Tooltip>von {photographer}</Tooltip>}
      delay={400}
    >
      <div
        className={`pexels-result qa-pexels-result ${
          landscape ? 'landscape' : ''
        }`}
      >
        <ImageItem
          key={pexelsId}
          src={tiny}
          pexelsId={pexelsId}
          alt={`Stock image by ${photographer}`}
          onClick={handleImageClick}
        />
      </div>
    </OverlayTrigger>
  );
}

PhotoResult.propTypes = {
  photo: PexelsPhotoShape.isRequired,
};

export default PhotoResult;
