import { dimensions } from '../constants';
import { generateId, valueBetween } from './index';

// TODO move
export function flatten(a) {
  return a.reduce((flat, i) => {
    if (Array.isArray(i)) {
      return flat.concat(flatten(i));
    }
    return flat.concat(i);
  }, []);
}

export function pointToSpreadIndex(point, spreadsCount) {
  const spreadIndex = Math.floor(
    (point.y + dimensions.pagePadding / 2) /
      (dimensions.pageHeight + dimensions.pagePadding)
  );

  // Limit value to the existing spreads range
  return valueBetween(spreadIndex, 0, spreadsCount - 1);
}

export function generateSpread() {
  const page = {
    type: 'Spread',
    props: {
      id: generateId(),
      fill: '#fff',
    },
    children: [],
  };
  return page;
}

export function reorderSpreadsAccordingToSections(spreadNodes, sections) {
  let index = 0;
  const newSpreads = [];

  function addTillSectionNextSection(currentSectionId = false) {
    while (true) {
      if (index >= spreadNodes.length) break;
      if (currentSectionId) {
        // continue until another section is set
        if (
          spreadNodes[index].props.sectionId &&
          spreadNodes[index].props.sectionId !== currentSectionId
        )
          break;
      } else {
        // continue until any section is set
        if (spreadNodes[index].props.sectionId) break;
      }
      newSpreads.push(spreadNodes[index].props.id);
      index += 1;
    }
  }

  addTillSectionNextSection();
  sections.forEach(section => {
    const lastIndex = index;
    index = spreadNodes.findIndex(item => item.props.sectionId === section.id);
    if (index === -1) {
      index = lastIndex;
    } else {
      addTillSectionNextSection(section.id);
    }
  });
  addTillSectionNextSection();

  return newSpreads;
}

// TODO: extractElementsByTypeWithSpreadPosition / extractElementsByIdsWithParentPosition are almost identical - refactor into one function?
export const extractElementsByIdsWithParentPosition = (
  children,
  ids,
  x = 0,
  y = 0
) =>
  children.map(element => {
    if (ids.indexOf(element.props.id) !== -1) {
      return { ...element, x, y };
    }
    if (Array.isArray(element.children)) {
      const ex = element.props.x !== undefined ? element.props.x : 0;
      const ey = element.props.y !== undefined ? element.props.y : 0;
      return extractElementsByIdsWithParentPosition(
        element.children,
        ids,
        x + ex,
        y + ey
      );
    }
    return null;
  });

export const extractElementsByIds = (children, ids) =>
  children.map(element => {
    if (ids.indexOf(element.props.id) !== -1) {
      return element;
    }
    if (Array.isArray(element.children)) {
      return extractElementsByIds(element.children, ids);
    }
    return null;
  });
