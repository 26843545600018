import * as faceapi from 'face-api.js';

import { dimensions } from '../constants';

faceapi.nets.tinyFaceDetector.loadFromUri('/assets/weights');

/**
 * Runs face detection on an image element. Requires weights to be already loaded
 * @param {} imgNode HTMLImageElement | HTMLVideoElement | HTMLCanvasElement
 */
async function detectFaceFromImage(imgNode) {
  const options = new faceapi.TinyFaceDetectorOptions({
    inputSize: 608,
    scoreThreshold: 0.2,
  });

  // TODO: This seems to be blocking the main thread?
  const result = await faceapi.detectSingleFace(imgNode, options);

  if (!result) {
    return null;
  }

  const {
    relativeBox: { x, y, width, height },
  } = result;

  // Ignore faces that take up less than 10% of the image
  const minSize = 0.1;
  if (width < minSize || height < minSize) {
    return null;
  }

  return { x, y, width, height };
}

/**
 * Fetch an image and run face detection
 * @param {} url image url
 */
export async function detectFaceFromUrl(url) {
  return new Promise((resolve, reject) => {
    const imgNode = document.createElement('img');
    imgNode.onload = () => resolve(detectFaceFromImage(imgNode));
    imgNode.onerror = reject;
    imgNode.src = url;
  });
}

/**
 * Fetch an image and run face detection
 * @param {} face result of `detectFaceFromImage`: { x, y, width, height } in relative values to the image size
 * @param {} size result of `imageSize`: { width, height } in mm
 */
export function getStickerPositionFromFace(face, size) {
  if (!face) {
    return null;
  }

  // Percentage values where to fit the face-area inside the sticker-area
  const targetWidth = 0.47;
  const targetTop = 0.36;

  // Simplified calculation, derived from the interal-editors function of the same name
  const cscale =
    (targetWidth * dimensions.stickerWidth) / (face.width * size.width);
  const px = (face.x + face.width / 2) * size.width * cscale;
  const py = (face.y + face.height / 2) * size.height * cscale;
  const cx = dimensions.stickerWidth * 0.5 - px;
  const cy = dimensions.stickerHeight * targetTop - py;
  const crotation = 0;

  return { cx, cy, cscale, crotation };
}
