import React from 'react';
import { func } from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';

import { OrderShape } from '../../../components/shapes';
import useEditRecord from './useEditRecord';

function EditOrderForm({ order, onClose, refetchOrder }) {
  const {
    editedRecord: editedOrder,
    createHandler,
    submitting,
    handleSubmit,
  } = useEditRecord({
    initialRecord: order,
    url: `/orders/${order.id}`,
    pickKeys: ['email', 'comment', 'amount', 'amount_gross'],
    onSuccess: () => {
      refetchOrder();
      onClose();
    },
  });

  const { email, comment, amount, amount_gross } = editedOrder; // eslint-disable-line camelcase

  function handleNetToGross() {
    const handler = createHandler('amount_gross');
    handler({ target: { value: parseInt(amount * 1.19, 10) } });
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="E-Mail"
            className="mb-2"
            value={email}
            onChange={createHandler('email')}
            name="order[email]"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            type="text"
            placeholder="Betrag"
            className="mb-2"
            value={amount}
            onChange={createHandler('amount')}
            name="order[amount]"
          />
        </Col>
        <Col>
          <InputGroup>
            <Button
              variant="secondary"
              className="mb-2 qa-net-to-gross-btn"
              onClick={handleNetToGross}
            >
              x 1.19
            </Button>
            <Form.Control
              type="text"
              placeholder="Betrag"
              className="mb-2"
              value={amount_gross} // eslint-disable-line camelcase
              onChange={createHandler('amount_gross')}
              name="order[amount_gross]"
            />
          </InputGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Kommentar"
            value={comment}
            onChange={createHandler('comment')}
            name="order[comment]"
          />
        </Col>
      </Row>
      <Button
        variant="primary"
        type="submit"
        className="mt-3 qa-submit-order-btn"
        disabled={submitting}
      >
        Speichern
      </Button>
      <Button variant="link" className="mt-3" onClick={onClose}>
        Abbrechen
      </Button>
    </Form>
  );
}

EditOrderForm.defaultProps = {};

EditOrderForm.propTypes = {
  order: OrderShape.isRequired,
  onClose: func.isRequired,
  refetchOrder: func.isRequired,
};

export default EditOrderForm;
