import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectAllowedApplicableTools } from '../../../selectors/selection';
import * as toolComponents from './tools';
import MobileFooterMenu from '../../../containers/editor/FooterMenu.mobile';
import { clearSelection } from '../../../modules/selection';
import { setMobileFooterOpen } from '../../../modules/controls';
import ToolbarNotification from './ToolbarNotification';

function MobileToolbar() {
  const tools = useSelector(selectAllowedApplicableTools);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMobileFooterOpen(true));

    return () => {
      dispatch(setMobileFooterOpen(false));
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    dispatch(clearSelection());
    dispatch(setMobileFooterOpen(false));
  };

  return (
    <MobileFooterMenu onClose={handleClose}>
      <div className="element-toolbar-mobile qa-toolbar-mobile rounded py-1">
        <div className="p-2">
          <ToolbarNotification />
          {tools.map(toolName => {
            const Component = toolComponents[toolName];
            return <Component key={toolName} dropDirection="up" />;
          })}
        </div>
      </div>
    </MobileFooterMenu>
  );
}

MobileToolbar.propTypes = {};

export default MobileToolbar;
