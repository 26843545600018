import { createAction } from 'redux-actions';

import { CLOSE, PUSH, types } from '../modules/notifications';
import { generateId } from '../util/index';

function createNotification(message, type) {
  return {
    id: generateId(),
    message,
    type,
    timestamp: Date.now(),
  };
}

/**
 * Add an error notification.
 */
export const createError = createAction(PUSH, message =>
  createNotification(message, types.error)
);

/**
 * Add a warning notification.
 */
export const createWarning = createAction(PUSH, message =>
  createNotification(message, types.warning)
);

/**
 * Add an info notification.
 */
export const createInfo = createAction(PUSH, message =>
  createNotification(message, types.info)
);

export const closeNotification = createAction(CLOSE);
