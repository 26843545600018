import { arrayOf } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import {
  getColors,
  getFonts,
} from '../../../../../../selectors/colorsAndFonts';
import { getWorkspace } from '../../../../../../selectors/legacy';
import {
  ColorsShape,
  FontsShape,
  StylesPanelStateMachineShape,
} from '../../../../../shapes';
import AlbumPreviewNavigator from '../../../Content/AlbumPreviewNavigator';
import DropoutSection from '../DropoutSection';
import ColorsCarousel from './ColorCarousel';
import FontsCarousel from './FontsCarousel';
import StickerTemplateCarousel from './StickerTemplateCarousel';

function WizardStylesPanelMobile({
  availableColors,
  availableFonts,
  stateMachine,
}) {
  const colors = useSelector(getColors);
  const fonts = useSelector(getFonts);
  const workspace = useSelector(getWorkspace);
  const { stickerTemplateId, setStickerTemplateId } = stateMachine;

  return (
    <div className="d-flex flex-column wizard-styles-panel-mobile">
      <DropoutSection title="Vorschau" className="preview-navigator__wrapper">
        <div className="bg-light py-4 d-flex justify-content-center">
          <AlbumPreviewNavigator
            className="preview-navigator qa-preview-navigator"
            flatButtons
            workspace={workspace}
          />
        </div>
      </DropoutSection>
      <div className="wizard-styles-panel-mobile__carousels">
        <ColorsCarousel options={availableColors} value={colors} />
        <FontsCarousel options={availableFonts} value={fonts} />
        <StickerTemplateCarousel
          stickerTemplateId={stickerTemplateId}
          setStickerTemplateId={setStickerTemplateId}
        />
      </div>
    </div>
  );
}

WizardStylesPanelMobile.defaultProps = {};

WizardStylesPanelMobile.propTypes = {
  availableColors: arrayOf(ColorsShape).isRequired,
  availableFonts: arrayOf(FontsShape).isRequired,
  stateMachine: StylesPanelStateMachineShape.isRequired,
};

export default WizardStylesPanelMobile;
