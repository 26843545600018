import React from 'react';
import { string, bool, number } from 'prop-types';
import Spinner from 'react-bootstrap/Spinner';

import formatStickerNumber from '../../svg/elements/Sticker/formatStickerNumber';
import StickerImage from '../../svg/elements/Sticker/StickerImage';
import { dimensions, resolutions } from '../../../constants';

function Sticker(props) {
  const {
    name,
    position,
    stickerNumber,
    image,
    placeholder,
    showImageLoadingSpinner,
    doubleSticker,
  } = props;

  const formattedStickerNumber = formatStickerNumber(stickerNumber);

  // 10 mm spacing below image, to reserve space for text (name, position, number)
  const spacing = 10;
  const viewBox = `0 0 ${dimensions.stickerWidth *
    (doubleSticker ? 2 : 1)} ${dimensions.stickerHeight - spacing}`;

  return (
    <>
      <div className="sticker-image qa-sticker-image w-100 h-80">
        {!placeholder && image ? (
          <svg
            shapeRendering="optimizeSpeed"
            viewBox={viewBox}
            xmlns="http://www.w3.org/2000/svg"
            className="w-100 h-100"
          >
            <StickerImage {...props} resolution={resolutions.medium} />
          </svg>
        ) : (
          <div className="no-image qa-no-image h-100 d-flex justify-content-center">
            {placeholder && <span className="qa-add-new-sticker">+</span>}
          </div>
        )}
        {showImageLoadingSpinner && (
          <div className="sticker-image-loading-spinner position-absolute w-100 h-100 d-flex justify-content-center align-items-center">
            <Spinner size="lg" animation="border" variant="light" />
          </div>
        )}
      </div>
      <div className="sticker-info-container w-100 d-flex justify-content-between align-items-end">
        <div className="sticker-info h-100 w-70 d-flex flex-column flex-no-wrap">
          <div className="sticker-name qa-sticker-name">{name}</div>
          <div className="sticker-position qa-sticker-position">{position}</div>
        </div>
        <span className="sticker-number qa-sticker-number w-30 text-right">
          {formattedStickerNumber}
        </span>
      </div>
    </>
  );
}

Sticker.defaultProps = {
  name: 'Name',
  position: 'Titel',
  doubleSticker: false,
  image: null,
  placeholder: false,
  stickerNumber: null,
  resolution: undefined,
  showImageLoadingSpinner: false,
};

Sticker.propTypes = {
  name: string,
  position: string,
  doubleSticker: bool,
  stickerNumber: number,
  image: string,
  placeholder: bool,
  resolution: string,
  showImageLoadingSpinner: bool,
};

export default Sticker;
