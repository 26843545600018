import React from 'react';
import { string, number, oneOfType } from 'prop-types';
import { useDrag } from 'react-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useAsync } from 'react-use';

import { itemTypes } from '../../../constants';
import ImageItem from './ImageItem';
import { computeNativeImageSize } from '../../../util/images';
import { getImage } from '../../../selectors/images';
import usePexel from '../../../hooks/usePexel';
import { updateControls } from '../../../modules/controls';

function ImageItemWithDnd(props) {
  const dispatch = useDispatch();
  const { image: imageId, pexelsId } = props;
  const { resolvePexelsId } = usePexel();

  const imageObject = useSelector(state => getImage(state, imageId));
  const { value: pexelsObject } = useAsync(
    async () => resolvePexelsId(pexelsId),
    [pexelsId]
  );
  const size = computeNativeImageSize(imageObject || { details: pexelsObject });
  const [, drag] = useDrag({
    item: {
      data: {
        type: 'Image',
        props: {
          image: imageId,
          pexelsId,
          ...size,
        },
      },
      type: pexelsId ? itemTypes.stock : itemTypes.image,
    },
    begin() {
      dispatch(
        updateControls({ operationActive: true, shouldHideHandles: true })
      );
    },
    end() {
      dispatch(
        updateControls({ operationActive: false, shouldHideHandles: false })
      );
    },
  });

  return <ImageItem {...props} dragRef={drag} />;
}

ImageItemWithDnd.defaultProps = {
  image: null,
  pexelsId: null,
};

ImageItemWithDnd.propTypes = {
  image: oneOfType([string, number]),
  pexelsId: number,
};

export default ImageItemWithDnd;
