import React from 'react';
import { node, number, string, bool } from 'prop-types';

import SvgImageBase from '../../SvgImage/SvgImageBase';
import { dimensions } from '../../../../constants';
import withImageResolver from '../../SvgImage/withImageResolver';

const ImageWithResolver = withImageResolver(SvgImageBase);

function StickerImage({
  id,
  cx,
  cy,
  cscale,
  crotation,
  image,
  filters,
  doubleSticker,
  withFaceBox,
  resolution,
  clipPath,
}) {
  const { stickerWidth: width, stickerHeight: height, pageBleed } = dimensions;

  /**
   * Our sticker images extend into the bleed. The to-be-trimmed part
   * will be clipped in the workspace (via `clipPath`), but not while rendering.
   */
  const cxWithBleed = cx - pageBleed;
  const cyWithBleed = cy - pageBleed;

  return (
    <g clipPath={clipPath}>
      <rect
        width={doubleSticker ? 2 * width : width}
        height={height}
        fill="#fff"
      />
      <g
        transform={`translate(${cxWithBleed} ${cyWithBleed}) scale(${cscale}) rotate(${crotation})`}
        data-id={id}
      >
        {filters && <defs>{filters}</defs>}
        <ImageWithResolver
          resolution={resolution}
          image={image}
          withFaceBox={withFaceBox}
        />
      </g>
    </g>
  );
}

StickerImage.defaultProps = {
  cx: 0,
  cy: 0,
  cscale: 1,
  crotation: 0,
  image: null,
  filters: null,
  doubleSticker: false,
  withFaceBox: false,
  resolution: null,
};

StickerImage.propTypes = {
  id: string.isRequired,
  cx: number,
  cy: number,
  cscale: number,
  crotation: number,
  image: string,
  filters: node,
  doubleSticker: bool,
  withFaceBox: bool,
  resolution: string,
  clipPath: string.isRequired,
};

export default StickerImage;
