import { createSelector } from 'reselect';

import { dimensions } from '../constants';
import { getEditorState } from './selection';
import { getPan, getViewportHeight, getZoom } from './viewport';

export const getOperationActive = state => state.controls.operationActive;

export const getSpacebarPressed = state => state.controls.spacebarPressed;

export const getLassoActive = state => state.controls.lassoActive;

export const getShouldHideHandles = state => state.controls.shouldHideHandles;

export const getShowStickers = state => state.controls.showStickers;

export const getGridEnabled = state => state.controls.gridEnabled;

export const getCropPreview = state => state.controls.cropPreview;

export const getShowElementsTree = state => state.controls.showElementsTree;

export const getSidebarMinimized = state => state.controls.sidebarMinimized;

export const selectWebFontsLoaded = state => state.controls.webFontsLoaded;

export const selectMobileFooterOpen = state =>
  state.controls.mobileUIState.mobileFooterOpen;

const fullSpreadHeight = dimensions.pageHeight + dimensions.pagePadding;

export const calculateIsSpreadVisible = (
  zoom,
  pan,
  viewportHeight,
  spreadIndex
) => {
  if (spreadIndex === -1) {
    return true;
  }

  const viewportMinY = pan.y;
  const viewportMaxY = pan.y + viewportHeight / zoom;

  const spreadTopY = spreadIndex * fullSpreadHeight;
  const spreadBottomY = (spreadIndex + 1) * fullSpreadHeight;

  const isVisible = viewportMinY < spreadBottomY && viewportMaxY > spreadTopY;

  return isVisible;
};

export const makeSelectIsSpreadVisible = () =>
  createSelector(
    [getZoom, getPan, getViewportHeight, (_, spreadIndex) => spreadIndex],
    calculateIsSpreadVisible
  );

export const makeGetClipPath = () =>
  createSelector(
    [getCropPreview, (_, spreadProps) => spreadProps],
    (cropPreview, spreadProps) => {
      if (!spreadProps) {
        return null;
      }
      const { nodeSiblingCount, nodeIndex } = spreadProps;
      const isFrontCover = nodeIndex === 0;
      const isBackCover = nodeIndex === nodeSiblingCount - 1;
      const isCover = isFrontCover || isBackCover;
      const coverType = isFrontCover ? 'cover' : 'page';
      const type = isCover ? coverType : 'spread';
      return `url(#clip-${type}${cropPreview ? '-preview' : ''})`;
    }
  );

export const getEditingStickerId = state => state.controls.editingStickerId;

export const selectIsEditingSticker = state => !!getEditingStickerId(state);

export const getEditingTextId = state => state.controls.editingTextId;

export const selectCanUndo = state => {
  const userIsEditingTextFrame = !!getEditorState(state);
  return state.workspaceAndStickers.past.length > 1 && !userIsEditingTextFrame;
};

export const selectCanRedo = state => {
  const userIsEditingTextFrame = !!getEditorState(state);
  return (
    state.workspaceAndStickers.future.length > 0 && !userIsEditingTextFrame
  );
};

export const selectMobileUIState = state => state.controls.mobileUIState;

export const selectActiveAlbumPreviewSpreadIndex = state =>
  state.controls.activeAlbumPreviewSpreadIndex;
