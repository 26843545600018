const snapAnchorsForBox = ({ width, height }) => ({
  x: [
    /* xMin */ { offset: 0 },
    /* xMid */ { offset: -width / 2 },
    /* xMax */ { offset: -width },
  ],
  y: [
    /* yMin */ { offset: 0 },
    /* yMid */ { offset: -height / 2 },
    /* yMax */ { offset: -height },
  ],
});

/**
 * The snapping always maps one point to the nearest matching guide(s), if any.
 * To support snapping on the elements bottom/right edges and its center also,
 * more guides are added based on the elements width/height.
 * Guides are defined like this: { dimension: 'y', value: 10 }
 */
export default (guides, size) => {
  const snapAnchors = snapAnchorsForBox(size);
  return guides.flatMap(guide => {
    return snapAnchors[guide.dimension].map(({ offset }) => ({
      ...guide,
      value: guide.value + offset,
    }));
  });
};
