import React from 'react';
import { useSelector } from 'react-redux';

import { actions } from '../../../../constants';
import { getSingleSelectedElement } from '../../../../selectors/legacy';
import { getSelectInside } from '../../../../selectors/selection';
import ActionButton from '../ActionButton';

function ImageEditButton(props) {
  const imageEditActive = useSelector(getSelectInside);
  const singleSelectedElement = useSelector(getSingleSelectedElement);

  // Do not render button in multi-selection
  if (!singleSelectedElement) {
    return null;
  }

  return (
    <ActionButton
      {...props}
      actionName={imageEditActive ? actions.exitEditImage : actions.editImage}
      tooltip={imageEditActive ? 'Bearbeitung beenden' : 'Bild bearbeiten'}
      icon={imageEditActive ? 'close' : 'edit'}
      disabled={!!singleSelectedElement?.props?.symbol}
    />
  );
}

export default ImageEditButton;
