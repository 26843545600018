export const REQUEST_BLUEPRINTS = 'REQUEST_BLUEPRINTS';
export const RECEIVE_BLUEPRINTS = 'RECEIVE_BLUEPRINTS';
export const REQUEST_BLUEPRINT_DATA = 'REQUEST_BLUEPRINT_DATA';
export const RECEIVE_BLUEPRINT_DATA = 'RECEIVE_BLUEPRINT_DATA';
export const SELECT_BLUEPRINT = 'SELECT_BLUEPRINT';
export const SET_BLUEPRINT_CATEGORY = 'SET_BLUEPRINT_CATEGORY';

const initialState = {
  blueprints: [],
  currentBlueprintId: null,
  currentBlueprintCategory: null,
  loading: false,
  blueprintDataByBlueprintId: {},
};

function blueprintData(
  state = {
    workspace: {},
    stickers: [],
    colors: [],
    images: [],
    fonts: {},
    blueprintCategory: null,
  },
  action
) {
  switch (action.type) {
    case REQUEST_BLUEPRINT_DATA:
      return { ...state, loading: true };
    case RECEIVE_BLUEPRINT_DATA:
      return {
        ...state,
        loading: false,
        workspace: action.workspace,
        stickers: action.stickers,
        colors: action.colors,
        images: action.images,
        title: action.title,
        fonts: action.fonts,
        blueprintCategory: action.blueprintCategory,
      };
    default:
      return state;
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SELECT_BLUEPRINT:
      return {
        ...state,
        currentBlueprintId: action.blueprintId,
      };
    case SET_BLUEPRINT_CATEGORY:
      return {
        ...state,
        currentBlueprintCategory: action.blueprintCategory,
      };
    case REQUEST_BLUEPRINTS:
      return { ...state, loading: true };
    case RECEIVE_BLUEPRINTS:
      return {
        ...state,
        loading: false,
        blueprints: action.blueprints,
      };
    case RECEIVE_BLUEPRINT_DATA:
    case REQUEST_BLUEPRINT_DATA:
      return {
        ...state,
        blueprintDataByBlueprintId: {
          ...state.blueprintDataByBlueprintId,
          [action.blueprintId]: blueprintData(
            state[action.blueprintData],
            action
          ),
        },
      };
    default:
      return state;
  }
};

export const selectBlueprint = blueprintId => {
  return {
    type: SELECT_BLUEPRINT,
    blueprintId,
  };
};

export function setBlueprintCategory(blueprintCategory) {
  return {
    type: SET_BLUEPRINT_CATEGORY,
    blueprintCategory,
  };
}

export function requestBlueprints() {
  return {
    type: REQUEST_BLUEPRINTS,
  };
}

export function receiveBlueprints(blueprints) {
  return {
    type: RECEIVE_BLUEPRINTS,
    blueprints,
  };
}

export function receiveBlueprintData(blueprintId, blueprint) {
  const {
    workspace,
    stickers,
    colors,
    images,
    title,
    fonts,
    blueprint_category: blueprintCategory,
  } = blueprint;

  return {
    type: RECEIVE_BLUEPRINT_DATA,
    blueprintId,
    workspace,
    stickers,
    colors,
    images,
    title,
    fonts,
    blueprintCategory,
  };
}
