import React, { useCallback, useMemo } from 'react';
import { func, number, string } from 'prop-types';

import useMouseDragCoordinates from '../../hooks/useMouseDragCoordinates';
import { axisAlignedBounds, domMatrix } from '../../util/geometry';
import getTransformToElement from '../../util/getTransformToElement';
import snapToPoint from '../../util/snapToPoint';
import { makeMapPropsToDeltaMap } from '../../util/workspace';
import { InitialOperationValuesShape } from '../shapes';
import { getClientPivotsAndDomNode, getPivot, pivotNames } from './pivots';
import moveMousePointAccordingToRatio from './moveMousePointAccordingToRatio';
import withOperationProps from './withOperationProps';

const handleRotation = {
  top: 0,
  right: 90,
  bottom: 180,
  left: 270,
};

const ResizeHandle = ({
  pivotName,
  handleName,
  fixDimension,
  width: handleWidth,
  height: handleHeight,
  zoom,
  initialValues,
  operationStart,
  updateElements,
  operationEnd,
  updateGuides,
  fitContentCover,
}) => {
  const pivots = useMemo(() => {
    if (!initialValues) {
      return null;
    }

    const {
      selectedElements: [firstElement],
    } = initialValues;
    const { id, width, height } = firstElement.props;
    return getClientPivotsAndDomNode(
      [pivotName, pivotNames.center],
      { width, height },
      `[data-id='${id}']`
    );
  }, [initialValues, pivotName]);

  const handleUpdate = useCallback(
    ({ currentPointer, shiftKey, altKey }) => {
      const { guides, selectedElements, operationTargetNodeId } = initialValues;
      const { clientPivots, pivotDomNode } = pivots;

      const parentDomNode = document.querySelector(
        `[data-id='${operationTargetNodeId}']`
      );

      const [firstElement] = selectedElements;
      const { props: nodeProps, type } = firstElement;
      const { image: imageId, pexelsId } = nodeProps;
      const hasImage = type === 'Image' && (imageId || pexelsId);

      // Calculate two corner-points defining the new area: `mouse` and `pivot`.
      // Both points are converted to element space to factor in all transforms:
      // - Convert the pivot-point:
      const clientToPivot = domMatrix(pivotDomNode.getScreenCTM()).inverse();

      const [clientPivot, altClientPivot] = clientPivots;
      const activePivot = altKey ? altClientPivot : clientPivot;
      const pivot = clientToPivot.transformPoint(activePivot);

      // - The other one is the mouse-pointer, optionally corrected to keep the ratio:
      const clientToSpread = domMatrix(parentDomNode.getScreenCTM()).inverse();
      const currentPoint = clientToSpread.transformPoint(currentPointer);
      const [snappedPoint, activeGuideIds] = snapToPoint(
        currentPoint,
        guides,
        zoom
      );

      updateGuides({ activeGuideIds });
      const parentToElement = domMatrix(
        getTransformToElement(parentDomNode, pivotDomNode)
      );
      const elementToParent = parentToElement.inverse();
      const actualMouse = parentToElement.transformPoint(snappedPoint);

      const applyResize = initialProps => {
        const mouse = shiftKey
          ? moveMousePointAccordingToRatio(pivot, actualMouse, initialProps)
          : actualMouse;

        if (altKey) {
          // Adjust the pivot to be on the opposing side of the mouse-position unless
          // the dimension is fixed. In this case the pivot is adjusted further below.
          pivot.x -= fixDimension === 'x' ? 0 : mouse.x - pivot.x;
          pivot.y -= fixDimension === 'y' ? 0 : mouse.y - pivot.y;
        }

        // For resizing with fixed dimensions, mouse- and pivot-points are at the,
        // egdes/centers not the corners. In order to get the correct area, they are
        // moved to the corners, based on the correct size.
        if (fixDimension === 'x') {
          const { width, height: originalHeight } = initialProps;
          const newHeight = mouse.y - pivot.y;
          const ratio = shiftKey ? newHeight / originalHeight : 1;
          mouse.x = pivot.x - (width * ratio) / 2;
          pivot.x += (width * ratio) / 2;
        } else if (fixDimension === 'y') {
          const { width: originalWidth, height } = initialProps;
          const newWidth = mouse.x - pivot.x;
          const ratio = shiftKey ? newWidth / originalWidth : 1;
          mouse.y = pivot.y - (height * ratio) / 2;
          pivot.y += (height * ratio) / 2;
        }

        // Calculate the new area as a bounding rectangle of the two points
        const { width, height, ...position } = axisAlignedBounds([
          pivot,
          mouse,
        ]);

        // Convert the position to parent-space
        const { x, y } = elementToParent.transformPoint(position);

        // Apply the changes
        return { x, y, width, height };
      };

      const mapPropsToDeltaMap = makeMapPropsToDeltaMap(selectedElements);
      updateElements(mapPropsToDeltaMap(applyResize));

      if (hasImage) {
        fitContentCover();
      }
    },
    [
      fitContentCover,
      updateElements,
      updateGuides,
      fixDimension,
      initialValues,
      zoom,
      pivots,
    ]
  );

  const handleRadius = 6 / zoom;
  const { x, y } = getPivot(handleName, {
    width: handleWidth,
    height: handleHeight,
  });

  const { handleMouseDown } = useMouseDragCoordinates({
    onStart: operationStart,
    onUpdate: initialValues && handleUpdate,
    onEnd: operationEnd,
  });

  if (fixDimension) {
    /**
     * These values are shortened for use in the `path.d` property that draws
     * a custom path. This path is basically a wide rectangle with a border-
     * radius on two corners.
     */
    const r = handleRadius * 0.8; // Border radius, just renamed for brevity
    const width = r * 4; // The length of the wide edge
    const height = r / 2; // The length of the short edge

    /**
     * Test if the wide handle would fit in the available space. If it does
     * not fit, we simply don't show it.
     */
    const availableSpace = fixDimension === 'x' ? handleWidth : handleHeight;
    if (availableSpace < width * 2.2) {
      return null;
    }

    return (
      <g
        transform={`translate(${x},${y}) rotate(${handleRotation[handleName]})`}
      >
        <path
          className={`handle resize ${handleName} qa-handle-resize-${handleName}`}
          onMouseDown={handleMouseDown}
          d={`M -${r} 0 h ${width} v -${height} a-${r},${r} 1 0 0 -${r},-${r} h -${width} a-${r},${r} 1 0 0 -${r},${r} v ${height} z`}
        />
      </g>
    );
  }

  return (
    <circle
      onMouseDown={handleMouseDown}
      className={`handle resize ${handleName} qa-handle-resize-${handleName}`}
      r={handleRadius}
      cx={x}
      cy={y}
    />
  );
};

ResizeHandle.defaultProps = {
  fixDimension: null,
  initialValues: null,
};

ResizeHandle.propTypes = {
  pivotName: string.isRequired,
  handleName: string.isRequired,
  width: number.isRequired,
  height: number.isRequired,
  fixDimension: string,
  initialValues: InitialOperationValuesShape,
  zoom: number.isRequired,
  operationStart: func.isRequired,
  updateElements: func.isRequired,
  operationEnd: func.isRequired,
  updateGuides: func.isRequired,
  fitContentCover: func.isRequired,
};

export default withOperationProps(ResizeHandle);
