import { resolutions } from '../constants';

const resolutionToPexelResolution = {
  [resolutions.small]: 'small',
  [resolutions.medium]: 'medium',
  [resolutions.large]: 'large',
  [resolutions.full]: 'large2x',
  [resolutions.original]: 'original',
};

export default (pexelsObject, resolution) => {
  return pexelsObject?.src?.[resolutionToPexelResolution[resolution]];
};
