import { UPDATE } from '../modules/operations';
import { getSelectedElements } from '../selectors/legacy';
import { getGuides } from '../selectors/smartGuides';
import { getAxisAlignedBounds } from '../util/geometry';
import { updateGuides } from '../modules/smartGuides';
import { updateControls } from '../modules/controls';
import { historyAnchor } from '../modules/history';
import { getOperationTargetNodeId } from '../selectors/operations';
import { getSelectInside } from '../selectors/selection';
import { getImageSize } from '../selectors/images';

const update = payload => ({ type: UPDATE, payload });

export const operationStart = ({ moveNodeId = null }) => (
  dispatch,
  getState
) => {
  const state = getState();

  const selectedElements = getSelectedElements(state);
  const operationTargetNodeId = getOperationTargetNodeId(state);
  const guides = getGuides(state);
  const selectInside = getSelectInside(state);

  const selectionBounds = getAxisAlignedBounds(
    selectedElements,
    `[data-id='${operationTargetNodeId}']`
  );

  const [singleSelectedElement] = selectInside ? selectedElements : [];
  const imageSize = singleSelectedElement
    ? getImageSize(state, singleSelectedElement)
    : null;

  dispatch(updateControls({ operationActive: true }));

  dispatch(
    update({
      moveNodeId,
      initialValues: {
        selectedElements,
        selectionBounds,
        guides,
        imageSize,
        operationTargetNodeId,
        selectInside,
      },
    })
  );
};

export const operationEnd = () => dispatch => {
  dispatch(updateGuides({ activeGuideIds: [] }));
  dispatch(updateControls({ operationActive: false }));
  dispatch(historyAnchor());

  dispatch(
    update({
      moveNodeId: null,
      initialValues: null,
    })
  );
};
