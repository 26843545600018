import React from 'react';
import { number } from 'prop-types';

import { colorValues } from '../../../../constants';
import useViewport from '../../Viewport/useViewport';

function MoveInsideIcon({ width, height }) {
  const size = 18;
  const { zoom } = useViewport();
  const scale = 1.3 / zoom;
  const scaledSize = size * scale;
  if (width < scaledSize || height < scaledSize) {
    return null;
  }

  const x = (width - scaledSize) / 2;
  const y = (height - scaledSize) / 2;
  return (
    <g transform={`translate(${x},${y}) scale(${scale})`}>
      <circle
        r={size / 2}
        cx={size / 2}
        cy={size / 2}
        fill={colorValues.selectionInside}
      />
      <path
        d="M8.882 3.17a.143.143 0 01.235 0L10.7 5.453a.143.143 0 01-.117.225H7.417a.143.143 0 01-.118-.225L8.882 3.17zM8.882 14.83a.143.143 0 00.235 0l1.583-2.284a.143.143 0 00-.117-.225H7.417a.143.143 0 00-.118.225l1.583 2.285zM3.17 9.117a.143.143 0 010-.234L5.453 7.3a.143.143 0 01.225.117v3.166c0 .115-.13.183-.225.117L3.17 9.117zM14.83 9.117a.143.143 0 000-.234L12.547 7.3a.143.143 0 00-.225.117v3.166c0 .115.13.183.225.117l2.285-1.583z"
        fill="#fff"
      />
    </g>
  );
}

MoveInsideIcon.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
};

export default MoveInsideIcon;
