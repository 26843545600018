import memoize from 'lodash/memoize';

import { fontSizeScaleFactor } from '../constants';

const inchPerPixel = 96;
const inchPerPoint = 72;
const millimeterPerPixel = inchPerPixel / inchPerPoint / fontSizeScaleFactor;
const minScale = 0.1;
const canvasContext = document.createElement('canvas').getContext('2d');

function calculateTextScale(text, fontStyle, autosizeWidth) {
  canvasContext.font = fontStyle;
  const { width } = canvasContext.measureText(text);

  // Calculate scale
  const scale = (autosizeWidth * millimeterPerPixel) / width;
  if (scale > 1) {
    return 1;
  }

  if (scale < minScale) {
    return minScale;
  }

  return scale;
}

export default memoize(calculateTextScale, (...args) => args.join());
