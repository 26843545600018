import React, { useEffect, useState } from 'react';
import { func, number, objectOf } from 'prop-types';
import { connect, useDispatch, useSelector } from 'react-redux';

import {
  getSectionIds,
  getStickers,
  getStickersCount,
} from '../../../selectors/legacy';
import { ImageContext } from '../../ImageContext';
import { resolutions } from '../../../constants';
import {
  createSection,
  deleteSticker,
  unlinkStickerCellForStickerId,
} from '../../../actions/stickers';
import { historyAnchor } from '../../../modules/history';
import SectionPanel from './index';
import { RefShape, IdListShape, SectionShape } from '../../shapes';
import { makeStickerMatchesFilter } from './util';
import useStickerForm, {
  StickerFormProvider,
} from './StickerForm/useStickerForm';
import { getSectionsById } from '../../../selectors/stickers';
import useFirstTime from '../../../hooks/useFirstTime';
import SectionSidebarMenu from './SectionSidebarMenu';

const SectionPanelContainer = ({
  sectionIds,
  sectionsById,
  stickersCount,
  addNewSection,
  sidebarRef,
}) => {
  const { stickerFormData, reset } = useStickerForm();
  const [filter, setFilter] = useState('');
  const [openSectionIds, setOpenSectionIds] = useState([]);
  const dispatch = useDispatch();

  const [firstSectionId] = sectionIds.filter(id => !sectionsById[id].static);
  useFirstTime(() => setOpenSectionIds([firstSectionId]), firstSectionId);

  const stickers = useSelector(getStickers);

  /**
   * If stickers are queried via the search bar, we match them by name,
   * position, or number. We then open all sections for which at least
   * one sticker matched the quey.
   */
  useEffect(() => {
    let filteredStickers = [];

    if (filter) {
      const stickerMatchesFilter = makeStickerMatchesFilter(filter);
      filteredStickers = stickers.filter(stickerMatchesFilter);

      const nextOpenSectionIds = filteredStickers.map(
        sticker => sticker.sectionId
      );
      setOpenSectionIds(nextOpenSectionIds);
    }
  }, [stickers, filter]);

  const handleOpen = isOpen => {
    if (isOpen) {
      setOpenSectionIds(sectionIds);
    } else {
      setOpenSectionIds([]);
    }
  };

  const handleStickerDestroy = () => {
    const { stickerId } = stickerFormData;

    dispatch(unlinkStickerCellForStickerId(stickerId));
    dispatch(deleteSticker(stickerId));
    dispatch(historyAnchor());
    reset();
  };

  const hasSelectedSticker = !!stickerFormData.stickerId;
  const { placeholder } = stickerFormData;

  return (
    <>
      <SectionSidebarMenu
        filter={filter}
        setFilter={setFilter}
        setOpen={handleOpen}
        itemsCount={stickersCount}
        hasSelection={hasSelectedSticker && !placeholder}
        onDestroy={handleStickerDestroy}
      />
      <SectionPanel
        filter={filter}
        sectionIds={sectionIds}
        sectionsById={sectionsById}
        openSectionIds={openSectionIds}
        setOpenSectionIds={setOpenSectionIds}
        addNewSection={addNewSection}
        sidebarRef={sidebarRef}
      />
    </>
  );
};

SectionPanelContainer.propTypes = {
  addNewSection: func.isRequired,
  sectionIds: IdListShape.isRequired,
  sectionsById: objectOf(SectionShape).isRequired,
  stickersCount: number.isRequired,
  sidebarRef: RefShape.isRequired,
};

function SectionPanelWithContext(props) {
  return (
    <ImageContext.Provider value={{ resolution: resolutions.small }}>
      <StickerFormProvider>
        <SectionPanelContainer {...props} />
      </StickerFormProvider>
    </ImageContext.Provider>
  );
}

const mapStateToProps = state => ({
  sectionsById: getSectionsById(state),
  sectionIds: getSectionIds(state),
  stickersCount: getStickersCount(state),
});

const mapDispatchToProps = {
  addNewSection: createSection,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionPanelWithContext);
