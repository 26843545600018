import axios from 'axios';

import { API_URL } from '../constants';
import { addToQueue, removeFromQueue } from './loading';
import { UPSERT, RESET } from '../actions/images';

const NULL = 'images/NULL';
const CREATE = 'images/CREATE';
const UPDATE = 'images/UPDATE';
const DELETE = 'images/DELETE';
const REPLACE = 'images/REPLACE';

export const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE: {
      if (!action.payload.id) {
        console.error('id not set during create');
        return state;
      }

      return [...state, action.payload];
    }

    case UPDATE: {
      const updateIndex = state.findIndex(item => item.id === action.id);
      if (updateIndex === -1) {
        console.error('id not found during update');
        return state;
      }
      const nextItem = { ...state[updateIndex], ...action.payload };
      return [
        ...state.slice(0, updateIndex),
        nextItem,
        ...state.slice(updateIndex + 1),
      ];
    }

    case UPSERT: {
      const { image: nextImage } = action.payload;

      const existingImage = state.find(image => image.id === nextImage.id);

      if (existingImage) {
        return state.map(image =>
          image.id === nextImage.id ? nextImage : image
        );
      }

      return [...state, nextImage];
    }

    case DELETE: {
      const deleteIndex = state.findIndex(item => item.id === action.id);
      if (deleteIndex === -1) {
        console.error('id not found during delete');
        return state;
      }
      return [...state.slice(0, deleteIndex), ...state.slice(deleteIndex + 1)];
    }

    case REPLACE:
      return action.payload;

    case RESET:
      return [...initialState];

    case NULL:
    default:
      return state;
  }
};

/**
 * Appends an image to the image list.
 * @param {} payload
 */
export const createImage = payload => ({
  type: CREATE,
  payload,
});

export const updateImage = (id, payload) => ({
  type: UPDATE,
  id,
  payload,
});

export const replaceImages = payload => ({
  type: REPLACE,
  payload,
});

export const tagImage = (id, tag_id) => dispatch => {
  const jobId = `tag-image-${id}`;
  dispatch(addToQueue(jobId, false));
  return axios
    .post(`${API_URL}/images/${id}/tags`, { tag_id })
    .then(({ data: { image } }) => {
      dispatch(updateImage(image.id, image));
      dispatch(removeFromQueue(jobId));
    });
};

/**
 * Updates an existing image on the server after a successful direct-upload and updates it in the image list.
 * @param id = id of the image element to update
 * @param {*} image { blob_id, model }
 */
export const patchExistingImage = (id, originalImage) => (
  dispatch,
  getState
) => {
  const { images } = getState();
  const existingImage = images.find(item => item.id === id);
  let image;
  if (existingImage) {
    image = {
      ...originalImage,
      details: { ...existingImage.details, ...originalImage.details },
    };
  } else {
    image = originalImage;
  }
  const jobId = `image-${id}`;
  dispatch(addToQueue(jobId, false));

  return axios
    .patch(`${API_URL}/images/${id}`, image)
    .then(({ data: { image } }) => {
      dispatch(updateImage(id, image));
      dispatch(removeFromQueue(jobId, false));
    });
};
