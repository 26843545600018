import React, { useLayoutEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { bool, func, string } from 'prop-types';

import Logo from '../../../../assets/icons/logo.svg';
import { selectWizardState } from '../../../../selectors/wizard';
import { setAlbumData } from '../../../../modules/albums';
import useScreenSize from '../../../../hooks/useScreenSize';
import SubmitButtonWithLink from './SubmitButtonWithLink';

const sizes = [
  { value: 1, label: '1-150' },
  { value: 151, label: '151-200' },
  { value: 201, label: '201-1000' },
  { value: 1001, label: '1000+' },
];

function OrganizationForm({ handleSubmit, backLinkTo, submitDisabled }) {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const { isMobile } = useScreenSize();

  const {
    albumData: { organization },
  } = useSelector(selectWizardState);
  const invalidInput = !organization.name || !organization.size;

  const handleChange = (key, value) => {
    dispatch(setAlbumData({ organization: { ...organization, [key]: value } }));
  };

  useLayoutEffect(() => {
    if (isMobile) {
      return;
    }

    // eslint-disable-next-line no-unused-expressions
    inputRef.current?.focus();
  }, [isMobile]);

  return (
    <div className="organization-form qa-organization-form">
      <img src={Logo} className="logo my-4" alt="stickerstars logo" />
      <h1>
        <span className="highlight-emoji" role="img" aria-label="Wave emoji">
          👏
        </span>
      </h1>
      <h3>Fast geschafft!</h3>
      <Form className="w-100">
        <Form.Group className="mt-4 text-left">
          <Form.Label>Name des Unternehmens*</Form.Label>
          <Form.Control
            type="text"
            className="qa-organization-name-input"
            name="organization_name"
            value={organization.name}
            onChange={event => handleChange('name', event.target.value)}
            ref={inputRef}
          />
        </Form.Group>
        <Form.Group className="mt-4 text-left">
          <Form.Label>Anzahl Mitarbeiter:innen*</Form.Label>
          <Form.Control
            as="select"
            className="qa-organization-size-input"
            value={organization.size}
            onChange={event => handleChange('size', event.target.value)}
            name="organization_size"
          >
            <option value="">Bitte wählen</option>
            {sizes.map(({ value, label }) => (
              <option
                key={value}
                value={value}
                className="qa-blueprint-category-option"
              >
                {label}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
        <div className="mt-4">
          <SubmitButtonWithLink
            onSubmit={handleSubmit}
            disabled={submitDisabled || invalidInput}
          />
          <Link to={backLinkTo} className="qa-back-link">
            <Button variant="link" block>
              Zurück
            </Button>
          </Link>
        </div>
      </Form>
    </div>
  );
}

OrganizationForm.defaultProps = {};

OrganizationForm.propTypes = {
  handleSubmit: func.isRequired,
  backLinkTo: string.isRequired,
  submitDisabled: bool.isRequired,
};

export default OrganizationForm;
