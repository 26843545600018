import React from 'react';
import { number, string } from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import Icon from '../Icon';
import UnzoomedContent from './UnzoomedContent';

const iconSize = 30;
const padding = 10;

function UnzoomedIcon({ width, height, name, className, tooltip, scale }) {
  const x = factor => width - (iconSize + padding) / factor;
  const y = factor => padding / factor;
  return (
    <UnzoomedContent
      width={width}
      height={height}
      x={x}
      y={y}
      hideBelowSize={iconSize + padding * 2}
      scale={scale}
    >
      <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
        <div style={{ width: iconSize }}>
          <Icon name={name} className={className} />
        </div>
      </OverlayTrigger>
    </UnzoomedContent>
  );
}

UnzoomedIcon.defaultProps = {
  className: null,
  scale: null,
};

UnzoomedIcon.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
  name: string.isRequired,
  className: string,
  tooltip: string.isRequired,
  scale: number,
};

export default UnzoomedIcon;
