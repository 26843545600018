import { useEffect } from 'react';

function useFadeIn(ref) {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const { current } = ref;
    current.style.transition = `opacity 400ms ease 0s`;
    current.style.opacity = 1;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current]);

  return { style: { opacity: 0 } };
}

export default useFadeIn;
