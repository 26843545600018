import { node } from 'prop-types';
import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSetState } from 'react-use';

import { setEditingStickerId } from '../../../../modules/controls';
import { getStickersById } from '../../../../selectors/stickers';
import { getEditingStickerId } from '../../../../selectors/controls';

const StickerFormContext = createContext();

const initialState = {
  stickerId: '',
  nodeRef: { current: null },
  sectionId: null,
  placeholder: false,
};

export function StickerFormProvider({ children }) {
  const dispatch = useDispatch();
  const [stickerFormData, setStickerFormData] = useSetState(initialState);
  const selectedSticker = useSelector(getStickersById)[
    stickerFormData.stickerId
  ];

  const reset = () => {
    setStickerFormData(initialState);
    dispatch(setEditingStickerId(null));
  };

  const editingStickerId = useSelector(getEditingStickerId);
  useEffect(() => {
    if (editingStickerId) {
      setStickerFormData({ stickerId: editingStickerId });
    }
  }, [editingStickerId, setStickerFormData]);

  return (
    <StickerFormContext.Provider
      value={{
        stickerFormData,
        setStickerFormData,
        selectedSticker,
        reset,
      }}
    >
      {children}
    </StickerFormContext.Provider>
  );
}

StickerFormProvider.propTypes = {
  children: node.isRequired,
};

function useStickerForm() {
  return useContext(StickerFormContext);
}

export default useStickerForm;
