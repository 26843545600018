import { useSelector } from 'react-redux';

import useAuth from '../../../hooks/useAuth';
import { selectImageIdsFromLockedNodesWithChildren } from '../../../selectors/workspace';

function useScopedImages(images) {
  const { isAdmin } = useAuth();

  const lockedImageIds = useSelector(selectImageIdsFromLockedNodesWithChildren);
  const availableImages = images.filter(
    image => !lockedImageIds.includes(image.id) || isAdmin
  );

  return {
    images: availableImages,
  };
}

export default useScopedImages;
