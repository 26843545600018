import React from 'react';
import { string, node } from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

import Icon from '../Icon';

function DropdownItemWithIcon({ iconName, children, ...rest }) {
  return (
    <Dropdown.Item {...rest}>
      <div className="w-10 d-inline-block mr-3">
        <Icon name={iconName} />
      </div>
      {children}
    </Dropdown.Item>
  );
}

DropdownItemWithIcon.propTypes = {
  iconName: string.isRequired,
  children: node.isRequired,
};

export default DropdownItemWithIcon;
