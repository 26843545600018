import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bool, number, string } from 'prop-types';
import { useHistory } from 'react-router';

import BaseElement from '../BaseElement';
import Sticker from '../Sticker/Sticker';
import StickerIcon from '../../../../assets/images/stickerSymbol.svg';
import { dimensions } from '../../../../constants';
import { getStickersById } from '../../../../selectors/stickers';
import { ImageContext } from '../../../ImageContext';
import { setEditingStickerId } from '../../../../modules/controls';
import { selectAlbumData } from '../../../../selectors/albums';

export function EmptyCell({ width, height }) {
  const { rendering, showStickers } = useContext(ImageContext);
  if (!showStickers || rendering) {
    return null;
  }

  const iconHeight = 14;
  const iconWidth = 11.5;

  return (
    <g className="qa-empty-cell">
      <rect
        fill="#000"
        fillOpacity={0.2}
        strokeWidth={0.5}
        strokeLinecap="square"
        height={height}
        width={width}
      />
      <use
        height={iconHeight}
        width={iconWidth}
        x={(width - iconWidth) / 2}
        y={(height - iconHeight) / 2}
        fill="#fff"
        href={`${StickerIcon}#icon-1`}
      />
    </g>
  );
}

EmptyCell.propTypes = {
  width: number.isRequired,
  height: number.isRequired,
};

function StickerCell(props) {
  const dispatch = useDispatch();
  const { id: albumId } = useSelector(selectAlbumData);
  const history = useHistory();
  const { dropIndication, stickerId } = props;
  const { stickerWidth, stickerHeight } = dimensions;

  const stickersById = useSelector(getStickersById);
  const sticker = stickerId ? stickersById[stickerId] : null;

  const { doubleSticker } = sticker || {};
  const width = stickerWidth * (doubleSticker ? 2 : 1);

  const handleDoubleClick = () => {
    history.push(`/album/${albumId}/stickers`);
    dispatch(setEditingStickerId(stickerId));
  };

  return (
    <BaseElement
      {...props}
      width={width}
      height={stickerHeight}
      onDoubleClick={handleDoubleClick}
    >
      {sticker ? (
        <Sticker {...props} {...sticker} width={width} height={stickerHeight} />
      ) : (
        <EmptyCell width={width} height={stickerHeight} />
      )}
      {dropIndication && (
        <rect
          className="drop-indication"
          height={stickerHeight}
          width={width}
        />
      )}
    </BaseElement>
  );
}

StickerCell.defaultProps = {
  dropIndication: false,
  stickerId: null,
};

StickerCell.propTypes = {
  dropIndication: bool,
  stickerId: string,
};

export default StickerCell;
