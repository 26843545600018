import { createSelector } from 'reselect';

export const selectStickerUploads = state => state.stickerUploads;

export const selectPendingStickerUploads = createSelector(
  [selectStickerUploads],
  stickerUploads =>
    stickerUploads.filter(({ merged_at: mergedAt }) => !mergedAt)
);

export default selectStickerUploads;
