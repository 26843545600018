export const UPSERT = 'images/UPSERT';
export const DELETE = 'images/DELETE';
export const RESET = 'images/RESET';

export function upsertImage(image) {
  return { type: UPSERT, payload: { image } };
}

export function deleteImage(id) {
  return { type: DELETE, id };
}

export function resetImages() {
  return { type: RESET };
}
