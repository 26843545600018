import executeActionByName from './executeActionByName';
import { keys, dimensions } from '../constants';
import { historyAnchor } from '../modules/history';
import { nudgeElements } from './workspace';
import { inputElementFocused } from '../util';
import { clearSelection, setSelectInside } from '../modules/selection';
import { getSelectedElements } from '../selectors/legacy';
import { getSelectInside, selectAllowedHotkeys } from '../selectors/selection';

/**
 * Our hotkey options match events on `ctrlKey: true`, not `metaKey: true`.
 * To serve Mac users, we "normalize" events by setting `ctrlKey` to
 * `true` whenever `event.metaKey` is `true`.
 */
export const normalizeEvent = event => {
  const { metaKey } = event;
  if (!metaKey) return event;
  return { ...event, ctrlKey: true };
};

/**
 * In order to check whether a user `event` maps to a pre-defined
 * hotkey, we loop through an action's hotkeys (arr of objects) and
 * compare them to the event's key(s). If the `key` values match, this
 * function returns `true`, else `false`.
 */
export const isHotkeyEvent = (hotkeys, event) => {
  const defaultKey = {
    ctrlKey: false,
    shiftKey: false,
  };
  return hotkeys.some(hotkey => {
    const completeHotkey = { ...defaultKey, ...hotkey };
    return Object.keys(completeHotkey).every(objectKey => {
      return completeHotkey[objectKey] === event[objectKey];
    });
  });
};

/** Internal Function to unselect elements in editor when ESC used */
function handleEscape(getState, dispatch) {
  const state = getState();
  const selectedElements = getSelectedElements(state);
  const selectInside = getSelectInside(state);

  if (selectedElements.length === 0) {
    return;
  }

  if (selectInside) {
    dispatch(setSelectInside(false));
    return;
  }

  dispatch(clearSelection());
}

/**
 * We loop through all passed actions (inside `actionRefs`), each with
 * or without one or more defined hotkey(s), compare them to an `event`
 * and dispatch an associated action on match. If an action was dispatched,
 * i. e. the event was handled, this function returns `true`, else `false`.
 */
export const handleKeyboardEvent = event => (dispatch, getState) => {
  if (inputElementFocused()) {
    return;
  }

  const nudge = (x, y) => {
    // Default distance without modifier keys
    let nudgeDistance = 1;

    // Coarse distance
    if (event.shiftKey) {
      nudgeDistance = dimensions.gridSize;
    }

    // Fine distance
    if (event.ctrlKey) {
      nudgeDistance = 0.1;
    }

    dispatch(nudgeElements(x * nudgeDistance, y * nudgeDistance));

    event.preventDefault();
  };

  const normalizedEvent = normalizeEvent(event);
  const allowedHotkeys = selectAllowedHotkeys(getState());

  switch (event.key) {
    case 'Escape':
      handleEscape(getState, dispatch);
      break;

    // Cursor-keys: move elements
    case keys.left:
      nudge(-1, 0);
      break;
    case keys.right:
      nudge(+1, 0);
      break;
    case keys.up:
      nudge(0, -1);
      break;
    case keys.down:
      nudge(0, +1);
      break;
    default:
      allowedHotkeys.forEach(actionHotkey => {
        const [action, hotkeys] = actionHotkey;
        if (!hotkeys || !isHotkeyEvent(hotkeys, normalizedEvent)) {
          return;
        }
        dispatch(executeActionByName(action));
        dispatch(historyAnchor());
        event.preventDefault();
      });
  }
};

export default handleKeyboardEvent;
