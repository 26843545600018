import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import {
  selectCurrentAlbum,
  selectHasUnsavedChanges,
} from '../../selectors/albums';
import { getSpreadsCount } from '../../selectors/legacy';
import Icon from '../../components/Icon';
import useApi from '../../hooks/useApi';
import useScreenSize from '../../hooks/useScreenSize';

const preflightCheckoutParams = { no_albums: 50, country_code: 'DE' };

function PreflightErrorsNotification() {
  const albumId = useSelector(selectCurrentAlbum);
  const spreadsCount = useSelector(getSpreadsCount);
  const hasChanges = useSelector(selectHasUnsavedChanges);

  const api = useApi();
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    async function fetchOrderDetails() {
      const {
        data: { checkout },
      } = await api.get(`/albums/${albumId}/checkout`, null, {
        params: preflightCheckoutParams,
      });

      setErrors(checkout.errors);
    }
    if (!hasChanges) {
      fetchOrderDetails();
    }
  }, [spreadsCount, hasChanges, api, albumId]);

  if (errors.length === 0) {
    return null;
  }

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip className="tooltip-mobile">
          {errors.map(err => (
            <div key={err}>{err}</div>
          ))}
        </Tooltip>
      }
    >
      <Icon
        className="text-danger qa-order-preflight-error-icon pulse"
        name="exclamation_triangle"
      />
    </OverlayTrigger>
  );
}

function OrderButton() {
  const albumId = useSelector(selectCurrentAlbum);
  const { isMobile } = useScreenSize();

  return (
    <div className="order-button mr-2 d-flex justify-content-center">
      {!isMobile && <PreflightErrorsNotification />}
      <Link to={`/album/${albumId}/checkout`}>
        <Button size="sm d-flex ml-2">Jetzt bestellen</Button>
      </Link>
    </div>
  );
}

export default OrderButton;
