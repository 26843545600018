import { connect } from 'react-redux';

import {
  updateSection,
  moveSection,
  moveSticker,
  createSticker,
  unlinkStickerCell,
  deleteStickersForSection,
} from '../../../actions/stickers';
import { insertElements, deleteSection } from '../../../actions/workspace';
import { historyAnchor } from '../../../modules/history';
import {
  updateControls,
  resetViewToSectionId,
} from '../../../modules/controls';
import SectionInputWithDnd from './index.dnd';
import { getStickerCellIdsByStickerId } from '../../../selectors/stickers';

const mapStateToProps = (state, ownProps) => {
  const { sectionIds } = ownProps;

  return {
    sectionsCount: sectionIds.length,
    sectionDragEnabled: state.controls.sectionDragEnabled,
    sectionIndex: sectionIds.findIndex(id => id === ownProps.sectionId),
    stickerCellIdsByStickerId: getStickerCellIdsByStickerId(state),
  };
};

const mapDispatchToProps = {
  updateControls,
  updateSection,
  moveSection,
  moveSticker,
  createSticker,
  historyAnchor,
  insertElements,
  deleteSection,
  unlinkStickerCell,
  deleteStickersForSection,
  resetViewToSectionId,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SectionInputWithDnd);
