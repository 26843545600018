import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import { connect, useDispatch, useSelector } from 'react-redux';
import formatDistance from 'date-fns/formatDistance';
import ProgressBar from 'react-bootstrap/ProgressBar';

import Icon from '../Icon';
import CreateJob from './CreateJob';
import { FontSwatchPicker } from './FontSwatchPicker';
import useApi from '../../hooks/useApi';
import { selectAlbumData } from '../../selectors/albums';
import { setAlbumData } from '../../modules/albums';
import { jobStateStyles } from '../../constants';

export const secsToDuration = secs => {
  const date = new Date(null);
  date.setSeconds(secs);
  return date.toISOString().substr(11, 8);
};

const JobItem = ({
  id,
  params,
  state,
  progress,
  file_url,
  onDelete,
  created_at,
  currentAlbum,
}) => {
  const styles = jobStateStyles[state];

  const filename = `${params.mode}.${params.format}`;

  let title;
  if (params.album_id !== currentAlbum) {
    title = `Anderes Album (ID: ${params.album_id}): ${filename} / ${id}`;
  } else {
    title = `${filename} / ${id}`;
  }

  return (
    <ListGroup.Item
      className={`job job-${state} qa-job qa-job-${state}`}
      variant={styles.variant}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <div className="mr-2">
            <Icon name={styles.icon} />
          </div>
          <div className="middle">
            <div className="font-bold small">
              <strong>{title}</strong>
              <span className="ml-2 text-muted">
                {formatDistance(new Date(created_at), Date.now())}
              </span>
            </div>
            {state === 'working' && <ProgressBar animated now={progress} />}
            {file_url && (
              <div className="links">
                <a href={file_url} target="_blank" rel="noopener noreferrer">
                  <Button
                    className="qa-job-download-btn mt-2"
                    variant="light"
                    size="sm"
                    onClick={() => window.open(file_url, '_blank')}
                  >
                    <Icon name="download" />
                  </Button>
                </a>
              </div>
            )}
          </div>
        </div>
        <Button variant="light" size="sm" onClick={onDelete}>
          <Icon name="trash" />
        </Button>
      </div>
    </ListGroup.Item>
  );
};

JobItem.defaultProps = {
  file_url: null,
  progress: null,
};

export const jobParamsShape = PropTypes.shape({
  album_id: PropTypes.string,
  mode: PropTypes.string,
  format: PropTypes.string,
});

JobItem.propTypes = {
  id: PropTypes.string.isRequired,
  params: jobParamsShape.isRequired,
  state: PropTypes.string.isRequired,
  progress: PropTypes.number,
  file_url: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  created_at: PropTypes.string.isRequired,
  currentAlbum: PropTypes.string.isRequired,
};

export const JobsPanelComponent = ({ currentAlbum }) => {
  const [jobs, setJobs] = useState([]);
  const api = useApi();
  const dispatch = useDispatch();

  const pollRunningRef = useRef(false);

  const { locked } = useSelector(selectAlbumData);

  const handleLockedChange = async ({ target: { checked } }) => {
    await api.patch(`/albums/${currentAlbum}`, { locked: checked });
    dispatch(setAlbumData({ locked: checked }));
  };

  const poll = useCallback(() => {
    if (!pollRunningRef.current) {
      pollRunningRef.current = true;
      api
        .get(`/render_jobs?album_id=${currentAlbum}`)
        .then(({ data: { render_jobs: jobsFromServer } }) => {
          setJobs(jobsFromServer);
          pollRunningRef.current = false;
        })
        .catch(() => {
          pollRunningRef.current = false;
        });
    }
  }, [api, pollRunningRef, setJobs, currentAlbum]);

  useEffect(() => {
    poll();
    const ivPoll = setInterval(poll, 5 * 1000);
    return () => clearInterval(ivPoll);
  }, [poll]);

  const abort = id => {
    api.delete(`/render_jobs/${id}`).then(poll);
  };

  const addJob = job => {
    setJobs([job, ...jobs]);
  };

  return (
    <div className="scrollable py-3">
      <CreateJob onJobCreate={addJob} />
      <ListGroup className="flex qa-current-jobs" celled>
        {jobs.map(job => (
          <ListGroup.Item key={`album-${job.id}`}>
            <JobItem
              {...job}
              onDelete={() => abort(job.id)}
              currentAlbum={currentAlbum}
            />
          </ListGroup.Item>
        ))}
      </ListGroup>
      <FontSwatchPicker albumId={currentAlbum} />
      <Card className="my-4" bg="light">
        <Card.Header>Einstellungen</Card.Header>
        <Card.Body>
          <Form.Group className="my-2">
            <Form.Check
              type="switch"
              label="Gesperrt"
              checked={locked}
              id="locked-switch"
              name="locked-switch"
              className="qa-locked-switch"
              onChange={handleLockedChange}
            />
          </Form.Group>
        </Card.Body>
      </Card>
    </div>
  );
};

JobsPanelComponent.defaultProps = {
  currentAlbum: null,
};

JobsPanelComponent.propTypes = {
  currentAlbum: PropTypes.string,
};

const mapStateToProps = state => ({
  currentAlbum: state.albums.currentAlbum,
});

export default connect(mapStateToProps)(JobsPanelComponent);
