export const mapIconToElementType = type => {
  switch (type) {
    case 'Group':
      return 'group';
    case 'Image':
      return 'image';
    case 'Text':
      return 'font';
    case 'Rectangle':
      return 'rectangle';
    case 'Circle':
      return 'circle';
    case 'Line':
      return 'line';
    default:
      return 'question';
  }
};

export const getNodeDescription = (type, text, symbol) => {
  if (type !== 'Text') {
    return type;
  }

  if (symbol) {
    return `<${symbol}>`;
  }

  try {
    return text.blocks[0].text;
  } catch {
    return JSON.stringify(text);
  }
};
