import { useCallback, useState } from 'react';
import { useEvent } from 'react-use';

const clientPoint = ({ clientX, clientY }) => ({ x: clientX, y: clientY });

const extractKeys = event => {
  const { shiftKey, altKey, ctrlKey } = event;
  /**
   * In chrome the alt-key moves the focus to its menu, which leads to missing further key-events.
   * Calling `preventDefault` prevents this.
   * https://stackoverflow.com/questions/47780836/detecting-alt-key-in-chrome
   */
  if (altKey) {
    event.preventDefault();
  }
  return { shiftKey, altKey, ctrlKey };
};

export default function useMouseDragCoordinates({ onStart, onUpdate, onEnd }) {
  const [initialPointer, setInitialPointer] = useState(null);
  const [currentPointer, setCurrentPointer] = useState(null);

  const handleMouseDown = useCallback(
    event => {
      if (event.button !== 0) {
        return;
      }

      const pointer = clientPoint(event);
      setInitialPointer(pointer);

      if (!onStart) {
        return;
      }

      onStart({
        ...extractKeys(event),
        currentPointer: pointer,
        event,
      });
    },
    [onStart]
  );

  function handleMouseMove(event) {
    const pointer = clientPoint(event);
    setCurrentPointer(pointer);
    if (!onUpdate) {
      return;
    }

    onUpdate({
      ...extractKeys(event),
      initialPointer,
      currentPointer: pointer,
      event,
    });
  }

  function handleKeyUpAndDown(event) {
    if (!onUpdate) {
      return;
    }

    onUpdate({
      ...extractKeys(event),
      initialPointer,
      currentPointer,
      event,
    });
  }

  function handleMouseUp(event) {
    setInitialPointer(null);
    setCurrentPointer(null);
    if (!onEnd) {
      return;
    }

    onEnd({
      ...extractKeys(event),
      currentPointer: clientPoint(event),
      event,
    });
  }

  useEvent('mousemove', initialPointer && handleMouseMove);
  useEvent('keydown', initialPointer && currentPointer && handleKeyUpAndDown);
  useEvent('keyup', initialPointer && currentPointer && handleKeyUpAndDown);
  useEvent('mouseup', initialPointer && handleMouseUp);

  return {
    initialPointer,
    handleMouseDown,
  };
}
