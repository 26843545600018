import { func, number, objectOf, string } from 'prop-types';
import React, { useState } from 'react';
import { useEvent } from 'react-use';

import useMouseDragCoordinates from '../../../hooks/useMouseDragCoordinates';
import { pointToSpreadIndex } from '../../../util/generators';
import { axisAlignedBounds, rectsIntersect } from '../../../util/geometry';
import { AreaShape, IdListShape, WorkspaceShape } from '../../shapes';
import useViewport from '../Viewport/useViewport';
import performSelection from './performSelection';

const lassoMinSize = 5;

function Selector({
  workspace,
  spreadIds,
  isolationId,
  selectedElementIds,
  spreadsCount,
  lassoSelectableElementAreas,
  lassoSelectableElementIds,
  elementSelect,
  setLassoSpreadIndex,
  updateControls,
  spreadSelect,
}) {
  const { zoom, clientToViewport, viewportRef } = useViewport();
  const [lassoInitialElementIds, setLassoInitialElementIds] = useState(null);
  const [lassoArea, setLassoArea] = useState(null);

  const handleStart = ({ event, shiftKey, currentPointer }) => {
    // Determine clicked element
    const clickedElementId = event.target.closest('.element')?.dataset.id;

    // If no element is clicked, start lasso by setting the initial selection
    if (!clickedElementId) {
      setLassoInitialElementIds(selectedElementIds);
      return;
    }

    // If the element is already selection without modifier-key, ignore this click
    if (selectedElementIds.includes(clickedElementId) && !shiftKey) {
      return;
    }

    // Determine nodes on the same spread as the clicked spread
    const currentPoint = clientToViewport(currentPointer);
    const currentSpreadIndex = pointToSpreadIndex(currentPoint, spreadsCount);
    const parentId = isolationId || spreadIds[currentSpreadIndex];
    const selectableIds = parentId ? workspace.nodes[parentId].children : [];

    // Perform click selection
    performSelection({
      deltaIds: [clickedElementId],
      selectableIds,
      initialIds: selectedElementIds,
      currentIds: selectedElementIds,
      shiftKey,
      elementSelect,
    });
  };

  const handleUpdate = ({ initialPointer, currentPointer, shiftKey }) => {
    const currentPoint = clientToViewport(currentPointer);
    const initialPoint = clientToViewport(initialPointer);

    // Set spread index (updates lassoSelectableElementIds/lassoSelectableElementAreas)
    const lassoSpreadIndex = pointToSpreadIndex(currentPoint, spreadsCount);
    setLassoSpreadIndex(lassoSpreadIndex);

    // Calculate lasso area and if the area is large enough
    const nextLassoArea = axisAlignedBounds([initialPoint, currentPoint]);
    const lassoActive =
      nextLassoArea.width > lassoMinSize / zoom ||
      nextLassoArea.height > lassoMinSize / zoom;

    // Draw lasso and update controls
    setLassoArea(lassoActive ? nextLassoArea : null);
    updateControls({ lassoActive });

    // If the lasso is too small, don't process further
    if (!lassoActive) {
      return;
    }

    // Calculate elements that match the lasso area
    const lassoIds = Object.keys(lassoSelectableElementAreas).filter(id =>
      rectsIntersect(lassoSelectableElementAreas[id], nextLassoArea)
    );

    // Perform lasso selection
    performSelection({
      deltaIds: lassoIds,
      selectableIds: lassoSelectableElementIds,
      initialIds: lassoInitialElementIds,
      currentIds: selectedElementIds,
      shiftKey,
      elementSelect,
    });
  };

  const handleEnd = ({ event }) => {
    // Reset state
    setLassoInitialElementIds(null);
    setLassoArea(null);
    updateControls({ lassoActive: false });

    // If the lasso was used, don't process the click further
    if (lassoArea) {
      return;
    }

    // Clear element selection and update selected spread
    elementSelect([]);
    const clickedSpreadId = event.target.closest('.spread')?.dataset?.id;
    spreadSelect(clickedSpreadId ? [clickedSpreadId] : []);
  };

  const { handleMouseDown } = useMouseDragCoordinates({
    onStart: handleStart,
    onUpdate: lassoInitialElementIds && handleUpdate,
    onEnd: lassoInitialElementIds && handleEnd,
  });

  useEvent('mousedown', handleMouseDown, viewportRef.current);

  return lassoArea && <rect className="selector" {...lassoArea} />;
}

Selector.defaultProps = {
  isolationId: null,
};

Selector.propTypes = {
  workspace: WorkspaceShape.isRequired,
  isolationId: string,
  spreadIds: IdListShape.isRequired,
  selectedElementIds: IdListShape.isRequired,
  spreadsCount: number.isRequired,
  lassoSelectableElementAreas: objectOf(AreaShape).isRequired,
  lassoSelectableElementIds: IdListShape.isRequired,
  elementSelect: func.isRequired,
  setLassoSpreadIndex: func.isRequired,
  updateControls: func.isRequired,
  spreadSelect: func.isRequired,
};

export default Selector;
