import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { setStickerUploads } from '../../../actions/stickerUploads';
import { selectCurrentAlbum } from '../../../selectors/albums';
import useApi from '../../../hooks/useApi';
import useAsyncPolling from '../../../hooks/useAsyncPolling';
import { stickerUploadsPollingInterval } from '../../../constants';
import { selectStickerUploads } from '../../../selectors/stickerUploads';
import { upsertImage } from '../../../actions/images';

function useStickerUploadsPolling() {
  const dispatch = useDispatch();
  const api = useApi();
  const albumId = useSelector(selectCurrentAlbum);
  const stickerUploads = useSelector(selectStickerUploads);
  const stickerUploadsRef = useRef();
  stickerUploadsRef.current = stickerUploads;

  const pollStickerUpload = useCallback(async () => {
    if (!albumId) {
      return;
    }
    const {
      data: { sticker_uploads: nextStickerUploads },
    } = await api.get(`/albums/${albumId}/sticker_uploads`);

    if (!isEqual(stickerUploadsRef.current, nextStickerUploads)) {
      dispatch(setStickerUploads(nextStickerUploads));

      nextStickerUploads.forEach(({ image }) => {
        dispatch(upsertImage(image));
      });
    }
  }, [dispatch, api, albumId, stickerUploadsRef]);

  useAsyncPolling(pollStickerUpload, stickerUploadsPollingInterval);
}

export default useStickerUploadsPolling;
