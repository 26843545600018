import React from 'react';
import { number } from 'prop-types';

import { RectShape } from '../../shapes';

function FaceBox({ face, width: imgWidth, height: imgHeight }) {
  const x = face.x * imgWidth;
  const y = face.y * imgHeight;
  const width = face.width * imgWidth;
  const height = face.height * imgHeight;

  return (
    <g className="qa-face-box">
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="white"
        strokeWidth={0.4}
        strokeLinecap="square"
        fill="none"
        vectorEffect="non-scaling-stroke"
      />
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        stroke="black"
        strokeDasharray="2 2"
        strokeWidth={0.4}
        strokeLinecap="square"
        fill="rgba(0,0,0,0.0)"
        vectorEffect="non-scaling-stroke"
      />
    </g>
  );
}

FaceBox.propTypes = {
  face: RectShape.isRequired,
  width: number.isRequired,
  height: number.isRequired,
};

export default FaceBox;
