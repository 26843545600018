import React, { useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import { shape, bool } from 'prop-types';

import { useFormState } from '../FormStateProvider';

function Data({ config: { requirePosition } }) {
  const { name, lastName, position, setState } = useFormState();
  const nameInputRef = useRef();

  useEffect(() => {
    nameInputRef.current.focus();
  }, []);

  return (
    <Form>
      <Form.Group>
        <Form.Label>Dein Name</Form.Label>
        {/**
         * Why a max-length of 39? These fields are concenated with one space-
         * char and the total max-length is 80 for sticker-names.
         */}
        <Form.Control
          ref={nameInputRef}
          size="lg"
          value={name}
          name="name"
          onChange={({ target: { value } }) => setState('name', value)}
          type="text"
          placeholder="Dein Name"
          maxLength={30}
        />
        <Form.Text className="text-muted">
          Wir passen gut auf deine Daten auf.{' '}
          <a
            href="https://www.stickerstars.de/datenschutz"
            target="_blank"
            rel="noopener noreferrer"
          >
            Hier
          </a>{' '}
          gibt es Informationen zum Datenschutz.
        </Form.Text>
      </Form.Group>
      <Form.Group>
        <Form.Label>Dein Nachname (optional)</Form.Label>
        <Form.Control
          value={lastName}
          name="last_name"
          onChange={({ target: { value } }) => setState('lastName', value)}
          size="lg"
          type="text"
          placeholder="Dein Nachname"
          maxLength={30}
        />
      </Form.Group>
      {requirePosition && (
        <Form.Group>
          <Form.Label>Titel/Position (optional)</Form.Label>
          <Form.Control
            value={position}
            name="position"
            onChange={({ target: { value } }) => setState('position', value)}
            size="lg"
            type="text"
            placeholder="Dein Titel"
            maxLength={30}
          />
        </Form.Group>
      )}
    </Form>
  );
}

Data.propTypes = {
  config: shape({
    requirePosition: bool,
  }),
};

Data.defaultProps = {
  config: {
    requirePosition: false,
  },
};

export default Data;
