import { useEffect, useState } from 'react';

/**
 * Hook triggering a callback once the `targetNode` element has fully been painted
 * (i. e. no mutations have been observed for `idletime` milliseconds).
 */
function useMutationObserver({
  callback,
  targetNode = document.querySelector('body'),
  idleTime = 10000,
  config = { attributes: true, childList: true, subtree: true },
}) {
  const [lastMutationTime, setLastMutationTime] = useState(Date.now());
  const [isFullyPainted, setIsFullyPainted] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (Date.now() - lastMutationTime > idleTime) {
        if (typeof callback === 'function') {
          callback();
        }

        clearInterval(interval);
        setIsFullyPainted(true);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [callback, idleTime, lastMutationTime]);

  /**
   * Effect for setting up + tearing down the observer, every observed
   * mutation updates `lastMutationTime`.
   */
  useEffect(() => {
    const observer = new MutationObserver(() => {
      setLastMutationTime(Date.now());
    });

    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isFullyPainted,
  };
}

export default useMutationObserver;
