import React, { useRef, useState } from 'react';
import { arrayOf, number, shape, string } from 'prop-types';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';

import useQuery from '../../hooks/useQuery';
import useQueryParams from '../../hooks/useQueryParams';
import Icon from '../../components/Icon';

function ConversionsTable({ data, className }) {
  return (
    <Table striped bordered hover size="sm" className={className}>
      <thead>
        <tr>
          <th>Monat</th>
          <th>Anzahl</th>
        </tr>
      </thead>
      <tbody>
        {data.map(({ month, count }) => (
          <tr key={month}>
            <td>{month}</td>
            <td>
              <b>{count}</b>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

ConversionsTable.defaultProps = {
  className: '',
};

ConversionsTable.propTypes = {
  data: arrayOf(
    shape({
      month: string,
      count: number,
    })
  ).isRequired,
  className: string,
};

function AffiliateDashboard() {
  const token = useQueryParams().get('token');
  const { isLoading, data, error } = useQuery(
    `/public/affiliate_conversions${token ? `?url_token=${token}` : ''}`,
    []
  );
  const sharingRef = useRef();
  const [selectedAudience, setSelectedAudience] = useState('wedding');

  const handleCopy = () => {
    sharingRef.current.select();
    document.execCommand('copy');
  };

  const {
    album_conversions: albumConversions,
    order_conversions: orderConversions,
    url_source: source,
  } = data || {};

  const shareUrl =
    selectedAudience === 'wedding'
      ? `https://wedding.stickerstars.de?utm_source=${source}&utm_medium=affiliate-share&utm_campaign=wd-affiliate`
      : `https://business.stickerstars.de?utm_source=${source}&utm_medium=affiliate-share&utm_campaign=bu-affiliate`;

  if (isLoading) {
    return <div>Lade Partneraccount...</div>;
  }

  return (
    <Container>
      <div className="my-5">
        <h2>
          Dein Partneraccount <small className="text-muted">{source}</small>
        </h2>
        {error && (
          <div className="alert alert-danger" role="alert">
            Fehler beim Laden deines Partneraccounts. Bitte versuche es später
            erneut.
          </div>
        )}
        {data && (
          <>
            <Row>
              <Col md={12}>
                <div className="alert alert-primary" role="alert">
                  <p>
                    Über deinen Partnerlink wurden insgesamt{' '}
                    <strong>{albumConversions.total_count} Alben</strong>{' '}
                    erstellt und{' '}
                    <strong>{orderConversions.total_count} Bestellungen</strong>{' '}
                    aufgegeben.
                  </p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card bg="light" text="dark" className="mb-3">
                  <Card.Header>Dein Link</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      Deinen individuellen Partnerlink kannst du teilen, um
                      Provisionen zu verdienen. Alben und Bestellungen, die über
                      diesen Link erstellt werden, werden dir zugeordnet.
                    </Card.Text>
                    <div className="my-3">
                      <small>
                        <Form.Check
                          inline
                          label="Hochzeiten & private Events"
                          type="radio"
                          name="audience"
                          id="audience-wedding"
                          checked={selectedAudience === 'wedding'}
                          onChange={() => setSelectedAudience('wedding')}
                        />
                        <Form.Check
                          inline
                          label="Unternehmen & Geschäftskunden"
                          type="radio"
                          name="audience"
                          id="audience-business"
                          checked={selectedAudience === 'business'}
                          onChange={() => setSelectedAudience('business')}
                        />
                      </small>
                    </div>
                    <InputGroup className="mb-3">
                      <FormControl
                        type="text"
                        className="qa-sticker-uploads-url"
                        value={shareUrl}
                        ref={sharingRef}
                        readOnly
                      />
                      <InputGroup.Append>
                        <Button variant="dark" onClick={handleCopy}>
                          <Icon name="clipboard" />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6}>
                <h5>Alben</h5>
                {albumConversions.total_count > 0 ? (
                  <ConversionsTable
                    data={albumConversions.data}
                    className="qa-monthly-stats-albums"
                  />
                ) : (
                  <div>Keine Alben</div>
                )}
              </Col>
              <Col lg={6} md={6}>
                <h5>Bestellungen</h5>
                {orderConversions.total_count > 0 ? (
                  <ConversionsTable
                    data={orderConversions.data}
                    className="qa-monthly-stats-orders"
                  />
                ) : (
                  <div>Keine Bestellungen</div>
                )}
              </Col>
            </Row>
          </>
        )}
      </div>
    </Container>
  );
}

AffiliateDashboard.defaultProps = {};

AffiliateDashboard.propTypes = {};

export default AffiliateDashboard;
