import { createSelector } from 'reselect';

import { getSingleSelectedElement, getTargetNodeId } from './legacy';
import { getSelectInside } from './selection';

export const getOperationMoveNodeId = state => state.operations.moveNodeId;

export const getOperationInitialValues = state =>
  state.operations.initialValues;

/**
 * This returns the id of the node in which coordinate-space the
 * operation-results are stored. This is basically always the targetNodeId
 * exept for "selectInside"-operations on an image (cx, cy...).
 */
export const getOperationTargetNodeId = createSelector(
  [getSingleSelectedElement, getTargetNodeId, getSelectInside],
  (singleSelectedElement, targetNodeId, selectInside) => {
    const imageInsideSelected =
      selectInside && singleSelectedElement.type === 'Image';
    return imageInsideSelected ? singleSelectedElement.props.id : targetNodeId;
  }
);
