import isEqual from 'lodash/isEqual';
import {
  createSelector,
  createSelectorCreator,
  defaultMemoize,
} from 'reselect';

import {
  itemTypes,
  fonts,
  fontSizeScaleFactor,
  pointToMillimeterFactor,
} from '../constants';
import { flatten, pointToSpreadIndex } from '../util/generators';
import { denormalizeWorkspace } from '../util/workspace';
import { getImage } from './images';
import {
  fontSizes,
  lineHeights,
} from '../components/svg/elements/Text/TextConstants';
import { getPan, getViewportHeight, getZoom } from './viewport';

const identity = param => param;

const createSelectorWithDeepComparison = createSelectorCreator(
  defaultMemoize,
  isEqual
);

// Create memoized selector with deep comparison performed using `isEqual` by lodash
export const createMemoizedSelectorWithDeepComparison = (...params) =>
  createSelectorWithDeepComparison(createSelector(...params), identity);

export const getIsolation = createSelector(
  [state => state.controls.isolation],
  identity
);

export const getWorkspace = createSelector(
  [state => state.workspaceAndStickers.present.workspace],
  identity
);

export const getStickers = createSelector(
  [state => state.workspaceAndStickers.present.stickers],
  identity
);

export const getSections = createMemoizedSelectorWithDeepComparison(
  [getWorkspace],
  workspace => {
    const { root, nodes } = workspace;
    const { children } = nodes[root];
    return children.reduce((acc, cur) => {
      acc.push(nodes[cur].props);
      return acc;
    }, []);
  }
);

export const getStickerIds = createSelector([getSections], sections =>
  sections.flatMap(section => section.stickers.map(sticker => sticker.id))
);

export const getLegacySpreads = createSelector([getWorkspace], workspace => {
  return denormalizeWorkspace(workspace);
});

export const getSectionIds = createSelector(
  [getWorkspace],
  ({ nodes, root }) => {
    return nodes[root].children;
  }
);

export const getSectionNodes = createSelector(
  [getWorkspace, getSectionIds],
  ({ nodes }, sectionIds) => sectionIds.map(id => nodes[id])
);

export const getSectionNodesById = createSelector(
  [getSectionNodes],
  sectionNodes =>
    sectionNodes.reduce((acc, cur) => ({ ...acc, [cur.props.id]: cur }), {})
);

export const getSpreadIds = createSelector([getSectionNodes], sectionNodes => {
  return sectionNodes.reduce((acc, cur) => [...acc, ...cur.children], []);
});

export const getSpreadsCount = createSelector(
  [getSpreadIds],
  spreadIds => spreadIds.length
);

export const makeGetSelectedIds = itemType =>
  createSelector([state => state.selection], selection => selection[itemType]);

export const getSelectedSpreadIds = makeGetSelectedIds(itemTypes.spread);

export const getSelectedStickerIds = makeGetSelectedIds(itemTypes.sticker);

export const getSelectedSectionIds = makeGetSelectedIds(itemTypes.section);

export const internallyGetSelectedElementIds = makeGetSelectedIds(
  itemTypes.element
);

export const internallyGetAllNodeIds = createSelector(
  [getWorkspace],
  ({ nodes }) => Object.keys(nodes)
);

export const getSelectedElementIds = createMemoizedSelectorWithDeepComparison(
  [internallyGetSelectedElementIds, internallyGetAllNodeIds],
  (selectedIds, getAllNodeIds) => {
    return selectedIds.filter(id => getAllNodeIds.includes(id));
  }
);

export const getSelectedSections = createSelector(
  [getSelectedSectionIds, getSections],
  (selectedIds, sections) => {
    return sections.filter(item => selectedIds.includes(item.id));
  }
);

export const getSelectedElements = createSelector(
  [getSelectedElementIds, getWorkspace],
  (selectedElementIds, { nodes }) => {
    return selectedElementIds.map(id => nodes[id]);
  }
);

export const getSingleSelectedElement = state => {
  const selectedElements = getSelectedElements(state);
  const isSingleElement = selectedElements.length === 1;
  if (!isSingleElement) return null;

  const [element] = selectedElements;

  // Return the element itself if the select element is not an image
  const isImage = element.type === 'Image';
  if (!isImage) return element;

  const { image: imageId } = element.props;
  return {
    ...element,
    props: {
      ...element.props,
      imageObject: getImage(state, imageId),
    },
  };
};

export const getSelectedTextElements = createSelector(
  [getSelectedElements],
  selectedElements => selectedElements.filter(item => item.type === 'Text')
);

export const getAllNodes = createSelector([getWorkspace], ({ nodes }) =>
  Object.values(nodes)
);

export const getAllTextNodes = createSelector([getAllNodes], elements =>
  elements.filter(item => item.type === 'Text')
);

export const getSelectedStickers = createSelector(
  [getSelectedStickerIds, getSections],
  (selectedStickerIds, sections) => {
    return flatten(
      sections.map(section =>
        section.stickers.filter(
          sticker => selectedStickerIds.indexOf(sticker.id) !== -1
        )
      )
    );
  }
);

export const getSimplifiedImageNodes = createSelector([getAllNodes], nodes =>
  nodes
    .filter(({ type }) => type === 'Image')
    .map(({ type, props: { id, image, pexelsId } }) => ({
      type,
      props: { id, image, pexelsId },
    }))
);

export const getImageUsage = createMemoizedSelectorWithDeepComparison(
  [getSimplifiedImageNodes],
  imageNodes => {
    return imageNodes.reduce((acc, { props: { image } }) => {
      acc[image] = acc[image] + 1 || 1;
      return acc;
    }, {});
  }
);

export const getLocalStyleMap = text => {
  const styles = flatten(
    text.blocks.map(block =>
      block.inlineStyleRanges
        ? block.inlineStyleRanges.map(range => range.style)
        : []
    )
  );

  return styles
    .filter(item => {
      return (
        item.indexOf('COLOR-#') === 0 || item.indexOf('LETTERSPACING-') === 0
      );
    })
    .reduce((result, name) => {
      const i = name.indexOf('-');
      if (i !== -1) {
        const a0 = name.substring(0, i);
        const a1 = name.substring(i + 1);
        if (a0 === 'COLOR') {
          result[name] = { color: a1 };
        } else if (a0 === 'LETTERSPACING') {
          result[name] = { letterSpacing: `${a1}pt` };
        }
      }
      return result;
    }, {});
};

export const getStyleMap = createSelector(
  [state => state.colorsAndFonts.colors, state => state.colorsAndFonts.fonts],
  (albumColors, albumFonts) => {
    const resolvedPseudoFonts = {
      'FONT-TITLE': { fontFamily: albumFonts?.title },
      'FONT-SUBTITLE': { fontFamily: albumFonts?.sub_title },
      'FONT-PARAGRAPH': { fontFamily: albumFonts?.paragraph },
    };

    const resolvedCustomFonts = fonts.reduce((result, name) => {
      result[`FONT-${name.toUpperCase()}`] = {
        fontFamily: name,
      };
      return result;
    }, {});

    const resolvedLineHeights = lineHeights.reduce(
      (result, { value: lineHeight }) => {
        result[`LINEHEIGHT-${lineHeight}`] = {
          lineHeight,
        };
        return result;
      },
      {
        UPPERCASE: { textTransform: 'uppercase' },
        'LINEHEIGHT-1': { lineHeight: '100%' }, // Fix for small-lineheight issue in PrinceXML`s HTML rendering
      }
    );

    const resolvedColors = albumColors.reduce((result, color) => {
      const index = albumColors.indexOf(color);
      result[`COLOR-${index}`] = { color: `${color}` };
      return result;
    }, {});

    const resolvedFontSizes = fontSizes.reduce((result, size) => {
      result[`SIZE-${size}`] = {
        // pt to mm. scaled x10 up here, scaled down in foreinObject-styles, solving chrome small font size problem
        fontSize: (size * pointToMillimeterFactor) / fontSizeScaleFactor,
      };
      return result;
    }, {});

    return {
      ...resolvedPseudoFonts,
      ...resolvedCustomFonts,
      ...resolvedFontSizes,
      ...resolvedColors,
      ...resolvedLineHeights,
    };
  }
);

export const getStickersCount = createSelector([getStickers], stickers => {
  return stickers.reduce(
    (acc, sticker) => acc + (sticker.doubleSticker ? 2 : 1),
    0
  );
});

/*
This is calculating values used in the preflight-check before a PDF is rendered. In order for a print, the page-count
needs to be dividable by four, so the number of inlay-spreads (2 pages each) needs to be dividable by 2:
(see https://opusdesign.us/wordcount/printing-multiples-of-four/)
 */
export const getAlbumStatisticsForPreflight = createSelector(
  [getSpreadsCount],
  spreadCount => {
    const inlaySpreadsCount = spreadCount > 0 ? spreadCount - 1 : 0;

    // Calculating the book spine, based on a formula from our print shop
    let spine = Math.ceil((inlaySpreadsCount - 2) * 0.14 * 10) / 10;
    if (spine < 0) {
      spine = 0;
    }

    // The number of inlay-spreads needed until the number of inlay-spreads is dividable by 2
    const spreadsNeeded = inlaySpreadsCount % 2;

    return { inlaySpreadsCount, spreadsNeeded, spine };
  }
);

export const getViewCenterIndex = createSelector(
  [getSpreadsCount, getPan, getZoom, getViewportHeight],
  (spreadsCount, pan, zoom, viewportHeight) => {
    const viewCenterIndex = pointToSpreadIndex(
      { x: 0, y: pan.y + viewportHeight / zoom / 2 },
      spreadsCount
    );
    return viewCenterIndex;
  }
);

export const getTargetSpreadId = createSelector(
  [
    getSpreadIds,
    getWorkspace,
    getViewCenterIndex,
    getSelectedSpreadIds,
    getSelectedElementIds,
    getIsolation,
  ],
  (
    spreadIds,
    { root, nodes },
    viewCenterIndex,
    selectedSpreadIds,
    selectedElementIds,
    isolation
  ) => {
    if (isolation) {
      return nodes[isolation].parent;
    }
    if (selectedElementIds.length > 0) {
      return nodes[selectedElementIds[0]].parent;
    }
    if (selectedSpreadIds.length > 0 && nodes[selectedSpreadIds[0]]) {
      return selectedSpreadIds[0];
    }
    if (viewCenterIndex !== -1) {
      const viewCenterSpreadId = spreadIds[viewCenterIndex];
      if (!viewCenterSpreadId) {
        return root;
      }
      return viewCenterSpreadId;
    }
    return root;
  }
);

export const getTargetSpreadIndex = createSelector(
  [getSpreadIds, getTargetSpreadId],
  (spreadIds, targetSpreadId) => {
    return spreadIds.indexOf(targetSpreadId);
  }
);

export const getTargetNodeId = createSelector(
  [getIsolation, getTargetSpreadId],
  (isolation, targetSpreadId) => {
    if (isolation) {
      return isolation;
    }
    return targetSpreadId;
  }
);

export const getTargetNode = createSelector(
  [getWorkspace, getTargetNodeId],
  ({ nodes }, targetNodeId) => {
    return nodes[targetNodeId];
  }
);

export const getLockedElementIds = createSelector(
  [getTargetNodeId, getWorkspace],
  (nodeId, { nodes }) => {
    return nodes[nodeId].children.filter(id => nodes[id].props.locked);
  }
);

export const getTableOfContents = createSelectorWithDeepComparison(
  [getSections, getWorkspace],
  (sections, { nodes }) =>
    sections.reduce(
      ({ toc, page }, section) => {
        const { name, id, static: staticSection } = section;
        const nextToc = staticSection ? toc : [...toc, { name, page }];
        const count = nodes[id].children.length;
        const nextPage = page + count * (staticSection ? 1 : 2);
        return { page: nextPage, toc: nextToc };
      },
      { toc: [], page: 1 }
    ).toc
);

export const getTocSections = createSelector([getTableOfContents], toc => {
  return toc.reduce((acc, cur) => `${acc + cur.name}\n`, '');
});

export const getTocPages = createSelector([getTableOfContents], toc => {
  return toc.reduce((acc, cur) => `${acc + cur.page}\n`, '');
});

export const makeLoadingSelector = blocking =>
  createSelector([state => state.loading], loading => {
    return loading.filter(item => item.blocking === blocking).length > 0;
  });

export const getAppearanceFromProps = createSelector(
  [
    props => props.fill,
    props => props.fillOpacity,
    props => props.stroke,
    props => props.strokeWidth,
    props => props.opacity,
    props => props.colors,
  ],
  (fill, fillOpacity, stroke, strokeWidth, opacity, colors) => {
    if (fill !== null && fill !== undefined) {
      if (typeof fill === 'number') {
        fill = colors[fill];
      }
    } else {
      fill = 'none';
    }

    if (stroke !== null && stroke !== undefined) {
      if (typeof stroke === 'number') {
        stroke = colors[stroke];
      }
    } else {
      stroke = 'none';
    }
    return { fill, fillOpacity, stroke, strokeWidth, opacity };
  }
);

export const getInteractiveParentIds = createSelector(
  [getSpreadIds, getIsolation],
  (spreadIds, isolation) => {
    if (isolation) {
      return [isolation];
    }
    return spreadIds;
  }
);
