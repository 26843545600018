import useAppEnvironment from './useAppEnvironment';

export const tutorialBaseUrl = 'https://docs.stickerstars.de';
const buildUrl = resource =>
  `${resource.indexOf('http') === -1 ? `${tutorialBaseUrl}/` : ''}${resource}`;

export const tutorialPermalinks = {
  wedding: {
    faq: 'https://wedding.stickerstars.de/faq',
    budget: 'https://wedding.stickerstars.de/preise',
  },
  business: {
    faq: 'https://business.stickerstars.de/informationen/faq',
    budget: 'https://business.stickerstars.de/angebot-erstellen',
  },
  default: {
    start: '',
    stickerlink: 'erstellung-der-sticker/sticker-einsammeln',
    stock: 'gestaltung-des-albums/stock-bibliothek-nutzen',
    layouts: 'gestaltung-des-albums/seitenlayouts-verwenden',
    sections: 'erstellung-der-sticker/kapitel-verwalten',
    viewport: 'grundlagen/bedienung',
    stickerPlacement: 'erstellung-der-sticker/sticker-platzieren',
    checkout: 'bestellung-und-lieferung/bestellung',
    images: 'gestaltung-des-albums/hintergrundbilder-platzieren',
    faq: 'https://wedding.stickerstars.de/faq',
    budget: 'https://wedding.stickerstars.de/preise',
  },
};

function useTutorial() {
  const { environment } = useAppEnvironment();

  const linkTo = resource =>
    buildUrl(
      tutorialPermalinks[environment]?.[resource] ||
        tutorialPermalinks.default[resource]
    );

  return {
    linkTo,
  };
}

export default useTutorial;
