import { createSelector } from 'reselect';

import { getWorkspace } from './legacy';
import Matrix from '../util/Matrix';

export const computeTransformationMatrix = (node, nodes) => {
  if (!node) {
    return new Matrix();
  }

  const { props, parent: parentNodeId } = node;
  const { x = 0, y = 0, rotation = 0, scale = 1 } = props;
  const parentNode = nodes[parentNodeId];

  return computeTransformationMatrix(parentNode, nodes)
    .translate(x, y)
    .scale(scale)
    .rotate(rotation);
};

/*
 * Make a selector that returns the SVG transformation matrix
 * for the given node relativ to the spread it is located on.
 */
export const makeGetTransformationMatrix = () => {
  return createSelector(
    [getWorkspace, (_, nodeId) => nodeId],
    ({ nodes }, nodeId) => {
      const node = nodes[nodeId];

      const matrix = computeTransformationMatrix(node, nodes);

      /** Enable memoization by converting to an Array. */
      return matrix.toArray().toString();
    }
  );
};

export default makeGetTransformationMatrix;
