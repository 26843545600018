// TODO: Add prop-type definitions
/* eslint-disable react/prop-types */

import React, { useEffect } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import rangeParser from 'parse-numeric-range';

import {
  API_URL,
  renderModes,
  renderFormats,
  resolutions,
} from '../../constants';
import StickerRenderer from '../../components/svg/StickerRenderer';
import { receiveAlbum, setCurrentAlbum } from '../../modules/albums';
import { updateControls } from '../../modules/controls';
import { ImageContext } from '../../components/ImageContext';
import { objectMap } from '../../util/index';
import SpreadContainerRenderer from '../../components/svg/SpreadContainerRenderer';
import { selectWebFontsLoaded } from '../../selectors/controls';

// todo move sanitizer, use shared code with api/render/index.js?
function parseRange(range) {
  if (typeof range !== 'string' || range === '') {
    return null;
  }
  return rangeParser.parse(range);
}

function sanitize(values, allowed) {
  return objectMap(values, (value, key) => {
    const allow = allowed[key];
    if (Array.isArray(allow)) {
      if (allow.indexOf(value) === -1) {
        return allow[0];
      }
    } else if (typeof allow === 'function') {
      return allow(value);
    } else if (allow && typeof value !== allow) {
      return null;
    }
    return value;
  });
}

function parseBool(value) {
  const int = parseInt(value, 10);
  if (isNaN(int)) {
    return value === 'true';
  }
  return int === 1;
}

function Renderer({ location }) {
  const dispatch = useDispatch();
  const vars = queryString.parse(location.search);
  const webFontsLoaded = useSelector(selectWebFontsLoaded);

  const {
    token,
    album_id: albumId,
    api_url: apiUrl,
    mode,
    format,
    resolution,
    showStickers,
    includeCover,
    includeBleed,
    range,
  } = sanitize(vars, {
    album_id: 'string',
    api_url: 'string',
    token: 'string',
    mode: Object.keys(renderModes),
    format: Object.keys(renderFormats),
    resolution: Object.keys(resolutions),
    showStickers: parseBool,
    includeCover: parseBool,
    includeBleed: parseBool,
    range: parseRange,
  });

  useEffect(() => {
    if (!albumId || !token || !webFontsLoaded) {
      return;
    }

    dispatch(setCurrentAlbum(albumId));
    dispatch(updateControls({ selectedIds: [], cropPreview: false }));

    (async () => {
      const {
        data: { album },
      } = await axios.get(
        `${apiUrl || API_URL}/albums/${albumId}/render_data`,
        {
          headers: {
            'X-Render-Token': token,
          },
        }
      );
      dispatch(receiveAlbum(album));
    })();
  }, [dispatch, vars, albumId, token, apiUrl, webFontsLoaded]);

  if (mode === 'stickers') {
    return (
      <ImageContext.Provider
        value={{
          rendering: true,
          stickerRendering: true,
          cropPreview: false,
          showGuides: false,
          resolution,
          showStickers,
          mode,
        }}
      >
        <StickerRenderer
          mode={mode}
          range={range}
          format={format}
          includeBleed={includeBleed}
        />
      </ImageContext.Provider>
    );
  }
  return (
    <ImageContext.Provider
      value={{
        rendering: true,
        stickerRendering: showStickers,
        resolution,
        showStickers,
        cropPreview: format === 'zip',
        showGuides: false,
        mode,
      }}
    >
      <SpreadContainerRenderer
        mode={mode}
        format={format}
        range={range}
        includeCover={includeCover}
        includeBleed={includeBleed}
      />
    </ImageContext.Provider>
  );
}

export default Renderer;
