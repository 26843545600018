import defaultDoubleCell from './default/default_double_cell.json';
import defaultDoubleCellLabel from './default/default_double_cell_label.json';
import defaultSingleCell from './default/default_single_cell.json';
import defaultSingleCellTextless from './default/default_single_cell_textless.json';
import defaultSingleCellSubtitle from './default/default_single_cell_subtitle.json';
import defaultDoubleSticker from './default/default_double_sticker.json';
import defaultDoubleStickerFull from './default/default_double_sticker_full.json';
import defaultSingleSticker from './default/default_single_sticker.json';
import defaultSingleStickerTextless from './default/default_single_sticker_textless.json';
import defaultSingleStickerFull from './default/default_single_sticker_full.json';
import defaultSingleCellNumberless from './default/default_single_cell_numberless.json';
import defaultSingleStickerNumberless from './default/default_single_sticker_numberless.json';
import defaultDoubleCellNumberless from './default/default_double_cell_numberless.json';
import defaultDoubleStickerNumberless from './default/default_double_sticker_numberless.json';
import defaultSingleStickerNumberlessFull from './default/default_single_sticker_nl_full.json';
import accentedDoubleSticker from './accented/accented_double_sticker.json';
import accentedSingleSticker from './accented/accented_single_sticker.json';
import accentedSingleStickerFull from './accented/accented_single_sticker_full.json';
import accentedDoubleStickerFull from './accented/accented_double_sticker_full.json';
import themedDoubleSticker from './themed/themed_double_sticker.json';
import themedSingleSticker from './themed/themed_single_sticker.json';
import themedSingleStickerFull from './themed/themed_single_sticker_full.json';
import triangleDoubleSticker from './triangle/triangle_double_sticker.json';
import triangleSingleSticker from './triangle/triangle_single_sticker.json';
import triangleSingleStickerFull from './triangle/triangle_single_sticker_full.json';
import goldDoubleSticker from './gold/gold_double_sticker.json';
import goldSingleSticker from './gold/gold_single_sticker.json';
import goldSingleStickerFull from './gold/gold_single_sticker_full.json';
import tiltedDoubleSticker from './tilted/tilted_double_sticker.json';
import tiltedSingleSticker from './tilted/tilted_single_sticker.json';
import tiltedSingleStickerFull from './tilted/tilted_single_sticker_full.json';

export default {
  default: {
    single: {
      sticker: {
        default: defaultSingleSticker,
        textless: defaultSingleStickerTextless,
        full: defaultSingleStickerFull,
        numberless: defaultSingleStickerNumberless,
        numberlessFull: defaultSingleStickerNumberlessFull,
      },
      cell: {
        default: defaultSingleCell,
        textless: defaultSingleCellTextless,
        subtitle: defaultSingleCellSubtitle,
        numberless: defaultSingleCellNumberless,
      },
    },
    double: {
      sticker: {
        default: defaultDoubleSticker,
        full: defaultDoubleStickerFull,
        numberless: defaultDoubleStickerNumberless,
      },
      cell: {
        default: defaultDoubleCell,
        withLabel: defaultDoubleCellLabel,
        numberless: defaultDoubleCellNumberless,
      },
    },
  },
  accented: {
    single: {
      sticker: {
        default: accentedSingleSticker,
        full: accentedSingleStickerFull,
      },
      cell: {
        default: defaultSingleCell,
        textLess: defaultSingleCellTextless,
        subtitle: defaultSingleCellSubtitle,
      },
    },
    double: {
      sticker: {
        default: accentedDoubleSticker,
        full: accentedDoubleStickerFull,
      },
      cell: {
        default: defaultDoubleCell,
        withLabel: defaultDoubleCellLabel,
      },
    },
  },
  themed: {
    single: {
      sticker: {
        default: themedSingleSticker,
        full: themedSingleStickerFull,
      },
      cell: {
        default: defaultSingleCell,
        textLess: defaultSingleCellTextless,
        subtitle: defaultSingleCellSubtitle,
      },
    },
    double: {
      sticker: {
        default: themedDoubleSticker,
      },
      cell: {
        default: defaultDoubleCell,
        withLabel: defaultDoubleCellLabel,
      },
    },
  },
  triangle: {
    single: {
      sticker: {
        default: triangleSingleSticker,
        triangleSingleStickerFull,
      },
      cell: {
        default: defaultSingleCell,
        textLess: defaultSingleCellTextless,
        subtitle: defaultSingleCellSubtitle,
      },
    },
    double: {
      sticker: {
        default: triangleDoubleSticker,
      },
      cell: {
        default: defaultDoubleCell,
        withLabel: defaultDoubleCellLabel,
      },
    },
  },
  gold: {
    single: {
      sticker: {
        default: goldSingleSticker,
        full: goldSingleStickerFull,
      },
      cell: {
        default: defaultSingleCell,
        textless: defaultSingleCellTextless,
        subtitle: defaultSingleCellSubtitle,
      },
    },
    double: {
      sticker: {
        default: goldDoubleSticker,
      },
      cell: {
        default: defaultDoubleCell,
        withLabel: defaultDoubleCellLabel,
      },
    },
  },
  tilted: {
    single: {
      sticker: {
        default: tiltedSingleSticker,
        full: tiltedSingleStickerFull,
      },
      cell: {
        default: defaultSingleCell,
        textLess: defaultSingleCellTextless,
        subtitle: defaultSingleCellSubtitle,
      },
    },
    double: {
      sticker: {
        default: tiltedDoubleSticker,
      },
      cell: {
        default: defaultDoubleCell,
        withLabel: defaultDoubleCellLabel,
      },
    },
  },
};
