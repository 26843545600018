import { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getSelectedElements } from '../selectors/legacy';
import { getAxisAlignedBounds } from '../util/geometry';
import { getAnimatedPan, getAnimatedZoom } from '../selectors/viewport';

export default function useSelectionBounds(targetSelector = null) {
  const pan = useSelector(getAnimatedPan);
  const zoom = useSelector(getAnimatedZoom);
  const selectedElements = useSelector(getSelectedElements);
  const [bounds, setBounds] = useState({});

  useLayoutEffect(() => {
    setBounds(getAxisAlignedBounds(selectedElements, targetSelector));
  }, [selectedElements, targetSelector, pan.x, pan.y, zoom]);

  return bounds;
}
