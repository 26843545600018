import { resolutions } from './constants';

const exportPresets = {
  album_softcover_inside: {
    label: 'Album Softcover Inside',
    mode: 'album_softcover_inside',
    format: 'pdf',
    resolution: resolutions.original,
    dpi: 300,
    showStickers: false,
    includeCover: false,
    includeBleed: true,
    compress: true,
    range: '',
    custom: false,
    preset: 'album_softcover_inside',
  },
  album_softcover: {
    label: 'Album Softcover',
    mode: 'album_softcover',
    format: 'pdf',
    resolution: resolutions.original,
    dpi: 600,
    showStickers: false,
    includeCover: false,
    includeBleed: true,
    compress: true,
    range: '',
    custom: false,
    preset: 'album_softcover',
  },
  stickers: {
    label: 'Stickers',
    mode: 'stickers',
    format: 'pdf',
    resolution: resolutions.full,
    dpi: 300,
    showStickers: true,
    includeCover: false,
    includeBleed: true,
    compress: true,
    range: '',
    custom: false,
    preset: 'stickers',
  },
  stickerpack: {
    label: 'Stickerpack',
    mode: 'stickerpack',
    format: 'pdf',
    resolution: resolutions.full,
    dpi: 300,
    showStickers: false,
    includeCover: true,
    includeBleed: true,
    compress: true,
    range: '',
    custom: false,
    preset: 'stickerpack',
  },
  album_check: {
    label: 'Check: Album',
    mode: 'album_softcover_inside',
    format: 'jpg',
    resolution: resolutions.full,
    dpi: 200,
    showStickers: false,
    includeCover: true,
    includeBleed: false,
    compress: true,
    range: '',
    custom: false,
    preset: 'album_check',
  },
  sticker_check: {
    label: 'Check: Sticker',
    mode: 'sticker',
    format: 'jpg',
    resolution: resolutions.full,
    dpi: 200,
    showStickers: true,
    includeCover: false,
    includeBleed: false,
    compress: true,
    range: '',
    custom: false,
    preset: 'sticker_check',
  },
  current_spread: {
    label: 'Check: Aktuelle Doppelseite',
    mode: 'spread',
    format: 'pdf',
    resolution: resolutions.full,
    dpi: 300,
    showStickers: false,
    includeCover: true,
    includeBleed: false,
    compress: true,
    custom: false,
    preset: 'current_spread',
  },
  custom: { label: 'Custom...', custom: true, preset: 'custom' },
};

export default exportPresets;
