import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import * as Sentry from '@sentry/react';

import reducer from './modules';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const sentryReduxEnhancer = Sentry.createReduxEnhancer();
const middlewares = [thunk];
const enhancers = composeEnhancers(
  applyMiddleware(...middlewares),
  sentryReduxEnhancer
);

export default createStore(reducer, enhancers);
